import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import AtivadoresChart from '../../../Components/dash/ativadores'
import Indicador from '../../../Components/dash/indicador'
import TabelaAtivadores from '../../../Components/dash/tabelaAtivadoresLocal'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage8,
  TitlePage9
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import okImg from '../../../Images/ok.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import Modal from '../ModalObservações/ModalObsLocal'

var user = authService.getCurrentUser()

function MesSetor() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const dateAtual = new Date()
  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
    sessionStorage.DataFim = new Date()
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    window.location.reload()
  }
  let id = useParams()
  sessionStorage.IdLocalArea = id['IdLocal']
  const [unidades, setUnidades] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/unidade/' + id['IdUnidade'])
      .then(response => {
        setUnidades(response.data)
      })
  }, [])
  const [observacaotipos, setObservacaotipos] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        response.data.some(function (item) {
          if (sessionStorage.IdObservacaoTipo === undefined) {
            response.data.forEach(element => {
              if (element.otpStatus === 1) {
                if (sessionStorage.IdObservacaoTipo === undefined) {
                  sessionStorage.IdObservacaoTipo = element.IdObservacaoTipo
                }
              }
            })
          }
        })

        setObservacaotipos(response.data)
      })
  }, [])
  const [dashLocais, setdashLocais] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL + '/api/v1/dashLocais/' + id['IdUnidade']
      )
      .then(response => {
        setdashLocais(response.data)
      })
  }, [])
  const [dashLocal, setdashLocal] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/dashLocal/' +
      sessionStorage.DataInicio +
      '/' +
      sessionStorage.DataFim

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setdashLocal(response.data)
    })
  }, [])
  const [metaLocaisMes, setmetaLocaisMes] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashLocais/meta/' +
        id['IdLocal']
      )
      .then(response => {
        setmetaLocaisMes(response.data)
      })
  }, [])
  const [Locais, setLocais] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/area/' + id['IdLocal'])
      .then(response => {
        setLocais(response.data)
      })
  }, [])

  const [searchTerm, setSearchTerm] = useState(sessionStorage.IdObservacaoTipo)
  function handleObsTipoChange(event) {
    sessionStorage.IdObservacaoTipo = event.target.value

    sessionStorage.ObservacaoDesc =
      event.target.options[event.target.selectedIndex].text
    window.location.reload()
  }
  let history = useHistory()
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  const [ativador, setAtivador] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/ativadoresLocal/' +
      sessionStorage.DataInicio +
      '/' +
      sessionStorage.DataFim +
      '/' +
      id['IdLocal'] +
      '/' +
      sessionStorage.IdObservacaoTipo

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setAtivador(response.data)
    })
  }, [])
  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <Modal />
          <div className="logo" />
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>

          <TitleOfPage>
            <TitlePage1>
              {sessionStorage.uniRazaoSocial} /
              <Translate> RELATÓRIO / SETORES</Translate>:
            </TitlePage1>
            {Locais.map(local => (
              <TitlePage9>
                {sessionStorage.uniRazaoSocial} / {local.locDescricao}
              </TitlePage9>
            ))}
            <TitlePage8>
              <Translate> Observações realizadas</Translate>:
              {dashLocal
                .filter(val => {
                  if (
                    searchTerm === '' &&
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                  ) {
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                  ) {
                    return val
                  }
                })
                .map(unidade => (
                  <label className="subtRel3">
                    {unidade.mtlQuantitativaReal}
                  </label>
                ))}
            </TitlePage8>
          </TitleOfPage>
          <div className="tabelaPadraoCad1">
            <div className="DivisorTabelaPadrao">
              {metaLocaisMes
                .filter(val => {
                  if (
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo) &&
                    parseInt(val.IdLocal) === parseInt(id['IdLocal'])
                  ) {
                    return val
                  }
                })
                .map(local => (
                  <button
                    className={
                      parseFloat(local.rtqPontuacao) === 0
                        ? 'relProjetado ics-cor-cinza'
                        : parseFloat(local.rtqPontuacao) < 70
                          ? 'relProjetado ics-cor-vermelho'
                          : parseFloat(local.rtqPontuacao) < 89
                            ? 'relProjetado ics-cor-amarelo'
                            : 'relProjetado ics-cor-verde'
                    }
                  >
                    <img className="statusRel" src={okImg} alt="okImg" />
                    <span data-tooltip="Projetado">
                      <label htmlFor="" className="TittleProjetadoAcumulado">
                        <Translate>Projetado</Translate>
                      </label>
                    </span>
                    <label className="FuncaoResultado">
                      {parseFloat(local.mtlQualitativa).toFixed(2)}%
                    </label>
                    <label className="FuncaoMetaP">
                      <Translate>Meta </Translate>(Qtde):
                      <br />
                      {local.mtlQuantitativa}
                    </label>
                  </button>
                ))}
              {dashLocais
                .filter(val => {
                  if (
                    searchTerm === '' &&
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                  ) {
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                  ) {
                    return val
                  }
                })
                .map(local => (
                  <button
                    className={
                      parseFloat(local.rtqPontuacao) === 0
                        ? 'relProjetado ics-cor-cinza'
                        : parseFloat(local.rtqPontuacao) < 70
                          ? 'relProjetado ics-cor-vermelho'
                          : parseFloat(local.rtqPontuacao) < 90
                            ? 'relProjetado ics-cor-amarelo'
                            : 'relProjetado ics-cor-verde'
                    }
                  >
                    <Indicador
                      p_resultado={parseFloat(local.rtqPontuacao).toFixed(2)}
                      p_titulo={'Acumulado'}
                      p_meta={local.mtlQuantitativa}
                      p_real={local.mtlQuantitativaReal}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </button>
                ))}{' '}
            </div>
            <div className="relPizza">
              <div className="divisor1">
                <AtivadoresChart data={ativador} />
              </div>
              <div className="divisor2">
                <TabelaAtivadores />
              </div>
            </div>
          </div>
          <div className="TittleIndice">
            <Translate>Resultado por Mês/Setor da Empresa</Translate>:
          </div>
          <div className="tabelaPadraoICS">
            {dashLocal
              .filter(val => {
                if (
                  searchTerm === '' &&
                  parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                ) {
                  return val
                } else if (
                  parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                  parseInt(val.IdLocalArea) === parseInt(id['IdLocal'])
                ) {
                  return val
                }
              })
              .map(local => (
                <Link
                  className={
                    parseFloat(local.rtqPontuacao) === 0
                      ? 'UniClienteMes ics-cor-cinza'
                      : parseFloat(local.rtqPontuacao) < 70
                        ? 'UniClienteMes ics-cor-vermelho'
                        : parseFloat(local.rtqPontuacao) < 90
                          ? 'UniClienteMes ics-cor-amarelo'
                          : 'UniClienteMes ics-cor-verde'
                  }
                  to={`/UniContribSetor/${local.IdLocalArea}`}
                >
                  <Indicador
                    p_resultado={parseFloat(local.rtqPontuacao).toFixed(2)}
                    p_titulo={local.rtoIniciadoEm}
                    p_meta={local.mtlQuantitativa}
                    p_real={local.mtlQuantitativaReal}
                    p_pesIdioma={user[0].pesIdioma}
                  />
                </Link>
              ))}
          </div>
          <div className="cxLegenda">
            <label htmlFor="" className="lgHLegenda">
              <Translate>Legenda:</Translate>
            </label>
            <button className="lgHAcima">
              <Translate>Esperado</Translate>
            </button>
            <button className="lgHMedia">
              <Translate>Atenção</Translate>
            </button>
            <button className="lgHAbaixo">
              <Translate>Risco</Translate>
            </button>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <ul className="nav-menu1-items">
            <li className="navbar-toggle">
              <div className="Profile-direita">
                <div className="cxProfile">
                  <h6 className="titleProfile1">
                    {' '}
                    <Translate>Seja Muito Bem-vindo,</Translate>
                  </h6>
                  <h6 className="titleProfile2">{user[0].pesNome}</h6>
                  <div className="alignFotoProfile">
                    {image ? (
                      <img className="logo1" src={image} alt="perfil" />
                    ) : (
                      <img src={padrao} alt="Profile" className="logo1" />
                    )}
                    {/* <Link to="/ViewProfile">
                      <button
                        className="profile-profileEditor"
                      // onClick={() => handleToggleComplete(user[0])}
                      >
                        <Translate>Ver perfil</Translate>
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              <aside className="calendarRelatorio1">
                <h1 className="selectAnalise">
                  <Translate>Selecione</Translate>:
                </h1>
                <form action="">
                  <label className="SelectCliente" htmlFor="">
                    <Translate>Cliente/Unidade</Translate>:
                  </label>
                  <select name="IdUnidade" className="SelectIdCliente">
                    {unidades
                      .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                      .map(unidade => (
                        <option
                          value={unidade.IdUnidade}
                          selected={
                            parseInt(unidade.IdUnidade) ===
                            parseInt(sessionStorage.IdUnidade)
                          }
                        >
                          {unidade.uniNome}
                        </option>
                      ))}
                  </select>
                </form>
                <form action="">
                  <label className="SelectObserv" htmlFor="">
                    <Translate>Tipo de Observação</Translate>:
                  </label>
                  <label className="SelectDate">
                    <Translate>Periodo Analisado</Translate>:
                  </label>
                  <select
                    name="SelectIdObserv"
                    className="SelectIdObserv"
                    onChange={event => {
                      setSearchTerm(event.target.value)
                      handleObsTipoChange(event)
                    }}
                  >
                    {observacaotipos
                      .sort((a, b) =>
                        a.otpDescricao > b.otpDescricao ? 1 : -1
                      )
                      .filter(val => {
                        if (val.otpStatus === 1) {
                          return val
                        }
                      })
                      .map(observacaotipo => (
                        <option
                          value={observacaotipo.IdObservacaoTipo}
                          selected={
                            parseInt(observacaotipo.IdObservacaoTipo) ===
                            parseInt(sessionStorage.IdObservacaoTipo)
                          }
                        >
                          {observacaotipo.otpDescricao}
                        </option>
                      ))}
                  </select>
                  <label className="SelectDate">
                    <Translate>Periodo Analisado</Translate>:
                  </label>

                  <div className="calendarioAnalise1">
                    <Calendar
                      calendarType="Hebrew"
                      onChange={setSeacrhDate}
                      value={date}
                      selectRange={true}
                    />
                  </div>
                </form>
              </aside>
              {user[0].gruDescricao === 'Humanit' ? (
                <SidebarMobile />
              ) : user[0].gruDescricao === 'Administrativo' ? (
                <SidebarMobile />
              ) : (
                <div></div>
              )}
              <img className="logologo" src={logologo} alt="logo" />
            </li>
          </ul>
        </nav>
      </Translator>
    </div>
  )
}

export default MesSetor
