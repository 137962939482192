import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function ModalFuncaoEPermissao({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()

  function handleInputChange(event) {
    if (event.target.name === 'fncStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdFuncao: modalInfo.IdFuncao,
    IdEmpresa: modalInfo.IdEmpresa,
    fncDescricao: modalInfo.fncDescricao,
    fncObservacoes: modalInfo.fncObservacoes,
    fncStatus: modalInfo.fncStatus,
    fncAlteradoPor: user[0].pesEmail,
    fncAlteradoEm: newDate
  })
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/v1/funcao/' + campos.IdFuncao,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Função</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Descrição Da Função</Translate>:
              </label>
              <input
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                defaultValue={campos.fncDescricao}
                name="fncDescricao"
                onChange={handleInputChange}
              ></input>

              <label>
                <Translate>Observação</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                rows={4}
                style={{textTransform:'uppercase'}}
                defaultValue={campos.fncObservacoes}
                name="fncObservacoes"
                onChange={handleInputChange}
                row="none"
                maxLength="500"
              ></textarea>
              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="fncStatus"
                      type="checkbox"
                      defaultChecked={!!campos.fncStatus}
                      onChange={handleInputChange}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
          </div>
        </Translator>
      </div>
    </>
  )
}
