import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import { ContainerPage, CxTitle, TitlePage2 } from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SideBarDireita from '../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'

function ObservacoesApontar() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  var user = authService.getCurrentUser()
  let history = useHistory()
  let id = useParams()
  var IdUnidade = parseInt(id['idUni'])
  var IdLocalArea = parseInt(id['idAr'])
  const [observacaogrupos, setObservacaoGrupo] = useState([])
  Moment.locale('pt-br')
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoobservacao/list/' +
        user[0].IdEmpresa +
        '/' +
        IdUnidade +
        '/' +
        IdLocalArea
      )
      .then(response => {
        setObservacaoGrupo(response.data)
      })
  }, [])

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div className="logo" />
          <div>
            <input
              className="fCxPesquisa"
              placeholder="Pesquisar"
              type="text"
              id="fname1"
              name="fname"
              onChange={event => {
                setSearchTerm(event.target.value)
              }}
            />
          </div>
          <Link
            to={
              '/ApontarObservacao/PreenchimentoObservacao/' +
              IdUnidade +
              '/' +
              IdLocalArea
            }
          >
            <button className="buttonModalPadrao">
              <Translate>Nova Observação</Translate>
            </button>
            <button
              className="btnReturn"
              type="button"
              onClick={() => history.goBack()}
            >
              <TypIcons.TiArrowBackOutline />
            </button>
          </Link>
          <CxTitle>
            <TitlePage2>
              {' '}
              {sessionStorage.uniRazaoSocial} /{' '}
              <Translate>Observações Realizadas</Translate>:
            </TitlePage2>
          </CxTitle>
          <div className="tabelaPadrao">
            <section className="">
              <div className="containerPadrao">
                <thead>
                  <tr className="header">
                    <th>
                      <div>CÓD</div>
                    </th>
                    <th>
                      <div>
                        <Translate>Observação</Translate>
                      </div>
                    </th>
                    <th>
                      <div>
                        <Translate>Status da Observação</Translate>
                      </div>
                    </th>
                    <th>
                      <div>
                        <Translate>Data da Observação</Translate>
                      </div>
                    </th>
                  </tr>
                </thead>
                <table>
                  {observacaogrupos
                    .filter(val => {
                      if (searchTerm === '') {
                        return val
                      } else if (
                        val.ogrDescricao
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val
                      }
                    })
                    .map(observacaogrupo => (
                      <tbody key={observacaogrupo.IdRetornoObservacao}>
                        <Link
                          className="tBodyStyle"
                          to={
                            '/RetornoQuestao/' +
                            IdUnidade +
                            '/' +
                            IdLocalArea +
                            '/' +
                            observacaogrupo.IdRetornoObservacao
                          }
                        >
                          <td className="tblGerCod1">
                            {observacaogrupo.IdRetornoObservacao}
                          </td>
                          <td className="tblGerDesc">
                            <Translate>
                              {observacaogrupo.atvDescricao}
                            </Translate>
                          </td>
                          <td className="tblGerUni1">
                            {observacaogrupo.rtoFinalizadoEm === ''
                              ? 'Contém Pendências'
                              : 'Observação Concluída'}
                          </td>
                          <td className="BtnDescFun1">
                            {Moment(observacaogrupo.rtoIniciadoEm).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                          </td>
                        </Link>
                      </tbody>
                    ))}
                </table>
              </div>
            </section>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ------------------------------------------------------------------------------------------------------ */}
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <img
            className="containerBackgroundProfile"
            // src={containerBackgroundProfile}
            alt=""
          />
          <div>
            <h6 className="titleProfile1">
              <Translate>Seja Muito Bem-vindo</Translate>,
            </h6>
          </div>
          <div>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
          </div>

          <div className="cxProfile">
            <img className="logo1" src={image} alt="logo" />

            {/* <Link to="/ViewProfile">
              <button
                className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
              >
                <Translate>Ver perfil</Translate>
              </button>
            </Link> */}
          </div>
          <div className="calendarRelatorio2">
            <SideBarDireita />
          </div>
          {user[0].gruDescricao === 'Humanit' ? (
            <SidebarMobile />
          ) : user[0].gruDescricao === 'Administrativo' ? (
            <SidebarMobile />
          ) : (
            <div></div>
          )}
          <img className="logologo" src={logologo} alt="logo" />
        </nav>
      </Translator>
    </div>
  )
}

export default ObservacoesApontar
