import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../../Components/Login/auth.service'
import logologo from '../../../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../../../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../../../Components/Main'
import padrao from '../../../../Components/profile.png'

import SideBarDireita from '../../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../../Components/SideBar-right/SidebarMobile'
import ModalEditar from '../modal-textosAtivadores/ModalEditarAtivador'
import Modal from '../modal-textosAtivadores/ModalNovoAtivador'

var user = authService.getCurrentUser()
function Ativadores() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  let history = useHistory()
  let id = useParams()

  const [ativadores, setAtivadores] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)

  const [searchTerm, setSearchTerm] = useState('')
  sessionStorage.IdAtivadorGrupo = id['IdAtivadorGrupo']

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/textoativador/list/' +
        user[0].IdEmpresa +
        '/' +
        id['IdAtivadorGrupo']
      )
      .then(response => {
        setAtivadores(response.data)
      })
  }, [])

  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  const [toogle, setToogle] = React.useState(false)

  return (
    <div className="displayInteract">
      {modalOpen && (
        <ModalEditar setOpenModal={setModalOpen} modalInfo={modalInfo} />
      )}

      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>ATIVADORES</Translate>:
              </TitlePage1>
              {/* <TitlePage2>
                <Translate>Ativadores</Translate>:
              </TitlePage2> */}
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <Modal />
            </div>
          </div>

          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="tabelaPadrao">
            <table>
              <tr>
                <th>CÓD</th>
                <th>
                  <Translate>Grupo Ativador</Translate>
                </th>
                <th>
                  <Translate>Observações/Aplicação</Translate>
                </th>
                <th>
                  <Translate>Status</Translate>
                </th>
              </tr>
              {ativadores
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.attDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(ativador => (
                  <tr onClick={() => handleToggleComplete(ativador)}>
                    <td style={{ width: '45px', textAlign: 'center' }}>
                      {ativador.IdAtivadorTexto}
                    </td>
                    <td style={{
                      textTransform: 'uppercase'
                    }}>
                      <Translate>{ativador.gpaDescricao}</Translate>
                    </td>
                    <td style={{
                      textTransform: 'uppercase'
                    }}>
                      <Translate>{ativador.attDescricao}</Translate>: <br />{' '}
                      <Translate>{ativador.attObservacoes}</Translate>
                    </td>
                    <td style={{ width: '80px', textAlign: 'center' }}>
                      <label className="container">
                        <input
                          type="checkbox"
                          defaultChecked={!!ativador.attStatus}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          {/* <SideBarDireita /> */}
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Ativadores
