import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import AtivadoresChart from '../../../Components/dash/ativadores'
import Indicador from '../../../Components/dash/indicador'
import TabelaAtivadores from '../../../Components/dash/tabelaAtivadoresGerencia'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  //ContainerPageProfile,
  TitleOfPage,
  TitlePage1,
  TitlePage2,
  TitlePage21,
  //TitlePage24,
  //TitlePage3,
  //TitlePage8
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import okImg from '../../../Images/ok.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import Modal from '../ModalObservações/ModalObsGerencia'

//var user = authService.getCurrentUser()

function UniClieSetor() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let id = useParams()
  sessionStorage.id_gerencia_local = id['id_gerencia_local']
  //console.log(id['mes'])
  var nn = Number(id['id_gerencia_local'])

  const [mesDoAno, setmesDoAno] = useState([])
  useEffect(() => {
    if (id['mes'] === '1') setmesDoAno('JANEIRO')
    else if (id['mes'] === '2') setmesDoAno('FEVEREIRO')
    else if (id['mes'] === '3') setmesDoAno('MARÇO')
    else if (id['mes'] === '4') setmesDoAno('ABRIL')
    else if (id['mes'] === '5') setmesDoAno('MAIO')
    else if (id['mes'] === '6') setmesDoAno('JUNHO')
    else if (id['mes'] === '7') setmesDoAno('JULHO')
    else if (id['mes'] === '8') setmesDoAno('AGOSTO')
    else if (id['mes'] === '9') setmesDoAno('SETEMBRO')
    else if (id['mes'] === '10') setmesDoAno('OUTUBRO')
    else if (id['mes'] === '11') setmesDoAno('NOVEMBRO')
    else if (id['mes'] === '12') setmesDoAno('DEZEMBRO')
    else setmesDoAno('MÊS')
    sessionStorage.MesDoAno = mesDoAno
  })

  //console.log(nn)
  const dateAtual = new Date()
  sessionStorage.DataFiltroInicio = new Date(id['ano'], id['mes'] - 1, 1)
    .toISOString()
    .slice(0, 10)
  sessionStorage.DataFiltroFim = new Date(id['ano'], id['mes'], 0)
    .toISOString()
    .slice(0, 10)
  sessionStorage.idGerencia = Number(id['id_gerencia_local'])

  const [unidadeAtual, setunidadeAtual] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/' +
        parseInt(id['IdUnidade'])
      )
      .then(response => {
        setunidadeAtual(response.data[0].uniNome)
        //console.log(unidadeAtual)
      })
  })

  // const [dashUnidades, setdashUnidades] = useState([])
  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_API_URL +
  //       '/api/v1/dashUnidadesAcumulado/' +
  //       sessionStorage.DataFiltroInicio +
  //       '/' +
  //       sessionStorage.DataFiltroFim +
  //       '/' +
  //       id['IdUnidade']
  //     )
  //     .then(response => {
  //       setdashUnidades(response.data)
  //     })
  // }, [])
  const [dashLocal, setdashLocal] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashLocalGerenciaOTP/' +
        sessionStorage.DataFiltroInicio +
        '/' +
        sessionStorage.DataFiltroFim +
        '/' +
        id['id_gerencia_local'] +
        '/' +
        sessionStorage.IdObservacaoTipo
      )
      .then(response => {
        setdashLocal(response.data)
        //console.log(response.data)
      })
  }, [])
  // const [metaUnidadesMes, setmetaUnidadesMes] = useState([])
  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_API_URL +
  //       '/api/v1/dashUnidades/meta/' +
  //       id['IdUnidade']
  //     )
  //     .then(response => {
  //       setmetaUnidadesMes(response.data)
  //     })
  // }, [])
  const [metaGerenciaMes, setMetaGerenciaMes] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashGerenciaUnidade/' +
        sessionStorage.DataFiltroInicio +
        '/' +
        sessionStorage.DataFiltroFim +
        '/' +
        id['IdUnidade']
      )
      .then(response => {
        setMetaGerenciaMes(response.data)
      })
  }, [])
  const [searchTerm, setSearchTerm] = useState(sessionStorage.IdObservacaoTipo)
  let history = useHistory()
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  const [ativador, setAtivador] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/ativadoresUnidadeGerencia/' +
      sessionStorage.DataFiltroInicio +
      '/' +
      sessionStorage.DataFiltroFim +
      '/' +
      id['IdUnidade'] +
      '/' +
      sessionStorage.IdObservacaoTipo +
      '/' +
      id['id_gerencia_local']
    //console.log(url)
    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      //console.log(url)
      setAtivador(response.data)
    })
  }, [])
  const [unidadeBarraTitulo, setUnidadeBarraTitulo] = useState([])
  useEffect(() => {
    if (sessionStorage.uniAgrupamento !== "" && sessionStorage.uniAgrupamento !== undefined) {
      setUnidadeBarraTitulo(sessionStorage.uniAgrupamento)
    }
    else {
      setUnidadeBarraTitulo(unidadeAtual)
    }
  })
  const [subUnidadeBarraTitulo, setSubUnidadeBarraTitulo] = useState([])
  useEffect(() => {
    if (sessionStorage.uniAgrupamento === '') {
      setSubUnidadeBarraTitulo(' ')
    }
    else {
      setSubUnidadeBarraTitulo(unidadeAtual + ' / ')
    }
  })

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>{' '}
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1 style={{
                textTransform: 'uppercase'
              }}>
                {/* RELATÓRIO / GIMPO / FEVEREIRO / GIMPO - AZ 03 /  */}
                {' '}
                <Translate>RELATÓRIO</Translate> / {unidadeBarraTitulo} / <Translate>{mesDoAno}</Translate> / {subUnidadeBarraTitulo.toString() !== unidadeBarraTitulo.toString() + " / " ? subUnidadeBarraTitulo : ' '}
                {metaGerenciaMes
                  .filter(val => {
                    if (
                      searchTerm === '' &&
                      parseInt(val.IdUnidade) === parseInt(id['IdUnidade'])
                    ) {
                      return val
                    } else if (
                      parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                      parseInt(val.IdUnidade) === parseInt(id['IdUnidade']) &&
                      parseInt(val.id_gerencia_local) === parseInt(nn)
                    ) {
                      return val
                    }
                  })
                  .map(gerencia => gerencia.gerencia)}
                <Translate> / SETORES</Translate>:

              </TitlePage1>
              <TitlePage2>

              </TitlePage2>
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <Modal />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="divIcs">
            <div className="dashBoadMetaPadrao">
              {metaGerenciaMes
                .filter(val => {
                  if (
                    searchTerm === '' &&
                    parseInt(val.IdUnidade) === parseInt(id['IdUnidade'])
                  ) {
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                    parseInt(val.IdUnidade) === parseInt(id['IdUnidade']) &&
                    parseInt(val.id_gerencia_local) === parseInt(nn)
                  ) {
                    return val
                  }
                })
                .map(gerencia => (
                  <div
                    style={{ cursor: 'default' }}
                    className="UniClienteMes ics-cor-verde"
                  >
                    <div className="DashItem">
                      <span>
                        <h1 style={{ cursor: 'default' }} htmlFor="">
                          <Translate>Projetado</Translate>
                        </h1>
                      </span>
                      <div className="linha1">
                        <img width={40} height={40} src={okImg} alt="okImg" />
                      </div>
                      <div className="linha1">90.00%</div>
                      <div className="linha2">
                        <div className="op1">
                          <div className="op1-1">
                            <Translate>Meta </Translate>(Qtde):
                          </div>
                          <div className="op1-1">
                            {parseInt(gerencia.mtlQuantitativa)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {metaGerenciaMes
                .filter(val => {
                  if (
                    searchTerm === '' &&
                    parseInt(val.IdUnidade) === parseInt(id['IdUnidade'])
                  ) {
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                    parseInt(val.IdUnidade) === parseInt(id['IdUnidade']) &&
                    parseInt(val.id_gerencia_local) === parseInt(nn)
                  ) {
                    return val
                  }
                })
                .map(unidade => (
                  <div
                    className={
                      parseFloat(unidade.rtqPontuacao) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(unidade.rtqPontuacao) < 70
                          ? 'UniClienteMes ics-cor-vermelho'
                          : parseFloat(unidade.rtqPontuacao) < 90
                            ? 'UniClienteMes ics-cor-amarelo'
                            : 'UniClienteMes ics-cor-verde'
                    }
                  >
                    <Indicador
                      p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                      p_titulo={'Acumulado'}
                      p_meta={unidade.mtlQuantitativa}
                      p_real={unidade.mtlQuantitativaReal}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </div>
                ))}
            </div>
            {ativador.length ? (
              <div className="relPizza">
                <div className="divisor1">
                  <AtivadoresChart data={ativador} />
                </div>
                <div className="divisor2">
                  <TabelaAtivadores
                    p_Inicio={sessionStorage.DataFiltroInicio}
                    p_Fim={sessionStorage.DataFiltroFim}
                  />
                </div>
              </div>
            ) : (''
              // EXIBIDO CASO NÃO TENHA ATIVADORES NO RETORNO
              // <div className="relPizza">
              // <TitlePage2>
              //   <Translate>
              //     <tr>
              //       <td>
              //         {/* SEM INCIDÊNCIA DE ATIVADORES NO PERÍODO */}
              //       </td>
              //     </tr>
              //   </Translate>
              // </TitlePage2>
              // </div>
            )}
          </div>
          <div id="containerHeadSub">
            <TitleOfPage>
              <TitlePage21>
                <Translate>Resultado por Setor(es) da Unidade Mês - {mesDoAno}</Translate>:
              </TitlePage21>
            </TitleOfPage>
          </div>
          <div className="dashBoadPadrao">
            {dashLocal
              .filter(val => {
                if (searchTerm === '') {
                  return val
                } else if (
                  parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                  parseInt(val.IdUnidade) === parseInt(id['IdUnidade'])
                ) {
                  return val
                }
              })
              .map(local => (
                <div>

                  {local.rtqPontuacao > 0 ? (
                    <Link
                      className={
                        parseFloat(local.rtqPontuacao) === 0
                          ? 'UniClienteMes ics-cor-cinza'
                          : parseFloat(local.rtqPontuacao) < 70
                            ? 'UniClienteMes ics-cor-vermelho'
                            : parseFloat(local.rtqPontuacao) < 90
                              ? 'UniClienteMes ics-cor-amarelo'
                              : 'UniClienteMes ics-cor-verde'
                      }
                      to={`/UniContribSetor/${local.IdLocalArea}`}
                    >
                      <Indicador
                        p_resultado={parseFloat(local.rtqPontuacao).toFixed(2)}
                        p_titulo={local.local}
                        p_meta={local.mtlQuantitativa}
                        p_real={local.mtlQuantitativaReal}
                        p_pesIdioma={user[0].pesIdioma}
                      />
                    </Link>) : (
                    <div
                      className={
                        parseFloat(local.rtqPontuacao) === 0
                          ? 'UniClienteMes ics-cor-cinza'
                          : parseFloat(local.rtqPontuacao) < 70
                            ? 'UniClienteMes ics-cor-vermelho'
                            : parseFloat(local.rtqPontuacao) < 90
                              ? 'UniClienteMes ics-cor-amarelo'
                              : 'UniClienteMes ics-cor-verde'
                      }>
                      <Indicador
                        p_resultado={parseFloat(local.rtqPontuacao).toFixed(2)}
                        p_titulo={local.local}
                        p_meta={local.mtlQuantitativa}
                        p_real={local.mtlQuantitativaReal}
                        p_pesIdioma={user[0].pesIdioma}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="cxLegenda">
            <label htmlFor="" className="lgHLegenda">
              <Translate>Legenda:</Translate>
            </label>
            <button className="lgHAcima">
              <Translate>Esperado</Translate>
            </button>
            <button className="lgHMedia">
              <Translate>Atenção</Translate>
            </button>
            <button className="lgHAbaixo">
              <Translate>Risco</Translate>
            </button>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default UniClieSetor
