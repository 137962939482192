import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdObservacaoGrupo: modalInfo.IdObservacaoGrupo,
    IdObservacaoTipo: modalInfo.IdObservacaoTipo,
    IdEmpresa: modalInfo.IdEmpresa,
    ogrNumero: modalInfo.ogrNumero,
    ogrDescricao: modalInfo.ogrDescricao,
    ogrStatus: modalInfo.ogrStatus,
    ogrObservacoes: modalInfo.ogrObservacoes,
    ogrAlteradoPor: user[0].pesEmail,
    ogrAlteradoEm: newDate
  })

  function handleInputChange(event) {
    if (event.target.name === 'ogrStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/observacaogrupo', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  const [observacaoTipos, setObservacaoTipo] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/observacaotipo/list/' +
          user[0].IdEmpresa
      )
      .then(response => {
        setObservacaoTipo(response.data)
      })
  }, [])

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <div className="overlay">
          <div className="modalPadrao-content">
            <h2 className="titleModal">
              <Translate>Editar Observação</Translate>
              <hr className="hrTitleModal" />
            </h2>
            <form className="formModalPadrao" onSubmit={handleFormSubmit}>
              <input
                placeholder="Nova Observação"
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                defaultValue={campos.ogrDescricao}
                name="ogrDescricao"
                onChange={handleInputChange}
              ></input>

              <input
                placeholder="Número"
                type="text"
                id="fname"
                defaultValue={campos.ogrNumero}
                name="ogrNumero"
                onChange={handleInputChange}
              ></input>

              <select
                name="IdObservacaoTipo"
                type="text"
                defaultValue={campos.IdObservacaoTipo}
                id="fSexo"
                onChange={handleInputChange}
              >
                <option value="0">Selecione um tipo</option>
                {observacaoTipos
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(observacaoTipo => (
                    <option
                      value={observacaoTipo.IdObservacaoTipo}
                      selected={
                        observacaoTipo.IdObservacaoTipo ===
                        campos.IdObservacaoTipo
                      }
                    >
                      {observacaoTipo.otpDescricao}
                    </option>
                  ))}
              </select>

              <textarea
                placeholder="Observações"
                type="text"
                id="fname"
                name="ogrObservacoes"
                rows={5}
                style={{textTransform:'uppercase'}}
                defaultValue={campos.ogrObservacoes}
                maxLength="500"
                onChange={handleInputChange}
              ></textarea>

              <button type="submit" className="gravar-modal">
                <Translate>Salvar Registro</Translate>
              </button>
            </form>
            <div className="InputAuthorization4">
              <label>
                <input
                  className="CheckAcess"
                  name="ogrStatus"
                  type="checkbox"
                  defaultChecked={!!campos.ogrStatus}
                  onChange={handleInputChange}
                />
                <span className="checkText">
                  <Translate>Status</Translate>
                </span>
              </label>
            </div>

            <button
              className="close-modal"
              onClick={() => {
                setOpenModal(false)
              }}
            >
              X
            </button>
          </div>
        </div>
      </Translator>
    </>
  )
}
