import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [obsTipo, setobsTipo] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/observacaotipo/list/' +
          user[0].IdEmpresa
      )
      .then(response => {
        setobsTipo(response.data)
      })
  }, [])

  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdMetaPessoa: modalInfo.IdMetaPessoa,
    IdPessoa: modalInfo.IdPessoa,
    IdObservacaoTipo: modalInfo.IdObservacaoTipo,
    mtpQuantitativa: modalInfo.mtpQuantitativa,
    mtpStatus: modalInfo.mtpStatus,
    mtpObservacoes: modalInfo.mtpObservacoes,
    mtpAlteradoPor: user[0].pesEmail,
    mtpAlteradoEm: newDate
  })
  function handleInputChange(event) {
    if (event.target.name === 'mtpStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    //console.log(campos)
    if(campos.IdObservacaoTipo === 0 || campos.IdObservacaoTipo === undefined || campos.IdObservacaoTipo === null)
    {
      alert("Obrigatório selecionar o Tipo de Observação!")
    }
    else {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            '/api/v1/metapessoa/' +
            campos.IdMetaPessoa,
          campos
        )
        .then(response => {
          if (response.status === 200) {
            window.location.reload()
          } else {
            alert(response.data.message)
          }
        })
    }
  }
  return (
    <div className="modalArea">
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Meta</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>

            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Quantitativa</Translate>:
              </label>
              <input
                type="number"
                id="mtpQuantitativa"
                defaultValue={campos.mtpQuantitativa}
                name="mtpQuantitativa"
                onChange={handleInputChange}
              ></input>
              <label>
                <Translate>Selecione um tipo de observação</Translate>:
              </label>
              <select
                name="IdObservacaoTipo"
                type="text"
                defaultValue={campos.IdObservacaoTipo}
                id="fSexo"
                onChange={handleInputChange}
                disabled
              >
                <option value="0">Selecione um tipo de observação</option>
                {obsTipo
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(metaTipo => (
                    <option
                      value={metaTipo.IdObservacaoTipo}
                      selected={
                        metaTipo.IdObservacaoTipo === campos.IdObservacaoTipo
                      }
                    >
                      {metaTipo.otpDescricao}
                    </option>
                  ))}
              </select>
              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="mtpObservacoes"
                defaultValue={campos.mtpObservacoes}
                rows={5}
                style={{textTransform:'uppercase'}}
                onChange={handleInputChange}
                maxLength="500"
              ></textarea>
              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="mtpStatus"
                      type="checkbox"
                      defaultChecked={!!campos.mtpStatus}
                      onChange={handleInputChange}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.mtpCriadoPor}</td>
                <td>em {modalInfo.mtpCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.mtpAlteradoPor}</td>
                <td>em {modalInfo.mtpAlteradoEm}</td>
              </tr>
            </table>
          </div>
        </Translator>
      </div>
    </div>
  )
}
