import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link } from 'react-router-dom'
import authService from '../../Components/Login/auth.service'
import logologo from '../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../Components/Main'
import padrao from '../../Components/profile.png'
import imgOpen from '../../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../Components/SideBar-right/SidebarMobile'
import ModalCadAtividade from './MenuDireito-Modal/ModalCadAtividade'
import Modal from './MenuDireito-Modal/ModalEdiAtividade'

function Atividades() {
  sessionStorage.TelaMenuLateral = 'Atividades'
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  var user = authService.getCurrentUser()
  const [atividades, setAtividades] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/atividade/list/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeForm
        )
        .then(response => {
          setAtividades(response.data)
        })
    }
    else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/atividade/list/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          setAtividades(response.data)
        })
    }
  }, [])
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <div className="displayInteract">
      {modalOpen && <Modal setOpenModal={setModalOpen} modalInfo={modalInfo} />}
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {parseInt(sessionStorage.IdUnidadeForm) === 0 ? (sessionStorage.uniRazaoSocial) : (sessionStorage.uniRazaoSocialForm)} /{' '}
                <Translate>DADOS CADASTRAIS / ATIVIDADES</Translate>:
              </TitlePage1>
              <TitlePage2>
                <Translate>Atividades Observadas</Translate>
              </TitlePage2>
            </TitleOfPage>

            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <ModalCadAtividade />
            </div>
          </div>

          <div className="tabelaPadrao">
            <table>
              <thead>
                <tr>
                  {/* <th>CÓD</th> */}
                  <th>
                    <Translate>Atividade</Translate>
                  </th>
                  <th>
                    <Translate>Status</Translate>
                  </th>
                  <th>
                    <Translate>Registro</Translate> /
                    <br /> <Translate>Atualização</Translate>
                  </th>
                </tr>
              </thead>
              {atividades
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.atvDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(atividade => (
                  <tbody>
                    <tr
                      key={atividade.IdAtividade}
                      onClick={() => handleToggleComplete(atividade)}
                    >
                      {/* <td className="tblPASCod">{atividade.IdAtividade}</td> */}
                      <td style={{ textTransform: 'uppercase' }}>
                        {atividade.atvDescricao}</td>
                      <td>
                        <label className="container">
                          <input
                            type="checkbox"
                            defaultChecked={!!atividade.atvStatus}
                            disabled
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        {' '}
                        {Moment(atividade.atvAlteradoEm).isValid()
                          ? Moment(atividade.atvAlteradoEm).format('DD/MM/YYYY')
                          : Moment(
                            atividade.atvAlteradoEm,
                            'DD/MM/YYYY HH:mm:ss'
                          ).format('DD/MM/YYYY')}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>

            {atividades.length === 0 ? (
              <p className="noObs">Carregando informações...</p>
            ) : (
              ''
              // <p className="noObs">
              //   <Translate>
              //     Não há observações para o período selecionado.
              //   </Translate>
              // </p>
            )}
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <SideBarDireita />
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Atividades
