import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import { Link } from 'react-router-dom'
import AtivadoresChart from '../Components/dash/ativadores'
import okImg from '../Images/ok.svg'
import Indicador from '../Components/dash/indicador'
//import TabelaAtivadores from '../Components/dash/tabelaAtivadoresEmpresa'
import authService from '../Components/Login/auth.service'
import logologo from '../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2,
  TitlePage21,
  TitlePage8
} from '../Components/Main'
import padrao from '../Components/profile.png'
import SidebarMobile from '../Components/SideBar-right/SidebarMobile'
import '../styles/components/Calendar.css'
import '../styles/pages/home.css'
import '../styles/components/profiledireita.css'
import Modal from './Relatorio/ModalObservações/ModalObsDash'
import ModalEditar from '../Components/Login/ViewProfile' //'./MenuDireito/MenuDireito-Modal/ModalEdiPessoa'
//import Loading from '../Components/Loading'

function Home() {
  sessionStorage.TelaMenuPrincipal = 'Home'
  sessionStorage.searchTerm = ""
  var user = authService.getCurrentUser()

  const [empresas, setEmpresas] = useState([])
  const [unidades, setUnidades] = useState([])
  const [observacaotipos, setObservacaotipos] = useState([])
  const [dashUnidades, setdashUnidades] = useState([])
  const [ativador, setAtivador] = useState([])
  const [ativadores, setAtivadores] = useState([])

  const dateAtual = new Date()
  const [image, setImage] = useState('')
  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
      .toISOString()
      .slice(0, 10)
    var x = new Date()
    sessionStorage.DataFim = x.toISOString().slice(0, 10)
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    window.location.reload()
  }
  const showSidebar = () => setSidebar(!sidebar)
  const [sidebar, setSidebar] = useState([])

  var img = new Image()
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState([])
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  //**** FUNCTIONS ****/
  function handleUnidadeChange(event) {
    sessionStorage.IdUnidade = event.target.options[event.target.selectedIndex].value
    sessionStorage.IdUnidadeEdit = event.target.options[event.target.selectedIndex].value
    if (parseInt(event.target.options[event.target.selectedIndex].value) > 0) {
      sessionStorage.IdUnidadeForm = 0
    }
    sessionStorage.uniAgrupamento = event.target.options[event.target.selectedIndex].text
    sessionStorage.uniRazaoSocial = event.target.options[event.target.selectedIndex].text

    window.location.reload()

    // atualizaMeta();
    // atualizaAtivadores();
    // atualizaAtivadoresLista();
    // atualizaResultado();
  }

  function handleObsTipoChange(event) {
    //setRemoveLoading(false)
    sessionStorage.IdObservacaoTipo = event.target.options[event.target.selectedIndex].value
    sessionStorage.ObservacaoDesc = event.target.options[event.target.selectedIndex].text

    window.location.reload()

    //  atualizaMeta();
    //  atualizaAtivadores();
    //  atualizaAtivadoresLista();
    //  atualizaResultado();
  }

  function AtualizaUnidades() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUserAgrupamentoTodos/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)

        if (sessionStorage.IdUnidade === undefined) {
          sessionStorage.IdUnidade = response.data[0].IdUnidade
          sessionStorage.IdUnidadeEdit = response.data[0].IdUnidade
          if (parseInt(response.data[0].IdUnidade) > 0) {
            sessionStorage.IdUnidadeForm = 0
          }
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento

          //window.location.reload()
        }
        if (sessionStorage.uniAgrupamento === undefined) {
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento

          //window.location.reload()
        }
        // ObtemTiposDeObservacao();
        // atualizaMeta();
        // atualizaAtivadores();
        // atualizaAtivadoresLista();
        // atualizaResultado();
      })
  }

  function ObtemTiposDeObservacao() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        response.data.some(function (item) {
          if (sessionStorage.IdObservacaoTipo === undefined) {
            response.data.forEach(element => {
              if (element.otpStatus === 1) {
                if (sessionStorage.IdObservacaoTipo === undefined) {
                  sessionStorage.IdObservacaoTipo = element.IdObservacaoTipo
                  sessionStorage.ObservacaoDesc = element.otpDescricao
                }
              }
            })
          }
        })
        setObservacaotipos(response.data)

        AtualizaUnidades()
        atualizaMeta()
        atualizaAtivadores();
        atualizaAtivadoresLista();
        atualizaResultado();

      })

  }

  function atualizaMeta() {

    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios.get(process.env.REACT_APP_API_URL +
        '/api/v1/dashEmpresasUnicaOTP/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo).then(response => {
          setEmpresas(response.data)
        })
    }

    // axios.get(process.env.REACT_APP_API_URL +
    //   '/api/v1/dashEmpresasUnica/' +
    //   sessionStorage.DataInicio +
    //   '/' +
    //   sessionStorage.DataFim +
    //   '/' +
    //   user[0].IdEmpresa)
    //   .then(response => {
    //     setEmpresas(response.data)
    //   })
  }

  function atualizaResultado() {

    //setRemoveLoading(false)
    if (parseInt(sessionStorage.IdUnidadeEdit) === 0 && sessionStorage.uniAgrupamento !== 'Todos' && sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoAgrupamentoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.uniAgrupamento +
          '/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    else if (parseInt(sessionStorage.IdUnidadeEdit) === 0 && sessionStorage.uniAgrupamento === 'Todos' && sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoVinculadosOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          user[0].IdPessoa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    //SE TEM IDUNIDADE E NAO AGRUPAMENTO
    else if (parseInt(sessionStorage.IdUnidadeEdit !== 0) && sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.IdUnidadeEdit +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
          //console.log(response.data)
        })
    }
  }

  function atualizaAtivadores() {
    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios.get(process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo)
        .then(response => {
          setAtivador(response.data)
        })
    }
  }

  function atualizaAtivadoresLista() {
    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      var url =
        '/api/v1/ativadoresListaEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo

      axios.get(process.env.REACT_APP_API_URL + url)
        .then(response => {
          setAtivadores(response.data)
        })
    }
  }



  //OBTEM A LISTA DE TIPOS DE OBSERVACAO PARA LISTA SUSPENSA
  ///*
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        response.data.some(function (item) {
          if (sessionStorage.IdObservacaoTipo === undefined) {
            response.data.forEach(element => {
              if (element.otpStatus === 1) {
                if (sessionStorage.IdObservacaoTipo === undefined) {
                  sessionStorage.IdObservacaoTipo = element.IdObservacaoTipo
                  sessionStorage.ObservacaoDesc = element.otpDescricao
                }
              }
            })
          }
        })
        setObservacaotipos(response.data)

      })

  }, [])
  //*/

  //OBTEM A LISTA DE UNIDADES E AGRUPAMENTOS
  ///*
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUserAgrupamentoTodos/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)

        if (sessionStorage.IdUnidade === undefined) {
          sessionStorage.IdUnidade = response.data[0].IdUnidade
          sessionStorage.IdUnidadeEdit = response.data[0].IdUnidade
          if (parseInt(response.data[0].IdUnidade) > 0) {
            sessionStorage.IdUnidadeForm = 0
          }
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento

          //window.location.reload()
        }
        if (sessionStorage.uniAgrupamento === undefined) {
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento

          //window.location.reload()
        }
        // ObtemTiposDeObservacao();
        // atualizaMeta();
        // atualizaAtivadores();
        // atualizaAtivadoresLista();
        // atualizaResultado();
      })
  }, [])
  //*/

  //OBTEM O RESULTADO DA EMPRESA
  ///*
  useEffect(() => {

    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios.get(process.env.REACT_APP_API_URL +
        '/api/v1/dashEmpresasUnicaOTP/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo).then(response => {
          setEmpresas(response.data)
        })
    }
    sessionStorage.resultadoEmpresa = null

  }, [])

  //*/
  //OBTEM RESULTADO ACUMULADO DA UNIDADE SELECIONADA
  //SE IDUNIDADE = 0 E EXISTIR AGRUPAMENTO
  ///*
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidade) === 0 && sessionStorage.uniAgrupamento !== '' && sessionStorage.uniAgrupamento !== undefined && sessionStorage.uniAgrupamento !== 'Todos' && sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      console.log("1")
      //console.log(sessionStorage.uniAgrupamento)
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoAgrupamentoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.uniAgrupamento +
          '/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    //SE TEM IDUNIDADE E NAO AGRUPAMENTO
    else if (parseInt(sessionStorage.IdUnidade) !== 0 && (sessionStorage.uniAgrupamento !== '' || sessionStorage.uniAgrupamento !== undefined) && sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      console.log("2")
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.IdUnidadeEdit +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    else if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      console.log("3")
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoVinculadosOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          user[0].IdPessoa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    else {
      console.log("deu ruim!")
    }
  }, [])
  //*/

  // OBTEM A LISTA DE ATIVADORES DO GRÁFICO
  ///*
  useEffect(() => {
    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      axios.get(process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo)
        .then(response => {
          setAtivador(response.data)
        })
    }
  }, [])
  //*/

  //OBTEM LISTA DE ATIVADORES PARA A TABELA
  ///*
  useEffect(() => {
    if (sessionStorage.IdObservacaoTipo !== undefined && parseInt(sessionStorage.IdObservacaoTipo) > 0) {
      var url =
        '/api/v1/ativadoresListaEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo
      axios
        .get(process.env.REACT_APP_API_URL + url)
        .then(response => {
          setAtivadores(response.data)
        })
    }
  }, [])
  //*/

  if (sessionStorage.IdObservacaoTipo === undefined || parseInt(sessionStorage.IdObservacaoTipo) === 0) {
    ObtemTiposDeObservacao();
    atualizaResultado();
  }

  // MONTAGEM DA TELA
  return (
    <div className="displayInteract">

      {modalOpen && <ModalEditar setOpenModal={setModalOpen} modalInfo={modalInfo} />}

      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >

        <ContainerPage>
          {/* LOGO-TITULO-SUBTITULO */}
          {/* <div>
          <iframe 
          title="Tela_Gráfico_ativador" width="100%" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=fcd8c1fa-1555-4b25-807e-85038a112462&autoAuth=true&ctid=3894d44a-bc55-4cb1-abf9-0fb91c1f313b" frameborder="0" allowFullScreen="true"></iframe>
          </div> */}

          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>PÁGINA INICIAL</Translate>
              </TitlePage1>
              <TitlePage2>
                <Translate>
                  {sessionStorage.ObservacaoDesc === 'Observação Comportamental'
                    ? 'Resultado do ICS'
                    : sessionStorage.ObservacaoDesc === 'Qualidade da Observação'
                      ? 'Resultado do IQO'
                      : 'Resultado do ICS'}
                </Translate>
              </TitlePage2>
              <TitlePage8>
                <Translate>
                  {sessionStorage.ObservacaoDesc === 'Observação Comportamental'
                    ? 'Índice de Comportamento Seguro'
                    : sessionStorage.ObservacaoDesc === 'Qualidade da Observação'
                      ? ' Índice de Qualidade da Observação'
                      : 'Índice de Comportamento Seguro'}
                </Translate>
              </TitlePage8>
            </TitleOfPage>
            {sessionStorage.uniRazaoSocial === 'Todos' ? (
              <div id="containerPesquisaModal">
                <Modal />
              </div>
            ) : ('')}

          </div>

          {/* PROJETADO-ACUMULADO-GRÁFICO-ATIVADORES */}
          <div className="divIcs">
            {/* PROJETO E ACUMULADO EMPRESA */}
            <div className="dashBoadMetaPadrao">
              {/* PROJETADO EMPRESA*/}
              {
                empresas
                  .filter(val => {
                    if (
                      parseInt(val.IdEmpresa) === parseInt(user[0].IdEmpresa) &&
                      parseInt(val.IdObservacaoTipo) ===
                      parseInt(sessionStorage.IdObservacaoTipo)
                    ) {
                      return val
                    }
                  })
                  .map(empresa => (
                    <div>

                      {/* QUADRO PROJETADO */}
                      <div className="UniClienteMes ics-cor-verde">
                        <div className="DashItem">
                          <span data-tooltip="Projetado">
                            <h1 htmlFor="">
                              <Translate>PROJETADO</Translate>
                            </h1>
                          </span>
                          <div className="linha1">
                            <img width={40} height={40} src={okImg} alt="okImg" />
                          </div>
                          <div className="linha1">90.00%</div>
                          <div className="linha2">
                            <div className="op1">
                              <div className="op1-1">
                                <Translate>Meta </Translate>(Qtde):
                              </div>
                              <div className="op1-1">{empresa.mtlQuantitativa}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  )}
              {/* FINAL - PROJETADO EMPRESA*/}
              {/* ACUMULADO EMPRESA */}
              {empresas
                .filter(val => {
                  if (
                    parseInt(val.IdEmpresa) === parseInt(user[0].IdEmpresa) &&
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo)
                  ) {
                    return val
                  }
                })
                .map(empresa => (
                  <div
                    className={
                      parseFloat(empresa.rtqPontuacao) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(empresa.rtqPontuacao) < 70
                          ? 'UniClienteMes ics-cor-vermelho'
                          : parseFloat(empresa.rtqPontuacao) < 89
                            ? 'UniClienteMes ics-cor-amarelo'
                            : 'UniClienteMes ics-cor-verde'
                    }
                  >
                    <div className="DashItem">
                      <span data-tooltip={empresa.empRazaoSocial}>
                        <h1 htmlFor="">{empresa.empRazaoSocial}</h1>
                      </span>
                      <div className="linha1">
                        <img width={40} height={40} src={okImg} alt="okImg" />
                      </div>

                      <div className="linha1">
                        {parseFloat(empresa.rtqPontuacao).toFixed(2)}%
                      </div>
                      <div className="linha2">
                        <div className="op1">
                          <div className="op1-2">
                            <Translate>Meta </Translate>(Qtde):
                          </div>
                          <div className="op1-1">{empresa.mtlQuantitativa}</div>
                        </div>
                        <hr className="dashHr"></hr>
                        <div className="op1">
                          <div className="op1-2">
                            <Translate>Real</Translate>(Qtde):
                          </div>
                          <div className="op1-1">
                            {empresa.mtlQuantitativaReal}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {/* FINAL - ACUMULADO EMPRESA */}
            </div>

            {/* GRÁFICO E LISTA DE ATIVADORES */}
            {ativador.length > 0 ? (
              <div className="relPizza2">
                {/* GRÁFICO PIZZA */}
                <div className="divisor1">
                  <AtivadoresChart data={ativador} />
                </div>

                {/* TABELA ATIVADORES */}
                {/* <TabelaAtivadores /> */}
                <div className="divisor2">
                  {ativadores
                    .filter(val => {
                      return val
                    })
                    .map(ativador => (
                      <tr>
                        <td><Translate>{ativador.quantidade}</Translate></td>
                        <td><Translate>{ativador.attDescricao}</Translate></td>
                        <td><Translate>{ativador.gpaDescricao}</Translate></td>
                      </tr>
                    ))}
                </div>
              </div>
            ) : ('')
              // EXIBIDO CASO NÃO TENHA ATIVADORES NO RETORNO
              /*<div className="relPizza2">
              <TitlePage8>
                <Translate>
                  <tr>
                    <td>
                      SEM INCIDÊNCIA DE ATIVADORES NO PERÍODO
                    </td>
                  </tr>
                </Translate>
              </TitlePage8>
              </div>
              ) */}

            {/* FINAL - GRÁFICO E LISTA DE ATIVADORES */}
          </div>

          {/* ICONES RESULTADO POR UNIDADES */}
          <div id="containerHeadSub">
            <TitleOfPage>
              <TitlePage21>
                <Translate>Resultado por Unidade:</Translate>
              </TitlePage21>
            </TitleOfPage>
          </div>
          <div className="dashBoadMetaPadrao">
            {
              // dashUnidades.length > 0 ? (
              dashUnidades
                .filter(val => {
                  //console.log(sessionStorage.IdUnidade)
                  //console.log(sessionStorage.uniAgrupamento)
                  if (
                    sessionStorage.uniAgrupamento !== '' && sessionStorage.uniAgrupamento !== 'Todos' &&
                    parseInt(sessionStorage.IdUnidade) === 0
                  ) {
                    if (
                      val.uniAgrupamento === sessionStorage.uniAgrupamento &&
                      parseInt(val.IdUnidade) ===
                      parseInt(sessionStorage.IdUnidade) &&
                      parseInt(val.IdObservacaoTipo) ===
                      parseInt(sessionStorage.IdObservacaoTipo)
                    ) {
                      //if (!removeLoading) {setRemoveLoading(true)}
                      return val
                    }
                  } else if (
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo) &&
                    parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  ) {
                    //if (!removeLoading) {setRemoveLoading(true)}
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo)
                  ) {
                    return val
                  }
                  else {
                    //if (!removeLoading) {setRemoveLoading(true)}
                    return undefined
                  }//setLoading(false)
                })
                .map(unidade => (
                  <div>
                    {unidade.rtqPontuacao > 0 ? (
                      <Link
                        className={
                          parseFloat(unidade.rtqPontuacao) === 0
                            ? 'UniClienteMes ics-cor-cinza'
                            : parseFloat(unidade.rtqPontuacao) < 70
                              ? 'UniClienteMes ics-cor-vermelho'
                              : parseFloat(unidade.rtqPontuacao) < 90
                                ? 'UniClienteMes ics-cor-amarelo'
                                : 'UniClienteMes ics-cor-verde'
                        }
                        to={`/UniClienteExe/${unidade.IdUnidade}`}
                      >
                        <Indicador
                          p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                          p_titulo={unidade.uniNome}
                          p_meta={unidade.mtlQuantitativa}
                          p_real={unidade.mtlQuantitativaReal}
                          p_pesIdioma={user[0].pesIdioma}
                        />
                      </Link>
                    ) : (
                      <div
                        className={
                          parseFloat(unidade.rtqPontuacao) === 0
                            ? 'UniClienteMes ics-cor-cinza'
                            : parseFloat(unidade.rtqPontuacao) < 70
                              ? 'UniClienteMes ics-cor-vermelho'
                              : parseFloat(unidade.rtqPontuacao) < 90
                                ? 'UniClienteMes ics-cor-amarelo'
                                : 'UniClienteMes ics-cor-verde'
                        }>
                        <Indicador
                          p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                          p_titulo={unidade.uniNome}
                          p_meta={unidade.mtlQuantitativa}
                          p_real={unidade.mtlQuantitativaReal}
                          p_pesIdioma={user[0].pesIdioma}
                        />
                      </div>
                    )}
                  </div>
                ))
              // ) : (
              //   <div
              //         className={'UniClienteMes ics-cor-cinza'
              //             }
              //       >
              //         <Indicador
              //           p_resultado={0}
              //           p_titulo={sessionStorage.uniRazaoSocial}
              //           p_meta={90}
              //           p_real={0}
              //           p_pesIdioma={user[0].pesIdioma}
              //         />  

              //       </div> 
              // )
            }
          </div>

          {/* FINAL ICONES RESULTADO POR UNIDADES */}

          {/* LEGENDAS */}
          <div className="cxLegenda">
            <label htmlFor="" className="lgHLegenda">
              <Translate>Legenda:</Translate>
            </label>
            <button className="lgHAcima">
              <Translate>Esperado</Translate>
            </button>
            <button className="lgHMedia">
              <Translate>Atenção</Translate>
            </button>
            <button className="lgHAbaixo">
              <Translate>Risco</Translate>
            </button>
          </div>

        </ContainerPage>

        <button
          className={sidebar ? 'fecharMenu' : 'abrirMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ====================== */}

        <nav className={sidebar ? 'esconderFiltro' : 'mostrarFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile"> */}
              <button
                className="profile-profileEditor"
                onClick={() => handleToggleComplete(user[0])}
              >
                <Translate>Ver perfil</Translate>
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            <form action="">
              <h1 className="selectAnalise">
                <Translate>Selecione</Translate>:
              </h1>
              <label className="SelectCliente" htmlFor="">
                <Translate>Cliente/Unidade</Translate>:
              </label>
              <select
                name="IdUnidade"
                className="SelectIdCliente"
                onChange={event => {
                  handleUnidadeChange(event)
                }}
              >
                {unidades
                  .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                  .map(unidade => (
                    <option
                      value={unidade.IdUnidade}
                      text={unidade.uniAgrupamento}
                      selected={unidade.uniNome !== 'Todos' ?
                        (
                          parseInt(unidade.IdUnidade) ===
                          parseInt(sessionStorage.IdUnidade)
                        ) :
                        (
                          parseInt(unidade.IdUnidade) ===
                          parseInt(sessionStorage.IdUnidade)
                          &&
                          unidade.uniNome === sessionStorage.uniRazaoSocial
                        )
                      }
                    >
                      {unidade.uniNome}
                    </option>
                  ))}
              </select>
              <label className="SelectObserv" htmlFor="">
                <Translate>Tipo de Observação</Translate>:
              </label>
              <select
                name="SelectIdObserv"
                className="SelectIdObserv"
                onChange={event => {
                  //setSearchTerm(event.target.value)
                  handleObsTipoChange(event)
                }}
              >
                {observacaotipos
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(observacaotipo => (
                    <option
                      value={observacaotipo.IdObservacaoTipo}
                      selected={
                        parseInt(observacaotipo.IdObservacaoTipo) ===
                        parseInt(sessionStorage.IdObservacaoTipo)
                      }
                    >
                      {observacaotipo.otpDescricao}
                    </option>
                  ))}
              </select>
              <label className="SelectDate">
                <Translate>Periodo Analisado</Translate>:
              </label>

              <div className="calendarioAnalise2">
                <Calendar
                  calendarType="Hebrew"
                  onChange={setSeacrhDate}
                  value={date}
                  maxDetail={'year'}
                  selectRange={true}
                />
              </div>
            </form>
          </div>
          <div className="cxLogo">
            <img className="logologo" src={logologo} alt="logo" />
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Home
