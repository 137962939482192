import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import AtivadoresChart from '../../../Components/dash/ativadores'
import Indicador from '../../../Components/dash/indicador'
import TabelaAtivadores from '../../../Components/dash/tabelaAtivadoresLocal'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2,
  TitlePage24,
  TitlePage3,
  TitlePage8
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import Modal from '../ModalObservações/ModalObsLocal'
import okImg from '../../../Images/ok.svg'

var user = authService.getCurrentUser()

function UniContribSetor() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }

  let id = useParams()
  sessionStorage.IdLocalArea = id['IdLocal']
  const dateAtual = new Date()
  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
    sessionStorage.DataFim = new Date()
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    window.location.reload()
  }

  let history = useHistory()
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  const [ativador, setAtivador] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/ativadoresLocal/' +
      sessionStorage.DataFiltroInicio +
      '/' +
      sessionStorage.DataFiltroFim +
      '/' +
      id['IdLocal'] +
      '/' +
      sessionStorage.IdObservacaoTipo

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setAtivador(response.data)
    })
  }, [])

  const [unidadeAtual, setunidadeAtual] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/' +
        sessionStorage.IdUnidadeEdit
      )
      .then(response => {
        setunidadeAtual(response.data[0].uniNome)
        //console.log(unidadeAtual)
      })
  }, [])

  const [dashLocal, setdashLocal] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/dashLocal/' +
      sessionStorage.DataFiltroInicio +
      '/' +
      sessionStorage.DataFiltroFim

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      //console.log
      setdashLocal(response.data)
    })
  }, [])

  const [Locais, setLocais] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/area/' + id['IdLocal'])
      .then(response => {
        setLocais(response.data)
      })
  }, [])

  const [gerencia, setGerencia] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/gerencia/' + sessionStorage.id_gerencia_local)
      .then(response => {
        setGerencia(response.data)
      })
  }, [])
  //console.log(gerencia)
  //console.log(sessionStorage.id_gerencia_local)
  const [unidadeBarraTitulo, setUnidadeBarraTitulo] = useState([])
  useEffect(() => {
    if (sessionStorage.uniAgrupamento !== "" && sessionStorage.uniAgrupamento !== undefined) {
      setUnidadeBarraTitulo(sessionStorage.uniAgrupamento)
    }
    else {
      setUnidadeBarraTitulo(unidadeAtual)
    }
  })

  const [subUnidadeBarraTitulo, setSubUnidadeBarraTitulo] = useState([])
  useEffect(() => {
    if (sessionStorage.uniAgrupamento === '') {
      setSubUnidadeBarraTitulo(' ')
    }
    else {
      setSubUnidadeBarraTitulo(unidadeAtual + ' / ')
    }
  })

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />

            <div></div>
          </div>{' '}
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1 style={{
                textTransform: 'uppercase'
              }}>
                <Translate>RELATÓRIO</Translate> / {unidadeBarraTitulo} / {sessionStorage.MesDoAno} / {subUnidadeBarraTitulo.toString() !== unidadeBarraTitulo.toString() + " / " ? subUnidadeBarraTitulo : ' '}
                {gerencia.map(gerencia => (gerencia.gerDescricao))}
                / {Locais.map(local => (local.locDescricao))}
                :
              </TitlePage1>
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <Modal />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="divIcs">
            <div className="dashBoadMetaPadrao">
              {dashLocal
                .filter(val => {
                  if (
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal']) &&
                    parseInt(val.IdObservacaoTipo) ==
                    parseInt(sessionStorage.IdObservacaoTipo)
                  ) {
                    //console.log(val)
                    return val
                  }
                })
                .map(local => (
                  <div
                    style={{ cursor: 'default' }}
                    className="UniClienteMes ics-cor-verde"
                  >
                    <div className="DashItem">
                      <span>
                        <h1 style={{ cursor: 'default' }}>
                          <Translate>Projetado</Translate>
                        </h1>
                      </span>
                      <div className="linha1">
                        <img width={40} height={40} src={okImg} alt="okImg" />
                      </div>

                      <div className="linha1">90.00%</div>
                      <div className="linha2">
                        <div className="op1">
                          <div className="op1-1">
                            <Translate>Meta </Translate>(Qtde):
                          </div>
                          <div className="op1-1">{local.mtlQuantitativa}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {dashLocal
                .filter(val => {
                  if (
                    parseInt(val.IdLocalArea) === parseInt(id['IdLocal']) &&
                    parseInt(val.IdObservacaoTipo) ==
                    parseInt(sessionStorage.IdObservacaoTipo)
                  ) {
                    //console.log(val)
                    return val
                  }
                })
                .map(local => (
                  <div
                    className={
                      parseFloat(local.rtqPontuacao) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(local.rtqPontuacao) < 70
                          ? 'UniClienteMes ics-cor-vermelho'
                          : parseFloat(local.rtqPontuacao) < 90
                            ? 'UniClienteMes ics-cor-amarelo'
                            : 'UniClienteMes ics-cor-verde'
                    }
                  >
                    <Indicador
                      p_resultado={parseFloat(local.rtqPontuacao).toFixed(2)}
                      p_titulo={local.otpDescricao}
                      p_meta={local.mtlQuantitativa}
                      p_real={local.mtlQuantitativaReal}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </div>
                ))}
            </div>
            {ativador.length ? (
              <div className="relPizza2">
                <div className="divisor1">
                  <AtivadoresChart data={ativador} />
                </div>
                <div className="divisor2">
                  <TabelaAtivadores
                    p_Inicio={sessionStorage.DataInicio}
                    p_Fim={sessionStorage.DataFim}
                  />
                </div>
              </div>
            ) : (''
              // EXIBIDO CASO NÃO TENHA ATIVADORES NO RETORNO
              // <div className="relPizza">
              // <TitlePage2>
              //   <Translate>
              //     <tr>
              //       <td>
              //         SEM INCIDÊNCIA DE ATIVADORES NO PERÍODO
              //       </td>
              //     </tr>
              //   </Translate>
              // </TitlePage2>
              // </div>
            )}
            <div className="cxLegenda">
              <label htmlFor="" className="lgHLegenda">
                <Translate>Legenda:</Translate>
              </label>
              <button className="lgHAcima">
                <Translate>Esperado</Translate>
              </button>
              <button className="lgHMedia">
                <Translate>Atenção</Translate>
              </button>
              <button className="lgHAbaixo">
                <Translate>Risco</Translate>
              </button>
            </div>
          </div>{' '}
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="Profile-direita">
            <div className="cxProfile">
              <h6 className="titleProfile1">
                {' '}
                <Translate>Seja Muito Bem-vindo,</Translate>
              </h6>
              <h6 className="titleProfile2">{user[0].pesNome}</h6>
              <div className="alignFotoProfile">
                {image ? (
                  <img className="logo1" src={image} alt="perfil" />
                ) : (
                  <img src={padrao} alt="Profile" className="logo1" />
                )}
                {/* <Link to="/ViewProfile">
                  <button
                    className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                  >
                    <Translate>Ver perfil</Translate>
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="cxLogo">
            {' '}
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default UniContribSetor
