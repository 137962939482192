import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuTipoDeObservação/modal-tiposDeobservaçoes/modalNovaQuestão.css'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdObservacaoQuestao: modalInfo.IdObservacaoQuestao,
    oqsNumero: modalInfo.oqsNumero,
    oqsDescricao: modalInfo.oqsDescricao,
    oqsStatus: modalInfo.oqsStatus,
    oqsObservacoes: modalInfo.oqsObservacoes,
    oqsAlteradoPor: user[0].pesEmail,
    oqsAlteradoEm: newDate
  })
  function handleInputChange(event) {
    if (event.target.name === 'oqsStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/observacaoquestao/' +
          modalInfo.IdObservacaoQuestao,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <Translator
      cacheProvider={cacheProvider}
      from="pt"
      to={user[0].pesIdioma}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <>
        <div className="overlay">
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Novo Grupo De Questões</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Descrição</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="oqsDescricao"
                rows={3}
                onChange={handleInputChange}
                defaultValue={campos.oqsDescricao}
              ></textarea>

              <label>
                <Translate>Número</Translate>:
              </label>
              <input
                style={{ width: '80px' }}
                type="number"
                id="fname"
                name="oqsNumero"
                onChange={handleInputChange}
                defaultValue={campos.oqsNumero}
              ></input>

              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="oqsObservacoes"
                row="none"
                maxLength="500"
                onChange={handleInputChange}
                defaultValue={campos.oqsObservacoes}
              ></textarea>
              <div className="caixaSalvarPadrao">
                <button type="submit" className="gravar-modal">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Translator>
  )
}
