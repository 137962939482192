import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuEmpresas/Modal-SubMenuEmpresas/AlterarRegistroEmpresas.css'

export const mask = (v = '') => {
  v = v.replace(/\D/g, '')

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return v
}

function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [valor, setValor] = useState('')

  function handleChangeMask(event) {
    const { value } = event.target
    setValor(mask(value))
  }

  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdEmpresa: modalInfo.IdEmpresa,
    empRazaoSocial: modalInfo.empRazaoSocial,
    empLogo: modalInfo.empLogo,
    empCNPJ: modalInfo.empCNPJ,
    empEndereco: modalInfo.empEndereco,
    empStatus: modalInfo.empStatus,
    empQuantidadeLicencas: modalInfo.empQuantidadeLicencas,
    empObservacoes: modalInfo.empObservacoes,
    empCriadoPor: modalInfo.empCriadoPor,
    empCriadoEm: modalInfo.empCriadoEm,
    empAlteradoPor: modalInfo.empAlteradoPor,
    empAlteradoEm: modalInfo.empAlteradoEm
  })

  function handleInputChange(event) {
    if (event.target.name === 'empStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    campos["empAlteradoPor"] = user[0].pesNome
    campos["empAlteradoEm"] = newDate
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/empresa/' +
          modalInfo.IdEmpresa,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  return (
    <Translator
      cacheProvider={cacheProvider}
      from="pt"
      to={user[0].pesIdioma}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <>
        <div className="overlay">
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Empresa</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="div-group2">
                <img
                  src={campos.empLogo}
                  alt="Logo"
                  className="imgProfile"
                  width="150"
                  height="150"
                />
              </div>
              <label>
                <Translate>Razão Social</Translate>:
              </label>
              <input
                placeholder="Razão Social"
                type="text"
                id="fname"
                required
                accept=".gif,.jpg,.jpeg,.png"
                defaultValue={campos.empRazaoSocial}
                name="empRazaoSocial"
                onChange={handleInputChange}
              ></input>

              {/* <label className="ttlCadARE2"><Translate>Selecione o status da empresa</Translate>:</label>
          <select
            className="fSelectTipEmpresa"
            type="text"
            onChange={handleInputChange}
            name="empStatus"
            required
            defaultValue={campos.empStatus}
          >
            <option value="">Selecione o Status da Empresa:</option>
            <option value="0">Inativo</option>
            <option value="1">Ativo</option>
          </select> */}

              <div className="linhaDoisCadPessoa">
                <label>
                  <Translate>Digite o CNPJ</Translate>:
                </label>
              </div>

              <div className="linhaDoisCadPessoa">
                <input
                  placeholder="CNPJ"
                  type="text"
                  defaultValue={campos.empCNPJ}
                  id="fname"
                  maxLength="18"
                  name="empCNPJ"
                  required
                  onChange={function (event) {
                    handleChangeMask(event)
                    handleInputChange(event)
                  }}
                ></input>
              </div>

              <div className="linhaDoisCadPessoa">
                <label>
                  <Translate>Digite o Endereço</Translate>:
                </label>
              </div>

              <div className="linhaDoisCadPessoa">
                <input
                  placeholder="Endereço"
                  type="text"
                  id="fname"
                  defaultValue={campos.empEndereco}
                  name="empEndereco"
                  onChange={handleInputChange}
                ></input>
              </div>

              <div className="linhaDoisCadPessoa">
                <label>
                  <Translate>Quantidade de licenças</Translate>:
                </label>
              </div>

              {
                (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                  <div className="linhaDoisCadPessoa">
                    <input
                      type="number"
                      id="fname"
                      defaultValue={campos.empQuantidadeLicencas}
                      name="empQuantidadeLicencas"
                      min={1}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                ) : (
                  <div className="linhaDoisCadPessoa">
                    <label>{campos.empQuantidadeLicencas}</label>
                  </div>
                )
              }
              
              <label className="ttlCadARE5">
                <Translate>Observação</Translate>:
              </label>
              <textarea
                className="fObsEmpresa"
                placeholder="Observações"
                type="text"
                id="fname"
                name="empObservacoes"
                row="none"
                defaultValue={campos.empObservacoes}
                maxLength="500"
                onChange={handleInputChange}
              ></textarea>

              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                {
                  (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                    <label>
                      <input
                        className="CheckAcess1"
                        name="empStatus"
                        type="checkbox"
                        defaultChecked={!!campos.empStatus}
                        onChange={handleInputChange}
                      />
                      <span className="checkText">
                        <Translate>Status</Translate>
                      </span>
                    </label>
                  ) : (
                    ''
                  )
                }
                  
                </div>
                {
                  (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                    <button type="submit">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  ) : (
                    ''
                  )
                }


                
              </div>
            </form>
            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:{' '}
                </td>
                <td>{campos.empCriadoPor}</td>
                <td>em {campos.empCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{campos.empAlteradoPor}</td>
                <td>em {campos.empAlteradoEm}</td>
              </tr>
            </table>
          </div>
        </div>
      </>
    </Translator>
  )
}
export default Modal
