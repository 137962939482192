import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'

import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import '../../../styles/pages/SubMenuPages/SubMenuGruposUsuarios/pages-GruposDeUsuario/PermissoesDeAcessoAoSistema.css'

function VinculoUnidade() {

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  let id = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    setSearchTerm(sessionStorage.searchTerm)
  })
  var user = authService.getCurrentUser()
  let history = useHistory()
  let newDate = new Date()
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [campos, setCampos] = useState({
    IdVinculoPessoa: 0,
    IdPessoa: id['IdPessoa'],
    IdEmpresa: user[0].IdEmpresa,
    IdUnidade: 0,
    vcpStatus: 1,
    vcpCriadoPor: user[0].pesEmail,
    vcpCriadoEm: newDate,
    vcpAlteradoPor: user[0].pesEmail,
    vcpAlteradoEm: newDate,
    vcpCodigoGUID: '',
    vcpGUIDOrigem: '',
    vcpPrincipal: 0
  })
  const [unidades, setUnidades] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL + '/api/v1/vinculo/list/' + id['IdPessoa']
      )
      .then(response => {
        setUnidades(response.data)
        if (sessionStorage.IdUnidadeEdit === undefined) {
          sessionStorage.IdUnidadeEdit = response.data[0].IdUnidade
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          window.location.reload()
        }
      })
  }, [])

  const [nomePessoa, setNomePessoa] = useState("")
  const [pessoa, setPessoa] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/'
        + id['IdPessoa']
      )
      .then(response => {
        setPessoa(response.data)
        setNomePessoa(response.data[0].pesNome)
      })
  }, [])

  function handleUnidadeChange(event, unidade) {
    if (unidade.IdVinculoPessoa) {
      let status = 0
      campos.vcpPrincipal = unidade.vcpPrincipal;

      if (event.target.checked) {
        status = 1
      }
      else {
        campos.vcpPrincipal = 0;
      }

      campos.vcpStatus = status

      setCampos(campos)
      event.preventDefault()
      axios
        .post(
          process.env.REACT_APP_API_URL +
          '/api/v1/vinculo/' +
          unidade.IdVinculoPessoa,
          campos
        )
        .then(response => {
          window.location.reload()
        })
    } else {
      let status = 0
      if (event.target.checked) {
        status = 1
      }
      campos.vcpStatus = status
      campos.IdUnidade = unidade.IdUnidade
      setCampos(campos)
      event.preventDefault()
      axios
        .post(process.env.REACT_APP_API_URL + '/api/v1/vinculo', campos)
        .then(response => {
          window.location.reload()
        })
      //console.log(event.uniNome)
    }
  }

  function handlePrincipalChange(event, unidade) {
    if (unidade.IdVinculoPessoa) {
      if (parseInt(unidade.vcpStatus) === 1) {
        let status = 0
        if (event.target.checked) {
          status = 1
        }
        campos.vcpPrincipal = status
        setCampos(campos)
        event.preventDefault()

        axios
          .post(
            process.env.REACT_APP_API_URL +
            '/api/v1/vinculoprincipalunico/' +
            unidade.IdPessoa +
            '/' +
            unidade.IdVinculoPessoa
          )
          .then(resp => {
            axios
              .post(
                process.env.REACT_APP_API_URL +
                '/api/v1/vinculoprincipal/' +
                unidade.IdVinculoPessoa +
                '/' +
                unidade.IdPessoa,
                campos
              )
              .then(response => {
                window.location.reload()
              })
          })
      }
    }
  }


  //const [toogle, setToogle] = React.useState(false)

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>VINCULO DE PESSOA COM UNIDADES</Translate>:
              </TitlePage1>
              <TitlePage2>
                <Translate>{nomePessoa}</Translate>
              </TitlePage2>
            </TitleOfPage>
            <div id="containerPesquisaModal">
              {/* {' '} */}
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fpesquisa"
                value={sessionStorage.searchTerm}
                onChange={event => {
                  setSearchTerm(event.target.value)
                  sessionStorage.searchTerm = event.target.value
                }}
              />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="tabelaPadrao">
            <table>
              <tr>
                <th>
                  <Translate>Permissão</Translate>
                </th>
                <th>
                  <Translate>Principal</Translate>
                </th>
                <th>
                  <Translate>Módulo</Translate>
                </th>
                <th>
                  <Translate>Descrição</Translate>
                </th>
              </tr>

              {unidades
                .filter(val => {
                  if (searchTerm === '' &&
                    parseInt(val.IdEmpresa) === parseInt(user[0].IdEmpresa)
                  ) {
                    return val
                  } else if (
                    val.uniNome.toLowerCase().includes(searchTerm.toLowerCase())
                    &&
                    parseInt(val.IdEmpresa) === parseInt(user[0].IdEmpresa)
                  ) {
                    return val
                  }
                })
                .map(unidade => (
                  <tr>
                    <td >
                      <input
                        type="checkbox"
                        checked={!!unidade.vcpStatus}
                        onChange={event => {
                          handleUnidadeChange(event, unidade)
                        }}
                      />
                    </td>
                    <td >
                      <input
                        type="checkbox"
                        checked={!!unidade.vcpPrincipal}
                        onChange={event => {
                          handlePrincipalChange(event, unidade)
                        }}
                      />
                    </td>
                    <td style={{
                      textTransform: 'uppercase'
                    }}><p>{unidade.uniNome}</p></td>
                    <td style={{
                      textTransform: 'uppercase'
                    }}>
                      <p className="Space">
                        <Translate>{unidade.uniObservacoes}</Translate>
                      </p>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          {/* <SideBarDireita /> */}
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default VinculoUnidade
