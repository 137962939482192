import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage24
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'

import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import InfoUnidade from '../../Relatorio/DetalhesDaObservação/InfoUnidade'

const uuidv4 = require('uuid/v4')

function MesSetor() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  //var user = authService.getCurrentUser()
  let newDate = Moment(new Date()).utc(-3).format("DD/MM/yyyy HH:mm")
  //new Date().utc(-3).format("DD/MM/yyyy HH:mm:ss")
  let id = useParams()
  const [campos, setCampos] = useState({
    IdRetornoObservacao: '',
    IdObservacaoTipo: sessionStorage.IdObservacaoTipoPree,
    IdEmpresa: parseInt(user[0].IdEmpresa),
    IdPessoa: parseInt(user[0].IdPessoa),
    IdObservadores: parseInt(user[0].IdPessoa),
    IdUnidade: parseInt(id['idUni']),
    IdLocalArea: parseInt(id['idAr']),
    IdEquipe: sessionStorage.IdEquipePree,
    IdAtividade: sessionStorage.IdAtividade,
    rtoRotina: sessionStorage.rtoRotinaPree,
    rtoObservados: 0,
    rtoObservadores: 0,
    rtoPontuacao: 0,
    rtoIniciadoEm: newDate,
    rtoFinalizadoEm: '',
    rtoStatus: 1,
    rtoObservacoes: '',
    rtoCriadoPor: user[0].pesEmail,
    rtoCriadoEm: newDate,
    rtoAlteradoPor: user[0].pesEmail,
    rtoAlteradoEm: newDate,
    rtoCodigoGUID: uuidv4()
  })
  const [camposAtividade, setCamposAtividades] = useState({
    IdAtividade: 0,
    IdEmpresa: user[0].IdEmpresa,
    IdUnidade: id['idUni'],//sessionStorage.IdUnidadeNovaObs,
    atvDescricao: '',
    atvStatus: 1,
    atvObservacoes: '',
    atvCriadoPor: user[0].pesEmail,
    atvCriadoEm: newDate,
    atvAlteradoPor: user[0].pesEmail,
    atvAlteradoEm: newDate,
    atvCodigoGUID: uuidv4()
  })
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      camposAtividade['atvDescricao'] = event.target.value
      setCamposAtividades(camposAtividade)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/v1/atividade',
          camposAtividade
        )
        .then(response => {
          alert(response.data.message)
          sessionStorage.IdAtividade = response.data.id
          sessionStorage.IdAtividadePree = response.data.id
          window.location.reload()
        })
    }
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    console.log(campos)
    if (campos.IdEquipe === undefined || campos.IdEquipe === 0) {
      alert('Selecione a Equipe observada!')
    } else if (
      campos.IdObservacaoTipo === undefined ||
      campos.IdObservacaoTipo === 0
    ) {
      alert('Selecione o Tipo de Observação!')
    } else if (campos.rtoRotina === undefined || campos.rtoRotina === '') {
      alert('Selecione o Tipo de Atividade!')
    } else if (campos.IdAtividade === undefined || campos.IdAtividade === 0) {
      alert('Informe a Atividade Observada!')
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/v1/retornoobservacao',
          campos
        )
        .then(response => {
          alert(response.data.message)
          if (response.status === 200) {
            let idObservacao = response.data.id
            axios
              .post(process.env.REACT_APP_API_URL + '/api/v1/retornoquestao', {
                IdRetornoObservacao: idObservacao
              })
              .then(response => {
                history.push(
                  '/RetornoQuestao/' +
                  id['idUni'] +
                  '/' +
                  id['idAr'] +
                  '/' +
                  idObservacao
                )
              })
          }
        })
    }
  }
  const [equipes, setEquipes] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/equipe/list/' +
        user[0].IdEmpresa +
        '/' +
        id['idUni']
        //sessionStorage.IdUnidadeNovaObs
      )
      .then(response => {
        setEquipes(response.data)
      })
  }, [])
  const [observacaotipos, setObservacaotipos] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipopessoa/list/' +
        user[0].IdPessoa
      )
      .then(response => {
        //console.log(response.data)
        setObservacaotipos(response.data)
      })
  }, [])
  const [atividades, setAtividades] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/atividade/list/' +
        user[0].IdEmpresa +
        '/' +
        id['idUni']
        //sessionStorage.IdUnidadeNovaObs
      )
      .then(response => {
        setAtividades(response.data)
      })
  }, [])
  const [local, setLocal] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/area/' + id['idAr'])
      .then(response => {
        setLocal(response.data)
        sessionStorage.nomeLocal = response.data[0].locDescricao
      })
  }, [])
  const [eqp, setSearchEquipe] = useState('')
  const [rtoRotina, setrtoRotina] = useState('')
  const [IdAtividade, setIdAtividade] = useState('')
  let history = useHistory()
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [toogle, setToogle] = React.useState(false)
  const [width, setWidth] = React.useState('90%')
  React.useEffect(() => {
    setWidth(state => (toogle ? '90%' : '65%'))
  }, [toogle])

  function handleInputChange(event) {
    if (event.target.name === 'gerStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
    if (event.target.name === 'IdEquipe') {
      sessionStorage.EqpDesc =
        event.target.options[event.target.selectedIndex].text
      setSearchEquipe(event.target.options[event.target.selectedIndex].text)
      sessionStorage.IdEquipePree = event.target.value
    }
    if (event.target.name === 'IdAtividade') {
      sessionStorage.IdAtividade = event.target.options[event.target.value]
      setIdAtividade(event.target.options[event.target.value])
    }
    if (event.target.name === 'rtoRotina') {
      sessionStorage.rtoRotina =
        event.target.options[event.target.selectedIndex].text
      sessionStorage.rtoRotinaPree =
        event.target.options[event.target.selectedIndex].text
      setrtoRotina(event.target.options[event.target.selectedIndex].text)
    }
    if (event.target.name === 'IdObservacaoTipo') {
      sessionStorage.IdObservacaoTipoPree = event.target.value
    }
  }
  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <button
              className="btnReturn"
              type="button"
              onClick={() => history.goBack()}
            >
              <TypIcons.TiArrowBackOutline />
            </button>
            <TitleOfPage>
              <TitlePage1>
                <Translate>Informações inicias para nova Observação</Translate>:
              </TitlePage1>
              <TitlePage24>{sessionStorage.uniRazaoSocial}</TitlePage24>
            </TitleOfPage>
          </div>
          <div className="containerSelectInfoObser">
            <form onSubmit={handleFormSubmit}>
              <select
                required
                name="IdEquipe"
                typeof="text"
                id="IdEquipe"
                onChange={handleInputChange}
              >
                <option value="0">1 - Selecione a equipe observada</option>
                {equipes
                  .sort((a, b) => (a.eqpDescricao > b.eqpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.eqpStatus === 1) {
                      return val
                    }
                  })
                  .map(equipe => (
                    <option
                      value={equipe.IdEquipe}
                      selected={
                        parseInt(equipe.IdEquipe) ===
                        parseInt(sessionStorage.IdEquipePree)
                      }
                    >
                      {equipe.eqpDescricao}
                    </option>
                  ))}
              </select>
              <select
                required
                name="IdObservacaoTipo"
                typeof="text"
                defaultValue={campos.IdObservacaoTipo}
                id="IdObservacaoTipo"
                onChange={handleInputChange}
              >
                <option value="0">2 - Indique qual o tipo de observação</option>
                {observacaotipos
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(observacaotipo => (
                    <option
                      value={observacaotipo.IdObservacaoTipo}
                      selected={
                        parseInt(observacaotipo.IdObservacaoTipo) ===
                        parseInt(sessionStorage.IdObservacaoTipoPree)
                      }
                    >
                      {observacaotipo.otpDescricao}
                    </option>
                  ))}
              </select>
              <select
                required
                name="rtoRotina"
                typeof="text"
                id="rtoRotina"
                onChange={handleInputChange}
              >
                <option value="0">3 - Tipo de atividade</option>
                <option
                  value="ROTINA"
                  selected={sessionStorage.rtoRotinaPree === 'ROTINA'}
                >
                  ROTINA
                </option>
                <option
                  value="PARADA"
                  selected={sessionStorage.rtoRotinaPree === 'PARADA'}
                >
                  PARADA
                </option>
                <option
                  value="EVENTUAL"
                  selected={sessionStorage.rtoRotinaPree === 'EVENTUAL'}
                >
                  EVENTUAL
                </option>
              </select>
              <select
                name="IdAtividade"
                typeof="text"
                id="IdAtividade"
                onChange={handleInputChange}
              >
                <option value="0">4 - Atividade observada</option>
                {atividades
                  .sort((a, b) => (a.atvDescricao > b.atvDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.atvStatus === 1) {
                      return val
                    }
                  })
                  .map(atividade => (
                    <option
                      value={atividade.IdAtividade}
                      selected={
                        parseInt(atividade.IdAtividade) ===
                        parseInt(sessionStorage.IdAtividadePree)
                      }
                    >
                      {atividade.atvDescricao}
                    </option>
                  ))}
              </select>
              <label htmlFor="">
                OU - DIGITE A NOVA ATIVIDADE A SER OBSERVADA E PRESSIONE ENTER:
              </label>
              <textarea
                typeof="text"
                rows={1}
                className="fAtividadeObservIns"
                name="atvDescricao"
                onKeyDown={handleKeyDown}
              />
              <line>
                <button type="submit">
                  <Translate>Iniciar observação</Translate>
                </button>
              </line>
            </form>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ------------------------------------------------------------------------------------------------------ */}

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            <InfoUnidade
              Observador={user[0].pesNome}
              atvDescricao={IdAtividade}
              uniNome={sessionStorage.uniRazaoSocial}
              equipe_abordada={eqp}
              local={sessionStorage.nomeLocal}
              rtoIniciadoEm={''}
              rtoFinalizadoEm={''}
              rtoObservados={''}
              rtoRotina={rtoRotina}
              rtoObservadores={''}
              rtoObservacoes={''}
            />
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default MesSetor
