import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import graph from '../../Images/Graph.svg'
import Home from '../../Images/Home.svg'
import Logout from '../../Images/Logout.svg'
import Pessoas from '../../Images/Pessoas.svg'
import Pointer from '../../Images/pointer.svg'
import authService from '../Login/auth.service'

import '../../Components/Sidebar/index.css'

import logo from '../../Images/background.svg'

function SideBar() {
  const permissoes = localStorage.getItem('permissoes')
  let permissao = []
  if (permissoes) {
    permissao = JSON.parse(permissoes)
  } else {
    permissao = [
      {
        perModulo: 'ACECAD',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEQP',
        perAcesso: 1
      },
      {
        perModulo: 'ACEPES',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGER',
        perAcesso: 1
      },
      {
        perModulo: 'ACEATV',
        perAcesso: 1
      },
      {
        perModulo: 'ACEARE',
        perAcesso: 1
      },
      {
        perModulo: 'ACEOBS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEDAS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEREL',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEMP',
        perAcesso: 1
      },
      {
        perModulo: 'ACETPO',
        perAcesso: 1
      },
      {
        perModulo: 'ACETXA',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGPU',
        perAcesso: 1
      },
      {
        perModulo: 'ACEFUN',
        perAcesso: 1
      }
    ]
  }
  let ACECAD = permissao.find(element => {
    return element.perModulo === 'ACECAD'
  })
  let ACEEQP = permissao.find(element => {
    return element.perModulo === 'ACEEQP'
  })
  let ACEPES = permissao.find(element => {
    return element.perModulo === 'ACEPES'
  })
  let ACEGER = permissao.find(element => {
    return element.perModulo === 'ACEGER'
  })
  let ACEATV = permissao.find(element => {
    return element.perModulo === 'ACEATV'
  })
  let ACEARE = permissao.find(element => {
    return element.perModulo === 'ACEARE'
  })
  let ACEOBS = permissao.find(element => {
    return element.perModulo === 'ACEOBS'
  })
  let ACEDAS = permissao.find(element => {
    return element.perModulo === 'ACEDAS'
  })
  let ACEREL = permissao.find(element => {
    return element.perModulo === 'ACEREL'
  })
  let ACEEMP = permissao.find(element => {
    return element.perModulo === 'ACEEMP'
  })
  let ACETPO = permissao.find(element => {
    return element.perModulo === 'ACETPO'
  })
  let ACETXA = permissao.find(element => {
    return element.perModulo === 'ACETXA'
  })
  let ACEGPU = permissao.find(element => {
    return element.perModulo === 'ACEGPU'
  })
  let ACEFUN = permissao.find(element => {
    return element.perModulo === 'ACEFUN'
  })

  //let tela
  // if(tela !== sessionStorage.TelaMenuPrincipal)
  // {
  //   tela = sessionStorage.TelaMenuPrincipal
  //   window.location.reload()
  // }
  //sessionStorage.TelaMenuPrincipal = 'Home'
  let [iconeEsq, setIconeEsq] = useState([])
  useEffect(() => {
    setIconeEsq(sessionStorage.TelaMenuPrincipal)
  }, [])

  function updateIconLink(event) {
    sessionStorage.TelaMenuPrincipal = event
    setIconeEsq(sessionStorage.TelaMenuPrincipal)
    //window.location.reload()
  }
  //window.location.reload()
  return (
    <aside className="MenuPrincipal">
      <img className="logoMenu" src={logo} alt="Menu Interativo esquerdo" />
      <table>
        <tbody>
          <tr>
            {!!ACEREL.perAcesso ? (
              <Link
                onClick={event => {
                  updateIconLink('Home')
                }}
                to="/Home"
              >
                <div
                  className={
                    iconeEsq === 'Home' ? 'selectIcon' : 'noSelectIcon'
                  }
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Home"
                >
                  <img width="100%" height="100%" src={Home} alt="Home" />
                </div>
              </Link>
            ) : (
              <li></li>
            )}
          </tr>
          <tr>
            {!!ACECAD.perAcesso && sessionStorage.uniRazaoSocial !== 'Todos'? (
              <Link
                onClick={event => {
                  updateIconLink('FormularioCadastro')
                }}
                to="/FormularioCadastro"
              >
                <div
                  className={
                    iconeEsq === 'FormularioCadastro'
                      ? 'selectIcon'
                      : 'noSelectIcon'
                  }
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Dados cadastrais"
                >
                  <img
                    src={Pointer}
                    width="100%"
                    height="100%"
                    alt="Dados cadastrais"
                  />
                </div>
              </Link>
            ) : (
              <li></li>
            )}
          </tr>
          <tr>
            {!!ACEOBS.perAcesso ? (
              <Link
                onClick={event => {
                  updateIconLink('ObservacoesListar')
                }}
                to="/ApontarObservacao/ObservacoesListar"
              >
                <div
                  className={
                    iconeEsq === 'ObservacoesListar'
                      ? 'selectIcon'
                      : 'noSelectIcon'
                  }
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Minhas Observações"
                >
                  <img
                    src={Pessoas}
                    width="100%"
                    height="100%"
                    alt="Observações Realizadas"
                  />
                </div>
              </Link>
            ) : (
              <li></li>
            )}
          </tr>
          <tr>
            {!!ACEREL.perAcesso ? (
              <Link
                onClick={event => {
                  updateIconLink('Relatorio')
                }}
                to="/Relatorio"
              >
                <div
                  className={
                    iconeEsq === 'Relatorio' ? 'selectIcon' : 'noSelectIcon'
                  }
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Unidade / Relatório"
                >
                  <img
                    src={graph}
                    width="100%"
                    height="100%"
                    alt="Unidade / Relatório"
                  />
                </div>
              </Link>
            ) : (
              <li></li>
            )}
          </tr>
        </tbody>
      </table>
      <div className="espaçoMenu">
        <Link to="/">
          <div>
            <img
              src={Logout}
              width="100%"
              height="100%"
              alt="desconectar"
              onClick={() => authService.logout()}
            />
          </div>
        </Link>
      </div>
    </aside>
  )
}

export default SideBar
