import styled from 'styled-components'

export const ContainerPage = styled.div`
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  display: block;
  position: relative;
  left: 0;
  width: 100%;
  height: 100vh;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transition: all 500ms ease-in-out;
  padding-bottom: 5%;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: rgb(219, 219, 219);
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
    outline: 1px solid slategrey;
  }
  @media screen and (max-width: 820px) {
    position: fixed;
    overflow-x: hidden;
    display: block;
    left: 0;
    width: 100%;
    height: 90vh;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-bottom: 5%;
    z-index: 0;
    transition: all 0.3s ease-in-out;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: rgb(219, 219, 219);
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    }
    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 5px;
      outline: 1px solid slategrey;
    }
  }
`
export const ContainerPageProfile = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 35%;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
`
export const CxTitle = styled.div`
  background-color: transparent;
  width: 60%;
  position: absolute;
  display: grid;
  left: 5%;
  top: 120px;
`
export const CxTitle1 = styled.div`
  background-color: transparent;
  width: 60%;
  position: absolute;
  display: grid;
  left: 5%;
  top: 120px;
`
export const ContainerPage2 = styled.div`
  position: fixed;
  padding-top: 5%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`
export const TitleOfPage = styled.div`
  display: flex;
  position: relative;
  left: 5%;
  width: 90%;
  height: 100%;
  /* background-color: blue; */
`
export const TitleOfPageRel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  left: 10%;
  width: 80%;
  height: 20%;
  /* border: 1px solid #000; */
  /* background: red; */
`
export const TitlePageRel = styled.h5`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  font: clamp(0.01em, 0.01em + 1.8vw, 1.8em) BalooBhaina2-SemiBold;
  border-style: none;
  /* background: purple; */
  color: #249278;
  z-index: 2;
`
export const TitlePageRel1 = styled.h5`
  position: absolute;
  top: 10px;
  left: 0%;
  width: 80%;
  font-size: clamp(0.1em, 0.1em + 1.5vw, 1.5em);
  font-family: BalooBhaina2-Regular;
  padding-top: 5px;
  background: transparent;
  color: #8d8d8d;
  z-index: 2;
`
export const DataAudit = styled.h5`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  font-size: clamp(0.1em, 0.1em + 1.5vw, 1.5em);
  font-family: BalooBhaina2-Regular;
  border-style: none;
  background: transparent;
  color: #249278;
  z-index: 2;
`
export const TitlePage1 = styled.h5`
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 20px;
  width: 80%;
  line-height: 20px;
  display: block;
  font-size: clamp(0.1em, 0.1em + 1.4vw, 1.4em);
  font-family: BalooBhaina2-Bold;
  color: #adadad;
  @media screen and (max-width: 820px) {
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 20px;
    width: 80%;
    line-height: 20px;
    display: block;
    font-size: 16px;
    font-family: BalooBhaina2-Bold;
    color: #adadad;
  }
`
export const TitlePage11 = styled.h5`
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 20px;
  width: 400px;
  line-height: 20px;
  display: block;
  font-size: clamp(0.1em, 0.1em + 1.2vw, 1.2em);
  font-family: BalooBhaina2-Bold;
  color: #adadad;
  z-index: 1;
`
export const TitlePage2 = styled.h5`
  position: absolute;
  top: 40%;
  left: 0%;
  width: 50%;
  line-height: 25px;
  height: 30%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  /* background-color: red; */
  background-color: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePageRelSub = styled.h5`
  position: absolute;
  top: 40%;
  left: 0%;
  line-height: 25px;
  height: 30%;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  /* background-color: red; */
  background-color: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePage21 = styled.h5`
  position: absolute;
  top: 40%;
  left: 0%;
  width: 100%;
  line-height: 25px;
  height: 30%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  /* background-color: red; */
  background-color: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePageRelatorio = styled.h5`
  position: absolute;
  bottom: 5%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  /* background-color: red; */
  color: #172d52;
  z-index: 2;
`
export const TitlePage22 = styled.h5`
  position: absolute;
  top: 25%;
  left: 0%;
  font-size: clamp(0.1em, 0.1em + 1.5vw, 1.5em);
  font-family: BalooBhaina2-Bold;
  border-style: none;
  background: transparent;
  color: #172d52;
`
export const TitlePage23 = styled.h5`
  position: absolute;
  top: 15%;
  left: 0%;
  width: 80%;
  font-size: clamp(0.1em, 0.1em + 1.2vw, 1.2em);
  line-height: 18px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  background: transparent;
  color: #172d52;
  @media screen and (max-width: 820px) {
    position: absolute;
    top: 20%;
    left: 0%;
    width: 80%;
    font-size: 14px;
    line-height: 18px;
    font-family: BalooBhaina2-Bold;
    border-style: none;
    background: transparent;
    color: #172d52;
  }
`
export const TitlePage24 = styled.h5`
  position: absolute;
  top: 20%;
  left: 0%;
  width: 80%;
  font-size: clamp(0.1em, 0.1em + 1.5vw, 1.5em);
  line-height: 18px;
  font-family: BalooBhaina2-Bold;
  border-style: none;
  background: transparent;
  color: #172d52;
  @media screen and (max-width: 820px) {
    position: absolute;
    top: 20%;
    left: 0%;
    width: 80%;
    font-size: 12px;
    line-height: 18px;
    font-family: BalooBhaina2-Bold;
    border-style: none;
    background: transparent;
    color: #172d52;
  }
`
export const TitlePage3 = styled.h5`
  position: relative;
  left: 0;
  top: 15px;
  font-size: 16px;
  font-family: BalooBhaina2-Regular;
  border-style: none;
  background: transparent;
  line-height: 20px;
  color: #172d52;
  z-index: 2;
`
export const TitlePage4 = styled.h5`
  position: absolute;
  left: 5%;
  font-family: BalooBhaina2-bold;
  font-size: clamp(0.1em, 0.1em + 1.5vw, 1.5em);
  border-style: none;
  line-height: 20px;
  background: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePage5 = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  font-family: BalooBhaina2-bold;
  font-size: clamp(0.1em, 0.1em + 2vw, 2em);
  border-style: none;
  background: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePage6 = styled.h5`
  position: absolute;
  bottom: 0;
  left: 0;
  font: clamp(0.01em, 0.01em + 1.8vw, 1.8em) BalooBhaina2-Bold;
  border-style: none;
  background: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePage7 = styled.h5`
  position: absolute;
  top: 25%;
  left: 0%;
  font-size: clamp(0.1em, 0.1em + 1.4vw, 1.4em);
  font-family: BalooBhaina2-Bold;
  border-style: none;
  background: transparent;
  color: #172d52;
  z-index: 2;
`
export const TitlePage8 = styled.h5`
  position: absolute;
  bottom: 10%;
  left: 0;
  font-size: 18px;
  font-family: BalooBhaina2-Medium;
  border-style: none;
  background: transparent;
  color: #172d52;
  z-index: 2;
  @media screen and (max-width: 620px) {
    position: absolute;
    bottom: 20%;
    left: 0;
    font-size: 16px;
    font-family: BalooBhaina2-Medium;
    border-style: none;
    background: transparent;
    color: #172d52;
    z-index: 2;
  }
`
export const TitlePage9 = styled.h5`
  position: absolute;
  bottom: 40%;
  left: 0;
  width: 80%;
  font: clamp(0.01em, 0.01em + 1.4vw, 1.4em) BalooBhaina2-Bold;
  line-height: 18px;
  border-style: none;
  /* background: red; */
  color: #172d52;
  z-index: 2;
`
