import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdAtividade: modalInfo.IdAtividade,
    IdEmpresa: modalInfo.IdEmpresa,
    IdUnidade: modalInfo.IdUnidade,
    atvDescricao: modalInfo.atvDescricao,
    atvStatus: modalInfo.atvStatus,
    atvObservacoes: modalInfo.atvObservacoes,
    atvAlteradoPor: user[0].pesEmail,
    atvAlteradoEm: newDate
  })

  function handleInputChange(event) {
    if (event.target.name === 'atvStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/atividade/' +
          campos.IdAtividade,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Atividade</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form className="formModalPadrao" onSubmit={handleFormSubmit}>
              <label>
                <Translate>Alterar atividade :</Translate>
              </label>
              <input
                placeholder="Nova Atividade"
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                onChange={handleInputChange}
                name="atvDescricao"
                defaultValue={campos.atvDescricao}
              ></input>
              <label>
                <Translate>Observações</Translate>:
              </label>

              <textarea
                placeholder="Observações"
                type="text"
                id="fname"
                name="atvObservacoes"
                rows={5}
                style={{textTransform:'uppercase'}}
                defaultValue={campos.atvObservacoes}
                row="none"
                onChange={handleInputChange}
                maxLength="500"
              ></textarea>

              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="atvStatus"
                      type="checkbox"
                      defaultChecked={!!campos.atvStatus}
                      onChange={handleInputChange}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit" className="gravar-modal">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.atvCriadoPor}</td>
                <td>em {modalInfo.atvCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.atvAlteradoPor}</td>
                <td> em {modalInfo.atvAlteradoEm}</td>
              </tr>
            </table>
          </div>
        </Translator>
      </div>
    </>
  )
}
