import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Translate, Translator } from 'react-auto-translate';
import { Table, Tbody } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import authService from '../Login/auth.service';

export default function TabelaAtivadores(p_Inicio, p_Fim) {

    const cacheProvider = {
        get: (language, key) =>
            ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
            language
            ],
        set: (language, key, value) => {
            const existing = JSON.parse(localStorage.getItem('translations')) || {
                [key]: {},
            };
            existing[key] = { ...existing[key], [language]: value };
            localStorage.setItem('translations', JSON.stringify(existing));
        },
    };
    var user = authService.getCurrentUser()

    const [ativadores, setAtivador] = useState([])
    //console.log(p_Inicio)
    useEffect(() => {
        var url =
            '/api/v1/ativadoresListaGerencia/' +
            p_Inicio['p_Inicio'] +
            '/' +
            p_Inicio['p_Fim'] +
            '/' +
            sessionStorage.IdUnidadeEdit +
            '/' +
            sessionStorage.IdObservacaoTipo +
            '/' +
            sessionStorage.idGerencia

        axios.get(process.env.REACT_APP_API_URL + url).then(response => {
            //console.log(response.data)
            setAtivador(response.data)
        })
    }, [])
    return (
        <Translator
            cacheProvider={cacheProvider}
            from='pt'
            to={user[0].pesIdioma}
            googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
            <Table>
                <Tbody>
                    {ativadores
                        .map(ativador => (
                            <tr>
                                <td className="ResultPizza"><Translate>{ativador.quantidade}</Translate></td>
                                <td className="ResultPizza"><Translate>{ativador.attDescricao}</Translate></td>
                                <td className="ResultPizza"><Translate>{ativador.gpaDescricao}</Translate></td>
                            </tr>
                        ))}
                </Tbody>
            </Table>
        </Translator>
    );
}
TabelaAtivadores.propTypes = {
    p_Inicio: PropTypes.string.isRequired,
    p_Fim: PropTypes.string.isRequired
}