import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import validator from 'validator'
import '../../../styles/pages/MenuDireito/modal/modalPessoa.css'
import authService from '../../../Components/Login/auth.service'
const uuidv4 = require('uuid/v4')
//const crypto = require('crypto')

export default function ModalPessoa() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [funcoes, setFuncao] = useState([])
  const [grupos, setGrupo] = useState([])
  const [gerencias, setGerencia] = useState([])
  const [modal, setModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/funcao/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setFuncao(response.data)
      })
  },[])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/grupoUsuario/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setGrupo(response.data)
      })
  },[])
  useEffect(() => {
    if(parseInt(sessionStorage.IdUnidadeEdit) !== 0 && sessionStorage.IdUnidadeEdit !== undefined)
    {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/gerencia/listapp/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          setGerencia(response.data)
        })
    }
    else
    {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/gerencia/listapp/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setGerencia(response.data)
      })
    }
  },[])
  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  let newDate = new Date()
  const [imagens, setImagens] = useState({
    NomeImagem: '',
    Imagem: ''
  })
  const [campos, setCampos] = useState({
    IdPessoa: '',
    IdUnidade: sessionStorage.IdUnidadeEdit,
    IdEmpresa: user[0].IdEmpresa,
    IdFuncao: 0,
    IdGrupoUsuario: 0,
    IdGerencia: 0,
    pesNome: '',
    pesFoto: '',
    pesEmail: '',
    pesSenha: '',
    confPesSenha: '',
    pesTelefone: '',
    pesCelular: '',
    pesEndereco: '',
    pesAcessoWeb: 1,
    pesAcessoApp: 1,
    pesIdioma: '',
    pesStatus: 1,
    pesObservacoes: '',
    pesCriadoPor: user[0].pesEmail,
    pesCriadoEm: newDate,
    pesAlteradoPor: user[0].pesEmail,
    pesAlteradoEm: newDate,
    pesCodigoGUID: uuidv4()
  })
  
  function handleInputChange(event) {
    //console.log(event.target.checked)
    if (
      event.target.name === 'pesAcessoWeb' ||
      event.target.name === 'pesAcessoApp' ||
      event.target.name === 'pesStatus'
    ) {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
      //console.log(event.target.value)
    } else {
      if (event.target.name === 'pesSenha') {
        if (
          validator.isStrongPassword(event.target.value, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1
          })
        ) {
          setErrorMessage('')
          setErrorPass(false)
          if (event.target.value !== campos.confPesSenha) {
            setErrorPass(true)
            setErrorMessage('A confirmação da senha está incorreta')
          } else {
            setErrorMessage('')
            setErrorPass(false)
          }
        } else {
          setErrorPass(true)
          setErrorMessage(
            'A senha não é segura o bastante, a senha deve conter 1 caracter especial, uma letra maiúscula e um número!'
          )
        }
      }
      if (event.target.name === 'confPesSenha') {
        if (event.target.value !== campos.pesSenha) {
          setErrorPass(true)
          setErrorMessage('A confirmação da senha está incorreta')
        } else {
          setErrorMessage('')
          setErrorPass(false)
        }
      }
      
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
    //console.log(campos)
  }
  const [image, setImage] = useState('')
  const [endImg] = useState('./perfil.png')
  var bloqueio = false;
  
  function handleFormSubmit(event) {
    event.preventDefault()
    if(bloqueio === false)
    {
      bloqueio = true
      campos["pesEmail"] = campos["pesEmail"]
      .replace(/[áàãâä]/g, 'a')
      .replace(/[éèêë]/g, 'e')
      .replace(/[íìîï]/g, 'i')
      .replace(/[óòõôö]/g, 'o')
      .replace(/[úùûü]/g, 'u')
      .replace(/[ç]/g, 'c');

      const formData = new FormData()
      formData.append('file', image)
      var reader = new FileReader()
      if (image) {
        reader.readAsDataURL(image)
        reader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result
          imagens['Imagem'] = srcData.split(',')[1]
          imagens['NomeImagem'] = uuidv4()
          setImagens(imagens)
        }
        axios
          .post(process.env.REACT_APP_API_URL + '/upload', imagens)
          .then(response => {
            campos['pesFoto'] = response.data['url']
            //console.log(imagens)
            //console.log(response.data)
            setCampos(campos)
          })
      }
      axios
        .post(process.env.REACT_APP_API_URL + '/api/v1/pessoaV2', campos)
        .then(response => {
          if (response.status === 200) {
            bloqueio = false
            window.location.reload()
          } else {
            alert(response.data.message)
            bloqueio = false
          }
        })
    }
  }
  
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Nova Pessoa</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPessoa-content">
                <button className="close-modal" onClick={toggleModal}>
                  X
                </button>
                <h2 className="titleModal">
                  <Translate>Nova Pessoa</Translate>
                  <hr className="hrTitleModal" />
                </h2>
                <form className="formCadModal" onSubmit={handleFormSubmit}>
                  <table className="linhaUmCadPessoa">
                    <tr>
                      <th>
                        <Translate>Nome</Translate>
                      </th>
                    </tr>
                  </table>
                  <input
                    className="inputNome"
                    placeholder="Nome"
                    type="text"
                    id="fname"
                    style={{textTransform:'uppercase'}}
                    required
                    defaultValue={campos.pesNome}
                    name="pesNome"
                    onChange={handleInputChange}
                  />

                  <table className="linhaDoisCadPessoa">
                    <tr>
                      <th>
                        <Translate>Função</Translate>
                      </th>
                      <th style={{ paddingLeft: '5px' }}>
                        <Translate>Idioma</Translate>
                      </th>
                    </tr>
                    <td>
                      <select
                        name="IdFuncao"
                        type="text"
                        required
                        style={{textTransform:'uppercase'}}
                        defaultValue={campos.IdFuncao}
                        id="ffuncao"
                        onChange={handleInputChange}
                      >
                        <option value="0">Selecione uma Função</option>
                        {funcoes
                          .sort((a, b) =>
                            a.fncDescricao > b.fncDescricao ? 1 : -1
                          )
                          .map(funcao => (
                            <option value={funcao.IdFuncao}>
                              {funcao.fncDescricao}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td style={{ paddingLeft: '5px' }}>
                      <select
                        name="pesIdioma"
                        type="text"
                        required
                        defaultValue={campos.pesIdioma}
                        id="fidioma"
                        onChange={handleInputChange}
                      >
                        <option value="">Selecione o Idioma</option>
                        <option value="pt">Português</option>
                        <option value="en">Inglês</option>
                        <option value="es">Espanhol</option>
                      </select>
                    </td>
                  </table>
                  <table className="linhaTresCadPessoa">
                    <tr>
                      <th>
                        <Translate>Digite um E-mail</Translate>
                      </th>
                    </tr>
                  </table>
                  <input
                    className="inputCadMail"
                    placeholder="E-Mail"
                    type="text"
                    required
                    style={{textTransform:'lowercase'}}
                    defaultValue={campos.pesEmail}
                    id="fmail"
                    name="pesEmail"
                    onChange={handleInputChange}
                  />

                  <table className="linhaDoisCadPessoa">
                    <tr>
                      {' '}
                      <th>
                        <Translate>Telefone</Translate>
                      </th>
                      <th style={{ paddingLeft: '5px' }}>
                        <Translate>Celular</Translate>
                      </th>
                    </tr>
                    <td>
                      <input
                        disabled
                        placeholder="(__)____-____"
                        type="text"
                        defaultValue={campos.pesTelefone}
                        id="ftelefone"
                        name="pesTelefone"
                        onChange={handleInputChange}
                      />
                    </td>
                    <td style={{ paddingLeft: '5px' }}>
                      <input
                        disabled
                        placeholder="(__)____-____"
                        type="text"
                        defaultValue={campos.pesCelular}
                        id="ftelefone2"
                        name="pesCelular"
                        onChange={handleInputChange}
                      />
                    </td>
                  </table>

                  <table className="linhaDoisCadPessoa">
                    <tr>
                      <th>
                        <Translate>Grupo de Usuários</Translate>
                      </th>
                      <th style={{ paddingLeft: '5px' }}>
                        <Translate>Gerência Responsável</Translate>:
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <select
                          name="IdGrupoUsuario"
                          required
                          type="text"
                          defaultValue={campos.IdGrupoUsuario}
                          id="fgrupousuario"
                          onChange={handleInputChange}
                        >
                          <option value="">Selecione um grupo</option>
                          {grupos
                            .sort((a, b) =>
                              a.gruDescricao > b.gruDescricao ? 1 : -1
                            )
                            .map(grupo => (
                              <option value={grupo.IdGrupoUsuario}>
                                {grupo.gruDescricao}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td style={{ paddingLeft: '5px' }}>
                        {' '}
                        <select
                          name="IdGerencia"
                          type="text"
                          required
                          defaultValue={campos.IdGerencia}
                          id="fgerencia"
                          onChange={handleInputChange}
                        >
                          <option value="">Selecione uma gerência</option>
                          {gerencias
                            .sort((a, b) =>
                              a.gerDescricao > b.gerDescricao ? 1 : -1
                            )
                            .map(ger => (
                              <option value={ger.IdGerencia}>
                                {ger.gerDescricao}
                              </option>
                            ))}
                        </select>
                      </td>
                    </tr>
                  </table>
                  <table className="linhaDoisCadPessoa">
                    <tr>
                      {' '}
                      <th>
                        <Translate>Senha</Translate>
                      </th>
                      <th style={{ paddingLeft: '5px' }}>
                        <Translate>Confirmar Senha</Translate>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <input
                          placeholder="Senha"
                          type="password"
                          required
                          defaultValue={campos.pesSenha}
                          id="fsenha"
                          name="pesSenha"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td style={{ paddingLeft: '5px' }}>
                        <input
                          placeholder="Confirmar Senha"
                          type="password"
                          required
                          id="fconfsenha"
                          name="confPesSenha"
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  </table>
                  <table className="linhaQuatroCadPessoa">
                    <tr>
                      <th>
                        <Translate>Observações</Translate>
                      </th>
                    </tr>
                    <textarea
                      className="fcadastroPessoaObs"
                      placeholder="Observações"
                      type="text"
                      defaultValue={campos.pesObservacoes}
                      id="fobs"
                      name="pesObservacoes"
                      row="none"
                      maxLength="500"
                      onChange={handleInputChange}
                    />
                  </table>
                  <div className="barraSalvar">
                    <table className="cxAutorizacao">
                      <tr>
                        <td>
                          <input
                            name="pesAcessoApp"
                            type="checkbox"
                            id="facessoapp"
                            onChange={handleInputChange}
                            defaultChecked={!!campos.pesAcessoApp}
                          />
                        </td>
                        <td>
                          <span>
                            <Translate>Acesso ao APP</Translate>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            name="pesAcessoWeb"
                            type="checkbox"
                            id="facessoweb"
                            onChange={handleInputChange}
                            defaultChecked={!!campos.pesAcessoWeb}
                          />
                        </td>
                        <td>
                          <span>
                            <Translate>Acesso Web</Translate>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            name="pesStatus"
                            type="checkbox"
                            id="fstatus"
                            onChange={handleInputChange}
                            defaultChecked={!!campos.pesStatus}
                          />
                        </td>
                        <td>
                          <span>
                            <Translate>Status</Translate>
                          </span>
                        </td>
                      </tr>
                    </table>
                    {errorPass === true ? (
                      <span className="AlertPassword">
                        <Translate>{errorMessage}</Translate>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    <button
                      type="submit"
                      className="gravarPessoa"
                      disabled={errorPass}
                    >
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
                <div className="cxPhotoImport   ">
                  {image ? (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Logo2"
                      className="imgProfile"
                      width="150"
                      height="150"
                    />
                  ) : (
                    <img src={endImg} alt="Logo" className="imgProfile" />
                  )}
                  <input
                    type="file"
                    name="empLogo"
                    className="form-control-file-1"
                    id="empLogo"
                    accept=".gif,.jpg,.jpeg,.png"
                    onChange={function (event) {
                      setImage(event.target.files[0])
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
