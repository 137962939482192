import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'
const uuidv4 = require('uuid/v4')

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [gerencias, setGerencia] = useState([])
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) === 0)
    {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/gerencia/listapp/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeEdit
      )
      .then(response => {
        setGerencia(response.data)
      })
    } else {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/gerencia/listapp/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setGerencia(response.data)
      })
    }
  }, [])

  let newDate = new Date()
  //console.log(modalInfo)
  const [campos, setCampos] = useState({
    IdLocalAra: modalInfo.IdLocalAra,
    IdEmpresa: modalInfo.IdEmpresa,
    IdUnidade: modalInfo.IdUnidade,
    IdGerencia: modalInfo.IdGerencia ? modalInfo.IdGerencia : 0,
    locDescricao: modalInfo.locDescricao,
    locReferencia: modalInfo.locReferencia,
    locStatus: modalInfo.locStatus,
    locObservacoes: modalInfo.locObservacoes,
    locAlteradoPor: user[0].pesEmail,
    locAlteradoEm: newDate
  })
  //console.log(campos)
  function handleInputChange(event) {
    if (event.target.name === 'locStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    if (parseInt(campos.IdGerencia) === 0 || campos.IdGerencia === "0") {
      alert("Gerência é Obrigatório!")
    }
    else {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/v1/area/' + campos.IdLocalAra,
          campos
        )
        .then(response => {
          if (response.status === 200) {
            window.location.reload()
          } else {
            alert(response.data.message)
          }
        })
    }
  }
  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Área</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Nova Área</Translate>:
              </label>
              <input
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                defaultValue={campos.locDescricao}
                name="locDescricao"
                onChange={handleInputChange}
              ></input>

              <label>
                <Translate>Referência</Translate>:
              </label>
              <input
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                defaultValue={campos.locReferencia}
                name="locReferencia"
                onChange={handleInputChange}
              ></input>

              <label>
                <Translate>Selecione uma Gerência</Translate>:
              </label>
              <select
                name="IdGerencia"
                type="text"
                required
                defaultValue={campos.IdGerencia}
                id="fgerencia"
                onChange={handleInputChange}
              >
                <option value="0">Selecione uma gerência</option>
                {gerencias
                  //.sort((a, b) => (a.gerDescricao > b.gerDescricao ? 1 : -1))
                  .sort((a, b) =>
                    a.gerDescricao > b.gerDescricao ? 1 : -1
                  )
                  .map(gerencia => (
                    <option
                      value={gerencia.IdGerencia}
                      selected={gerencia.IdGerencia === campos.IdGerencia}
                    >
                      {gerencia.gerDescricao}
                    </option>
                  ))}
              </select>

              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                rows={4}
                style={{textTransform:'uppercase'}}
                name="locObservacoes"
                defaultValue={campos.locObservacoes}
                row="none"
                onChange={handleInputChange}
                maxLength="500"
              ></textarea>
              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="locStatus"
                      type="checkbox"
                      defaultChecked={!!campos.locStatus}
                      onChange={handleInputChange}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:{' '}
                </td>
                <td>{modalInfo.locCriadoPor}</td>
                <td>em {modalInfo.locCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.locAlteradoPor}</td>
                <td>em {modalInfo.locAlteradoEm}</td>
              </tr>
            </table>
            <span className="textoAlertAlt"></span>
          </div>
        </Translator>
      </div>
    </>
  )
}
