import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'
var user = authService.getCurrentUser()
const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
      [key]: {}
    }
    existing[key] = { ...existing[key], [language]: value }
    localStorage.setItem('translations', JSON.stringify(existing))
  }
}

export default function InfoUnidade({
  Observador,
  atvDescricao,
  uniNome,
  equipe_abordada,
  local,
  rtoIniciadoEm,
  rtoFinalizadoEm,
  rtoObservados,
  rtoRotina,
  rtoObservadores,
  rtoObservacoes
}) {
  return (
    <Translator
      cacheProvider={cacheProvider}
      from="pt"
      to={user[0].pesIdioma}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <div className="UnidadeSelecionada">
        <label htmlFor="" className="unidTittleRef1">
          <Translate>Unidade</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade1"
          placeholder="Unidade"
          type="text"
          id="fname"
          name="fname"
          value={uniNome}
        ></input>
        <label htmlFor="" className="unidTittleRef2">
          <Translate>Local</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade2"
          placeholder="Local"
          type="text"
          id="fname"
          name="fname"
          value={local}
        ></input>
        <label htmlFor="" className="unidTittleRef3">
          <Translate>Observador</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade3"
          placeholder="Observador"
          type="text"
          id="fname"
          name="fname"
          value={Observador}
        ></input>
        <label htmlFor="" className="unidTittleRef4">
          <Translate>Equipe Observada</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade4"
          placeholder="Equipe Observada"
          type="text"
          id="fname"
          name="fname"
          value={equipe_abordada}
        ></input>
        <label htmlFor="" className="unidTittleRef5">
          <Translate>Atividade</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade5"
          placeholder="Atividade"
          type="text"
          id="fname"
          name="fname"
          value={atvDescricao}
        ></input>
        <label htmlFor="" className="unidTittleRef6">
          <Translate>Início da Observação</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade6"
          placeholder="Início da Observação"
          type="text"
          id="fname"
          name="fname"
          value={
            Moment(rtoIniciadoEm).isValid()
              ? Moment(rtoIniciadoEm).format('DD/MM/YYYY HH:mm:ss')
              : ''
          }
        ></input>
        <label htmlFor="" className="unidTittleRef7">
          <Translate>Final da Observação</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade7"
          placeholder="Final da Observação"
          type="text"
          id="fname"
          name="fname"
          value={
            Moment(rtoFinalizadoEm).isValid()
              ? Moment(rtoFinalizadoEm).format('DD/MM/YYYY HH:mm:ss')
              : ''
          }
        ></input>
        <div className="unidTittleRefOBSS">
          <label htmlFor="" className="unidTittleRef8">
            <Translate>Observadores</Translate>:
          </label>
          <input
            disabled
            className="finputUnidade8"
            placeholder="Nº"
            type="text"
            id="fname"
            name="fname"
            value={rtoObservadores}
          ></input>
          <div className="headerDivider1"></div>
          <label htmlFor="" className="unidTittleRef9">
            <Translate>Observados</Translate>:
          </label>
          <input
            disabled
            className="finputUnidade9"
            placeholder="Nº"
            type="text"
            id="fname"
            name="fname"
            value={rtoObservados}
          ></input>
        </div>

        <label htmlFor="" className="unidTittleRef10">
          <Translate>Rotina</Translate>:
        </label>
        <input
          disabled
          className="finputUnidade10"
          placeholder="Rotina"
          type="text"
          id="fname"
          name="fname"
          value={rtoRotina}
        ></input>

        <label htmlFor="" className="unidTittleRefOG">
          <Translate>Observação geral</Translate>:
        </label>
        <input
          disabled
          className="finputUnidadeOG"
          placeholder="Observação geral"
          type="text"
          id="fname"
          name="fname"
          value={rtoObservacoes}
        ></input>
      </div>
    </Translator>
  )
}
InfoUnidade.propTypes = {
  Observador: PropTypes.string.isRequired,
  atvDescricao: PropTypes.string.isRequired,
  uniNome: PropTypes.string.isRequired,
  equipe_abordada: PropTypes.string.isRequired,
  local: PropTypes.string.isRequired,
  rtoIniciadoEm: PropTypes.string.isRequired,
  rtoFinalizadoEm: PropTypes.string.isRequired,
  rtoObservados: PropTypes.string.isRequired,
  rtoRotina: PropTypes.string.isRequired,
  rtoObservadores: PropTypes.string.isRequired,
  rtoObservacoes: PropTypes.string.isRequired
}
