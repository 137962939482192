import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [obsTipo, setobsTipo] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/observacaotipo/list/' +
          user[0].IdEmpresa
      )
      .then(response => {
        setobsTipo(response.data)
      })
  }, [])

  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdMetaLocal: modalInfo.IdMetaLocal,
    IdObservacaoTipo: modalInfo.IdObservacaoTipo,
    mtlQuantitativa: modalInfo.mtlQuantitativa,
    mtlQualitativa: modalInfo.mtlQualitativa,
    mtlStatus: modalInfo.mtlStatus,
    mtlObservacoes: modalInfo.mtlObservacoes,
    mtlAlteradoPor: user[0].pesEmail,
    mtlAlteradoEm: newDate
  })
  function handleInputChange(event) {
    if (event.target.name === 'mtlStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/metalocal/' +
          campos.IdMetaLocal,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Meta</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>

            <form className="formModalPadrao" onSubmit={handleFormSubmit}>
              <label>
                <Translate>Selecione um tipo de observação</Translate>:
              </label>
              <select
                name="IdObservacaoTipo"
                type="text"
                defaultValue={campos.IdObservacaoTipo}
                id="fSexo"
                onChange={handleInputChange}
                disabled
              >
                <option value="0">Selecione um tipo de observação</option>
                {obsTipo
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(metaTipo => (
                    <option
                      value={metaTipo.IdObservacaoTipo}
                      selected={
                        metaTipo.IdObservacaoTipo === campos.IdObservacaoTipo
                      }
                    >
                      {metaTipo.otpDescricao}
                    </option>
                  ))}
              </select>
              <div
                className="linha2"
                style={{ marginLeft: '5px', width: '200px' }}
              >
                <label>
                  <Translate>Quantitativa</Translate>
                </label>
                <label>
                  <Translate>Qualitativa</Translate>
                </label>
              </div>
              <div
                className="linha2"
                style={{ marginLeft: '5px', width: '200px' }}
              >
                <input
                  style={{ textAlign: 'center' }}
                  placeholder=""
                  type="number"
                  id="mtlQuantitativa"
                  defaultValue={campos.mtlQuantitativa}
                  name="mtlQuantitativa"
                  onChange={handleInputChange}
                ></input>
                <input
                  style={{ marginLeft: '5px', textAlign: 'center' }}
                  placeholder=""
                  type="number"
                  id="fname"
                  defaultValue={campos.mtlQualitativa}
                  name="mtlQualitativa"
                  onChange={handleInputChange}
                ></input>
              </div>

              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="mtlObservacoes"
                defaultValue={campos.mtlObservacoes}
                rows={5}
                style={{textTransform:'uppercase'}}
                onChange={handleInputChange}
                maxLength="500"
              ></textarea>
              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="mtlStatus"
                      type="checkbox"
                      defaultChecked={!!campos.mtlStatus}
                      onChange={handleInputChange}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>

            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.mtlCriadoPor}</td>
                <td>em {modalInfo.mtlCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.mtlAlteradoPor}</td>
                <td>em {modalInfo.mtlAlteradoEm}</td>
              </tr>
            </table>
          </div>
        </Translator>
      </div>
    </>
  )
}
