import { BrowserRouter } from 'react-router-dom'
import SideBar from './Components/Sidebar/Index'
import Routes from './routes'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <SideBar />
        <Routes />
      </BrowserRouter>
    </div>
  )
}

export default App
