import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let history = useHistory()
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [errorMessage, setErrorMessage] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  //console.log(modalInfo)
  const [campos, setCampos] = useState({
    IdRetornoObservacao: modalInfo[0].IdRetornoObservacao,
    IdEquipe: modalInfo[0].IdEquipe,
    IdAtividade: modalInfo[0].IdAtividade,
    rtoObservados: modalInfo[0].rtoObservados,
    rtoObservadores: modalInfo[0].rtoObservadores,
    rtoPontuacao: modalInfo[0].rtoPontuacao,
    rtoFinalizadoEm: Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').isValid()
      ? Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
      : Moment(modalInfo[0].rtoFinalizadoEm).isValid()
        ? Moment(modalInfo[0].rtoFinalizadoEm).format('YYYY-MM-DDTHH:mm')
        : '',
    //modalInfo[0].rtoFinalizadoEm,
    rtoIniciadoEm: Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').isValid()
      ? Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
      : Moment(modalInfo[0].rtoIniciadoEm).isValid()
        ? Moment(modalInfo[0].rtoIniciadoEm).format('YYYY-MM-DDTHH:mm')
        : Moment(newDate).format('YYYY-MM-DDTHH:mm'),
    //modalInfo[0].rtoIniciadoEm,
    rtoObservacoes: modalInfo[0].rtoObservacoes,
    rtoAlteradoPor: user[0].pesEmail,
    rtoAlteradoEm: Moment(newDate).format('DD/MM/yyyy HH:mm:ss')
  })
  const [dataInicioObs, setDataInicioObs] = useState('original')
  const [dataFinalObs, setDataFinalObs] = useState('original')
  //console.log(campos)
  /*
  Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').isValid()
  ? Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
  : Moment(newDate).format('YYYY-MM-DDTHH:mm')
  */

  function handleInputChange(event) {

    if (event.target.name === 'rtoFinalizadoEm') {
      // console.log(event.target.value)
      // console.log(Moment(event.target.value).format("DD/MM/YYYY HH:mm:ss"))
      // console.log(Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm"))
      // console.log(Moment(campos.rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'))
      // console.log(Moment(campos.rtoIniciadoEm).format('YYYY-MM-DDTHH:mm'))
      // console.log(campos.rtoIniciadoEm)
      let dtAgora = newDate
      // final > iniciado
      if (
        Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm")
        <
        Moment(campos.rtoIniciadoEm).format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data final menor que inicial.')
      }
      // final === iniciado
      else if
        (
        Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm")
        ===
        Moment(campos.rtoIniciadoEm).format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data final igual a inicial.')
      }
      //final > agora
      else if
        (
        Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm")
        >
        Moment(dtAgora).format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data futura não é permitido.')
      } else {
        setErrorMessage('')
        setErrorPass(false)

        // let dt = Moment(event.target.value).format("DD/MM/YYYY HH:mm:ss").toString();
        // console.log(dt)
        // console.log(Moment(event.target.value).format("DD/MM/YYYY HH:mm:ss"))
        setDataFinalObs('nova')
        campos.rtoFinalizadoEm = Moment(event.target.value, 'YYYY-MM-DDTHH:mm').format("DD/MM/YYYY HH:mm").toString();
      }

    }
    if (event.target.name === 'rtoIniciadoEm') {
      // console.log("valor tela: " + event.target.value)
      // console.log("valor tela como data Moment: " + Moment(event.target.value).format("DD/MM/YYYY HH:mm"))
      // console.log("valor tela como data Moment utc: " + Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm"))
      // console.log("data format iniciado: " + Moment(campos.rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'))
      // console.log("data format finalizado: " + Moment(campos.rtoFinalizadoEm, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm'))

      let dtAgora = newDate
      // inicial > final
      if (
        Moment(new Date(event.target.value)).utc(-3).format("YYYY-MM-DDTHH:mm")
        >
        Moment(campos.rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data inicial maior que final.')
      }
      //inicial === final
      else if
        (
        Moment(new Date(event.target.value)).utc(-3).format('YYYY-MM-DDTHH:mm')
        ===
        Moment(campos.rtoFinalizadoEm, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data inicial igual a final.')
      }
      // inicial > agora
      else if
        (
        Moment(new Date(event.target.value)).utc(-3).format('YYYY-MM-DDTHH:mm')
        >
        Moment(dtAgora).format('YYYY-MM-DDTHH:mm')
      ) {
        setErrorPass(true)
        setErrorMessage('Data futura não é permitido.')
      } else {
        setErrorMessage('')
        setErrorPass(false)
        //campos[event.target.name] = Moment(event.target.value).format("DD/MM/YYYY HH:mm")
        // let dt = Moment(event.target.value).format("DD/MM/YYYY HH:mm:ss").toString();
        // console.log(dt)
        // console.log(Moment(event.target.value).format("DD/MM/YYYY HH:mm:ss"))
        setDataInicioObs('nova')
        campos[event.target.name] = Moment(event.target.value).format("DD/MM/YYYY HH:mm").toString();
      }
      //campos[event.target.name] = Moment(event.target.value).format("DD/MM/YYYY HH:mm")
    }
    else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
    //console.log(campos)
  }
  //console.log(campos.rtoFinalizadoEm)
  const [equipes, setEquipes] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/equipe/list/' +
        user[0].IdEmpresa +
        '/' +
        modalInfo[0].IdUnidade
      )
      .then(response => {
        setEquipes(response.data)
      })
  }, [])
  //console.log(sessionStorage.IdUnidadeNovaObs)
  const [atividades, setAtividades] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/atividade/list/' +
        user[0].IdEmpresa +
        '/' +
        modalInfo[0].IdUnidade//sessionStorage.IdUnidadeNovaObs
      )
      .then(response => {
        setAtividades(response.data)
      })
  }, [])
  function handleFormSubmit(event) {
    event.preventDefault()

    // if (!!Moment(campos.rtoFinalizadoEm).isValid()) {
    //   campos.rtoFinalizadoEm = Moment(campos.rtoFinalizadoEm).format("DD/MM/YYYY HH:mm")
    // }
    // if (!!Moment(campos.rtoIniciadoEm).isValid()) {
    //   campos.rtoIniciadoEm = Moment(campos.rtoIniciadoEm).format("DD/MM/YYYY HH:mm")
    // }

    if (dataInicioObs === 'original') {
      campos.rtoIniciadoEm = Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').isValid()
        ? Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
        : Moment(modalInfo[0].rtoIniciadoEm).isValid()
          ? Moment(modalInfo[0].rtoIniciadoEm).format('DD/MM/YYYY HH:mm')
          : Moment(newDate).format('DD/MM/YYYY HH:mm');
    } else {
      campos.rtoIniciadoEm = Moment(campos.rtoIniciadoEm, 'DD/MM/YYYY HH:mm').isValid()
        ? Moment(campos.rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
        : Moment(campos.rtoIniciadoEm).isValid()
          ? Moment(campos.rtoIniciadoEm).format('DD/MM/YYYY HH:mm')
          : Moment(newDate).format('DD/MM/YYYY HH:mm');
    }

    if (dataFinalObs === 'original') {
      campos.rtoFinalizadoEm = Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').isValid()
        ? Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
        : Moment(modalInfo[0].rtoFinalizadoEm).isValid()
          ? Moment(modalInfo[0].rtoFinalizadoEm).format('DD/MM/YYYY HH:mm')
          : '';
    } else {
      campos.rtoFinalizadoEm = Moment(campos.rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').isValid()
        ? Moment(campos.rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
        : Moment(campos.rtoFinalizadoEm).isValid()
          ? Moment(campos.rtoFinalizadoEm).format('DD/MM/YYYY HH:mm')
          : '';
    }


    console.log(campos)

    let dtAgora = newDate
    //iniciao > final
    if (//1 === 1
      Moment(campos.rtoIniciadoEm)
      >
      Moment(campos.rtoFinalizadoEm)
    ) {
      setErrorPass(true)
      setErrorMessage('Data inicial maior que final.')
    }


    // inicial === final
    // else if (
    //   Moment(new Date(campos.rtoIniciadoEm)).utc(-3).format("YYYY-MM-DDTHH:mm")
    //   ===
    //   Moment(campos.rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DDTHH:mm")
    // ) {
    //   setErrorPass(true)
    //   setErrorMessage('Data inicial igual a final.')
    // }
    // //inicial > agora
    // else if
    //   (
    //   (Moment(new Date(campos.rtoIniciadoEm)).utc(-3).format("YYYY-MM-DDTHH:mm")
    //     >
    //     Moment(dtAgora).format('YYYY-MM-DDTHH:mm'))
    //   ||
    //   (Moment(new Date(campos.rtoFinalizadoEm)).utc(-3).format("YYYY-MM-DDTHH:mm")
    //     >
    //     Moment(dtAgora).format('YYYY-MM-DDTHH:mm'))
    // ) {
    //   setErrorPass(true)
    //   setErrorMessage('Não é permitido data futura!')
    //}
    else {
      //console.log(dateFormatter)

      axios
        .post(
          process.env.REACT_APP_API_URL +
          '/api/v1/retornoobservacao/' +
          modalInfo[0].IdRetornoObservacao,
          campos
        )
        .then(response => {
          if (response.status === 200) {
            alert(response.data.message)
            axios.get(
              'https://ws.hitperformance.com.br/MeuCPTO/CalculaNota?idRetornoObservacao=' +
              modalInfo[0].IdRetornoObservacao
            )
              .then(resp => {
                history.push('/ApontarObservacao/ObservacoesListar/')
                //alert(resp.data)
              })
            //console.log(campos)
            //history.push('/ApontarObservacao/ObservacoesListar/')
          }
        })
    }
  }
  //console.log(Moment().format('YYYY-MM-DDTHH:mm:ss'))
  //console.log(Moment(modalInfo[0].rtoIniciadoEm).format('YYYY-MM-DDTHH:mm:ss'))

  // let criado = new Date(campos.rtoIniciadoEm)
  // console.log(criado.toString())
  // console.log(Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'))
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <div className="overlay">
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Revisão Fechamento</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Equipe Observada</Translate>:
              </label>
              <select
                name="IdEquipe"
                typeof="text"
                defaultValue={campos.IdEquipe}
                id="IdEquipe"
                onChange={handleInputChange}
              >
                {equipes
                  .sort((a, b) => (a.eqpDescricao > b.eqpDescricao ? 1 : -1))
                  .map(equipe => (
                    <option
                      value={equipe.IdEquipe}
                      selected={equipe.IdEquipe === modalInfo[0].IdEquipe}
                    >
                      {equipe.eqpDescricao}
                    </option>
                  ))}
              </select>
              <label>
                <Translate>Atividade Observada</Translate>:
              </label>
              <select
                name="IdAtividade"
                typeof="text"
                defaultValue={campos.IdAtividade}
                id="IdAtividade"
                onChange={handleInputChange}
              >
                <option value="0">Atividade observada</option>
                {atividades
                  .sort((a, b) => (a.atvDescricao > b.atvDescricao ? 1 : -1))
                  .map(atividade => (
                    <option
                      value={atividade.IdAtividade}
                      selected={
                        atividade.IdAtividade === modalInfo[0].IdAtividade
                      }
                    >
                      {atividade.atvDescricao}
                    </option>
                  ))}
              </select>
              <table className="linhaDoisCadPessoa">
                <tr>
                  <th style={{ paddingLeft: '10px' }}>
                    <Translate>Iniciado Em</Translate>:
                  </th>
                  <th style={{ paddingLeft: '10px' }}>
                    <Translate>Finalizado Em</Translate>:
                  </th>
                  <th style={{ paddingLeft: '10px' }}>
                    <Translate>Observados</Translate>:
                  </th>
                  <th style={{ paddingLeft: '10px' }}>
                    <Translate>Observadores</Translate>:
                  </th>
                </tr>
                <tr>
                  <td style={{ paddingRight: '10px', height: '30px' }}>
                    {' '}
                    <input
                      type="datetime-local"
                      id="rtoIniciadoEm"
                      defaultValue={
                        //Moment(Date(observacao.rtoIniciadoEm))
                        Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').isValid()
                          ? Moment(modalInfo[0].rtoIniciadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
                          : Moment(modalInfo[0].rtoIniciadoEm).isValid()
                            ? Moment(modalInfo[0].rtoIniciadoEm).format('YYYY-MM-DDTHH:mm')
                            : Moment(newDate).format('YYYY-MM-DDTHH:mm')
                      }
                      name="rtoIniciadoEm"
                      onChange={handleInputChange}
                      //required
                      max={Moment(newDate).format('YYYY-MM-DDTHH:mm')}
                    ></input>
                  </td>
                  <td style={{ paddingLeft: '10px', height: '30px' }}>
                    <input
                      type="datetime-local"
                      id="rtoFinalizadoEm"
                      defaultValue={
                        Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').isValid()
                          ? Moment(modalInfo[0].rtoFinalizadoEm, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm')
                          : Moment(modalInfo[0].rtoFinalizadoEm).isValid()
                            ? Moment(modalInfo[0].rtoFinalizadoEm).format('YYYY-MM-DDTHH:mm')
                            : ''
                      }
                      //max={Moment(Date()).format('YYYY-MM-DDTHH:mm')}
                      name="rtoFinalizadoEm"
                      onChange={handleInputChange}
                    //required
                    ></input>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    {' '}
                    <input
                      type="number"
                      id="fname"
                      name="rtoObservados"
                      min={1}
                      defaultValue={campos.rtoObservados}
                      onChange={handleInputChange}
                      required
                    ></input>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <input
                      type="number"
                      id="fname"
                      name="rtoObservadores"
                      min={1}
                      defaultValue={campos.rtoObservadores}
                      onChange={handleInputChange}
                      required
                    ></input>
                  </td>
                </tr>
              </table>

              <label>
                <Translate>Comentários/Fechamento/Compromissos</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                defaultValue={campos.rtoObservacoes}
                name="rtoObservacoes"
                onChange={handleInputChange}
                rows={3}
                maxLength="500"
              ></textarea>
              <div className="caixaSalvarPadrao">
                <div className="legendConcluiOBS">
                  {errorPass === true ? (
                    <span>
                      <Translate>{errorMessage}</Translate>
                    </span>
                  ) : (
                    <div></div>
                  )}
                </div>
                <button
                  type="submit"
                  className="gravar-modal"
                  disabled={errorPass}
                >
                  <Translate>Salvar / Fechar</Translate>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Translator>
    </>
  )
}
