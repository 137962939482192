import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import * as TypIcons from 'react-icons/ti'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage24
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'

import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import logoHumaniTI from '../../../Images/LogoHumani-TI.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'

function SelecionarUnidade() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  console.log(user[0].IdEmpresa)
  console.log(user[0].IdPessoa)
  const [searchTerm, setSearchTerm] = useState('')
  const [unidades, setUnidades] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUser/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)
      })
  }, [])
  let history = useHistory()
  const [sidebar, setSidebar] = useState(true)

  const showSidebar = () => setSidebar(!sidebar)

  // console.log(unidades)
  // console.log(sessionStorage.uniAgrupamento)

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
            </div>
          </div>
          <div id="containerHead2">
            <button
              className="btnReturn"
              type="button"
              onClick={() => history.goBack()}
            >
              <TypIcons.TiArrowBackOutline />
            </button>
            <TitleOfPage>
              <TitlePage1>
                <Translate>Qual Unidade você vai aplicar observação?</Translate>
              </TitlePage1>
              <img
                className="logoK"
                style={{ width: '80px', height: '80px' }}
                src={logoHumaniTI}
                alt=""
              />
              <TitlePage24> {sessionStorage.uniRazaoSocial} </TitlePage24>
            </TitleOfPage>
          </div>
          <div className="tabelaPadrao">
            {unidades
              .filter(val => {
                if (searchTerm === ''
                  && sessionStorage.uniAgrupamento !== ""
                  && sessionStorage.uniAgrupamento !== "Todos"
                  && val.uniAgrupamento === sessionStorage.uniAgrupamento) {
                  return val
                }
                else if (searchTerm === ''
                  && (sessionStorage.uniAgrupamento === ""
                    || sessionStorage.uniAgrupamento !== "Todos")) {
                  return val
                }
                else if (searchTerm !== ''
                  && sessionStorage.uniAgrupamento !== ""
                  && sessionStorage.uniAgrupamento !== "Todos"
                  && val.uniAgrupamento === sessionStorage.uniAgrupamento
                  && val.uniNome.toLowerCase().includes(searchTerm.toLowerCase())) {
                  return val
                }
                else if (searchTerm !== ''
                  && (sessionStorage.uniAgrupamento === ""
                    || sessionStorage.uniAgrupamento !== "Todos")
                  && val.uniNome.toLowerCase().includes(searchTerm.toLowerCase())) {
                  return val
                }
                else {
                  return val
                }
                // else if ( 
                //   val.uniNome.toLowerCase().includes(searchTerm.toLowerCase())
                // ) {
                //     return val
                //   }
              })
              .map(unidade => (
                <Link
                  to={
                    '/ApontarObservacao/SelecionarArea/' + unidade.IdUnidade
                  }
                >
                  <button className="fAreaSelecionada">
                    {unidade.uniNome}
                  </button>
                </Link>
              ))}
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ---------------------------------------------------------- */}
        <nav className={sidebar ? 'mostrarFiltro ' : 'esconderFiltro'}>
          <div className="Profile-direita">
            <div className="cxProfile">
              <h6 className="titleProfile1">
                {' '}
                <Translate>Seja Muito Bem-vindo,</Translate>
              </h6>
              <h6 className="titleProfile2">{user[0].pesNome}</h6>
              <div className="alignFotoProfile">
                {image ? (
                  <img className="logo1" src={image} alt="perfil" />
                ) : (
                  <img src={padrao} alt="Profile" className="logo1" />
                )}
                {/* <Link to="/ViewProfile">
                  <button
                    className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                  >
                    <Translate>Ver perfil</Translate>
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default SelecionarUnidade
