import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
//import validator from 'validator'
import authService from '../../../Components/Login/auth.service'
import '../../../styles/pages/MenuDireito/modal/modalPessoa.css'
import padrao from '../../../Components/profile.png'


const uuidv4 = require('uuid/v4')
//const crypto = require('crypto')


export default function Modal({ setOpenModal, modalInfo }) {

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [funcoes, setFuncao] = useState([])
  const [grupos, setGrupo] = useState([])
  const [gerencias, setGerencia] = useState([])
  const [modal, setModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  //const [errorPass, setErrorPass] = useState(false)

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/funcao/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setFuncao(response.data)
      })
  }, [])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/grupoUsuario/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setGrupo(response.data)
      })
  }, [])
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeEdit) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/gerencia/listapp/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          setGerencia(response.data)
        })
    }
    else if (parseInt(sessionStorage.IdUnidadeForm) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/gerencia/listapp/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeForm
        )
        .then(response => {
          setGerencia(response.data)
        })
    }
  }, [])

  // console.log(sessionStorage.IdUnidadeEdit)
  // console.log(sessionStorage.IdUnidade)
  // console.log(sessionStorage.IdUnidadeForm)
  // eslint-disable-next-line no-unused-vars
  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  let newDate = new Date()
  const [imagens, setImagens] = useState({
    NomeImagem: '',
    Imagem: ''
  })

  const [campos, setCampos] = useState({
    IdPessoa: 0,
    IdEmpresa: 0,
    IdFuncao: 0,
    IdGrupoUsuario: 0,
    IdGerencia: 0,
    pesNome: '',
    pesFoto: '',
    pesEmail: '',
    pesSenha: '',
    confPesSenha: '',
    pesTelefone: '',
    pesCelular: '',
    pesEndereco: '',
    pesAcessoWeb: 0,
    pesAcessoApp: 0,
    pesIdioma: '',
    pesStatus: 0,
    pesObservacoes: '',
    pesAlteradoPor: user[0].pesEmail,
    pesAlteradoEm: newDate
  })

  function handleChangeNome(event) {
    campos.pesNome = event.target.value
    modalInfo.pesNome = event.target.value
  }

  function handleChangeFuncao(event) {
    campos.IdFuncao = parseInt(event.target.value)
    modalInfo.IdFuncao = parseInt(event.target.value)
  }

  function handleChangeIdioma(event) {
    campos.pesIdioma = event.target.value
    modalInfo.pesIdioma = event.target.value
  }

  function handleChangeEmail(event) {
    campos.pesEmail = event.target.value
    modalInfo.pesEmail = event.target.value
  }

  function handleChangeTelefone(event) {
    campos.pesTelefone = event.target.value
    modalInfo.pesTelefone = event.target.value
  }

  function handleChangeCelular(event) {
    campos.pesCelular = event.target.value
    modalInfo.pesCelular = event.target.value
  }

  function handleChangeGrupoUsuario(event) {
    campos.IdGrupoUsuario = event.target.value
    modalInfo.IdGrupoUsuario = event.target.value
  }

  function handleChangeGerencia(event) {
    campos.IdGerencia = parseInt(event.target.value)
    modalInfo.IdGerencia = parseInt(event.target.value)
  }

  function handleChangeObservacoes(event) {
    campos.pesObservacoes = event.target.value
    modalInfo.pesObservacoes = event.target.value
  }

  function handleChangeSenha(event) {
    campos.pesSenha = event.target.value
    modalInfo.pesSenha = event.target.value
  }

  function handleChangeConfSenha(event) {
    campos.confPesSenha = event.target.value
    modalInfo.confPesSenha = event.target.value
  }

  function handleChangeApp(event) {
    if (event.target.checked) {
      campos.pesAcessoApp = 1
      modalInfo.pesAcessoApp = 1
    }
    else {
      campos.pesAcessoApp = 0
      modalInfo.pesAcessoApp = 0
    }
  }

  function handleChangeWeb(event) {
    if (event.target.checked) {
      campos.pesAcessoWeb = 1
      modalInfo.pesAcessoWeb = 1
    }
    else {
      campos.pesAcessoWeb = 0
      modalInfo.pesAcessoWeb = 0
    }
  }

  function handleChangeStatus(event) {
    if (event.target.checked) {
      campos.pesStatus = 1
      modalInfo.pesStatus = 1
    }
    else {
      campos.pesStatus = 0
      modalInfo.pesStatus = 0
    }
  }

  if (modalInfo !== undefined) {
    //console.log("obteve do modal info")
    campos.IdPessoa = modalInfo.IdPessoa
    campos.IdEmpresa = modalInfo.IdEmpresa
    campos.IdFuncao = modalInfo.IdFuncao ? modalInfo.IdFuncao : 0
    campos.IdGrupoUsuario = modalInfo.IdGrupoUsuario ? modalInfo.IdGrupoUsuario : 0
    campos.IdGerencia = modalInfo.IdGerencia ? modalInfo.IdGerencia : 0
    campos.pesNome = modalInfo.pesNome
    campos.pesFoto = modalInfo.pesFoto
    campos.pesEmail = modalInfo.pesEmail
    //campos.pesSenha = modalInfo.pesSenha
    //campos.confPesSenha = modalInfo.confPesSenha
    campos.pesTelefone = modalInfo.pesTelefone
    campos.pesCelular = modalInfo.pesCelular
    campos.pesEndereco = modalInfo.pesEndereco
    campos.pesAcessoWeb = modalInfo.pesAcessoWeb
    campos.pesAcessoApp = modalInfo.pesAcessoApp
    campos.pesIdioma = modalInfo.pesIdioma
    campos.pesStatus = modalInfo.pesStatus ? modalInfo.pesStatus : 1
    campos.pesObservacoes = modalInfo.pesObservacoes
    campos.pesAlteradoPor = user[0].pesEmail
    campos.pesAlteradoEm = newDate

  }
  else if (parseInt(campos.IdPessoa) === 0) {
    //console.log("obteve da base")
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/pessoa/' + sessionStorage.camposPessoa)
      .then(response => {
        campos.IdPessoa = response.data[0].IdPessoa
        campos.IdEmpresa = response.data[0].IdEmpresa
        campos.IdFuncao = response.data[0].IdFuncao ? response.data[0].IdFuncao : 0
        campos.IdGrupoUsuario = response.data[0].IdGrupoUsuario ? response.data[0].IdGrupoUsuario : 0
        campos.IdGerencia = response.data[0].IdGerencia ? response.data[0].IdGerencia : 0
        campos.pesNome = response.data[0].pesNome
        campos.pesFoto = response.data[0].pesFoto
        campos.pesEmail = response.data[0].pesEmail
        //campos.pesSenha= ''
        //campos.confPesSenha= ''
        campos.pesTelefone = response.data[0].pesTelefone
        campos.pesCelular = response.data[0].pesCelular
        campos.pesEndereco = response.data[0].pesEndereco
        campos.pesAcessoWeb = response.data[0].pesAcessoWeb
        campos.pesAcessoApp = response.data[0].pesAcessoApp
        campos.pesIdioma = response.data[0].pesIdioma
        campos.pesStatus = response.data[0].pesStatus ? response.data[0].pesStatus : 1
        campos.pesObservacoes = response.data[0].pesObservacoes
        campos.pesAlteradoPor = user[0].pesEmail
        campos.pesAlteradoEm = newDate
      })
  }

  function handleInputChange(event) {
    //campos[event.target.name] = event.target.value
    setCampos(campos)
  }
  var bloqueio = 0
  const [image, setImage] = useState('')

  function handleFormSubmit(event) {
    event.preventDefault()
    if (bloqueio === 0) {
      bloqueio = 1
      if (campos.pesSenha !== campos.confPesSenha && (campos.pesSenha !== undefined && campos.confPesSenha !== undefined)) {
        //console.log(campos.pesSenha)
        //console.log(campos.confPesSenha)
        setErrorMessage('A confirmação de senha está incorreta')
        bloqueio = 0
        return
      }
      else {
        setErrorMessage('')

        const formData = new FormData()
        formData.append('file', image)
        var reader = new FileReader()
        if (image) {
          reader.readAsDataURL(image)
          reader.onload = function (fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result
            imagens['Imagem'] = srcData.split(',')[1]
            imagens['NomeImagem'] = uuidv4()
            setImagens(imagens)
          }
          axios
            .post(process.env.REACT_APP_API_URL + '/upload', imagens)
            .then(response => {
              campos.pesFoto = response.data['url']
              modalInfo.pesFoto = response.data['url']
            })
        }

        modalInfo.pesEmail = String(modalInfo.pesEmail).replace(/[áàãâä]/g, 'a')
          .replace(/[éèêë]/g, 'e')
          .replace(/[íìîï]/g, 'i')
          .replace(/[óòõôö]/g, 'o')
          .replace(/[úùûü]/g, 'u')
          .replace(/[ç]/g, 'c')

        campos.pesEmail = modalInfo.pesEmail
        campos.confPesSenha = modalInfo.confPesSenha

        //setCampos(campos)

        //console.log(campos)

        axios
          .post(
            process.env.REACT_APP_API_URL +
            '/api/v1/updatepessoaV2/' +
            campos.IdPessoa,
            campos
          )
          .then(response => {
            if (response.status === 200) {
              window.location.reload()
            } else {
              alert(response.data.message)
            }
          })

        bloqueio = 0
      }
    }
  }

  console.log(modalInfo)

  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Pessoa G</Translate>
                <hr className="hrTitleModal" />
              </h1>

              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <table className="linhaUmCadPessoa">
                <tr>
                  <th>
                    <Translate>Nome</Translate>
                  </th>
                </tr>
              </table>
              <input
                className="inputNome"
                placeholder="Nome"
                type="text"
                required
                id="fname"
                style={{ textTransform: 'uppercase' }}
                defaultValue={campos.pesNome}
                name="pesNome"
                onChange={handleChangeNome}
              />

              <table className="linhaDoisCadPessoa">
                <tr>
                  <th>
                    <Translate>Função</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Idioma</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <select
                      name="IdFuncao"
                      className="fcadastroPessoaFuncion"
                      type="text"
                      required
                      style={{ textTransform: 'uppercase' }}
                      defaultValue={campos.IdFuncao}
                      id="ffuncao"
                      onChange={handleChangeFuncao}
                    >
                      <option value="0">Selecione uma Função</option>
                      {funcoes
                        .sort((a, b) =>
                          a.fncDescricao > b.fncDescricao ? 1 : -1
                        )
                        .map(funcao => (
                          <option
                            value={funcao.IdFuncao}
                            selected={funcao.IdFuncao === campos.IdFuncao}
                          >
                            {funcao.fncDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <select
                      name="pesIdioma"
                      className="fcadastroPessoaIdiom"
                      type="text"
                      required
                      defaultValue={campos.pesIdioma}
                      id="fidioma"
                      onChange={handleChangeIdioma}
                    >
                      <option value="">Selecione o Idioma</option>
                      <option value="pt">Português</option>
                      <option value="en">Inglês</option>
                      <option value="es">Espanhol</option>
                    </select>
                  </td>
                </tr>
              </table>

              <table className="linhaTresCadPessoa">
                <tr>
                  <th>
                    <Translate>Digite um E-mail</Translate>
                  </th>
                </tr>
              </table>
              <table className="linhaDoisCadPessoa">
                <input
                  className="inputCadMail"
                  placeholder="E-Mail"
                  type="text"
                  required
                  style={{ textTransform: 'lowercase' }}
                  defaultValue={campos.pesEmail}
                  id="fmail"
                  name="pesEmail"
                  onChange={handleChangeEmail}
                />
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  {' '}
                  <th>
                    <Translate>Telefone</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Celular</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      disabled
                      placeholder="(__)____-____"
                      type="text"
                      defaultValue={campos.pesTelefone}
                      id="ftelefone"
                      name="pesTelefone"
                      onChange={handleChangeTelefone}
                    />
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <input
                      disabled
                      placeholder="(__)____-____"
                      type="text"
                      defaultValue={campos.pesCelular}
                      id="ftelefone2"
                      name="pesCelular"
                      onChange={handleChangeCelular}
                    />
                  </td>
                </tr>
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  <th>
                    <Translate>Grupo de Usuários</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Gerência Responsável</Translate>:
                  </th>
                </tr>
                <tr>
                  <td>
                    <select
                      name="IdGrupoUsuario"
                      className="fcadastroPessoaGroup"
                      type="text"
                      required
                      defaultValue={campos.IdGrupoUsuario}
                      id="fgrupo"
                      onChange={handleChangeGrupoUsuario}
                      disabled={campos.pesEmail.includes('admin@')}
                    >
                      <option value="">Selecione um grupo</option>
                      {grupos
                        .sort((a, b) =>
                          a.gruDescricao > b.gruDescricao ? 1 : -1
                        )
                        .map(grupo => (
                          <option
                            value={grupo.IdGrupoUsuario}
                            selected={
                              grupo.IdGrupoUsuario === campos.IdGrupoUsuario
                            }
                          >
                            {grupo.gruDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    {' '}
                    <select
                      name="IdGerencia"
                      className="fcadastroPessoaGroup"
                      type="text"
                      required
                      defaultValue={campos.IdGerencia}
                      id="fgerencia"
                      onChange={handleChangeGerencia}
                      disabled={campos.pesEmail.includes('admin@')}
                    >
                      <option value="0">Selecione uma gerência</option>
                      {gerencias
                        .sort((a, b) =>
                          a.gerDescricao > b.gerDescricao ? 1 : -1
                        )
                        .map(ger => (
                          <option
                            value={ger.IdGerencia}
                            selected={ger.IdGerencia === campos.IdGerencia}
                          >
                            {ger.gerDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  {' '}
                  <th>
                    <Translate>Senha</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Confirmar Senha</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      placeholder="Senha"
                      type="password"
                      defaultValue={campos.pesSenha}
                      id="fsenha"
                      name="pesSenha"
                      onChange={handleChangeSenha}
                    />
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <input
                      placeholder="Confirmar Senha"
                      type="password"
                      defaultValue={campos.confPesSenha}
                      id="fconfsenha"
                      name="confPesSenha"
                      onChange={handleChangeConfSenha}
                    />
                  </td>
                </tr>
              </table>
              <table className="linhaQuatroCadPessoa">
                <tr>
                  <th>
                    <Translate>Observações</Translate>
                  </th>
                </tr>
                <textarea
                  className="fcadastroPessoaObs"
                  placeholder="Observações"
                  type="text"
                  defaultValue={campos.pesObservacoes}
                  id="fobs"
                  name="pesObservacoes"
                  row="none"
                  maxLength="500"
                  style={{ textTransform: 'uppercase' }}
                  onChange={handleChangeObservacoes}
                />
              </table>

              <div className="barraSalvar">
                <table className="cxAutorizacao">
                  <tr>
                    <td>
                      <input
                        name="pesAcessoApp"
                        type="checkbox"
                        id="facessoapp"
                        onChange={handleChangeApp}
                        defaultChecked={!!campos.pesAcessoApp}
                      />
                    </td>
                    <td>
                      <span>
                        <Translate>Acesso ao APP</Translate>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name="pesAcessoWeb"
                        type="checkbox"
                        id="facessoweb"
                        onChange={handleChangeWeb}
                        defaultChecked={!!campos.pesAcessoWeb}
                      />
                    </td>
                    <td>
                      <span>
                        <Translate>Acesso Web</Translate>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name="pesStatus"
                        type="checkbox"
                        id="fstatus"
                        onChange={handleChangeStatus}
                        defaultChecked={!!campos.pesStatus}
                      />
                    </td>
                    <td>
                      <span>
                        <Translate>Status</Translate>
                      </span>
                    </td>
                  </tr>
                </table>
                {errorMessage !== '' ? (
                  <span className="AlertPassword">
                    <Translate>{errorMessage}</Translate>
                  </span>
                ) : (
                  <div></div>
                )}
                <button
                  type="submit"
                  className="gravarPessoa"
                //disabled={errorPass}
                >
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>

            <div className="cxPhotoImport">
              {image ? (
                <img
                  //src={padrao}  
                  src={URL.createObjectURL(image)}
                  alt="Logo2"
                  className="imgProfile"
                  width="150"
                  height="150"
                />
              ) : (
                <img
                  src={padrao} alt="Foto" className="imgProfile" />
              )}
              <input
                type="file"
                name="empLogo"
                className="form-control-file-1"
                id="empLogo"
                accept=".gif,.jpg,.jpeg,.png"
                onChange={function (event) {
                  setImage(event.target.files[0])
                }}
              />
            </div>
            <table className="criadoAlteradoPor">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.pesCriadoPor}</td>
                <td>em {modalInfo.pesCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.pesAlteradoPor}</td>
                <td>em {modalInfo.pesAlteradoEm}</td>
              </tr>
            </table>
          </div>
        </Translator>
      </div>
    </>
  )
}
