import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage24
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'

import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import logoHumaniTI from '../../../Images/LogoHumani-TI.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'

function SelecionarArea() {
  let history = useHistory()
  let id = useParams()
  sessionStorage.IdUnidadeNovaObs = id['id']
  //console.log(id['id'])

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [areas, setAreas] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/area/listApp/' +
        user[0].IdEmpresa +
        '/' +
        id['id']
      )
      .then(response => {
        setAreas(response.data)
      })
  }, [])

  //const setUnidades = useState([])
  const searchUnidade = useState(sessionStorage.IdUnidadeNovaObs)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        //setUnidades(response.data)
        if (sessionStorage.IdUnidadeNovaObs === undefined) {
          sessionStorage.IdUnidadeNovaObs = response.data[0].IdUnidade
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
        }
      })
  }, [])

  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
            <input
              className="fCxPesquisa"
              placeholder="Pesquisar"
              type="text"
              id="fname1"
              name="fname"
              onChange={event => {
                setSearchTerm(event.target.value)
              }}
            />
          </div>
          <div id="containerHead2">
            <button
              className="btnReturn"
              type="button"
              onClick={() => history.goBack()}
            >
              <TypIcons.TiArrowBackOutline />
            </button>
            <TitleOfPage>
              <TitlePage1>
                <Translate>Qual área você vai aplicar observação?</Translate>
              </TitlePage1>
              <img
                className="logoK"
                style={{ width: '80px', height: '80px' }}
                src={logoHumaniTI}
                alt=""
              />
              <TitlePage24> {sessionStorage.uniRazaoSocial} </TitlePage24>
            </TitleOfPage>
          </div>
          <div className="tabelaPadrao">
            {areas
              .sort((a, b) => (a.locDescricao > b.locDescricao ? 1 : -1))
              .filter(val => {
                if (
                  searchTerm === '' &&
                  parseInt(val.IdUnidade) === parseInt(searchUnidade) &&
                  parseInt(val.locStatus) === 1
                ) {
                  return val
                } else if (
                  val.locDescricao
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) &&
                  parseInt(val.IdUnidade) === parseInt(searchUnidade) &&
                  parseInt(val.locStatus) === 1
                ) {
                  return val
                }
              })
              .map(area => (
                <Link
                  to={
                    history.location.pathname.includes(
                      'ApontarObservacao/SelecionarUnidade'
                    )
                      ? '/ApontarObservacao/ObservacoesApontar/' +
                      id['id'] +
                      '/' +
                      area.IdLocalAra
                      : '/ApontarObservacao/PreenchimentoObservacao/' +
                      id['id'] +
                      '/' +
                      area.IdLocalAra
                  }
                >
                  <button className="fAreaSelecionada">
                    {area.locDescricao}
                  </button>
                </Link>
              ))}
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ----------------------------------------------------------- */}
        <nav className={sidebar ? 'mostrarFiltro ' : 'esconderFiltro'}>
          <div className="Profile-direita">
            <div className="cxProfile">
              <h6 className="titleProfile1">
                {' '}
                <Translate>Seja Muito Bem-vindo,</Translate>
              </h6>
              <h6 className="titleProfile2">{user[0].pesNome}</h6>
              <div className="alignFotoProfile">
                {image ? (
                  <img className="logo1" src={image} alt="perfil" />
                ) : (
                  <img src={padrao} alt="Profile" className="logo1" />
                )}
                {/* <Link to="/ViewProfile">
                  <button
                    className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                  >
                    <Translate>Ver perfil</Translate>
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default SelecionarArea
