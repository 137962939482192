import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import AtivadoresChart from '../../../Components/dash/ativadores'
import Indicador from '../../../Components/dash/indicador'
import TabelaAtivadores from '../../../Components/dash/tabelaAtivadoresUnidade'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage3,
  TitlePage2,
  TitlePage21
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import okImg from '../../../Images/ok.svg'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import Modal from '../ModalObservações/ModalObsUnidades'

//var user = authService.getCurrentUser()

function UniExe() {
  let id = useParams()
  if (id.IdUnidade !== 0 && sessionStorage.uniRazaoSocial === 'Todos') {
    sessionStorage.IdUnidade = id.IdUnidade
    sessionStorage.IdUnidadeEdit = id.IdUnidade
    ObtemUnidadeAtual()
    window.location.reload()
  }
  const history = useHistory()
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const dateAtual = new Date()

  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
    sessionStorage.DataFim = new Date()
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  sessionStorage.DataFiltroInicio = sessionStorage.DataInicio
  sessionStorage.DataFiltroFim = sessionStorage.DataFim
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    window.location.reload()
  }
  const [searchTerm, setSearchTerm] = useState(sessionStorage.IdObservacaoTipo)
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  //unidades vinculadas do usuário
  const [unidades, setUnidades] = useState([])
  const [dashUnidades, setdashUnidades] = useState([])
  const [dashUnidadesMes, setdashUnidadesMes] = useState([])
  //const [metaUnidadesMes, setmetaUnidadesMes] = useState([])
  const [observacaotipos, setObservacaotipos] = useState([])
  const [ativador, setAtivador] = useState([])
  const [unidadeBarraTitulo, setUnidadeBarraTitulo] = useState([])

  function ObtemUnidadeAtual() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/' + id.IdUnidade
      )
      .then(response => {
        sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento
        sessionStorage.uniRazaoSocial = response.data[0].uniRazaoSocial
      })
  }

  function handleUnidadeChange(event) {
    sessionStorage.IdUnidade =
      event.target.options[event.target.selectedIndex].value
    sessionStorage.IdUnidadeEdit =
      event.target.options[event.target.selectedIndex].value
    sessionStorage.uniAgrupamento =
      event.target.options[event.target.selectedIndex].text
    sessionStorage.uniRazaoSocial =
      event.target.options[event.target.selectedIndex].text

    // console.log("IDUNIDADE: " + sessionStorage.IdUnidade)
    // console.log("UNIAGRUPAMENTO: " + sessionStorage.uniAgrupamento)
    // console.log("IDEMPRESA: " + user[0].IdEmpresa)

    //window.location.reload()
    AtualizarProjetadoAcumulado();
    AtualizarGraficoAtivadores();
    AtualizarResultado()
  }

  function handleObsTipoChange(event) {
    sessionStorage.IdObservacaoTipo =
      event.target.options[event.target.selectedIndex].value
    sessionStorage.ObservacaoDesc =
      event.target.options[event.target.selectedIndex].text

    //window.location.reload()
    AtualizarProjetadoAcumulado();
    AtualizarGraficoAtivadores();
    AtualizarResultado();
  }

  //OBTEM PROJETADO E ACUMULADO EMPRESA
  function AtualizarProjetadoAcumulado() {
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      // console.log("dash agrupamento")
      // console.log("dash agrupamento: " + sessionStorage.IdUnidade)
      // console.log("dash agrupamento: " + sessionStorage.uniAgrupamento)
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoAgrupamentoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.uniAgrupamento +
          '/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    //SE TEM IDUNIDADE E NAO AGRUPAMENTO
    else {
      //  console.log("dash acumulado")
      //  console.log("dash acumulado: " + sessionStorage.IdUnidade)
      //  console.log("dash acumulado: " + sessionStorage.uniAgrupamento)
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.IdUnidade +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
  }

  //ATUALIZA RESULTADO ACUMULADO POR MÊS
  function AtualizarResultado() {
    var url = ''
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      //console.log("dashUnidadesMes: Agrupamento")
      var url =
        '/api/v1/dashUnidadesAgrupamento/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.uniAgrupamento +
        '/' +
        user[0].IdEmpresa
    } else {
      //console.log("dashUnidadesMes: Unidade")
      var url =
        '/api/v1/dashUnidadesEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa
    }
    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setdashUnidadesMes(response.data)
    })
  }

  //ATUALIZA GRÁFICO ATIVADORES
  function AtualizarGraficoAtivadores() {
    var url = ''
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      var url = process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresUnidadeAgrupamento/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.uniAgrupamento +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo
    } else {
      var url = process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresUnidade/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.IdUnidade +
        '/' +
        sessionStorage.IdObservacaoTipo
    }
    axios.get(url).then(response => {
      // console.log(sessionStorage.IdUnidade)
      // console.log(url)
      setAtivador(response.data)
    })
  }

  //OBTEM UNIDADES PARA LISTA DE SELEÇÃO
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUserAgrupamento/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)
      })
  }, [])

  //TIPOS DE OBSERVAÇÒES
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        response.data.some(function (item) {
          if (sessionStorage.IdObservacaoTipo === undefined) {
            response.data.forEach(element => {
              if (element.otpStatus === 1) {
                if (sessionStorage.IdObservacaoTipo === undefined) {
                  sessionStorage.IdObservacaoTipo = element.IdObservacaoTipo
                }
              }
            })
          }
        })

        setObservacaotipos(response.data)
      })
  }, [])

  //ACUMULADO UNIDADE OU AGRUPAMENTO
  useEffect(() => {
    //SE IDUNIDADE = 0 E EXISTIR AGRUPAMENTO
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      // console.log("dash agrupamento")
      // console.log("dash agrupamento: " + sessionStorage.IdUnidade)
      // console.log("dash agrupamento: " + sessionStorage.uniAgrupamento)
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoAgrupamentoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.uniAgrupamento +
          '/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
    //SE TEM IDUNIDADE E NAO AGRUPAMENTO
    else {
      //  console.log("dash acumulado")
      //  console.log("dash acumulado: " + sessionStorage.IdUnidade)
      //  console.log("dash acumulado: " + sessionStorage.uniAgrupamento)
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashUnidadesAcumuladoOTP/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          sessionStorage.IdUnidade +
          '/' +
          sessionStorage.IdObservacaoTipo
        )
        .then(response => {
          setdashUnidades(response.data)
        })
    }
  }, [])

  //ACUMULADO POR MES UNIDADE OU AGRUPAMENTO
  useEffect(() => {
    var url = ''
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      //console.log("dashUnidadesMes: Agrupamento")
      var url =
        '/api/v1/dashUnidadesAgrupamento/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.uniAgrupamento +
        '/' +
        user[0].IdEmpresa
    } else {
      //console.log("dashUnidadesMes: Unidade")
      var url =
        '/api/v1/dashUnidadesEmpresa/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        user[0].IdEmpresa
    }
    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setdashUnidadesMes(response.data)
    })
  }, [])

  //META UNIDADE MES
  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_API_URL +
  //       '/api/v1/dashUnidades/meta/' +
  //       sessionStorage.IdUnidade
  //     )
  //     .then(response => {
  //       setmetaUnidadesMes(response.data)
  //     })
  // }, [])

  //GRÁFICO DE ATIVADORES
  useEffect(() => {
    var url = ''
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      var url = process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresUnidadeAgrupamento/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.uniAgrupamento +
        '/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdObservacaoTipo
    } else {
      var url = process.env.REACT_APP_API_URL +
        '/api/v1/ativadoresUnidade/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim +
        '/' +
        sessionStorage.IdUnidade +
        '/' +
        sessionStorage.IdObservacaoTipo
    }
    axios.get(url).then(response => {
      // console.log(sessionStorage.IdUnidade)
      // console.log(url)
      setAtivador(response.data)
    })
  }, [])

  //SETA NOME DA UNIDADE
  useEffect(() => {
    if (sessionStorage.uniAgrupamento !== "" && sessionStorage.uniAgrupamento !== undefined) {
      setUnidadeBarraTitulo(sessionStorage.uniAgrupamento)
    } else {
      setUnidadeBarraTitulo(sessionStorage.uniRazaoSocial)
    }
  }) //, [])

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>{' '}
          {/* BARRA COM TITULO E BOTÃO OBSERVAÇÕES E MODAL */}
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>RELATÓRIO</Translate> / {unidadeBarraTitulo} /{' '}
                <Translate>PERÍODO</Translate>:
                {/* <Translate>RELATÓRIO / UNIDADE</Translate> / {sessionStorage.uniRazaoSocial} : */}
              </TitlePage1>
              <TitlePage3></TitlePage3>
              {/* <TitlePage2>
                {unidadeBarraTitulo}
              </TitlePage2> */}
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <Modal />
            </div>
          </div>
          {/* FINAL - BARRA COM TITULO E BOTÃO OBSERVAÇÕES E MODAL */}
          <div className="divIcs">
            {/* QUADROS DE META E ACUMULADO */}
            <div className="dashBoadMetaPadrao">
              {
                dashUnidades
                  .filter(val => {
                    if (
                      searchTerm === '' &&
                      parseInt(val.IdObservacaoTipo) === parseInt(sessionStorage.IdObservacaoTipo) &&
                      parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                    ) {
                      return val
                    } else if (
                      parseInt(val.IdObservacaoTipo) === parseInt(sessionStorage.IdObservacaoTipo) &&
                      parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                    ) {
                      return val
                    }

                  })
                  .map(unidade => (
                    <div
                      style={{ cursor: 'default' }}
                      className="UniClienteMes ics-cor-verde"
                    >
                      <div className="DashItem">
                        <span>
                          <h1 htmlFor="" style={{ cursor: 'default' }}>
                            <Translate>PROJETADO</Translate>
                          </h1>
                        </span>
                        <div className="linha1">
                          <img width={40} height={40} src={okImg} alt="okImg" />
                        </div>
                        <div className="linha1">90.00%</div>
                        <div className="linha2">
                          <div className="op1">
                            <div className="op1-1">
                              <Translate>Meta </Translate>(Qtde):
                            </div>
                            <div className="op1-1">
                              {parseInt(unidade.mtlQuantitativa)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              {dashUnidades
                .filter(val => {
                  if (
                    searchTerm === '' &&
                    parseInt(val.IdObservacaoTipo) === parseInt(sessionStorage.IdObservacaoTipo) &&
                    parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  ) {
                    //console.log("if")
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) === parseInt(sessionStorage.IdObservacaoTipo) &&
                    parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  ) {
                    //console.log("else if")
                    //console.log(parseInt(sessionStorage.IdObservacaoTipo))
                    return val
                  }
                  // if (
                  //   searchTerm === '' &&
                  //   val.uniAgrupamento === sessionStorage.uniAgrupamento
                  //   //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  // ) {
                  //   return val
                  // } else if (
                  //   parseInt(val.IdObservacaoTipo) ===
                  //   parseInt(sessionStorage.IdObservacaoTipo) &&
                  //   val.uniAgrupamento === sessionStorage.uniAgrupamento
                  //   //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  // ) {
                  //   return val
                  // }
                })
                .map(unidade => (
                  <div
                    className={
                      parseFloat(unidade.rtqPontuacao) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(unidade.rtqPontuacao) < 70
                          ? 'UniClienteMes ics-cor-vermelho'
                          : parseFloat(unidade.rtqPontuacao) < 90
                            ? 'UniClienteMes ics-cor-amarelo'
                            : 'UniClienteMes ics-cor-verde'
                    }
                    style={{ cursor: 'default' }}
                  >
                    <Indicador
                      p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                      p_titulo={'ACUMULADO'}
                      p_meta={parseInt(unidade.mtlQuantitativa)}
                      p_real={unidade.mtlQuantitativaReal}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </div>
                ))}
            </div>
            {/* FINAL - QUADROS DE META E ACUMULADO */}
            {/* GRÁFICO E LISTAGEM DE ATIVADORES */}
            {ativador.length ? (
              <div className="relPizza2">
                <div className="divisor1">
                  <AtivadoresChart data={ativador} />
                </div>
                <div className="divisor2">
                  <TabelaAtivadores
                    p_Inicio={sessionStorage.DataInicio}
                    p_Fim={sessionStorage.DataFim}
                  />
                </div>
              </div>
            ) : (''
              // EXIBIDO CASO NÃO TENHA ATIVADORES NO RETORNO
              // <div className="relPizza2">
              // <TitlePage2>
              //   <Translate>
              //     <tr>
              //       <td>
              //         SEM INCIDÊNCIA DE ATIVADORES NO PERÍODO
              //       </td>
              //     </tr>
              //   </Translate>
              // </TitlePage2>
              // </div>
            )}
            {/* FINAL - GRÁFICO E LISTAGEM DE ATIVADORES */}
          </div>
          <div id="containerHeadSub">
            <TitleOfPage>
              <TitlePage21>
                <Translate>Resultado do período selecionado:</Translate>
              </TitlePage21>
            </TitleOfPage>
          </div>
          {/* DASH COM ÍCONES DOS RESULTADOS POR MÊS */}
          <div className="dashBoadPadrao">
            {dashUnidadesMes
              .filter(val => {
                if (parseInt(sessionStorage.IdUnidade) === 0) {
                  //console.log("dashUnidadesMes.filter(0): " + sessionStorage.IdUnidade)
                  if (
                    searchTerm === '' &&
                    val.uniAgrupamento === sessionStorage.uniAgrupamento
                  ) {
                    //console.log(val)
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo) &&
                    val.uniAgrupamento === sessionStorage.uniAgrupamento
                  ) {
                    return val
                  }
                } else {
                  //console.log("dashUnidadesMes.filter(>0): " + sessionStorage.IdUnidade)
                  if (
                    searchTerm === '' &&
                    //val.uniAgrupamento === sessionStorage.uniAgrupamento
                    parseInt(val.IdUnidade) ===
                    parseInt(sessionStorage.IdUnidade)
                  ) {
                    return val
                  } else if (
                    parseInt(val.IdObservacaoTipo) ===
                    parseInt(sessionStorage.IdObservacaoTipo) &&
                    //val.uniAgrupamento === sessionStorage.uniAgrupamento
                    parseInt(val.IdUnidade) ===
                    parseInt(sessionStorage.IdUnidade)
                  ) {
                    return val
                  }
                }
              })
              .map(unidade => (
                <Link
                  className={
                    parseFloat(unidade.rtqPontuacao) === 0
                      ? 'UniClienteMes ics-cor-cinza'
                      : parseFloat(unidade.rtqPontuacao) < 70
                        ? 'UniClienteMes ics-cor-vermelho'
                        : parseFloat(unidade.rtqPontuacao) < 90
                          ? 'UniClienteMes ics-cor-amarelo'
                          : 'UniClienteMes ics-cor-verde'
                  }
                  to={
                    parseInt(sessionStorage.IdUnidade) === 0
                      ? `/UniUnidades/${unidade.ano}/${unidade.mes}`
                      : `/UniGerencia/${unidade.IdUnidade}/${unidade.ano}/${unidade.mes}`
                  }
                //to={`/UniClieSetor/0/${unidade.ano}/${unidade.mes}`}
                >
                  <Indicador
                    p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                    p_titulo={unidade.rtoIniciadoEm}
                    p_meta={unidade.mtlQuantitativa}
                    p_real={unidade.mtlQuantitativaReal}
                    p_pesIdioma={user[0].pesIdioma}
                  />
                </Link>
              ))}
          </div>
          {/* FINAL DASH COM ÍCONES DOS RESULTADOS POR MÊS */}
          <div className="cxLegenda">
            <label htmlFor="" className="lgHLegenda">
              <Translate>Legenda:</Translate>
            </label>
            <button className="lgHAcima">
              <Translate>Esperado</Translate>
            </button>
            <button className="lgHMedia">
              <Translate>Atenção</Translate>
            </button>
            <button className="lgHAbaixo">
              <Translate>Risco</Translate>
            </button>
          </div>
          {/* ------------------------------------------------------------------------------------------------------ */}
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        {/* ====================== */}

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            <form action="">
              <h1 className="selectAnalise">
                <Translate>Selecione</Translate>:
              </h1>
              <label className="SelectCliente" htmlFor="">
                <Translate>Cliente/Unidade</Translate>:
              </label>
              <select
                name="IdUnidade"
                className="SelectIdCliente"
                onChange={event => {
                  handleUnidadeChange(event)
                }}
              >
                {unidades
                  .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                  .map(unidade => (
                    <option
                      value={unidade.IdUnidade}
                      text={unidade.uniAgrupamento}
                      selected={
                        parseInt(unidade.IdUnidade) ===
                        parseInt(sessionStorage.IdUnidade)
                      }
                    >
                      {unidade.uniNome}
                    </option>
                  ))}
              </select>
              <label className="SelectObserv" htmlFor="">
                <Translate>Tipo de Observação</Translate>:
              </label>
              <select
                name="SelectIdObserv"
                className="SelectIdObserv"
                onChange={event => {
                  setSearchTerm(event.target.value)
                  handleObsTipoChange(event)
                }}
              >
                {observacaotipos
                  .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                  .filter(val => {
                    if (val.otpStatus === 1) {
                      return val
                    }
                  })
                  .map(observacaotipo => (
                    <option
                      value={observacaotipo.IdObservacaoTipo}
                      selected={
                        parseInt(observacaotipo.IdObservacaoTipo) ===
                        parseInt(sessionStorage.IdObservacaoTipo)
                      }
                    >
                      {observacaotipo.otpDescricao}
                    </option>
                  ))}
              </select>
              <label className="SelectDate">
                <Translate>Periodo Analisado</Translate>:
              </label>

              <div className="calendarioAnalise2">
                <Calendar
                  calendarType="Hebrew"
                  onChange={setSeacrhDate}
                  value={date}
                  maxDetail={'year'}
                  selectRange={true}
                />
              </div>
            </form>
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default UniExe
