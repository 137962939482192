import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import InfoUnidade from '../../Relatorio/DetalhesDaObservação/InfoUnidade'

const uuidv4 = require('uuid/v4')

function RetornoQuestaoAtivador() {
  // DESATIVA O VOLTAR DO NAVEGADOR
  window.history.pushState(
    { name: 'browserBack' },
    'on browser back click',
    window.location.href
  )
  window.addEventListener(
    'popstate',
    event => {
      if (event.state) {
        //alert("saindo");
      }
    },
    false
  )

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [image, setImage] = useState('')
  const [imagePerfil, setImagePerfil] = useState('')
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImagePerfil(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImagePerfil(padrao)
  }
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  let history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  let newDate = new Date()
  let id = useParams()
  const [campos, setCampos] = useState({
    IdRetornoAtivador: 0,
    IdRetornoObservacao: id['idObs'],
    IdRetornoQuestao: id['idQue'],
    IdAtivadorGrupo: 0,
    IdAtivadorTexto: 0,
    IdPessoa: parseInt(user[0].IdPessoa),
    rtaStatus: 1,
    rtaObservacoes: '',
    rtaCriadoPor: user[0].pesEmail,
    rtaCriadoEm: newDate,
    rtaAlteradoPor: user[0].pesEmail,
    rtaAlteradoEm: newDate,
    rtaCodigoGUID: uuidv4()
  })
  const [camposImagem, setCamposImagem] = useState({
    IdRetornoImagem: 0,
    IdRetornoObservacao: id['idObs'],
    IdRetornoQuestao: id['idQue'],
    rtiCaminho: '',
    rtiImagem: '',
    rtiStatus: 1,
    rtiCriadoPor: user[0].pesEmail,
    rtiCriadoEm: newDate,
    rtiAlteradoPor: user[0].pesEmail,
    rtiAlteradoEm: newDate,
    rtiCodigoGUID: uuidv4()
  })
  const [auditorias, setAuditorias] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashAuditoriasId/' +
        id['idObs']
      )
      .then(response => {
        setAuditorias(response.data)
      })
  }, [])
  const [ativadores, setAtivadores] = useState([])
  const [imagens, setImagens] = useState({
    NomeImagem: '',
    Imagem: ''
  })

  function handleFormSubmit(event) {
    event.preventDefault()

    axios.post(
      process.env.REACT_APP_API_URL + '/api/v1/retornoquestao/' + id['idQue'],
      {
        IdRetornoQuestao: id['idQue'],
        rtqPontuacao: id['nota'],
        rtqStatus: 1,
        rtqObservacoes: campos.rtaObservacoes,
        rtqAlteradoPor: user[0].pesEmail,
        rtqAlteradoEm: newDate
      }
    )
    let value = observacaoAtivadores.find(
      element => element.IdRetornoAtivador > 0 && element.rtaStatus === 1
    )
    let value2 = ativadores.find(element => element.status === 1)
    //console.log(ativadores)
    if (value2 !== undefined || value !== undefined) {
      const formData = new FormData()
      formData.append('file', image)
      //console.log(image)
      if (image) {
        var reader = new FileReader()
        if (image) {
          reader.readAsDataURL(image)
          reader.onload = function (fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result
            imagens['Imagem'] = srcData.split(',')[1]
            imagens['NomeImagem'] = uuidv4()
            //console.log(srcData.split(',')[1])
            setImagens(imagens)
            axios
              .post(process.env.REACT_APP_API_URL + '/upload', imagens)
              .then(response => {
                camposImagem['rtiCaminho'] = response.data['url']
                //console.log(response.data['url'])
                setCamposImagem(campos)
                axios
                  .post(
                    process.env.REACT_APP_API_URL + '/api/v1/retornoimagem',
                    camposImagem
                  )
                  .then(response => { })
              })
          }
        }
      }
      ativadores.forEach(element => {
        if (element.idAtivador) {
          campos['IdAtivadorGrupo'] = element.grupo
          campos['IdAtivadorTexto'] = element.id
          campos['rtaStatus'] = element.status
          campos['IdRetornoAtivador'] = element.idAtivador
          setCampos(campos)
          axios
            .post(
              process.env.REACT_APP_API_URL +
              '/api/v1/retornoativador/' +
              element.idAtivador,
              campos
            )
            .then(response => { })
        } else {
          campos['IdAtivadorGrupo'] = element.grupo
          campos['IdAtivadorTexto'] = element.id
          setCampos(campos)
          axios
            .post(
              process.env.REACT_APP_API_URL + '/api/v1/retornoativador',
              campos
            )
            .then(response => { })
        }
      })
      alert('Apontamento realizado com sucesso!')
      history.push(
        '/RetornoQuestao/' +
        sessionStorage.IdUnidadeNovaObs +
        '/' +
        0 +
        '/' +
        id['idObs'] +
        '/hash-scroll#' +
        id['idQue']
      )
    } else {
      if (id['nota'] === '1') {
        const formData = new FormData()
        formData.append('file', image)
        //console.log(image)
        if (image) {
          var reader = new FileReader()
          if (image) {
            reader.readAsDataURL(image)
            reader.onload = function (fileLoadedEvent) {
              var srcData = fileLoadedEvent.target.result
              imagens['Imagem'] = srcData.split(',')[1]
              imagens['NomeImagem'] = uuidv4()
              //console.log(srcData.split(',')[1])
              setImagens(imagens)
              axios
                .post(process.env.REACT_APP_API_URL + '/upload', imagens)
                .then(response => {
                  camposImagem['rtiCaminho'] = response.data['url']
                  //console.log(response.data['url'])
                  setCamposImagem(campos)
                  axios
                    .post(
                      process.env.REACT_APP_API_URL + '/api/v1/retornoimagem',
                      camposImagem
                    )
                    .then(response => { })
                })
            }
          }
        }
        if (campos.rtaObservacoes !== '') {
          axios
            .post(
              process.env.REACT_APP_API_URL + '/api/v1/retornoativador',
              campos
            )
            .then(response => { }
            )

          history.push(
            '/RetornoQuestao/' +
            sessionStorage.IdUnidadeNovaObs +
            '/' +
            0 +
            '/' +
            id['idObs'] +
            '/hash-scroll#' +
            id['idQue']
          )
        } else {
          history.push(
            '/RetornoQuestao/' +
            sessionStorage.IdUnidadeNovaObs +
            '/' +
            0 +
            '/' +
            id['idObs'] +
            '/hash-scroll#' +
            id['idQue']
          )
        }
      } else {
        alert('Selecione ao menos um ativador!')
      }
    }
  }

  function handleAtivador(id, grupo, idAtivador, status, event) {
    if (event.target.checked) {
      const newList = ativadores//.filter(item => item.idAtivador !== idAtivador)

      const newList2 = newList.concat({
        id: id,
        grupo: grupo,
        idAtivador: idAtivador,
        status: 1
      })
      setAtivadores(newList2)
    } else {
      if (idAtivador) {
        const newList = ativadores.concat({
          id: id,
          grupo: grupo,
          idAtivador: idAtivador,
          status: 0
        })
        setAtivadores(newList)
      } else {
        const newList = ativadores.filter(item => item.id !== id)
        setAtivadores(newList)
      }
    }
  }

  function handleInputChange(event) {
    campos[event.target.name] = event.target.value
    setCampos(campos)
  }
  const [observacaoquestoes, setObservacaoquestao] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL + '/api/v1/retornoquestao/' + id['idQue']
      )
      .then(response => {
        if (response.status === 200) {
          setObservacaoquestao(response.data)
          campos.rtaObservacoes = response.data[0].rtqObservacoes
        }
      })
  }, [])
  //console.log(observacaoquestoes)
  const [observacaoAtivadores, setObservacaoAtivador] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/textoativadorap/list/' +
        parseInt(user[0].IdEmpresa) +
        '/' +
        id['idQue']
      )
      .then(response => {
        if (response.status === 200) {
          setObservacaoAtivador(response.data)
        }
      })
  }, [])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoativador/obs/' +
        id['idQue'] +
        '/' +
        parseInt(user[0].IdEmpresa)
      )
      .then(response => {
        if (response.status === 200) {
          response.data.forEach(element => {
            //console.log(element)
            if (element.rtaObservacoes) {
              campos['rtaObservacoes'] = element.rtaObservacoes
              setCampos(campos)
            }
          })
          setObservacaoAtivador(response.data)
        }
      })
  }, [])
  let idGrupo = 0
  const [toogle, setToogle] = React.useState(false)
  //console.log(ativadores)
  //console.log(campos.rtaObservacoes)
  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>{sessionStorage.uniRazaoSocial}</TitlePage1>
              <TitlePage2>
                <Translate>Relato do item observado</Translate>
              </TitlePage2>
            </TitleOfPage>
          </div>
          <form onSubmit={handleFormSubmit} encType="multipart/form-data">
            <div id="containerHeadUploadPhoto">
              <label>
                <Translate>Upload de foto</Translate>:
              </label>
              <input
                type="file"
                name="empLogo"
                id="empLogo"
                accept=".gif,.jpg,.jpeg,.png"
                onChange={function (event) {
                  setImage(event.target.files[0])
                  //console.log(event.target.files[0])
                }}
              />
              <button className="RQAbuttonSave" typeof="submit">
                <i className="far fa-save"></i>
              </button>
            </div>
            <div id="containerObs">
              {observacaoquestoes.map(observacaoquestao => (
                <table>
                  <tr>
                    <th>{observacaoquestao.ogrDescricao}</th>
                  </tr>
                  <tr>
                    <td key={observacaoquestao.IdRetornoQuestao}>
                      <label>
                        <Translate>{observacaoquestao.oqsDescricao}</Translate>
                      </label>
                      <textarea
                        typeof="text"
                        id="fname"
                        name="rtaObservacoes"
                        defaultValue={observacaoquestao.rtqObservacoes === "" || observacaoquestao.rtqObservacoes === undefined ? campos.rtaObservacoes : observacaoquestao.rtqObservacoes}
                        rows={5}
                        onChange={handleInputChange}
                        required={id['nota'] !== '1'}
                      ></textarea>
                    </td>
                  </tr>
                </table>
              ))}
            </div>
          </form>
          {id['nota'] === '1' ? (
            ''
          ) : (
            <>
              <form>
                <div id="containerHeadpesquisa">
                  <label>
                    <Translate>Selecionar Ativadores</Translate>:
                  </label>
                  <input
                    placeholder="Pesquisar"
                    type="text"
                    id="fname1"
                    name="fname"
                    onChange={event => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </div>
                <div className="tabelaPadrao">
                  {observacaoAtivadores
                    .filter(val => {
                      if (searchTerm === '') {
                        return val
                      } else if (
                        val.attDescricao
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        val.attObservacoes
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val
                      }
                    })
                    .map(observacaoAtivador =>
                      observacaoAtivador.IdAtivadorGrupo === idGrupo ? (
                        <div style={{ border: '1px solid #000' }}>
                          <div
                            className="RQAText1"
                            key={observacaoAtivador.IdAtivadorTexto}
                          >
                            <input
                              type="checkbox"
                              className="CheckAcess"
                              key={observacaoAtivador.IdAtivadorTexto}
                              onChange={event =>
                                handleAtivador(
                                  observacaoAtivador.IdAtivadorTexto,
                                  observacaoAtivador.IdAtivadorGrupo,
                                  observacaoAtivador.IdRetornoAtivador,
                                  observacaoAtivador.rtaStatus,
                                  event
                                )
                              }
                              defaultChecked={!!observacaoAtivador.rtaStatus}
                            />
                            <span>
                              <Translate>
                                {observacaoAtivador.attDescricao}
                              </Translate>
                            </span>
                            <hr className="hrDivisor"></hr>
                          </div>
                          <div className="btnlistPlanej3">
                            <Translate>
                              {observacaoAtivador.attObservacoes}
                            </Translate>
                          </div>
                        </div>
                      ) : (
                        <div style={{ border: '1px solid #000' }}>
                          {/* ====Ativador=== */}
                          <div className="RQAText">
                            <Translate>
                              {''}
                              {observacaoAtivador.gpaDescricao}
                            </Translate>
                          </div>
                          {/* ========descrição do ativador======= */}
                          <div
                            className="RQAText1"
                            key={observacaoAtivador.IdAtivadorGrupo}
                          >
                            <input
                              className="CheckAcess"
                              type="checkbox"
                              key={observacaoAtivador.IdAtivadorTexto}
                              onChange={event =>
                                handleAtivador(
                                  observacaoAtivador.IdAtivadorTexto,
                                  observacaoAtivador.IdAtivadorGrupo,
                                  observacaoAtivador.IdRetornoAtivador,
                                  observacaoAtivador.rtaStatus,
                                  event
                                )
                              }
                              defaultChecked={!!observacaoAtivador.rtaStatus}
                            />
                            <span>
                              <Translate>
                                {''}
                                {observacaoAtivador.attDescricao}
                              </Translate>
                            </span>
                            <hr className="hrDivisor"></hr>
                          </div>
                          {/* =============observação do ativador======= */}

                          <div className="btnlistPlanej3">
                            <Translate>
                              {''}
                              {observacaoAtivador.attObservacoes}
                            </Translate>
                          </div>
                          <div className="funcOcultar">
                            {(idGrupo = observacaoAtivador.IdAtivadorGrupo)}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </form>
            </>
          )}
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>{' '}
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {imagePerfil ? (
                <img className="logo1" src={imagePerfil} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            {auditorias
              .filter(val => {
                if (
                  parseFloat(val.IdRetornoObservacao) ===
                  parseFloat(id['idObs'])
                ) {
                  return val
                }
              })
              .map(auditoria => (
                <InfoUnidade
                  Observador={auditoria.Observador}
                  atvDescricao={auditoria.atvDescricao}
                  uniNome={auditoria.uniNome}
                  equipe_abordada={auditoria.equipe_abordada}
                  local={auditoria.local}
                  rtoIniciadoEm={auditoria.rtoIniciadoEm}
                  rtoFinalizadoEm={auditoria.rtoFinalizadoEm}
                  rtoObservados={auditoria.rtoObservados}
                  rtoRotina={auditoria.rtoRotina}
                  rtoObservadores={auditoria.rtoObservadores}
                  rtoObservacoes={auditoria.rtoObservacoes}
                />
              ))}
          </div>
          <div className="cxLogo">
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default RetornoQuestaoAtivador
