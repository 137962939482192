import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useHistory, useParams } from 'react-router-dom'
import authService from '../../Components/Login/auth.service'
import {
  ContainerPage2,
  TitleOfPageRel,
  TitlePageRelSub,
  TitlePageRel,
  TitlePageRel1
} from '../../Components/Main'
import padrao from '../../Components/profile.png'
import '../../styles/pages/MenuDireito/relatorioICS.css'

function RelatorioICS() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let history = useHistory()
  let id = useParams()
  const [sidebar, setSidebar] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermFiltro, setSearchTermFiltro] = useState(0)

  const [modalInfo, setModalInfo] = useState([])
  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_API_URL +
  //         '/api/v1/retornoquestao/list/' +
  //         id['IdRetornoObservacao']
  //     )
  //     .then(response => {
  //       setModalInfo(response.data)
  //     })
  // }, [])
  //console.log(modalInfo)
  const [tipoObservacao, setTipoObservacao] = useState([])
  const [tituloTipoObservacao, setTituloTipoObservacao] = useState([])
  const [auditorias, setAuditorias] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasId/' +
          id['IdRetornoObservacao']
      )
      .then(response => {
        if (response.data.length > 0) {
          setAuditorias(response.data)
          //console.log(auditorias)
          //valida nota e recalcula
          if(parseFloat(response.data[0].rtoPontuacao) === 0)
          {
            axios
              .get(
                'https://ws.hitperformance.com.br/MeuCPTO/CalculaNota?idRetornoObservacao=' +
                response.data[0].IdRetornoObservacao
              )
              .then(responseRC => {
                axios
                .get(
                  process.env.REACT_APP_API_URL +
                    '/api/v1/dashAuditoriasId/' +
                    id['IdRetornoObservacao']
                )
                .then(responseO => {
                  if (responseO.data.length > 0) {
                    setAuditorias(responseO.data)
                  }
                  //window.location.reload()
                })
              })
              console.log("RECALCULOU")              
          }

          //obtem dados do tipo de observacao
          axios
            .get(
              process.env.REACT_APP_API_URL +
                '/api/v1/observacaotipo/' +
                response.data[0].IdObservacaoTipo
            )
            .then(responseOT => {
              //console.log(responseOT.data)
              if (
                responseOT.data[0].otpDescricao == 'Observação Comportamental'
              ) {
                setTipoObservacao('ICS')
                setTituloTipoObservacao('Observação Comportamental')
              } else if (
                responseOT.data[0].otpDescricao == 'Qualidade da Observação'
              ) {
                setTipoObservacao('IQO')
                setTituloTipoObservacao('Qualidade da Observação')
              } else {
                setTipoObservacao('ÍNDICE')
                setTituloTipoObservacao('ÍNDICE')
              }
            })

          axios
          .get(
            process.env.REACT_APP_API_URL +
              '/api/v1/retornoquestao/list/' +
              id['IdRetornoObservacao']
          )
          .then(response => {
            setModalInfo(response.data)
          })
        }
      })
  }, [])
  
  const [imagens, setImagens] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/retornoimagem/list/' +
          id['IdRetornoObservacao']
      )
      .then(response => {
        setImagens(response.data)
      })
  }, [])
  const [ativadores, setAtivadores] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/retornoativador/list/' +
          id['IdRetornoObservacao']
      )
      .then(response => {
        setAtivadores(response.data)
      })
  }, [])
  
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  //console.log(ativadores)
  const [MensagemQuestoes, setMensagemQuestoes] = useState("CARREGANDO OBSERVAÇÃO...")
  useEffect(() => {
    setTimeout(function mensagem(){
      if(modalInfo.length === 0)
      {
        setMensagemQuestoes("Existem pendências de fechamento.")
      }
      else
      {
        setMensagemQuestoes("")
      }
    },9000);
  })
    //history.goBack()
  console.log(MensagemQuestoes)

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage2>
          {auditorias.length > 0 ? <table style={{border: 0}}>
          <a
            href={
              'https://ws.hitperformance.com.br/MeuCPTO/RelatorioObservacao?id=' +
              id['IdRetornoObservacao']
            }
            target="_blank"
            rel="noreferrer"
          >
            {/* <button className="verifObserv2"></button> */}
            <i class="verifObserv2 fa fa-file-pdf" ></i>
          </a>
          
          <TitleOfPageRel>
            <div className="logoRelatorio" />
            <TitlePageRel>
              <Translate>RESULTADO DA OBSERVAÇÃO</Translate>
            </TitlePageRel>
          </TitleOfPageRel>

          <button
            className="btnReturnRel"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          {/* Data da Auditoria: {Moment(audData).format('DD/MM/YYYY')} */}
          {auditorias
            .filter(val => {
              if (
                parseFloat(val.IdRetornoObservacao) ===
                parseFloat(id['IdRetornoObservacao'])
              ) {
                return val
              } else if (
                parseInt(val.IdRetornoQuestao) ===
                parseInt(id['IdRetornoQuestao'])
              ) {
                return val
              }
            })
            .map(auditoria => (
              <div className="RelForm">
                <div id="containerHeadRel">
                  <TitlePageRelSub>{tituloTipoObservacao}</TitlePageRelSub>
                </div>
                <div className="RelFormInfo">
                  <div className="relFormFormulario" >
                    <label className="TtlInfoRel1">
                      <Translate>UNIDADE</Translate>:
                      <input
                        disabled
                        className="InfoRel1"
                        value={auditoria.uniAgrupamento === '' ? auditoria.uniNome : auditoria.uniAgrupamento}
                      ></input>
                    </label>
                    
                    <label className='TtlInfoRel2'>
                      <Translate>SUB UNIDADE</Translate>:
                      <input
                        disabled
                        className='InfoRel1'
                        value={auditoria.uniAgrupamento !== '' ? auditoria.uniNome : ''}
                        ></input>
                    </label>  
                    
                    <label className="TtlInfoRel2">
                      <Translate>LOCAL</Translate>:
                      <textarea
                        disabled
                        className="InfoRel2"
                        value={auditoria.local}
                      />
                    </label>

                    <label className="TtlInfoRel3">
                      <Translate>OBSERVADOR</Translate>:
                      <input
                        disabled 
                        className="InfoRel3"
                        value={auditoria.Observador}
                      />
                    </label>

                    <label className="TtlInfoRel4">
                      <Translate>EQUIPE OSERVADA</Translate>:
                      <input
                        disabled
                        className="InfoRel4"
                        value={auditoria.equipe_abordada}
                      />
                    </label>

                    <label className="TtlInfoRel5">
                      <Translate>ATIVIDADE:</Translate>
                      <input
                        disabled
                        className="InfoRel5"
                        value={auditoria.atvDescricao}
                      />
                    </label>
                  </div>

                  {/* ============================================================================================== */}
                  <div className="relFormFormulario">
                    <label className="TtlInfoRel11">
                      <Translate>INICIO DA OBSERVAÇÃO</Translate>:
                      <input
                        disabled
                        className="InfoRel11"
                        value={Moment(auditoria.rtoIniciadoEm).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                      />
                    </label>
                    <label className="TtlInfoRel21">
                      <Translate>FINAL DA OBSERVAÇÃO</Translate>:
                      <input
                        disabled
                        className="InfoRel21"
                        value={Moment(auditoria.rtoFinalizadoEm).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                      />
                    </label>
                    <label className="TtlInfoRel31">
                      <Translate>OBSERVADORES</Translate>:
                      <input
                        disabled
                        className="InfoRel31"
                        value={auditoria.rtoObservadores}
                      />
                    </label>{' '}
                    <label className="TtlInfoRel41">
                      <Translate>OBSERVADOS</Translate>:
                      <input
                        disabled
                        className="InfoRel41"
                        value={auditoria.rtoObservados}
                      />
                    </label>{' '}
                    <label className="TtlInfoRel5">
                      <Translate>CLASSE DA ATIVIDADE</Translate>:
                      <input
                        disabled
                        className="InfoRel5"
                        value={auditoria.rtoRotina}
                      />
                    </label>
                  </div>
                  
                  {parseFloat(auditoria.rtqPontuacao) === 0 ? ('') : 
                  (
                    <div
                      className={
                        parseFloat(auditoria.rtqPontuacao) === 0
                          ? 'relFormFormulario3 ics-cor-cinza'
                          : parseFloat(auditoria.rtqPontuacao) < 70
                          ? 'relFormFormulario3 ics-cor-vermelho'
                          : parseFloat(auditoria.rtqPontuacao) < 90
                          ? 'relFormFormulario3 ics-cor-amarelo'
                          : 'relFormFormulario3 ics-cor-verde'
                      }
                    >
                      <div className="FuncaoResultadoRel1">{tipoObservacao}</div>
                      <div className="FuncaoResultadoRel2">{parseFloat(auditoria.rtqPontuacao).toFixed(2)}%</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {auditorias
            .filter(val => {
              if (
                parseFloat(val.IdRetornoObservacao) ===
                parseFloat(id['IdRetornoObservacao'])
              ) {
                return val
              }
            })
            .map(auditoria => (
              <div className="RelForm2">
                <TitlePageRel1>
                  <Translate>RELATO DA OBSERVAÇÃO</Translate>
                </TitlePageRel1>

                <div className="ObservRel">
                  {' '}
                  <Translate>{auditoria.rtoObservacoes} </Translate>
                </div>
              </div>
            ))}

          {/* primeira parte do relatorio com questoes ativadiores e notas */}

          <div className="RelForm3">
            <TitlePageRel1>
              <Translate>DETALHES DA OBSERVAÇÃO</Translate>
            </TitlePageRel1>
            {/* titulos */}
            <table className="TableRelForm3">
              <tr>
                <td>
                  <div className="ttlRelForm3">
                    <Translate>GRUPO</Translate>
                  </div>
                </td>
                <td>
                  <div className="ttlRelForm3">
                    <Translate>QUESTÃO</Translate>
                  </div>
                </td>
                <td>
                  <div className="ttlRelForm3">
                    <Translate>ATIVADORES/RELATO</Translate>
                  </div>
                </td>
                <td></td>
              </tr>
              {/* final titulos */}
              {modalInfo
                .filter(val => {
                  if (searchTerm === '' && searchTermFiltro === 0) {
                    return val
                  } else if (
                    searchTerm === '' &&
                    parseInt(val.rtqPontuacao) === searchTermFiltro
                  ) {
                    return val
                  } else if (
                    val.oqsDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) &&
                    parseInt(val.rtqPontuacao) === searchTermFiltro
                  ) {
                    return val
                  } else if (
                    val.oqsDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) &&
                    searchTermFiltro === 0
                  ) {
                    return val
                  }
                })
                .map(observacaoquestao => (
                  <tr>
                    <td>
                      <Translate>{observacaoquestao.ogrDescricao}</Translate>
                    </td>
                    <td>
                      <Translate>{observacaoquestao.oqsDescricao}</Translate>
                    </td>

                    <td>
                      <div
                        className="relDesc"
                        style={{
                          lineHeight: '15px',
                          padding: '20px'
                        }}
                      >
                        {ativadores
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                          .filter(val => {
                            if (
                              parseInt(val.IdRetornoQuestao) ===
                              parseInt(observacaoquestao.IdRetornoQuestao)
                            ) {
                              return val
                            }
                          })
                          .map(ativador => (
                            <tr
                              style={{
                                //textTransform: 'uppercase',
                                padding: '5px'
                              }}
                            >
                              {/* <Translate>{ativador.rtaObservacoes}</Translate> */}

                              {/* {'Relato: ' + observacaoquestao.rtqObservacoes} */}
                            </tr>
                          ))}

                        {ativadores
                          .filter(val => {
                            if (
                              parseInt(val.IdRetornoQuestao) ===
                                parseInt(observacaoquestao.IdRetornoQuestao) &&
                              observacaoquestao.rtqPontuacao > 1
                            ) {
                              return val
                            }
                          })
                          .map(ativador => (
                            <tr
                              style={{
                                padding: '5px'
                              }}
                            >
                              <Translate>
                                {ativador.gpaDescricao +
                                  ' - ' +
                                  ativador.attDescricao}{' '}
                              </Translate>
                            </tr>
                          ))}
                      </div>
                      <div>{observacaoquestao.rtqObservacoes}</div>
                    </td>
                    <td className="lineRelForm3-1">
                      <div
                        className={
                          parseInt(observacaoquestao.rtqPontuacao) === 0
                            ? 'tblRelFilter ics-cor-cinza1'
                            : parseInt(observacaoquestao.rtqPontuacao) === 3
                            ? 'tblRelFilter ics-cor-vermelho1'
                            : parseInt(observacaoquestao.rtqPontuacao) === 2
                            ? 'tblRelFilter ics-cor-amarelo1'
                            : 'tblRelFilter ics-cor-verde1'
                        }
                      >
                        {parseInt(observacaoquestao.rtqPontuacao) === 0 ? (
                          <i className="far fa-laugh-beam"></i>
                        ) : parseInt(observacaoquestao.rtqPontuacao) === 3 ? (
                          <i className="far fa-frown"></i>
                        ) : parseInt(observacaoquestao.rtqPontuacao) === 2 ? (
                          <i className="far fa-meh"></i>
                        ) : (
                          <i className="far fa-laugh-beam"></i>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </table>
          </div>

          {/* final primeira parte do relatorio com questoes ativadiores e notas */}
          {/* segunda parte do relatorio com as imagens e detalhes em texto */}
          <div className="RelForm4">
            <TitlePageRel1>
              <Translate>REGISTRO DA OBSERVAÇÃO</Translate>
            </TitlePageRel1>
            <table className="TableRelForm3">
              {/* coluna icone de nota */}
              {imagens.map(imagemRetorno => (
                <tr>
                  <td
                    style={{ border: '1px solid #d8d8d8' }}
                    className="lineRelForm3"
                  >
                    {modalInfo
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )
                      .filter(val => {
                        if (
                          parseInt(val.IdRetornoQuestao) ===
                          parseInt(imagemRetorno.IdRetornoQuestao)
                        ) {
                          return val
                        }
                      })
                      .map(retornoquestao => (
                        <div
                          className={
                            parseInt(retornoquestao.rtqPontuacao) === 0
                              ? 'tblRelFilter ics-cor-cinza1'
                              : parseInt(retornoquestao.rtqPontuacao) === 3
                              ? 'tblRelFilter ics-cor-vermelho1'
                              : parseInt(retornoquestao.rtqPontuacao) === 2
                              ? 'tblRelFilter ics-cor-amarelo1'
                              : 'tblRelFilter ics-cor-verde1'
                          }
                        >
                          {parseInt(retornoquestao.rtqPontuacao) === 0 ? (
                            <i className="far fa-laugh-beam"></i>
                          ) : parseInt(retornoquestao.rtqPontuacao) === 3 ? (
                            <i className="far fa-frown"></i>
                          ) : parseInt(retornoquestao.rtqPontuacao) === 2 ? (
                            <i className="far fa-meh"></i>
                          ) : (
                            <i className="far fa-laugh-beam"></i>
                          )}
                        </div>
                      ))}
                  </td>
                  <td
                    style={{ border: '1px solid #d8d8d8' }}
                    className="lineRelForm3"
                  >
                    {/* final coluna icone */}
                    <div className="alignContent">
                      <div className="relDesc">
                        {modalInfo
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                          .filter(val => {
                            if (
                              parseInt(val.IdRetornoQuestao) ===
                              parseInt(imagemRetorno.IdRetornoQuestao)
                            ) {
                              return val
                            }
                          })
                          .map(retornoquestao => (
                            <>
                              <div style={{ display: 'flex' }}>
                                <label
                                  style={{ fontWeight: '600', padding: '5px' }}
                                >
                                  GRUPO:
                                </label>
                                <label style={{ padding: '5px' }}>
                                  {retornoquestao.ogrDescricao}
                                </label>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <label
                                  style={{ fontWeight: '600', padding: '5px' }}
                                >
                                  QUESTÃO:
                                </label>
                                <label style={{ padding: '5px' }}>
                                  {retornoquestao.oqsDescricao}
                                </label>
                              </div>
                            </>
                          ))}
                        {ativadores
                          .filter(val => {
                            if (
                              parseInt(val.IdRetornoQuestao) ===
                              parseInt(imagemRetorno.IdRetornoQuestao)
                            ) {
                              return val
                            }
                          })
                          .map(ativador => (
                            <div style={{ display: 'flex' }}>
                              <label
                                style={{ fontWeight: '600', padding: '5px' }}
                              >
                                {' '}
                                ATIVADOR / DESCRIÇÃO:
                              </label>
                              <label style={{ padding: '5px' }}>
                                <Translate>
                                  {ativador.gpaDescricao +
                                    ' - ' +
                                    ativador.attDescricao}
                                </Translate>
                              </label>
                            </div>
                          ))}
                        {modalInfo
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                          .filter(val => {
                            if (
                              parseInt(val.IdRetornoQuestao) ===
                              parseInt(imagemRetorno.IdRetornoQuestao)
                            ) {
                              return val
                            }
                          })
                          .map(retornoquestao => (
                            <>
                              <div style={{ display: 'flex' }}>
                                <label
                                  style={{ fontWeight: '600', padding: '5px' }}
                                >
                                  RELATO:
                                </label>
                                <label style={{ padding: '5px' }}>
                                  {retornoquestao.rtqObservacoes}
                                </label>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                    {/* imagem da questào */}
                    <div>
                      <img
                        src={imagemRetorno.rtiCaminho}
                        alt=""
                        width={150}
                        height={150}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          </table> : 
          <div>
            <TitleOfPageRel>
              <div className="logoRelatorio" />
              <TitlePageRel>
                <Translate>{MensagemQuestoes}</Translate>
              </TitlePageRel>
              
            </TitleOfPageRel>
          </div>}
        </ContainerPage2>
      </Translator>
    </>
  )
}

export default RelatorioICS
