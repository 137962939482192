import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuTipoDeObservação/modal-tiposDeobservaçoes/modalNovoGrupoDeQuestoes.css'
const uuidv4 = require('uuid/v4')

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdObservacaoGrupo: 0,
    IdObservacaoTipo: sessionStorage.IdObservacaoGrupo,
    IdEmpresa: user[0].IdEmpresa,
    ogrNumero: 0,
    ogrDescricao: '',
    ogrStatus: 1,
    ogrObservacoes: '',
    ogrCriadoPor: user[0].pesEmail,
    ogrCriadoEm: newDate,
    ogrAlteradoPor: user[0].pesEmail,
    ogrAlteradoEm: newDate,
    ogrCodigoGUID: uuidv4()
  })
  function handleInputChange(event) {
    if (event.target.name === 'ogrStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/observacaogrupo', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Novo Grupo</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Novo Grupo De Questões</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button className="close-modal" onClick={toggleModal}>
                    X
                  </button>
                </div>

                <form onSubmit={handleFormSubmit}>
                  <div className="linhaTipObs" style={{ width: '100%' }}>
                    <tr>
                      <th>Cód:</th>
                      <th style={{ width: '70%' }}>
                        <Translate>Descrição</Translate>:
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className="NGQCod"
                          placeholder="Cód"
                          type="number"
                          id="fname"
                          name="ogrNumero"
                          min={1}
                          onChange={handleInputChange}
                        ></input>
                      </td>
                      <td>
                        <input
                          className="NGQDescricao"
                          placeholder="Descrição"
                          type="text"
                          id="fname"
                          name="ogrDescricao"
                          onChange={handleInputChange}
                        ></input>
                      </td>
                    </tr>
                  </div>

                  <label>
                    <Translate>Observações</Translate>:
                  </label>
                  <textarea
                    type="text"
                    id="fname"
                    name="ogrObservacoes"
                    row="none"
                    maxLength="500"
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <button typeof="submit" className="gravar-modal">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
