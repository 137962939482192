import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'
const uuidv4 = require('uuid/v4')

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [obsTipo, setobsTipo] = useState([])
  const [modal, setModal] = useState(false)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setobsTipo(response.data)
      })
  }, [])

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdMetaLocal: '',
    IdEmpresa: user[0].IdEmpresa,
    IdUnidade: sessionStorage.IdUnidadeEdit,
    IdLocal: sessionStorage.MetaLocal,
    IdObservacaoTipo: 0,
    mtlQuantitativa: 0,
    mtlQualitativa: 0,
    mtlStatus: 1,
    mtlObservacoes: '',
    mtlCriadoPor: user[0].pesEmail,
    mtlCriadoEm: newDate,
    mtlAlteradoPor: user[0].pesEmail,
    mtlAlteradoEm: newDate,
    mtlCodigoGUID: uuidv4()
  })
  function handleInputChange(event) {
    if (event.target.name === 'mtlStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/metalocal', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Nova Meta</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Nova Área</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>

                <form onSubmit={handleFormSubmit}>
                  <label>
                    <Translate>Selecione um tipo de observação</Translate>:
                  </label>
                  <select
                    name="IdObservacaoTipo"
                    type="text"
                    defaultValue={campos.IdObservacaoTipo}
                    id="fSexo"
                    onChange={handleInputChange}
                  >
                    <option value="0">Selecione um tipo de observação</option>
                    {obsTipo
                      .sort((a, b) =>
                        a.otpDescricao > b.otpDescricao ? 1 : -1
                      )
                      .filter(val => {
                        if (val.otpStatus === 1) {
                          return val
                        }
                      })
                      .map(metaTipo => (
                        <option value={metaTipo.IdObservacaoTipo}>
                          {metaTipo.otpDescricao}
                        </option>
                      ))}
                  </select>
                  <div
                    className="linha2"
                    style={{ marginLeft: '5px', width: '200px' }}
                  >
                    <label>
                      <Translate>Quantitativa</Translate>
                    </label>
                    <label>
                      <Translate>Qualitativa</Translate>
                    </label>
                  </div>
                  <div
                    className="linha2"
                    style={{ marginLeft: '5px', width: '200px' }}
                  >
                    <input
                      placeholder=""
                      type="number"
                      id="mtlQuantitativa"
                      defaultValue={campos.mtlQuantitativa}
                      name="mtlQuantitativa"
                      onChange={handleInputChange}
                    ></input>
                    <input
                      style={{ marginLeft: '5px' }}
                      placeholder=""
                      type="number"
                      id="fname"
                      defaultValue={campos.mtlQualitativa}
                      name="mtlQualitativa"
                      onChange={handleInputChange}
                    ></input>
                  </div>

                  <label>
                    <Translate>Observações</Translate>:
                  </label>
                  <textarea
                    placeholder="Observações"
                    type="text"
                    id="fname"
                    name="mtlObservacoes"
                    defaultValue={campos.mtlObservacoes}
                    rows={5}
                    style={{textTransform:'uppercase'}}
                    onChange={handleInputChange}
                    maxLength="500"
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          className="CheckAcess"
                          name="mtlStatus"
                          type="checkbox"
                          defaultChecked={!!campos.mtlStatus}
                          onChange={handleInputChange}
                        />
                        <span className="checkText">
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button type="submit" className="gravar-modal">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
