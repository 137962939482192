import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../../Components/Login/auth.service'
import logologo from '../../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  ContainerPageProfile,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../../../Components/Main'
import padrao from '../../../../Components/profile.png'

import imgOpen from '../../../../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../../Components/SideBar-right/SidebarMobile'
import '../../../../styles/pages/SubMenuPages/SubMenuTipoDeObservação/Questões-tiposDeQuestões/TQOQuestoesReg.css'
import ModalEditar from '../modal-tiposDeObservações/modalEditarQuestao'
import Modal from '../modal-tiposDeObservações/modalNovaQuestao'

function TQOQuestoesReg() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let history = useHistory()
  var user = authService.getCurrentUser()
  let id = useParams()
  sessionStorage.IdObservacaoGrupo = id['IdObservacaoQuestao']
  const [questoes, setQuestoes] = useState([])
  const uniques = questoes
    .map(item => item.ogrDescricao)
    .filter((value, index, self) => self.indexOf(value) === index)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaoquestao/list/' +
        user[0].IdEmpresa +
        '/' +
        id['IdObservacaoQuestao']
      )
      .then(response => {
        setQuestoes(response.data)
      })
  }, [])
  const [modal, setModal] = useState(false)
  const toggleModal = () => {
    setModal(!modal)
  }
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  const [modalOpen, setModalOpen] = useState(false)

  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [toogle, setToogle] = React.useState(false)
  const [modalInfo, setModalInfo] = useState([])

  return (
    <div className="displayInteract">
      {modalOpen && (
        <ModalEditar setOpenModal={setModalOpen} modalInfo={modalInfo} />
      )}
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {/* {sessionStorage.uniRazaoSocial} */}
                {uniques[0]} /
                <Translate> DADOS CADASTRAIS / QUESTÕES </Translate>:
              </TitlePage1>
              <TitlePage2>
                <Translate>Questões de</Translate> : {uniques[0]}
              </TitlePage2>
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <Modal />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>

          <div className="tabelaPadrao">
            <table>
              <tr>
                {/* <th>Id questao</th> */}
                <th>
                  <Translate>Questão</Translate>
                </th>
                <th>
                  <Translate>Status</Translate>{' '}
                </th>
              </tr>
              {questoes
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.oqsDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(questao => (
                  <tr onClick={() => handleToggleComplete(questao)}>
                    {/* <td className="TQOQuestCod">{questao.oqsNumero}</td> */}
                    <td>
                      <Translate>{questao.oqsDescricao}</Translate>
                    </td>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          defaultChecked={!!questao.oqsStatus}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>{' '}
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <SideBarDireita />
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default TQOQuestoesReg
function setSidebar(arg0) {
  throw new Error('Function not implemented.')
}
