import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuTextosAtivadores/modal-textosAtivadores/ModalNovoAtivador.css'
const uuidv4 = require('uuid/v4')

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [grupos, setGrupo] = useState([])

  const [campos, setCampos] = useState({
    IdAtivadorTexto: '',
    IdAtivadorGrupo: sessionStorage.IdAtivadorGrupo,
    IdEmpresa: user[0].IdEmpresa,
    attDescricao: '',
    attStatus: 1,
    attObservacoes: '',
    attCriadoPor: user[0].pesEmail,
    attCriadoEm: newDate,
    attAlteradoPor: user[0].pesEmail,
    attAlteradoEm: newDate,
    attCodigoGUID: uuidv4()
  })
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/api/v1/textoativador/listAtivadorGrupos/' +
          user[0].IdEmpresa
      )
      .then(response => {
        setGrupo(response.data)
      })
  }, [])
  function handleInputChange(event) {
    if (event.target.name === 'attStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/textoativador', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Novo Ativador</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Novo Ativador</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    <Translate>Descrição</Translate>:
                  </label>
                  <input
                    type="text"
                    id="fname"
                    name="attDescricao"
                    onChange={handleInputChange}
                  ></input>

                  <label>
                    <Translate>Observação</Translate>:
                  </label>
                  <textarea
                    type="text"
                    id="fname"
                    name="attObservacoes"
                    rows={4}
                    maxLength="500"
                    onChange={handleInputChange}
                  ></textarea>

                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          name="gpaStatus"
                          type="checkbox"
                          onChange={handleInputChange}
                        />
                        <span>
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button typeof="submit">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
