import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link } from 'react-router-dom'
import authService from '../Components/Login/auth.service'
import logologo from '../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  ContainerPageProfile,
  TitleOfPage,
  TitlePage1,
  TitlePage24,
  TitlePage8
} from '../Components/Main'
import imgOpen from '../Images/imgButtonEsquerda/imgOpen.svg'
import padrao from '../Components/profile.png'
import SideBarDireita from '../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../Components/SideBar-right/SidebarMobile'
import logoK from '../Images/logoQ.png'
import '../styles/pages/FormularioCadastro.css'
const uuidv4 = require('uuid/v4')

function FormularioCadastro() {
  sessionStorage.TelaMenuLateral = 'FormularioCadastro'
  sessionStorage.TelaMenuPrincipal = 'FormularioCadastro'
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  const [modalOpen, setModalOpen] = useState(false)
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  function handleToggleComplete(objetos = []) {
    setModalOpen(true)
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [unidades, setUnidades] = useState([])

  //console.log(sessionStorage.IdUnidadeEdit)
  //console.log(sessionStorage.IdUnidadeForm)

  useEffect(() => {
    //se recebeu idUnidadeEdit e não tem idunidadeForm
    // IdUnidadeEdit > 0 ---> está vindo do dash id de unidade selecionada
    // IdUnidadeForm = 0 ---> não foi apontada nenhuma unidade aqui no form
    // IdUnidadeForm  undefined ---> não foi apontada nenhuma unidade aqui no form
    // *** então carrega dados da unidade recebida do dash
    if (parseInt(sessionStorage.IdUnidadeEdit) !== 0
      && (parseInt(sessionStorage.IdUnidadeForm) === 0 || sessionStorage.IdUnidadeForm === undefined || parseInt(sessionStorage.IdUnidadeForm) > 0)) {
      //console.log("edit !0 - form 0")
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/unidade/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          setCampos(response.data[0])
          sessionStorage.IdUnidadeForm = 0
        })
    }
    //se não recebeu idunidadeEdit e não tem idunidadeForm
    // IdUnidadeEdit == 0 --> não recebeu IDUNIDADE DO DASH
    // IdUnidadeForm == 0 --> não foi apontado nenhuma unidade aqui no form
    // IdUnidadeForm  undefined ---> não foi apontada nenhuma unidade aqui no form
    // uniAgrupamento !== "" --> recebeu um agrupamento do dash
    // *** então busca apenas unidades vinculadas ao usuário que sejam do agrupamento recebido
    // e seta os valores do form com os dados da primeira unidade
    else if (parseInt(sessionStorage.IdUnidadeEdit) === 0 && (parseInt(sessionStorage.IdUnidadeForm) === 0 || sessionStorage.IdUnidadeForm === undefined) && sessionStorage.uniAgrupamento !== "") {
      //console.log("edit 0 - form 0")
      //busca na base as unidades vinculadas ao usuário deparado de agrupamento
      //para colocar na lista suspensa de unidades
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/unidadesAgrupamento/list/' +
          user[0].IdEmpresa +
          '/' +
          user[0].IdPessoa +
          '/' +
          sessionStorage.uniAgrupamento
        )
        .then(response => {
          setUnidades(response.data)
          //seta os valores das variaveis utilizadas nos filtros
          if (sessionStorage.IdUnidadeForm === undefined || parseInt(sessionStorage.IdUnidadeForm) === 0) {
            sessionStorage.IdUnidadeForm = response.data[0].IdUnidade
            sessionStorage.IdUnidadeEdit = 0
            sessionStorage.uniRazaoSocialForm = response.data[0].uniNome
            sessionStorage.uniAgrupamentoForm = response.data[0].uniAgrupamento
          }
          //carrega campos da primeira unidade vinculada ao usuário
          axios
            .get(
              process.env.REACT_APP_API_URL +
              '/api/v1/unidade/' +
              response.data[0].IdUnidade
            )
            .then(responseU => {
              setCampos(responseU.data[0])
            })
        })
    }
    //ou carrega com IdUniaddeForm
    else if (parseInt(sessionStorage.IdUnidadeEdit) === 0 && parseInt(sessionStorage.IdUnidadeForm) !== 0) {
      //console.log("edit 0 - form !0")
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/unidadesAgrupamento/list/' +
          user[0].IdEmpresa +
          '/' +
          user[0].IdPessoa +
          '/' +
          sessionStorage.uniAgrupamento
        )
        .then(response => {
          setUnidades(response.data)

          if (sessionStorage.IdUnidadeForm === undefined || parseInt(sessionStorage.IdUnidadeForm) === 0) {
            sessionStorage.IdUnidadeForm = response.data[0].IdUnidade
            sessionStorage.IdUnidadeEdit = 0
            sessionStorage.uniRazaoSocialForm = response.data[0].uniNome
            sessionStorage.uniAgrupamentoForm = response.data[0].uniAgrupamento
          }
          //carrega campos da primeira unidade vinculada ao usuário
          axios
            .get(
              process.env.REACT_APP_API_URL +
              '/api/v1/unidade/' +
              sessionStorage.IdUnidadeForm
            )
            .then(responseU => {
              setCampos(responseU.data[0])
            })
        })
    }
    else {
      console.log("!if")
    }
  }, [])

  // console.log(sessionStorage.IdUnidade)
  // console.log(sessionStorage.IdUnidadeEdit)
  // console.log(sessionStorage.IdUnidadeForm)
  // console.log(sessionStorage.uniAgrupamento)
  // console.log(sessionStorage.uniAgrupamentoForm)

  const [campos, setCampos] = useState({
    IdUnidade: '',
    IdEmpresa: user[0].IdEmpresa,
    uniNome: '',
    uniRazaoSocial: '',
    uniCNPJ: '',
    uniLogo: '',
    uniEndereco: '',
    uniStatus: 1,
    uniObservacoes: '',
    uniCriadoPor: user[0].pesEmail,
    uniCriadoEm: newDate,
    uniAlteradoPor: user[0].pesEmail,
    uniAlteradoEm: newDate,
    uniCodigoGUID: uuidv4(),
    uniAgrupamento: ''
  })

  function handleUnidadeChange(event) {
    sessionStorage.IdUnidadeForm = event.target.options[event.target.selectedIndex].value
    sessionStorage.IdUnidadeEdit = 0
    sessionStorage.uniRazaoSocialForm = event.target.options[event.target.selectedIndex].text
    sessionStorage.uniAgrupamentoForm = event.target.options[event.target.selectedIndex].text

    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setCampos(response.data[0])
      })

    //  console.log(sessionStorage.IdUnidade)
    //  console.log(sessionStorage.IdUnidadeEdit)
    //  console.log(sessionStorage.IdUnidadeForm)
    //  console.log(sessionStorage.uniAgrupamento)
    //  console.log(sessionStorage.uniRazaoSocial)
    //  console.log(sessionStorage.uniAgrupamentoForm)
    //  console.log(sessionStorage.uniRazaoSocialForm)

    window.location.reload()
  }

  function handleInputChange(event) {
    campos[event.target.name] = event.target.value
    setCampos(campos)
    //console.log(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/v1/unidade/' + campos.IdUnidade,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  const newLocal = <img className="logoKmobile" src={logoK} alt="LogoEmpresa" />
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  //console.log(campos)

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >

        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />

            <div></div>
          </div>{' '}

          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>UNIDADES</Translate>:
              </TitlePage1>
              <TitlePage24>{campos.uniRazaoSocial}</TitlePage24>
              <TitlePage8>
                <Translate>Dados Cadastrais</Translate>:
              </TitlePage8>
            </TitleOfPage>
            {/* <img
              className="logoK2"
              src={logoK}
              style={{ width: '80px', height: '80px' }}
            /> */}
          </div>
          <div className="tabelaPadraoFormCad">
            <form onSubmit={handleFormSubmit}>
              <div className="tabelaReg1">
                <p className="titulo1">
                  <Translate>Nome Fantasia</Translate>
                </p>
                <input
                  className="fcadastro1"
                  type="text"
                  autoComplete="nope"
                  id="fname"
                  onChange={handleInputChange}
                  name="uniNome"
                  defaultValue={campos.uniNome}
                />
                <p className="titulo2">CNPJ</p>
                <input
                  className="fcadastro2"
                  type="text"
                  autoComplete="nope"
                  id="fname"
                  onChange={handleInputChange}
                  name="uniCNPJ"
                  defaultValue={campos.uniCNPJ}
                />
                <p className="titulo3">
                  <Translate>Status</Translate>
                </p>
                <p className="fcadastro3" type="text" name="" id="">
                  <span>{campos.uniStatus === '0' ? 'Inativo' : 'Ativo'}</span>
                </p>
                <p className="titulo2">
                  <Translate>Exibir agrupado por:</Translate>
                </p>
                <input
                  className="fcadastro2"
                  autoComplete="none"
                  type="text"
                  onChange={handleInputChange}
                  name="uniAgrupamento"
                  defaultValue={campos.uniAgrupamento}
                  id="fAgrupamento"
                />
                {/* <select
              className="fcadastro3"
              typeof="text"
              onChange={handleInputChange}
              name="empStatus"
              defaultValue={campos.uniStatus}
            >
              <option value="">Selecione o Status da Empresa:</option>
              <option value="0">Inativo</option>
              <option value="1">Ativo</option>
            </select> */}
              </div>
              <div className="tabelaReg2">
                <p className="titulo7">
                  <Translate>Razão Social</Translate>
                </p>
                <input
                  className="fcadastro7"
                  type="text"
                  autoComplete="nope"
                  id="fname"
                  onChange={handleInputChange}
                  name="uniRazaoSocial"
                  defaultValue={campos.uniRazaoSocial}
                />
                <p className="titulo8">
                  <Translate>Endereço</Translate>
                </p>
                <input
                  className="fcadastro8"
                  type="text"
                  autoComplete="nope"
                  id="fname"
                  onChange={handleInputChange}
                  name="uniEndereco"
                  defaultValue={campos.uniEndereco}
                />
                <p className="titulo9">
                  <Translate>Observações</Translate>
                </p>
                <input
                  className="fcadastro9"
                  type="text"
                  autoComplete="nope"
                  id="fname"
                  onChange={handleInputChange}
                  name="uniObservacoes"
                  defaultValue={campos.uniObservacoes}
                />
              </div>
              <button className="buttonSave" typeof="submit">
                <Translate>Salvar</Translate>
              </button>
            </form>
          </div>

        </ContainerPage>

        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                  <button
                    className="profile-profileEditor"
                    // onClick={() => handleToggleComplete(user[0])}
                  >
                    <Translate>Ver perfil</Translate>
                  </button>
                </Link> */}
            </div>
          </div>

          {/* MENU SUSPENSO DE UNIDADES */}
          {parseInt(sessionStorage.IdUnidadeForm) !== 0 ? (
            <div>
              <h1 className="selectAnalise">Selecione:</h1>
              <label className="SelectCliente" htmlFor="">
                <Translate>SubUnidade</Translate>:
              </label>
              <select
                name="IdUnidade"
                className="SelectIdCliente"
                onChange={event => {
                  //setSearchUnidade(event.target.value)
                  handleUnidadeChange(event)
                }}
              >
                {unidades
                  .filter(val => {
                    if (val.uniAgrupamento === sessionStorage.uniAgrupamento) {
                      return val
                    }
                  })
                  .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                  .map(unidade => (
                    <option
                      value={unidade.IdUnidade}
                      text={unidade.uniAgrupamento}
                      selected={
                        parseInt(unidade.IdUnidade) ===
                        parseInt(sessionStorage.IdUnidadeForm)
                      }
                    >
                      {unidade.uniNome}
                    </option>
                  ))}
              </select>
            </div>
          ) : (<div></div>)}
          <SideBarDireita />

          <div className="cxLogo">

            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>

      </Translator>
    </div>
  )
}

export default FormularioCadastro
