import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import '../../../styles/pages/ModalObservaçoes/ModalUnidades.css'

import upDown from '../../../Images/upDown.svg'
var user = authService.getCurrentUser()

export default function Modal() {
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config)

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items]
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        })
      }
      return sortableItems
    }, [items, sortConfig])

    const requestSort = key => {
      let direction = 'ascending'
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending'
      }
      setSortConfig({ key, direction })
    }

    return { items: sortedItems, requestSort, sortConfig }
  }
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }

  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataFiltroInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataFiltroInicio} 00:00:00`)
      : new Date(sessionStorage.DataFiltroInicio),
    Moment(`${sessionStorage.DataFiltroFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFiltroFim} 23:59:59`)
      : new Date(sessionStorage.DataFiltroFim)
  ])
  //console.log(date)
  let id = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const [auditorias, setAuditorias] = useState([])
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasAgrupamento/' +
          sessionStorage.uniAgrupamento +
          '/' +
          user[0].IdEmpresa
        ).then(response => {
          setAuditorias(response.data)
        })
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditorias/' +
          sessionStorage.IdUnidade
        )
        .then(response => {
          setAuditorias(response.data)
        })
    }
  }, [])
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-ModalObsUnidades')
  } else {
    document.body.classList.remove('active-ModalObsUnidades')
  }
  const { items, requestSort, sortConfig } = useSortableData(auditorias)
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }
  const filtered = items.filter(val => {
    if (
      parseInt(val.IdObservacaoTipo) ===
      parseInt(sessionStorage.IdObservacaoTipo) &&
      new Date(new Date(val.rtoIniciadoEm).toDateString()) >= date[0] &&
      new Date(new Date(val.rtoIniciadoEm).toDateString()) <= date[1] &&
      searchTerm === ''
    ) {
      return val
    } else if (
      parseInt(val.IdObservacaoTipo) ===
      parseInt(sessionStorage.IdObservacaoTipo) &&
      new Date(new Date(val.rtoIniciadoEm).toDateString()) >= date[0] &&
      new Date(new Date(val.rtoIniciadoEm).toDateString()) <= date[1] &&
      ((val.Observador || '')
        .toLowerCase()
        .includes((searchTerm || '').toLowerCase()) ||
        (val.local || '')
          .toLowerCase()
          .includes((searchTerm || '').toLowerCase()) ||
        (val.gerencia_local || '')
          .toLowerCase()
          .includes((searchTerm || '').toLowerCase()) ||
        (val.gerencia_equipe || '')
          .toLowerCase()
          .includes((searchTerm || '').toLowerCase()) ||
        (val.equipe_abordada || '')
          .toLowerCase()
          .includes((searchTerm || '').toLowerCase()))
    ) {
      return val
    }
  })

  const [modalOpen, setModalOpen] = useState(false)
  function handleToggleComplete(objetos) {
    objetos = [
      {
        idUni: id['idUni'],
        idAr: id['idAr'],
        idObs: id['idObs']
      }
    ]
    // setModalInfo(objetos)
    setModalOpen(true)
  }

  const [total, setTotal] = useState(0)
  useEffect(() => { setTotal(auditorias.length)})

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalObservaçoes">
          <Translate>Observações</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay"></div>
            <div className="ModalObsUnidades-content">
              <h2 className="titleModal">
                <Translate>Observações Realizadas</Translate>
                <hr className="hrTitleModal" />
                {/* <div>{total}</div> */}
              </h2>
              <input
                className="cxPesquisaModal"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              {/* tabelaOBSRealizadas */}
              <div className="tabelaPadraoObs">
                <table>
                  <thead>
                    <tr>
                      <th
                        onClick={() => requestSort('Observador')}
                        className={getClassNamesFor('Observador')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Observador</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('uniNome')}
                        className={getClassNamesFor('uniNome')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>SubUnidade</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('local')}
                        className={getClassNamesFor('local')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Local</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('gerencia_local')}
                        className={getClassNamesFor('gerencia_local')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Gerência local</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('equipe_abordada')}
                        className={getClassNamesFor('equipe_abordada')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Equipe Abordada</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('rtoObservados')}
                        className={getClassNamesFor('rtoObservados')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          Observados
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('gerencia_equipe')}
                        className={getClassNamesFor('gerencia_equipe')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Gerência de Equipe</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('rtoIniciadoEm')}
                        className={getClassNamesFor('rtoIniciadoEm')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Data</Translate>
                        </div>
                      </th>
                      <th
                        onClick={() => requestSort('NotaFinal')}
                        className={getClassNamesFor('NotaFinal')}
                      >
                        <div className="tableAlign">
                          <img
                            style={{
                              transform: 'rotate(-45deg)',
                              width: '12px',
                              height: '12px'
                            }}
                            src={upDown}
                            alt=""
                          />
                          <Translate>Score</Translate>
                        </div>
                      </th>
                      <th>
                        <Translate>Detalhes</Translate>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.filter(val => {
                        if (
                          parseInt(val.IdObservacaoTipo) ===
                          parseInt(sessionStorage.IdObservacaoTipo) &&
                          new Date(
                            new Date(val.rtoIniciadoEm).toDateString()
                          ) >= date[0] &&
                          new Date(
                            new Date(val.rtoIniciadoEm).toDateString()
                          ) <= date[1] &&
                          searchTerm === ''
                        ) {
                          //setTotal(total + 1)
                          return val
                        } else if (
                          parseInt(val.IdObservacaoTipo) ===
                          parseInt(sessionStorage.IdObservacaoTipo) &&
                          new Date(
                            new Date(val.rtoIniciadoEm).toDateString()
                          ) >= date[0] &&
                          new Date(
                            new Date(val.rtoIniciadoEm).toDateString()
                          ) <= date[1] &&
                          ((val.Observador || '')
                            .toLowerCase()
                            .includes((searchTerm || '').toLowerCase()) ||
                            (val.local || '')
                              .toLowerCase()
                              .includes((searchTerm || '').toLowerCase()) ||
                            (val.gerencia_local || '')
                              .toLowerCase()
                              .includes((searchTerm || '').toLowerCase()) ||
                            (val.gerencia_equipe || '')
                              .toLowerCase()
                              .includes((searchTerm || '').toLowerCase()) ||
                            (val.equipe_abordada || '')
                              .toLowerCase()
                              .includes((searchTerm || '').toLowerCase()))
                        ) {
                          //setTotal(total + 1)
                          return val
                        }
                      })
                      .map(auditoria => (
                        <tr>
                          <td>
                            <div className="IdDateObsList">
                              {auditoria.Observador}
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              <Translate>{auditoria.uniNome}</Translate>
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              <Translate>{auditoria.local}</Translate>
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              <Translate>{auditoria.gerencia_local}</Translate>
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              <Translate>{auditoria.equipe_abordada}</Translate>
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              {auditoria.rtoObservados}
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              <Translate>{auditoria.gerencia_equipe}</Translate>
                            </div>
                          </td>
                          <td>
                            <div className="IdDateObsList">
                              {/* {auditoria.rtoIniciadoEm} */}
                              {/* <hr /> */}
                              {Moment(auditoria.rtoIniciadoEm).format(
                                'DD/MM/YYYY'
                              )}
                              <br />
                              {Moment(auditoria.rtoIniciadoEm).format(
                                'HH:mm:ss'
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="IdNotaObsList">
                              {parseFloat(auditoria.rtqPontuacao).toFixed(2)}%
                            </div>
                          </td>
                          <td>
                            <td
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent'
                              }}
                            >
                              <button
                                className={
                                  parseFloat(auditoria.rtqPontuacao) === 0
                                    ? 'verifObserv1 smile-cor-cinza'
                                    : parseFloat(auditoria.rtqPontuacao) < 70
                                      ? 'verifObserv1 smile-cor-vermelho'
                                      : parseFloat(auditoria.rtqPontuacao) < 89
                                        ? 'verifObserv1 smile-cor-amarelo'
                                        : 'verifObserv1 smile-cor-verde'
                                }
                              ></button>
                            </td>
                            <td
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent'
                              }}
                            >
                              <Link
                                to={
                                  '/RelatorioICS/' +
                                  auditoria.IdUnidade +
                                  '/' +
                                  auditoria.IdRetornoObservacao
                                }
                                target="_blank"
                              >
                                <button
                                  className="clipBoardObs"
                                  onClick={() => handleToggleComplete([])}
                                >
                                  <i className="far fa-clipboard"></i>
                                </button>
                              </Link>{' '}
                              {/* <a
                              href={
                                'http://ws.hitperformance.com.br/MeuCPTO/RelatorioObservacao?id=' +
                                auditoria.IdRetornoObservacao
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="verifObserv">
                                <i class="fas fa-eye"></i>
                              </button>
                            </a> */}
                            </td>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {filtered.length === 0 ? (
                  <span className="noObs">
                    <Translate>Não há observações a serem listadas!</Translate>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <button className="close-ModalObsUnidades" onClick={toggleModal}>
                X
              </button>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
