import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { IconContext } from 'react-icons'
import { Link } from 'react-router-dom'
import authService from '../../Components/Login/auth.service'
import logologo from '../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../Images/imgButtonEsquerda/imgOpen.svg'
import Moblogo from '../../Images/logoMeuCptoHorizBranco.png'

function SidebarMobile() {
  var user = authService.getCurrentUser()
  const permissoes = localStorage.getItem('permissoes')
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let permissao = []
  if (permissoes) {
    permissao = JSON.parse(permissoes)
  } else {
    permissao = [
      {
        perModulo: 'ACECAD',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEQP',
        perAcesso: 1
      },
      {
        perModulo: 'ACEPES',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGER',
        perAcesso: 1
      },
      {
        perModulo: 'ACEATV',
        perAcesso: 1
      },
      {
        perModulo: 'ACEARE',
        perAcesso: 1
      },
      {
        perModulo: 'ACEOBS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEDAS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEREL',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEMP',
        perAcesso: 1
      },
      {
        perModulo: 'ACETPO',
        perAcesso: 1
      },
      {
        perModulo: 'ACETXA',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGPU',
        perAcesso: 1
      },
      {
        perModulo: 'ACEFUN',
        perAcesso: 1
      }
    ]
  }
  let ACECAD = permissao.find(element => {
    return element.perModulo === 'ACECAD'
  })
  let ACEEQP = permissao.find(element => {
    return element.perModulo === 'ACEEQP'
  })
  let ACEPES = permissao.find(element => {
    return element.perModulo === 'ACEPES'
  })
  let ACEGER = permissao.find(element => {
    return element.perModulo === 'ACEGER'
  })
  let ACEATV = permissao.find(element => {
    return element.perModulo === 'ACEATV'
  })
  let ACEARE = permissao.find(element => {
    return element.perModulo === 'ACEARE'
  })
  let ACEOBS = permissao.find(element => {
    return element.perModulo === 'ACEOBS'
  })
  let ACEDAS = permissao.find(element => {
    return element.perModulo === 'ACEDAS'
  })
  let ACEREL = permissao.find(element => {
    return element.perModulo === 'ACEREL'
  })
  let ACEEMP = permissao.find(element => {
    return element.perModulo === 'ACEEMP'
  })
  let ACETPO = permissao.find(element => {
    return element.perModulo === 'ACETPO'
  })
  let ACETXA = permissao.find(element => {
    return element.perModulo === 'ACETXA'
  })
  let ACEGPU = permissao.find(element => {
    return element.perModulo === 'ACEGPU'
  })
  let ACEFUN = permissao.find(element => {
    return element.perModulo === 'ACEFUN'
  })
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  return (
    <>
      <IconContext.Provider value={{ color: 'hsl(0, 0%, 100%)' }}>
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="navbar">
            <button onClick={showSidebar} className="abrirSubMenu">
              <img src={imgOpen} alt="" />
            </button>
            <nav className={sidebar ? 'nav-subMenu1' : 'nav-active'}>
              <div className="headerbackground">
                <img src={Moblogo} alt="logo" />
              </div>
              <aside className="MobileProfileDireita">
                {!!ACEEMP.perAcesso &&
                ( user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                  user[0].pesEmail.toLowerCase().includes('admin@')) ? (
                  <Link to="/SubMenuEmpresas">
                    <button>
                      <Translate>Empresas </Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {!!ACETPO.perAcesso &&
                (user[0].pesEmail
                  .toLowerCase()
                  .includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                  <Link to="/SubMenuTipoDeObservacao">
                    <button>
                      <Translate>Tipo de Observação</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {!!ACETXA.perAcesso &&
                (user[0].pesEmail
                  .toLowerCase()
                  .includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                  <Link to="/SubMenuTextActive">
                    <button>
                      <Translate>Textos Ativadores</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {!!ACEPES.perAcesso ? (
                  <Link to="/SubMenuPessoas/0">
                    <button>
                      <Translate>Pessoas</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {!!ACEGPU.perAcesso ? (
                  <Link to="/SubMenuGruposUsuarios">
                    <button>
                      <Translate>Grupos de Usuários</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
                {!!ACEFUN.perAcesso ? (
                  <Link to="/SubMenuFuncaoEPermissao">
                    <button>
                      <Translate>Função</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </aside>
              <div>
                <img className="Moblogologo" src={logologo} alt="logo" />
                <button onClick={showSidebar} className="fecharSubMenu">
                  <img src={imgOpen} alt="" />
                </button>
              </div>
            </nav>
          </div>
        </Translator>
      </IconContext.Provider>
    </>
  )
}

export default SidebarMobile
