import axios from 'axios'
import Moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import * as TypIcons from 'react-icons/ti'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Label } from 'recharts'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage23
} from '../../../Components/Main'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import upDown from '../../../Images/upDown.svg'

function ObservacoesPessoa() {
  sessionStorage.TelaMenuLateral = 'Relatorio'
  sessionStorage.TelaMenuPrincipal = 'Relatorio'
  var user = authService.getCurrentUser()
  let id = useParams()

  //console.log(id)
  const history = useHistory()
  Moment.locale('pt-br')

  const [image, setImage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchConclusao, setSearchConclusao] = useState('')
  const [unidades, setUnidades] = useState([])
  const [observacaotipos, setObservacaotipos] = useState([])
  const [observacoes, setObservacoes] = useState([])

  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config)

    const sortedItems = useMemo(() => {
      const sortableItems = [...items]
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        })
      }
      return sortableItems
    }, [items, sortConfig])

    const requestSort = key => {
      let direction = 'ascending'
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending'
      }
      setSortConfig({ key, direction })
    }

    return { items: sortedItems, requestSort, sortConfig }
  }
  const { items, requestSort, sortConfig } = useSortableData(observacoes)

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const dateAtual = new Date()
  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
    sessionStorage.DataFim = new Date()
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  //console.log(date)
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    //window.location.reload()
    AtualizarObservacoes()
  }

  //obtem unidades
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUserAgrupamentoTodos/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)

        if (sessionStorage.IdUnidade === undefined) //|| parseInt(sessionStorage.IdUnidade) === 0) && sessionStorage.uniRazaoSocial !== "Todos") 
        {
          sessionStorage.IdUnidade = response.data[0].IdUnidade
          sessionStorage.IdUnidadeEdit = response.data[0].IdUnidade
          if (parseInt(response.data[0].IdUnidade) > 0) {
            sessionStorage.IdUnidadeForm = 0
          }
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento
          //sessionStorage.IdUnidadeRel = response.data[0].IdUnidade
          //sessionStorage.IdUnidadeEdit = response.data[0].IdUnidade
          //sessionStorage.uniRazaoSocial = response.data[0].uniNome
        }
        if (sessionStorage.uniAgrupamento === undefined) {
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
          sessionStorage.uniAgrupamento = response.data[0].uniAgrupamento

          //window.location.reload()
        }
      })
  }, [])

  //obtem tipos de observacoes
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaotipo/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setObservacaotipos(response.data)
        if (sessionStorage.IdObservacaoTipo === undefined) {
          response.data.forEach(element => {
            if (element.otpStatus === 1) {
              sessionStorage.IdObservacaoTipo = element.IdObservacaoTipo
            }
          })
        }
      })
  }, [])

  // console.log(user[0].IdEmpresa)
  // console.log(sessionStorage.uniAgrupamento)
  // console.log(sessionStorage.DataInicio)
  // console.log(sessionStorage.DataFim)
  // console.log(sessionStorage.IdObservacaoTipo)

  //obtem observacoes
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasPessoa/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          id["IdPessoa"] +
          '/' +
          id["IdObservacaoTipo"]
        )
        .then(response => {
          setObservacoes(response.data)
        })
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasPessoa/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          id["IdPessoa"] +
          '/' +
          id["IdObservacaoTipo"] //+
          //'/' +
          //sessionStorage.IdUnidade
        )
        .then(response => {
          setObservacoes(response.data)
        })
    }
  })

  const [message, setMessage] = useState('Carregando Observações...')


  function handleUnidadeChange(event) {
    sessionStorage.IdUnidade = event.target.options[event.target.selectedIndex].value
    sessionStorage.IdUnidadeEdit = event.target.options[event.target.selectedIndex].value
    if (parseInt(event.target.options[event.target.selectedIndex].value) > 0) {
      sessionStorage.IdUnidadeForm = 0
    }
    sessionStorage.uniAgrupamento = event.target.options[event.target.selectedIndex].text
    sessionStorage.uniRazaoSocial = event.target.options[event.target.selectedIndex].text
    // sessionStorage.IdUnidadeRel = event.target.value
    // sessionStorage.uniRazaoSocial = event.target.options[event.target.selectedIndex].text
    //window.location.reload()
    AtualizarObservacoes()
    // console.log(sessionStorage.IdUnidade)
    // console.log(sessionStorage.IdUnidadeEdit)
    // console.log(sessionStorage.uniAgrupamento)
    // console.log(sessionStorage.uniRazaoSocial)
  }
  // console.log(sessionStorage.DataInicio)
  // console.log(sessionStorage.DataFim)
  // console.log(id['IdPessoa'])
  // console.log(id['IdObservacaoTipo'])
  function AtualizarObservacoes() {
    //console.log(sessionStorage.IdUnidade)
    //console.log(sessionStorage.IdUnidadeEdit)
    //console.log(sessionStorage.uniAgrupamento)
    //console.log(sessionStorage.uniRazaoSocial)
    setObservacoes([])
    if (parseInt(sessionStorage.IdUnidade) === 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasPessoa/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          id["IdPessoa"] +
          '/' +
          id["IdObservacaoTipo"]
        ).then(response => {
          setObservacoes(response.data)
        })
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/dashAuditoriasPessoaUnidade/' +
          sessionStorage.DataInicio +
          '/' +
          sessionStorage.DataFim +
          '/' +
          id["IdPessoa"] +
          '/' +
          id["IdObservacaoTipo"] +
          '/' +
          sessionStorage.IdUnidade
        )
        .then(response => {
          setObservacoes(response.data)
        })
    }
  }

  function handleObsTipoChange(event) {
    sessionStorage.IdObservacaoTipo = event.target.value

    sessionStorage.ObservacaoDesc =
      event.target.options[event.target.selectedIndex].text


    //window.location.reload()
    AtualizarObservacoes()
  }

  function handleToggleComplete(objetos) {
    objetos = [
      {
        idUni: id['idUni'],
        idAr: id['idAr'],
        idObs: id['idObs']
      }
    ]
    // setModalInfo(objetos)
    setModalOpen(true)
  }
  //console.log(searchConclusao)
  function deleteObservacao(idobservacao) {
    if (window.confirm('Deletar observação?')) {
      axios
        .delete(
          process.env.REACT_APP_API_URL +
          '/api/v1/retornoobservacao/' +
          idobservacao
        )
        .then(response => {
          if (response.status === 200) {
            AtualizarObservacoes()
            //window.location.reload()
          } else {
            alert(response.data.message)
          }
        })
    }
  }

  function solicitarReenvio(idobservacao, rtoResync) {
    if (parseInt(rtoResync) === 1) {
      alert('Já existe uma solicitação de reenvio em aberto!')
    }
    else {
      if (window.confirm('Solicitar ao APP o reenvio dessa observação?')) {
        axios
          .get(
            process.env.REACT_APP_API_URL +
            '/api/v1/retornoobservacao/marcarreenvio/' +
            idobservacao
          )
          .then(response => {
            if (response.status === 200) {
              AtualizarObservacoes()
              //window.location.reload()
            } else {
              alert(response.data.message)
            }
          })
      }
    }
  }


  setTimeout(function mensagem() {
    if (observacoes.length === 0) {
      setMessage('...')
    }
    else {
      setMessage('Observações encontradas')
    }
  }, 9000);

  const [total, setTotal] = useState(0)
  useEffect(() => { setTotal(observacoes.length) })

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>

          <div id="containerHead1">
            <div className="logo" />
            <select
              className="fCxSelect"
              onChange={event => {
                setSearchConclusao(event.target.value)
              }}
            >
              <option value={''}>Todas</option>
              <option value={'Completas'}>Completas</option>
              <option value={'Incompletas'}>Incompletas</option>
            </select>

          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>MINHAS OBSERVAÇÕES - {total}</Translate>
              </TitlePage1>
              <TitlePage23>{sessionStorage.uniRazaoSocial}</TitlePage23>
            </TitleOfPage>

            <div id="containerPesquisaModal">

              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <Link
                to={
                  parseInt(sessionStorage.IdUnidade) === 0 ? '/ApontarObservacao/SelecionarUnidade/'
                    : '/ApontarObservacao/SelecionarArea/' + sessionStorage.IdUnidade
                }
                style={{ textDecoration: 'none' }}
              >
                {/* <button className="buttonModalPadrao">
                    <Translate>Nova Observação</Translate>
                  </button> */}
              </Link>

            </div>
          </div>

          <div className="tabelaPadrao">
            <table>
              {/* TITULOS DAS COLUNAS */}
              <thead>
                <tr>
                  {/* UNIDADE */}
                  {parseInt(sessionStorage.IdUnidade) === 0 && sessionStorage.uniAgrupamento !== '' ? (
                    //   <th 
                    //     onClick={() => requestSort('uniNome')}
                    //     className={getClassNamesFor('uniNome')}
                    //     >
                    <th>
                      <div className='tableAlign'>
                        <img
                          style={{
                            transform: 'rotate(-45deg)',
                            width: '12px',
                            height: '12px'
                          }}
                          src={upDown}
                          alt=""
                        />
                        <Translate>Sub Unidade</Translate>
                      </div>
                    </th>
                  ) : ('')}
                  {/* OBSERVADOR */}
                  {/* <th
                      onClick={() => requestSort('pesNome')}
                      className={getClassNamesFor('pesNome')}
                    > */}
                  <th>
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Observador</Translate>
                    </div>
                  </th>
                  {/* LOCAL */}
                  <th
                    onClick={() => requestSort('locDescricao')}
                    className={getClassNamesFor('locDescricao')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Local</Translate>
                    </div>
                  </th>
                  {/* TIPO OBSERVACAO */}
                  <th
                    onClick={() => requestSort('otpDescricao')}
                    className={getClassNamesFor('otpDescricao')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Tipo de Observação</Translate>
                    </div>
                  </th>
                  {/* GERENCIA LOCAL */}
                  <th
                    onClick={() => requestSort('gerDescricaoLcl')}
                    className={getClassNamesFor('gerDescricaoLcl')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Gerência Local</Translate>
                    </div>
                  </th>
                  {/* EQUIPE ABORDADA */}
                  <th
                    onClick={() => requestSort('eqpDescricao')}
                    className={getClassNamesFor('eqpDescricao')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Equipe Abordada</Translate>
                    </div>
                  </th>
                  {/* OBSERVADOS */}
                  <th
                    onClick={() => requestSort('rtoObservados')}
                    className={getClassNamesFor('rtoObservados')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Observados</Translate>
                    </div>
                  </th>
                  {/* GERENCIA DA EQUIPE */}
                  <th
                    onClick={() => requestSort('gerDescricaoEqp')}
                    className={getClassNamesFor('gerDescricaoEqp')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Gerencia da Equipe</Translate>
                    </div>
                  </th>
                  {/* DATA INICIO */}
                  <th
                    onClick={() => requestSort('rtoIniciadoEm')}
                    className={getClassNamesFor('rtoIniciadoEm')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Data Inicio</Translate>
                    </div>
                  </th>
                  {/* DATA FIM */}
                  <th
                    onClick={() => requestSort('rtoFinalizadoEm')}
                    className={getClassNamesFor('rtoFinalizadoEm')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Data Fim</Translate>
                    </div>
                  </th>
                  {/* SCORE */}
                  <th
                    onClick={() => requestSort('NotaFinal')}
                    className={getClassNamesFor('NotaFinal')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Score</Translate>
                    </div>
                  </th>
                  {/* ATIVIDADE */}
                  <th
                    onClick={() => requestSort('atvDescricao')}
                    className={getClassNamesFor('atvDescricao')}
                  >
                    {/* <th> */}
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Atividades</Translate>
                    </div>
                  </th>
                  {/* STATUS DA OBSERVACAO */}
                  <th>
                    <div className="tableAlign">
                      <img
                        style={{
                          transform: 'rotate(-45deg)',
                          width: '12px',
                          height: '12px'
                        }}
                        src={upDown}
                        alt=""
                      />
                      <Translate>Status da Observação</Translate>
                    </div>
                  </th>
                  {/* DETALHES */}
                  <th>
                    <p className="ORObservados">
                      <Translate>Detalhes</Translate>
                    </p>
                  </th>
                </tr>
              </thead>
              {/* REGISTROS */}
              <tbody>
                {
                  items.filter(val => {
                    if (searchTerm === '' && searchConclusao === '') {
                      return val
                    }
                    else if (
                      (
                        (val.uniNome || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.uniRazaoSocial || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.atvDescricao || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.pesNome || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.locDescricao || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.otpDescricao || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.gerDescricaoLcl || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.gerDescricaoEqp || '').toLowerCase().includes((searchTerm || '').toLowerCase()) ||
                        (val.eqpDescricao || '').toLowerCase().includes((searchTerm || '').toLowerCase())
                      )
                      &&
                      (
                        (
                          searchConclusao === 'Completas' &&
                          Moment(val.rtoFinalizadoEm).isValid()
                        )
                        ||
                        (
                          searchConclusao === 'Incompletas' &&
                          !val.rtoFinalizadoEm
                        )
                        ||
                        searchConclusao === ''
                      )
                    ) {
                      return val
                    }
                  })
                    .map(observacao => (
                      <tr key={observacao.IdRetornoObservacao}>
                        {/* UM UNICO LINK PARA VÁRIOS ITEBS NA LISTA */}
                        <Link
                          className="tBodyStyle"
                          to={
                            parseInt(observacao.IdPessoa) ===
                              parseInt(user[0].IdPessoa) ||
                              (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                                user[0].pesEmail.toLowerCase().includes('admin@'))
                              ?
                              '/RetornoQuestao/' +
                              observacao.IdUnidade +
                              '/' +
                              observacao.IdLocalArea +
                              '/' +
                              observacao.IdRetornoObservacao
                              : '#'
                          }
                        >
                          {/* UNIDADE */}
                          {parseInt(sessionStorage.IdUnidade) === 0 && sessionStorage.uniAgrupamento !== '' ? (
                            <td >
                              <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                                {observacao.uniNome}
                              </div>
                            </td>
                          ) : ('')}
                          {/* OBSERVADOR */}
                          <td >
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              {observacao.pesNome}
                            </div>
                          </td>
                          {/* LOCAL */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              {observacao.locDescricao}
                            </div>
                          </td>
                          {/* TIPO OBSERVACAO */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdObsList">
                              {observacao.otpDescricao}
                            </div>
                          </td>
                          {/* GERENCIA LOCAL */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              {observacao.gerDescricaoLcl}
                            </div>
                          </td>
                          {/* EQUIPE */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              {observacao.eqpDescricao}
                            </div>
                          </td>
                          {/* OBSERVADOS */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdDateObsList">
                              {observacao.rtoObservados}
                            </div>
                          </td>
                          {/* GERENCIA EQUIPE */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              {observacao.gerDescricaoEqp}
                            </div>
                          </td>
                          {/* INICIADO EM */}
                          <td>
                            <div className="IdObsList">
                              {Moment(observacao.rtoIniciadoEm).format(
                                'DD/MM/YYYY'
                              )}
                              <br />

                              {Moment(observacao.rtoIniciadoEm).format(
                                'HH:mm:ss'
                              )}
                            </div>
                          </td>
                          {/* FINALIZADO EM */}
                          <td>
                            <div className="IdObsList">
                              {Moment(observacao.rtoFinalizadoEm).isValid()
                                ? Moment(observacao.rtoFinalizadoEm).format(
                                  'DD/MM/YYYY'
                                )
                                : ''}
                              <br />
                              {Moment(observacao.rtoFinalizadoEm).isValid()
                                ? Moment(observacao.rtoFinalizadoEm).format(
                                  'HH:mm:ss'
                                )
                                : ''}
                            </div>
                          </td>
                          {/* PONTUAÇÃO */}
                          <td>
                            <div className="IdDateObsList">
                              {observacao.rtoFinalizadoEm
                                ? observacao.rtqPontuacao
                                  ? parseFloat(
                                    observacao.rtqPontuacao
                                  ).toFixed(2)
                                  : 0
                                : 0}
                              %
                            </div>
                          </td>
                          {/* ATIVIDADE */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdNameObsList">
                              <Translate>
                                {observacao.atvDescricao}
                              </Translate>
                            </div>
                          </td>
                          {/* STATUS */}
                          <td>
                            <div style={{ textTransform: 'uppercase' }} className="IdObsList">
                              {!observacao.rtoFinalizadoEm
                                ? 'Contém Pendências'
                                : 'Observação Concluída'}
                            </div>
                          </td>
                        </Link>
                        <td>
                          <div className="tableAlign">
                            OBS: {observacao.IdRetornoObservacao}
                          </div>
                          <div className="IdDetalhes">
                            {/* ATALHO PARA DETALHES VISÃO DA WEB */}
                            <td className="IdDetalhesItems">
                              {' '}
                              {!observacao.rtoFinalizadoEm
                                ? '' :
                                <Link
                                  to={'/RelatorioICS/' + observacao.IdUnidade + '/' + observacao.IdRetornoObservacao}
                                  target="_blank" >
                                  <button className="clipBoardObs"
                                    onClick={() => handleToggleComplete([])}>
                                    <i className="far fa-clipboard"></i>
                                  </button>
                                </Link>}
                            </td>

                            {(Moment(observacao.rtoFinalizadoEm).isValid() && parseInt(observacao.IdPessoa === id["IdPessoa"])) ||
                              (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                                user[0].pesEmail.toLowerCase().includes('admin@')) ? (
                              <td className="IdDetalhesItems">
                                <button
                                  className="clipBoardObs"
                                  enabled={(Moment(observacao.rtoFinalizadoEm).isValid() && parseInt(observacao.IdPessoa === id["IdPessoa"])) ||
                                    (user[0].pesEmail.toLowerCase().includes('@comportamento.com.br') ||
                                      user[0].pesEmail.toLowerCase().includes('@meucpto.com.br') ||
                                      user[0].pesEmail.toLowerCase().includes('@humanit-ti.com') ||
                                      user[0].pesEmail.toLowerCase().includes('admin@'))}
                                  onClick={() =>
                                    deleteObservacao(
                                      observacao.IdRetornoObservacao
                                    )
                                  }
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            ) : ('')}

                            {observacao.rtoDeviceID !== '' &&
                              (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                                user[0].pesEmail.toLowerCase().includes('admin@')) ? (
                              <td className="IdDetalhesItems">

                                <button
                                  className={parseInt(observacao.rtoReSync) === 1 ? "clipBoardReSync clipBoardReSyncCinza" : parseInt(observacao.rtoReSync) === 99 ? "clipBoardReSync clipBoardReSyncLaranja" : "clipBoardReSync clipBoardReSyncVerde"}
                                  disabled={parseInt(observacao.rtoReSync) === 1 || parseInt(observacao.rtoReSync) === 99 ? true : false}
                                  onClick={() =>
                                    solicitarReenvio(
                                      observacao.IdRetornoObservacao, observacao.rtoReSync
                                    )
                                  }
                                >
                                  <i className="fas fa-sync"></i>
                                </button>
                                {/* Style={observacao.rtoReSync === 0 ? "color: #a0a0a0" : "color: #35c2a9"} */}

                              </td>
                            ) : ('')}

                            {observacao.rtoDeviceID !== '' &&
                              (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                                user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                                user[0].pesEmail.toLowerCase().includes('admin@')) ?
                              (<td className="IdDetalhesItems">
                                <button
                                  className="clipBoardReSync clipBoardReSyncLaranja"
                                  onClick={() =>
                                    alert(observacao.rtoDeviceID)
                                  }
                                >
                                  <i className="fas fa-info"></i>
                                </button>
                              </td>)
                              : ('')}
                          </div>

                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
            <p>{message}</p>

            {observacoes.length === 0 ? (
              <p className="noObs">{message}</p>
            ) : (
              ''
            )}
          </div>

          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>

        </ContainerPage>{' '}
        <button
          className={sidebar ? 'fecharMenu' : 'abrirMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        {/* SIDEBAR */}

        <nav className={sidebar ? 'esconderFiltro' : 'mostrarFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            {/* <form action="">
                <h1 className="selectAnalise">Selecione:</h1>
                <label className="SelectCliente" htmlFor="">
                  <Translate>Cliente/Unidade</Translate>:
                </label>
                <select
                  name="IdUnidade"
                  className="SelectIdCliente"
                  onChange={event => {
                    handleUnidadeChange(event)
                  }}
                >
                  {unidades
                    .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                    .map(unidade => (
                      <option
                        value={unidade.IdUnidade}
                        text={unidade.uniAgrupamento}
                        selected={ unidade.uniRazaoSocial !== 'Todos' ? 
                        (
                          parseInt(unidade.IdUnidade) ===
                          parseInt(sessionStorage.IdUnidade)
                        ) : 
                        (
                            parseInt(unidade.IdUnidade) ===
                            parseInt(sessionStorage.IdUnidade)
                            &&
                            unidade.uniRazaoSocial === sessionStorage.uniRazaoSocial
                        )
                        }
                        // selected={
                        //   parseInt(sessionStorage.IdUnidade) === 0 ? 
                        //   (
                        //     unidade.uniAgrupamento ===
                        //     sessionStorage.uniAgrupamento
                        //   ) :
                        //   ( 
                        //     parseInt(unidade.IdUnidade) ===
                        //     parseInt(sessionStorage.IdUnidadeEdit)
                        //   )
                        // }
                      >
                        {unidade.uniNome}
                      </option>
                    ))}
                </select>
                <label className="SelectObserv" htmlFor="">
                  <Translate>Tipo de Observação</Translate>:
                </label>
                <select
                  name="SelectIdObserv"
                  className="SelectIdObserv"
                  onChange={event => {
                    //setSearchTipoObs(event.target.value)
                    handleObsTipoChange(event)
                  }}
                >
                  {observacaotipos
                    .sort((a, b) => (a.otpDescricao > b.otpDescricao ? 1 : -1))
                    .filter(val => {
                      if (val.otpStatus === 1) {
                        return val
                      }
                    })
                    .map(observacaotipo => (
                      <option
                        value={observacaotipo.IdObservacaoTipo}
                        selected={
                          parseInt(observacaotipo.IdObservacaoTipo) ===
                          parseInt(sessionStorage.IdObservacaoTipo)
                        }
                      >
                        {observacaotipo.otpDescricao}
                      </option>
                    ))}
                </select>
                <label className="SelectDate">
                  <Translate>Periodo Analisado</Translate>:
                </label>
  
                <div className="calendarioAnalise2">
                  <Calendar
                    calendarType="Hebrew"
                    onChange={setSeacrhDate}
                    value={date}
                    selectRange={true}
                  />
                </div>
                {(user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                  user[0].pesEmail.toLowerCase().includes('humanit-ti.com') ||
                  user[0].pesEmail.toLowerCase().includes('admin@'))
                  ?
                    <div>
                      <br />
                      <div>
                        <span className="clipBoardReSyncVerdeSmall">
                          {observacoes.length === 0 ? ('') : <i className="fas fa-sync"> 
                          <label className="clipBoardReSyncVerdeSmall"><Translate>Solicitar ao App Reenvio da Abordagem</Translate></label></i> } 
                        </span>
                      </div>
                      <br />
                      <div>
                        <span className="clipBoardReSyncCinzaSmall">
                          {observacoes.length === 0 ? ('') : <i className="fas fa-sync"> 
                          <label className="clipBoardReSyncCinzaSmall"><Translate>Solicitação Enviada ao App</Translate></label></i>} 
                        </span>
                      </div>
                      <br />
                      <div>
                      <span className="clipBoardReSyncLaranjaSmall">
                        {observacoes.length === 0 ? ('') : <i className="fas fa-sync"> 
                        <label className="clipBoardReSyncLaranjaSmall"><Translate>Aguardando Reenvio da Abordagem</Translate></label></i>} 
                      </span>
                      </div>
                    </div>
                  : ''}
              </form> */}

          </div>

          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default ObservacoesPessoa
function setModalOpen(arg0) {
  throw new Error('Function not implemented.')
}