import PropTypes from 'prop-types'
import { Translate, Translator } from 'react-auto-translate'
import accomplished from '../../Images/ok.svg'
import pending from '../../Images/pending.svg'
import wrong from '../../Images/wrong.svg'

const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
      [key]: {}
    }
    existing[key] = { ...existing[key], [language]: value }
    localStorage.setItem('translations', JSON.stringify(existing))
  }
}
export default function Indicador({
  p_resultado,
  p_titulo,
  p_meta = 90.0,
  p_real = 0.0,
  p_pesIdioma
}) {
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={p_pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <img
          src={p_real === 0 ? pending : p_real >= p_meta ? accomplished : wrong}
          className="pending"
          alt="Concluida"
        />

        <label className="tittleMeta2">
          {' '}
          {p_titulo}
        </label>

        <label htmlFor="" className="FuncaoMeta">
          {p_meta}
        </label>
        <hr className="hrICS" />
        <label htmlFor="" className="FuncaoReal">
          {p_real}
        </label>

        <label htmlFor="" className="Conclusao1">
          {p_real === 0 ? (
            <Translate>"Pendente"</Translate>
          ) : p_real >= p_meta ? (
            <Translate>"Concluída"</Translate>
          ) : (
            <Translate>"Não Concluída"</Translate>
          )}
        </label>
      </Translator>
    </>
  )
}
Indicador.propTypes = {
  p_resultado: PropTypes.string.isRequired,
  p_titulo: PropTypes.string.isRequired,
  p_meta: PropTypes.number.isRequired,
  p_real: PropTypes.number.isRequired,
  p_pesIdioma: PropTypes.string.isRequired
}
