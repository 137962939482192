import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'
const uuidv4 = require('uuid/v4')

export default function ModalFuncaoEPermissao() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    var user = authService.getCurrentUser()
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  function handleInputChange(event) {
    if (event.target.name === 'fncStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdFuncao: 0,
    IdEmpresa: user[0].IdEmpresa,
    fncDescricao: '',
    fncObservacoes: '',
    fncStatus: 1,
    fncCriadoPor: user[0].pesEmail,
    fncCriadoEm: newDate,
    fncAlteradoPor: user[0].pesEmail,
    fncAlteradoEm: newDate,
    fncCodigoGUID: uuidv4()
  })
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/funcao', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Nova Função</Translate>
        </button>

        {modal && (
          <div className="modalEquipe">
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Nova Função</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>
                <form className="formModalPadrao" onSubmit={handleFormSubmit}>
                  <label>
                    <Translate>Descrição Da Função</Translate>:
                  </label>
                  <input
                    type="text" 
                    style={{textTransform:'uppercase'}}
                    id="fname"
                    defaultValue={campos.fncDescricao}
                    name="fncDescricao"
                    onChange={handleInputChange}
                  />
                  <label>
                    <Translate>Observação</Translate>:
                  </label>
                  <textarea
                    type="text"
                    id="fname"
                    rows={5} 
                    style={{textTransform:'uppercase'}}
                    defaultValue={campos.fncObservacoes}
                    name="fncObservacoes"
                    onChange={handleInputChange}
                    row="none"
                    maxLength="500"
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          className="CheckAcess"
                          name="fncStatus"
                          type="checkbox"
                          defaultChecked={!!campos.fncStatus}
                          onChange={handleInputChange}
                        />
                        <span className="checkText">
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button type="submit" className="gravar-modal">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Translator>
    </>
  )
}
