import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link } from 'react-router-dom'
import authService from '../../Components/Login/auth.service'
import '../../Components/SideBar-right/navDireita.css'

function SideBarDireita() {
  var user = authService.getCurrentUser()
  const permissoes = localStorage.getItem('permissoes')
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let permissao = []
  if (permissoes) {
    permissao = JSON.parse(permissoes)
  } else {
    permissao = [
      {
        perModulo: 'ACECAD',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEQP',
        perAcesso: 1
      },
      {
        perModulo: 'ACEPES',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGER',
        perAcesso: 1
      },
      {
        perModulo: 'ACEATV',
        perAcesso: 1
      },
      {
        perModulo: 'ACEARE',
        perAcesso: 1
      },
      {
        perModulo: 'ACEOBS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEDAS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEREL',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEMP',
        perAcesso: 1
      },
      {
        perModulo: 'ACETPO',
        perAcesso: 1
      },
      {
        perModulo: 'ACETXA',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGPU',
        perAcesso: 1
      },
      {
        perModulo: 'ACEFUN',
        perAcesso: 1
      }
    ]
  }
  let ACECAD = permissao.find(element => {
    return element.perModulo === 'ACECAD'
  })
  let ACEEQP = permissao.find(element => {
    return element.perModulo === 'ACEEQP'
  })
  let ACEPES = permissao.find(element => {
    return element.perModulo === 'ACEPES'
  })
  let ACEGER = permissao.find(element => {
    return element.perModulo === 'ACEGER'
  })
  let ACEATV = permissao.find(element => {
    return element.perModulo === 'ACEATV'
  })
  let ACEARE = permissao.find(element => {
    return element.perModulo === 'ACEARE'
  })
  let ACEOBS = permissao.find(element => {
    return element.perModulo === 'ACEOBS'
  })
  let ACEDAS = permissao.find(element => {
    return element.perModulo === 'ACEDAS'
  })
  let ACEREL = permissao.find(element => {
    return element.perModulo === 'ACEREL'
  })
  let ACEEMP = permissao.find(element => {
    return element.perModulo === 'ACEEMP'
  })
  let ACETPO = permissao.find(element => {
    return element.perModulo === 'ACETPO'
  })
  let ACETXA = permissao.find(element => {
    return element.perModulo === 'ACETXA'
  })
  let ACEGPU = permissao.find(element => {
    return element.perModulo === 'ACEGPU'
  })
  let ACEFUN = permissao.find(element => {
    return element.perModulo === 'ACEFUN'
  })

  const [telaAtual, setTelaAtual] = useState([])
  useEffect(() => {
    setTelaAtual(sessionStorage.TelaMenuLateral)
  }, [])

  return (
    <Translator
      cacheProvider={cacheProvider}
      from="pt"
      to={user[0].pesIdioma}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <aside className="MenuProfileDireita">
        <table>
          <tbody>
            <td>
              {!!ACECAD.perAcesso ? (
                <Link to="/FormularioCadastro">
                  <button
                    className={
                      telaAtual === 'FormularioCadastro'
                        ? 'btnFundoDestaque'
                        : 'btnFundoPadrao'
                    }
                  >
                    <Translate>Dados Cadastrais</Translate>
                  </button>
                </Link>
              ) : (
                <div></div>
              )}
            </td>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>
                {!!ACEARE.perAcesso ? (
                  <Link to="/Area">
                    <button
                      className={
                        telaAtual === 'Area'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Áreas</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
              <td>
                {!!ACEATV.perAcesso ? (
                  <Link to="/Atividades">
                    <button
                      className={
                        telaAtual === 'Atividades'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Atividades</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                {!!ACEEQP.perAcesso ? (
                  <Link to="/Equipes">
                    <button
                      className={
                        telaAtual === 'Equipes'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Equipes</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
              <td>
                {!!ACEGER.perAcesso ? (
                  <Link to="/Gerencias">
                    <button
                      className={
                        telaAtual === 'Gerencias'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Gerências</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                {!!ACEREL.perAcesso ? (
                  <Link to="/Funcoes">
                    <button
                      className={
                        telaAtual === 'Funcoes'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Funções</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
              <td>
                {!!ACEPES.perAcesso ? (
                  <Link to="/Pessoas">
                    <button
                      className={
                        telaAtual === 'Pessoas'
                          ? 'btnFundoDestaque'
                          : 'btnFundoPadrao'
                      }
                    >
                      <Translate>Pessoas</Translate>
                    </button>
                  </Link>
                ) : (
                  <div></div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <td>
              {!!ACEOBS.perAcesso ? (
                <Link to={'/ApontarObservacao/ObservacoesListar/'}>
                  <button
                    className={
                      telaAtual === 'ObservacoesListar'
                        ? 'btnFundoDestaque'
                        : 'btnFundoPadrao'
                    }
                  >
                    <Translate>Observações</Translate>
                  </button>
                </Link>
              ) : (
                <div></div>
              )}
            </td>
          </tbody>
        </table>
      </aside>
    </Translator>
  )
}

export default SideBarDireita
