import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { Link } from 'react-router-dom'
import AtivadoresChart from '../../Components/dash/ativadores'
import Indicador from '../../Components/dash/indicadorMeta'
import TabelaAtivadores from '../../Components/dash/tabelaAtivadoresUnidade'
import authService from '../../Components/Login/auth.service'
import logologo from '../../Components/logoIniciativaAzul@2x.png'
import imgOpen from '../../Images/imgButtonEsquerda/imgOpen.svg'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2,
} from '../../Components/Main'
import padrao from '../../Components/profile.png'

import SidebarMobile from '../../Components/SideBar-right/SidebarMobile'

function Relatorio() {
  sessionStorage.TelaMenuPrincipal = 'Relatorio'
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  const dateAtual = new Date()
  if (sessionStorage.DataInicio === undefined) {
    sessionStorage.DataInicio = new Date(
      dateAtual.getFullYear(),
      dateAtual.getMonth(),
      1
    )
    sessionStorage.DataFim = new Date()
  }
  const [date, setDate] = useState([
    Moment(`${sessionStorage.DataInicio} 00:00:00`).isValid()
      ? new Date(`${sessionStorage.DataInicio} 00:00:00`)
      : new Date(sessionStorage.DataInicio),
    Moment(`${sessionStorage.DataFim} 23:59:59`).isValid()
      ? new Date(`${sessionStorage.DataFim} 23:59:59`)
      : new Date(sessionStorage.DataFim)
  ])
  const setSeacrhDate = e => {
    setDate(e)
    sessionStorage.DataInicio = e[0].toISOString().slice(0, 10)
    e[1].setDate(e[1].getDate() - 1)
    sessionStorage.DataFim = e[1].toISOString().slice(0, 10)
    window.location.reload()
    //AtualizarMetas()
  }
  var user = authService.getCurrentUser()

  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  var user = authService.getCurrentUser()
  const [unidades, setUnidades] = useState([])
  const [searchUnidade, setSearchUnidade] = useState('')
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidadeUser/list/' +
        user[0].IdEmpresa +
        '/' +
        user[0].IdPessoa
      )
      .then(response => {
        setUnidades(response.data)
        if (sessionStorage.IdUnidade === undefined) {
          sessionStorage.IdUnidade = response.data[0].IdUnidade
          sessionStorage.uniRazaoSocial = response.data[0].uniNome
        }
      })
  }, [])
  const [dashUnidadesMes, setdashUnidadesMes] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/dashUnidades/' +
      date[0].toISOString().slice(0, 10) +
      '/' +
      date[1].toISOString().slice(0, 10)

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setdashUnidadesMes(response.data)
    })
  }, [])
  const [ativador, setAtivador] = useState([])
  useEffect(() => {
    var url =
      '/api/v1/ativadoresUnidade/' +
      date[0].toISOString().slice(0, 10) +
      '/' +
      date[1].toISOString().slice(0, 10) +
      '/' +
      sessionStorage.IdUnidade +
      '/' +
      sessionStorage.IdObservacaoTipo

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      //console.log(response.data)
      setAtivador(response.data)
    })
  }, [])
  const [metasPessoa, setMetasPessoas] = useState([])
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL +
        '/api/v1/metapessoa/acumulado/' +
        sessionStorage.IdUnidade +
        '/' +
        sessionStorage.DataInicio +
        '/' +
        sessionStorage.DataFim
      )
      .then(response => {
        setMetasPessoas(response.data)
      })
  }, [])
  //console.log(metasPessoa)
  function handleUnidadeChange(event) {
    sessionStorage.IdUnidade = event.target.value
    sessionStorage.uniRazaoSocial =
      event.target.options[event.target.selectedIndex].text
    window.location.reload()
    //AtualizarMetas()
  }

  function AtualizarMetas() {
    var url =
      '/api/v1/dashUnidades/' +
      date[0].toISOString().slice(0, 10) +
      '/' +
      date[1].toISOString().slice(0, 10)

    axios.get(process.env.REACT_APP_API_URL + url).then(response => {
      setdashUnidadesMes(response.data)
    })

    var urlA =
      '/api/v1/ativadoresUnidade/' +
      date[0].toISOString().slice(0, 10) +
      '/' +
      date[1].toISOString().slice(0, 10) +
      '/' +
      sessionStorage.IdUnidade +
      '/' +
      sessionStorage.IdObservacaoTipo

    axios.get(process.env.REACT_APP_API_URL + urlA).then(response => {
      //console.log(response.data)
      setAtivador(response.data)
    })

    axios
      .get(process.env.REACT_APP_API_URL +
        '/api/v1/metapessoa/acumulado/' +
        sessionStorage.IdUnidade +
        '/' +
        date[0].toISOString().slice(0, 10) +
        '/' +
        date[1].toISOString().slice(0, 10)
      )
      .then(response => {
        setMetasPessoas(response.data)
      })
  }

  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [dashBoard, setDashBoard] = useState('')
  useEffect(() => {
    if (sessionStorage.dashAtual === undefined) {
      setDashBoard('Unidade')
      sessionStorage.dashAtual = 'Unidade'
    }
    else {
      setDashBoard(sessionStorage.dashAtual)
    }
  })
  const optionsDT = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>{' '}
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {sessionStorage.uniRazaoSocial} /{' '}
                <Translate>UNIDADES / RELATÓRIO / METAS </Translate>:
              </TitlePage1>
              {/* <TitlePage24>{sessionStorage.uniRazaoSocial}</TitlePage24> */}
              <Link to="/Home">
                <TitlePage2>
                  <Translate>Metas de </Translate>{' '}
                  {/* {date[0].toLocaleString('pt-br', { month: 'long' })} */}
                  {date[0].toLocaleString('pt-br', optionsDT)}
                  {date[0].toLocaleString('pt-br', optionsDT) !== date[1].toLocaleString('pt-br', optionsDT)
                    ? ' à ' + date[1].toLocaleString('pt-br', optionsDT) : ''}
                </TitlePage2>
              </Link>
            </TitleOfPage>
          </div>
          <form action="">
            <label className="SelectUnidade1" htmlFor="">
              <Translate>Selecione a Unidade</Translate>:
            </label>

            <select
              name="IdUnidade"
              className="SelectIdClienteRel"
              onChange={event => {
                setSearchUnidade(event.target.value)
                handleUnidadeChange(event)
              }}
            >
              {unidades
                .sort((a, b) => (a.uniNome > b.uniNome ? 1 : -1))
                .map(unidade => (
                  <option
                    value={unidade.IdUnidade}
                    selected={
                      parseInt(unidade.IdUnidade) ===
                      parseInt(sessionStorage.IdUnidade)
                    }
                  >
                    {unidade.uniNome}
                  </option>
                ))}
            </select>
            <select
              name="tipoDash"
              className="SelectTipoDashRel"
              selected={sessionStorage.dashAtual !== undefined ? sessionStorage.dashAtual : ''}
              onChange={event => {
                setDashBoard(event.target.value)
                sessionStorage.dashAtual = event.target.value
              }}
            >
              <option value={'Unidade'} selected={sessionStorage.dashAtual === 'Unidade'} >Meta Unidade</option>
              <option value={'Pessoas'} selected={sessionStorage.dashAtual === 'Pessoas'} >Meta Pessoas</option>
            </select>
          </form>

          {dashBoard === "Unidade" ?
            <div className="dashBoadRelPadrao">
              {dashUnidadesMes
                .filter(val => {
                  if (
                    parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                  ) {
                    return val
                  }
                })
                .map(unidade => (
                  <div
                    className={
                      parseFloat(unidade.mtlQuantitativaReal) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(unidade.mtlQuantitativaReal) <
                          parseFloat(unidade.mtlQuantitativa)
                          ? 'UniClienteMes ics-cor-vermelho'
                          : 'UniClienteMes ics-cor-verde'
                    }
                  >
                    <Indicador
                      p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                      p_titulo={`${unidade.otpDescricao} - ${unidade.rtoIniciadoEm}`}
                      p_meta={parseFloat(unidade.mtlQuantitativa).toFixed(0)} //%{unidade.mtlQuantitativa}
                      p_real={parseFloat(unidade.mtlQuantitativaReal).toFixed(0)} //{unidade.mtlQuantitativaReal}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </div>
                ))}
            </div>
            : <div className="dashBoadRelPadrao">
              {metasPessoa
                .filter(val => {
                  if (parseInt(val.IdPessoa) > 0) {
                    return val
                  }
                })
                .map(metas => (
                  <Link
                    className={
                      parseFloat(metas.Realizado) === 0
                        ? 'UniClienteMes ics-cor-cinza'
                        : parseFloat(metas.Realizado) <
                          parseFloat(metas.mtpQuantitativa)
                          ? 'UniClienteMes ics-cor-vermelho'
                          : 'UniClienteMes ics-cor-verde'
                    }
                    to={`/ObservacoesPessoa/${sessionStorage.DataInicio}/${sessionStorage.DataFim}/${metas.IdPessoa}/${metas.IdObservacaoTipo}/${sessionStorage.IdUnidade}`}>
                    <Indicador
                      p_resultado={parseFloat(metas.metaRealizada).toFixed(2)}
                      p_titulo={`${metas.pesNome} - ${metas.otpDescricao}`}
                      p_meta={parseFloat(metas.mtpQuantitativa).toFixed(0)}
                      p_real={parseFloat(metas.Realizado).toFixed(0)}
                      p_pesIdioma={user[0].pesIdioma}
                    />
                  </Link>
                ))}
            </div>
          }

          <div className="cxLegenda">
            <label htmlFor="" className="lgHLegenda">
              <Translate>Legenda</Translate>:
            </label>
            <button className="lgHAcima">
              <Translate>Meta Concluida</Translate>
            </button>
            <button className="lgHMedia">
              <Translate>Pendente</Translate>
            </button>
            <button className="lgHAbaixo">
              <Translate>Meta não Concluida</Translate>
            </button>
          </div>
          <div className="tabelaPadraoCadHome2">
            <div className="relPizza1">
              {/* <label htmlFor="" className="tittleICSRel">
                <Translate>
                  INCIDÊNCIA DE ATIVADORES
                </Translate>
              </label> */}
              <div className="divisor1">
                <AtivadoresChart data={ativador} />
              </div>
              <div className="divisor3">
                <TabelaAtivadores
                  p_Inicio={sessionStorage.DataInicio}
                  p_Fim={sessionStorage.DataFim}
                />
              </div>
            </div>
          </div>
        </ContainerPage>

        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="calendarRelatorio2">
            <form action="">
              <div className="calendarioAnalise2">
                <Calendar
                  calendarType="Hebrew"
                  onChange={setSeacrhDate}
                  value={date}
                  maxDetail={'year'}
                  selectRange={true}
                />
              </div>
            </form>
          </div>
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Relatorio
