import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  //ContainerPageProfile,
  TitleOfPage,
  TitlePage1,
  //TitlePage8
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'

import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
//import SideBarDireita from '../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import Modal from './modal-Empresas/AlterarRegistroEmpresas'
import ModalCriar from './modal-Empresas/CriarRegistroEmpresas'

import ModalEditPessoa from '../../MenuDireito/MenuDireito-Modal/ModalEdiPessoa'

function SubMenuEmpresas() {
  var user = authService.getCurrentUser()

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [empresas, setEmpresas] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const [modalOpen, setModalOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState([])

  const [modalOpenEditPessoa, setModalOpenEditPessoa] = useState(false)
  const [modalInfoEditPessoa, setModalInfoEditPessoa] = useState([])

  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)

  const permissoes = localStorage.getItem('permissoes')
  let permissao = []
  if (permissoes) {
    permissao = JSON.parse(permissoes)
  }
  else {
    permissao = [
      {
        perModulo: 'ACECAD',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEQP',
        perAcesso: 1
      },
      {
        perModulo: 'ACEPES',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGER',
        perAcesso: 1
      },
      {
        perModulo: 'ACEATV',
        perAcesso: 1
      },
      {
        perModulo: 'ACEARE',
        perAcesso: 1
      },
      {
        perModulo: 'ACEOBS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEDAS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEREL',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEMP',
        perAcesso: 1
      },
      {
        perModulo: 'ACETPO',
        perAcesso: 1
      },
      {
        perModulo: 'ACETXA',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGPU',
        perAcesso: 1
      },
      {
        perModulo: 'ACEFUN',
        perAcesso: 1
      }
    ]
  }
  let ACEEMP = permissao.find(element => {
    return element.perModulo === 'ACEEMP'
  })

  //obtem empresas
  useEffect(() => {
    if (!!ACEEMP.perAcesso && user[0].pesEmail.toLowerCase().includes('admin@')) {
      axios
        .get(process.env.REACT_APP_API_URL + '/api/v1/empresa/' + user[0].IdEmpresa)
        .then(response => {
          setEmpresas(response.data)
        })
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + '/api/v1/empresa')
        .then(response => {
          setEmpresas(response.data)
        })
    }
  }, [])

  const [admins, setAdmins] = useState([])
  //obtem admins
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/v1/pessoasadmins')
      .then(response => {
        setAdmins(response.data)
      })
  }, [])
  //console.log(admins)

  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }

  function handleToggleAdmin(objetos = []) {
    admins
      .filter(pessoa => {
        if (pessoa.IdEmpresa === objetos.IdEmpresa && pessoa.pesAdmin === 1) {
          console.log(pessoa)
          sessionStorage.camposPessoa = pessoa.IdPessoa
          setModalInfoEditPessoa(pessoa)
          setModalOpenEditPessoa(true)
        }

      })
  }

  //console.log(empresas)
  return (
    <div className="displayInteract">
      {modalOpenEditPessoa && (<ModalEditPessoa setOpenModal={setModalOpenEditPessoa} modalInfo={modalInfoEditPessoa} />)}

      {modalOpen && <Modal setOpenModal={setModalOpen} modalInfo={modalInfo} />}

      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          {' '}
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>CADASTRO GERAL DE EMPRESAS</Translate>:
              </TitlePage1>
              {/* <TitlePage8>
                <Translate></Translate>
              </TitlePage8> */}
            </TitleOfPage>

            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />{' '}
              <ModalCriar />
            </div>
          </div>
          <div className="tabelaPadrao">
            <table>
              <tr className="header">
                <th>
                  <Translate>Código</Translate>
                </th>
                <th>
                  <Translate>Unidades</Translate>
                </th>
                <th>
                  <Translate>Admin e Usuários</Translate>
                </th>
                <th>
                  <Translate>Empresa</Translate>
                </th>
                <th>
                  <Translate>Licenças</Translate>
                </th>
                <th>
                  <Translate>Utilizados</Translate>
                </th>
                <th>
                  <Translate>Ativas</Translate>
                </th>
                <th>
                  <Translate>% Ativas</Translate>
                </th>
                <th>
                  <Translate>Status</Translate>
                </th>
              </tr>
              {empresas
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.empRazaoSocial
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(empresa => (
                  <tr
                    key={empresa.IdEmpresa}
                  //onClick={() => handleToggleComplete(empresa)}
                  >
                    {/* IDEMPRESA */}
                    <td onClick={() => handleToggleComplete(empresa)}>
                      {empresa.IdEmpresa}
                    </td>
                    {/* UNIDADES */}
                    <td>
                      <div className="IdObsList">
                        <Link to={'/Unidades/' + empresa.IdEmpresa}>
                          <button className="btnMGUPermissoes">
                            <i className="fas fa-bars"></i>
                          </button>
                        </Link>
                      </div>
                    </td>
                    {/* ADMIN */}
                    <td>
                      <div className="IdObsList">
                        <table className='IdObsList'>
                          <tr>
                            <td>
                              <button className="btnMGUPermissoes" onClick={() => handleToggleAdmin(empresa)}>
                                <i className="fas fa-user"></i>
                              </button>
                            </td>
                            <td>
                              <Link to={'/SubMenuPessoas/' + empresa.IdEmpresa}>
                                <button className="btnMGUPermissoes">
                                  <i className="fas fa-users"></i>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </table>



                      </div>
                    </td>
                    {/* RAZAO SOCIAL */}
                    <td onClick={() => handleToggleComplete(empresa)} style={{ textTransform: 'uppercase' }}>
                      {empresa.empRazaoSocial}
                    </td>
                    {/* QUANTIDADE TOTAL LICENCAS */}
                    <td onClick={() => handleToggleComplete(empresa)}>
                      {empresa.empQuantidadeLicencas}
                    </td>
                    {/* LICENCAS UTILIZADAS */}
                    <td onClick={() => handleToggleComplete(empresa)}>
                      {empresa.LicencasUtilizadas}
                    </td>
                    {/* LICENCAS ATIVAS */}
                    <td onClick={() => handleToggleComplete(empresa)}>
                      {empresa.LicencasAtivos}
                    </td>
                    {/* PERCENTO ATIVAS EM RELACAO AO TOTAL */}
                    <td onClick={() => handleToggleComplete(empresa)}>
                      {empresa.LicencasPorcento} %
                    </td>
                    {/* STATUS DA EMPRESA */}
                    <td>
                      {/* {empresa.empStatus} */}
                      {
                        (user[0].pesEmail.toLowerCase().includes('comportamento.com.br') ||
                          user[0].pesEmail.toLowerCase().includes('meucpto.com.br') ||
                          user[0].pesEmail.toLowerCase().includes('humanit-ti.com')) ? (
                          <input
                            type="checkbox"
                            checked={!!empresa.empStatus}
                            onChange={event => {
                              handleToggleComplete(empresa)
                            }}
                          />
                        ) : ('')
                      }

                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          {/* <SideBarDireita /> */}

          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default SubMenuEmpresas
