import PropTypes from 'prop-types'
import { Translate, Translator } from 'react-auto-translate'
import accomplished from '../../Images/ok.svg'
import pending from '../../Images/pending.svg'
import wrong from '../../Images/wrong.svg'
const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
      [key]: {}
    }
    existing[key] = { ...existing[key], [language]: value }
    localStorage.setItem('translations', JSON.stringify(existing))
  }
}

export default function Indicador({
  p_resultado,
  p_titulo,
  p_meta = 90.0,
  p_real = 0.0,
  p_pesIdioma
}) {
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={p_pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <div className="DashItem">
          <span data-tooltip={p_titulo}>
            <h1>{p_titulo}</h1>
          </span>
          <div className="linha1">
            <img
              width={40}
              height={40}
              src={
                p_resultado === 0
                  ? pending
                  : p_resultado > 89
                  ? accomplished
                  : wrong
              }
              alt="pending"
            />
          </div>
          <div className="linha1">{p_resultado}%</div>
          <div className="linha2">
            <div className="op1">
              <div className="op1-1">
                <Translate>Meta</Translate>:
              </div>
              <div className="op1-1">{parseInt(p_meta)}</div>
            </div>
            <hr className="dashHr" />
            <div className="op1">
              <div className="op1-1">Real:</div>
              <div className="op1-1">{parseInt(p_real)}</div>
            </div>
          </div>
        </div>
      </Translator>
    </>
  )
}
Indicador.propTypes = {
  p_resultado: PropTypes.string.isRequired,
  p_titulo: PropTypes.string.isRequired,
  p_meta: PropTypes.number.isRequired,
  p_real: PropTypes.number.isRequired,
  p_pesIdioma: PropTypes.string.isRequired
}
