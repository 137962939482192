import { useState } from 'react'
import * as TypIcons from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import authService from './auth.service'

function ForgotPassword() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  function handleFormSubmit(event: any) {
    event.preventDefault()
    authService.enviaReset(email).then(
      response => {
        alert(response.data.message)
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        alert(resMessage)
      }
    )
  }

  return (
    <div className="login">
      <div className="bg1" />
      <div className="bg2" />

      <div className="login-right">
        <div id="tittleLogin">
          <h3 id="titleLogin1">Valorização da vida em tempo real</h3>

          <hr className="hrLogin" />
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="login-loginEmail">
            <input
              required
              id="usuario"
              type="email"
              placeholder="E-mail de cadastro"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <button className="forgoutPassword">Solicitar reset de senha</button>
          <button
            className="btnReturnForgout"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
        </form>
      </div>

      <div className="bg3" />
    </div>
  )
}
export default ForgotPassword
