import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

const uuidv4 = require('uuid/v4')

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }

  var user = authService.getCurrentUser()
  const [gerencias, setGerencia] = useState([])
  const [modal, setModal] = useState(false)
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) === 0)
    {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/gerencia/listapp/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeEdit
      )
      .then(response => {
        setGerencia(response.data)
        //console.log(response.data)
      })
    } else {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/gerencia/listapp/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setGerencia(response.data)
        //console.log(response.data)
      })
    }
  }, [])

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdLocalAra: '',
    IdEmpresa: user[0].IdEmpresa,
    IdUnidade: parseInt(sessionStorage.IdUnidadeForm) === 0 ? (sessionStorage.IdUnidadeEdit) : (sessionStorage.IdUnidadeForm),
    IdGerencia: 0,
    locDescricao: '',
    locReferencia: '',
    locStatus: 1,
    locObservacoes: '',
    locCriadoPor: user[0].pesEmail,
    locCriadoEm: newDate,
    locAlteradoPor: user[0].pesEmail,
    locAlteradoEm: newDate,
    locCodigoGUID: uuidv4()
  })
  function handleInputChange(event) {
    if (event.target.name === 'locStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
    //console.log(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    if (campos.IdGerencia === 0 || campos.IdGerencia === "0" || campos.IdGerencia === "") {
      alert("Gerência é Obrigatório!")
    }
    else {
      axios
        .post(process.env.REACT_APP_API_URL + '/api/v1/area', campos)
        .then(response => {
          if (response.status === 200) {
            alert(response.data.message)
            window.location.reload()
          } else {
            alert(response.data.message)
          }
        })
    }
  }
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Nova Área</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Nova Área</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    <Translate>Nova Área</Translate>:
                  </label>
                  <input
                    placeholder="Nova Área"
                    type="text"
                    id="fname"
                    style={{textTransform:'uppercase'}}
                    defaultValue={campos.locDescricao}
                    name="locDescricao"
                    onChange={handleInputChange}
                  ></input>

                  <label>
                    <Translate>Referência</Translate>:
                  </label>
                  <input
                    placeholder="Referência"
                    type="text"
                    id="fname"
                    style={{textTransform:'uppercase'}}
                    defaultValue={campos.locReferencia}
                    name="locReferencia"
                    onChange={handleInputChange}
                  ></input>

                  <label>
                    <Translate>Selecione uma Gerência</Translate>:
                  </label>
                  <select
                    name="IdGerencia"
                    type="text"
                    required
                    defaultValue={campos.IdGerencia}
                    id="fgerencia"
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione uma gerência</option>
                    {gerencias
                      .sort((a, b) =>
                        a.gerDescricao > b.gerDescricao ? 1 : -1
                      )
                      .map(ger => (
                        <option value={ger.IdGerencia}>
                          {ger.gerDescricao}
                        </option>
                      ))}
                  </select>

                  <label>
                    <Translate>Observações</Translate>:
                  </label>
                  <textarea
                    placeholder="Observações"
                    type="text"
                    id="fname"
                    rows={4}
                    style={{textTransform:'uppercase'}}
                    name="locObservacoes"
                    defaultValue={campos.locObservacoes}
                    row="none"
                    onChange={handleInputChange}
                    maxLength="500"
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          name="pesAcessoApp"
                          type="checkbox"
                          defaultChecked={!!campos.locStatus}
                          onChange={handleInputChange}
                        />
                        <span>
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button type="submit">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
