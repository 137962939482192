import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [pessoas, setPessoas] = useState([])
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) === 0)
    {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/list/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeEdit
      )
      .then(response => {
        setPessoas(response.data)
      })
    }
    else {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/list/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setPessoas(response.data)
      })
    }

  }, [])

  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdGerencia: modalInfo.IdGerencia,
    IdEmpresa: modalInfo.IdEmpresa,
    IdUnidade: modalInfo.IdUnidade,
    IdResponsavel: modalInfo.IdResponsavel,
    gerDescricao: modalInfo.gerDescricao,
    gerStatus: modalInfo.gerStatus,
    gerObservacoes: modalInfo.gerObservacoes,
    gerAlteradoPor: user[0].pesEmail,
    gerAlteradoEm: newDate
  })
  //console.log(campos)
  function handleInputChange(event) {
    if (event.target.name === 'gerStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    // console.log(campos)
    // console.log(process.env.REACT_APP_API_URL + '/api/v1/gerenciav2')
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/gerenciav2/', campos)
      .then(response => {
        //console.log(response.data.message)
        if (response.status === 200) {
          if (response.statusText === 'OK') {
            //console.log(response)
            if (response.data.message === 'Gerência atualizada com sucesso!') {
              window.location.reload()
            } else {
              alert(response.data.message)
            }
          } else {
            //console.log(response.statusText)
          }
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <div className="modalGerencia">
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Gerência</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Gerência</Translate>:
              </label>
              <input
                type="text"
                id="fname"
                style={{textTransform:'uppercase'}}
                defaultValue={campos.gerDescricao}
                name="gerDescricao"
                onChange={handleInputChange}
              ></input>
              <label>
                <Translate>Selecione um responsável</Translate>:
              </label>
              <select
                name="IdResponsavel"
                type="text"
                defaultValue={campos.IdResponsavel}
                id="fIdResponsavel"
                onChange={handleInputChange}
              >
                <option value="0">Selecione um responsável</option>
                {pessoas
                  .sort((a, b) => (a.pesNome > b.pesNome ? 1 : -1))
                  .map(pessoa => (
                    <option
                      value={pessoa.IdPessoa}
                      selected={pessoa.IdPessoa === campos.IdResponsavel}
                    >
                      {pessoa.pesNome}
                    </option>
                  ))}
              </select>
              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="gerObservacoes"
                rows={5}
                style={{textTransform:'uppercase'}}
                defaultValue={campos.gerObservacoes}
                maxLength="500"
                onChange={handleInputChange}
              ></textarea>

              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      className="CheckAcess"
                      name="gerStatus"
                      type="checkbox"
                      defaultChecked={!!campos.gerStatus}
                      onChange={handleInputChange}
                    />
                    <span className="checkText">
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>{' '}
            <table className="criadoAlteradoPor_Area">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.gerCriadoPor} </td>
                <td>
                  em
                  {modalInfo.gerCriadoEm}
                </td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.gerAlteradoPor}</td>
                <td>
                  em
                  {modalInfo.gerAlteradoEm}
                </td>
              </tr>
            </table>
          </div>
        </Translator>
      </div>
    </div>
  )
}
