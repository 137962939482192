import { useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import authService from './auth.service'

function Reset() {
  const history = useHistory()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  const [show] = useState(false)
  let id = useParams()

  function handleFormSubmit(event: any) {
    event.preventDefault()
    if (password !== confirmPassword) {
      alert('A confirmação de senha e senha não são iguais!')
    } else {
      authService.efetuaReset(password, id['codigo']).then(
        response => {
          if (response.status === 200) {
            history.push('/')
            alert(response.data.message)
            window.location.reload()
          } else {
            alert(response.data.message)
          }
        }
      )
    }
  }

  return (
    <div className="login">
      <div className="bg1" />
      <div className="bg2" />

      <div className="login-right">
        <div id="tittleLogin">
          <h3 id="titleLogin1">Valorização da vida em tempo real</h3>

          <hr className="hrLogin" />
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="login-loginEmail">
            <input
              required
              id="senha"
              type={show ? 'text' : 'password'}
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="login-loginPassword">
            <input
              required
              id="senha"
              placeholder="Confirmar senha"
              type={show ? 'text' : 'password'}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <button className="forgoutPassword">Confirmar</button>
        </form>
      </div>

      <div className="bg3" />
    </div>
  )
}
export default Reset
