import axios, {AxiosError} from "axios";


const API_URL = process.env.REACT_APP_API_URL + '/api/v1/login/';

class AuthService {
  login(username: string, password: string) {
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    localStorage.removeItem("permissoes");
    localStorage.clear();
    sessionStorage.clear();
    return axios
      .post(API_URL, {
        login: username,
        pass: password
      })
      .then(response => {  
        if (response.data.accessToken) {
          response.data[0].gruAlteradoEm = ''
          response.data[0].gruAlteradoPor = ''
          response.data[0].gruCodigoGUID = ''
          response.data[0].gruCriadoEm = ''
          response.data[0].gruCriadoPor = ''
          //response.data[0].gruDescricao = ''
          response.data[0].gruStatus = ''
          response.data[0].gruObservacoes = ''
          response.data[0].pesAcessoApp = ''
          //response.data[0].pesAcessoWeb = ''
          response.data[0].pesAlteradoEm = ''
          response.data[0].pesAlteradoPor = ''
          response.data[0].pesCelular = ''
          response.data[0].pesCodigoGUID = ''
          response.data[0].pesCriadoEm = ''
          response.data[0].pesCriadoPor = ''
          //response.data[0].pesEmail = ''
          response.data[0].pesEndereco = ''
          response.data[0].pesFoto = ''
          //response.data[0].pesIdioma = ''
          //response.data[0].pesNome = ''
          response.data[0].pesObservacoes = ''
          response.data[0].pesSenha = ''
          response.data[0].pesStatus = ''
          response.data[0].pesTelefone = ''
          localStorage.setItem("user", JSON.stringify(response.data));      
          axios
          .get(process.env.REACT_APP_API_URL + '/api/v1/permissao/list/' + response.data[0]["IdGrupoUsuario"])
          .then(responsePer => { 
                localStorage.setItem("permissoes", JSON.stringify(responsePer.data));
          })   
        }        
          //console.log(response.data);
          response.data[0].gruAlteradoEm = ''
          response.data[0].gruAlteradoPor = ''
          response.data[0].gruCodigoGUID = ''
          response.data[0].gruCriadoEm = ''
          response.data[0].gruCriadoPor = ''
          //response.data[0].gruDescricao = ''
          response.data[0].gruStatus = ''
          response.data[0].gruObservacoes = ''
          response.data[0].pesAcessoApp = ''
          //response.data[0].pesAcessoWeb = ''
          response.data[0].pesAlteradoEm = ''
          response.data[0].pesAlteradoPor = ''
          response.data[0].pesCelular = ''
          response.data[0].pesCodigoGUID = ''
          response.data[0].pesCriadoEm = ''
          response.data[0].pesCriadoPor = ''
          //response.data[0].pesEmail = ''
          response.data[0].pesEndereco = ''
          response.data[0].pesFoto = ''
          //response.data[0].pesIdioma = ''
          //response.data[0].pesNome = ''
          response.data[0].pesObservacoes = ''
          response.data[0].pesSenha = ''
          response.data[0].pesStatus = ''
          response.data[0].pesTelefone = ''

          localStorage.setItem("user", JSON.stringify(response.data));
          return response;
      })
      // .catch((err: Error | AxiosError) => {
      //   if (axios.isAxiosError(err)) {
      //     console.log('Primeiro if: ' + err.message)
      //   }
      //   else{
      //     console.log('Segundo else: ' + err.message)
      //   }
      //   return err;
      // });
  }
  enviaReset(username: string) {
    return axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/sendreset/' , {
        to: username
      })
      .then(response => {         
          return response;
      });
  }
  efetuaReset(senha: string, codigo: string) {
    return axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/reset/' , {
        senha: senha,
        codigo: codigo
      })
      .then(response => {         
          return response;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    localStorage.removeItem("permissoes");
    localStorage.clear();
    sessionStorage.clear();
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      const permissoes = localStorage.getItem("permissoes");
      if(!permissoes)
      {
        axios
        .get(process.env.REACT_APP_API_URL + '/api/v1/permissao/list/' + JSON.parse(userStr)[0]["IdGrupoUsuario"])
        .then(responsePer => { 
              localStorage.setItem("permissoes", JSON.stringify(responsePer.data));
        })
      }
      return JSON.parse(userStr);
    }
    return null;
  }
  
}

export default new AuthService();