import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import Calendar from 'react-calendar'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import AtivadoresChart from '../../../Components/dash/ativadores'
import Indicador from '../../../Components/dash/indicador'
import TabelaAtivadores from '../../../Components/dash/tabelaAtivadoresUnidade'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
    ContainerPage,
    TitleOfPage,
    TitlePage1,
    TitlePage3,
    TitlePage2,
    TitlePage21
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import okImg from '../../../Images/ok.svg'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import Modal from '../ModalObservações/ModalObsUnidades'

var user = authService.getCurrentUser()


function UniUnidades() {
    const cacheProvider = {
        get: (language, key) =>
            ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
            language
            ],
        set: (language, key, value) => {
            const existing = JSON.parse(localStorage.getItem('translations')) || {
                [key]: {}
            }
            existing[key] = { ...existing[key], [language]: value }
            localStorage.setItem('translations', JSON.stringify(existing))
        }
    }
    let id = useParams()
    if (parseInt(sessionStorage.IdUnidadeEdit) !== 0) {
        sessionStorage.IdUnidadeEdit = 0
        window.location.reload()
    }
    sessionStorage.DataFiltroInicio = new Date(id['ano'], id['mes'] - 1, 1)
        .toISOString()
        .slice(0, 10)
    sessionStorage.DataFiltroFim = new Date(id['ano'], id['mes'], 0)
        .toISOString()
        .slice(0, 10)
    const [mesDoAno, setmesDoAno] = useState([])
    useEffect(() => {
        if (id['mes'] === '1') setmesDoAno('JANEIRO')
        else if (id['mes'] === '2') setmesDoAno('FEVEREIRO')
        else if (id['mes'] === '3') setmesDoAno('MARÇO')
        else if (id['mes'] === '4') setmesDoAno('ABRIL')
        else if (id['mes'] === '5') setmesDoAno('MAIO')
        else if (id['mes'] === '6') setmesDoAno('JUNHO')
        else if (id['mes'] === '7') setmesDoAno('JULHO')
        else if (id['mes'] === '8') setmesDoAno('AGOSTO')
        else if (id['mes'] === '9') setmesDoAno('SETEMBRO')
        else if (id['mes'] === '10') setmesDoAno('OUTUBRO')
        else if (id['mes'] === '11') setmesDoAno('NOVEMBRO')
        else if (id['mes'] === '12') setmesDoAno('DEZEMBRO')
        else setmesDoAno('MÊS')
        sessionStorage.MesDoAno = mesDoAno
    })
    // DADOS ACUMULADO POR UNIDADE NO MES
    const [dashAcumulaUnidades, setdashAcumuladoUnidades] = useState([])
    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                '/api/v1/dashUnidadesAcumuladoAgrupamento/' +
                sessionStorage.DataFiltroInicio +
                '/' +
                sessionStorage.DataFiltroFim +
                '/' +
                sessionStorage.uniAgrupamento +
                '/' +
                user[0].IdEmpresa
            )
            .then(response => {
                setdashAcumuladoUnidades(response.data)
            })
    }, [])
    // DADOS LIST DE UNIDADE DO AGRUPAMENTO
    const [dashUnidades, setdashUnidades] = useState([])
    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                '/api/v1/dashListUnidadesAgrupamento/' +
                sessionStorage.DataFiltroInicio +
                '/' +
                sessionStorage.DataFiltroFim +
                '/' +
                sessionStorage.uniAgrupamento +
                '/' +
                user[0].IdEmpresa +
                '/' +
                sessionStorage.IdObservacaoTipo
            )
            .then(response => {
                setdashUnidades(response.data)
            })
    }, [])
    // ATIVADORES
    const [ativador, setAtivador] = useState([])
    useEffect(() => {
        var url =
            '/api/v1/ativadoresUnidadeAgrupamento/' +
            sessionStorage.DataInicio +
            '/' +
            sessionStorage.DataFim +
            '/' +
            sessionStorage.uniAgrupamento +
            '/' +
            user[0].IdEmpresa +
            '/' +
            sessionStorage.IdObservacaoTipo

        axios.get(process.env.REACT_APP_API_URL + url).then(response => {
            //console.log(response.data)
            setAtivador(response.data)
        })
    }, [])

    const [searchTerm, setSearchTerm] = useState(sessionStorage.IdObservacaoTipo)
    let history = useHistory()
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)
    const [image, setImage] = useState('')
    var user = authService.getCurrentUser()
    var img = new Image()
    img.src = user[0].pesFoto
    // Se foi possível carregar a imagem
    img.onload = () => {
        setImage(user[0].pesFoto)
    }
    // Caso não seja possível
    img.onerror = () => {
        setImage(padrao)
    }

    return (
        <div className='displayInteract'>
            <Translator
                cacheProvider={cacheProvider}
                from="pt"
                to={user[0].pesIdioma}
                googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                <ContainerPage>
                    <div id="containerHead1">
                        <div className="logo" />
                    </div>{' '}

                    {/* BARRA COM TITULO E BOTÃO OBSERVAÇÕES E MODAL */}
                    <div id="containerHead2">
                        <TitleOfPage>
                            <TitlePage1>
                                <Translate>RELATÓRIO</Translate> / {sessionStorage.uniAgrupamento} / <Translate>{mesDoAno} / SUBUNIDADES</Translate> :
                            </TitlePage1>
                            <TitlePage3></TitlePage3>
                            <TitlePage2>
                                <Translate>Observações realizadas</Translate>:
                            </TitlePage2>
                        </TitleOfPage>
                        <div id="containerPesquisaModal">
                            <Modal />
                        </div>
                    </div>
                    <button
                        className="btnReturn"
                        type="button"
                        onClick={() => history.goBack()}
                    >
                        <TypIcons.TiArrowBackOutline />
                    </button>
                    {/* FINAL - BARRA COM TITULO E BOTÃO OBSERVAÇÕES E MODAL */}
                    <div className="divIcs">
                        {/* QUADROS DE META E ACUMULADO */}
                        <div className="dashBoadMetaPadrao">
                            {dashAcumulaUnidades
                                .filter(val => {
                                    if (
                                        searchTerm === '' &&
                                        val.uniAgrupamento === sessionStorage.uniAgrupamento
                                        //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                                    ) {
                                        return val
                                    } else if (
                                        parseInt(val.IdObservacaoTipo) === parseInt(searchTerm) &&
                                        val.uniAgrupamento === sessionStorage.uniAgrupamento
                                        //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                                    ) {
                                        return val
                                    }
                                })
                                .map(unidade => (
                                    <div
                                        style={{ cursor: 'default' }}
                                        className="UniClienteMes ics-cor-verde"
                                    >
                                        <div className="DashItem">
                                            <span>
                                                <h1 htmlFor="" style={{ cursor: 'default' }}>
                                                    <Translate>Projetado</Translate>
                                                </h1>
                                            </span>
                                            <div className="linha1">
                                                <img width={40} height={40} src={okImg} alt="okImg" />
                                            </div>
                                            <div className="linha1">90.00%</div>
                                            <div className="linha2">
                                                <div className="op1">
                                                    <div className="op1-1">
                                                        <Translate>Meta </Translate>(Qtde):
                                                    </div>
                                                    <div className="op1-1">
                                                        {parseInt(unidade.mtlQuantitativa)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {dashAcumulaUnidades
                                .filter(val => {
                                    if (
                                        searchTerm === '' &&
                                        val.uniAgrupamento === sessionStorage.uniAgrupamento
                                        //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                                    ) {
                                        return val
                                    } else if (
                                        parseInt(val.IdObservacaoTipo) ===
                                        parseInt(sessionStorage.IdObservacaoTipo) &&
                                        val.uniAgrupamento === sessionStorage.uniAgrupamento
                                        //parseInt(val.IdUnidade) === parseInt(sessionStorage.IdUnidade)
                                    ) {
                                        return val
                                    }
                                })
                                .map(unidade => (
                                    <div
                                        className={
                                            parseFloat(unidade.rtqPontuacao) === 0
                                                ? 'UniClienteMes ics-cor-cinza'
                                                : parseFloat(unidade.rtqPontuacao) < 70
                                                    ? 'UniClienteMes ics-cor-vermelho'
                                                    : parseFloat(unidade.rtqPontuacao) < 90
                                                        ? 'UniClienteMes ics-cor-amarelo'
                                                        : 'UniClienteMes ics-cor-verde'
                                        }
                                        style={{ cursor: 'default' }}
                                    >
                                        <Indicador
                                            p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                                            p_titulo={'Acumulado'}
                                            p_meta={parseInt(unidade.mtlQuantitativa)}
                                            p_real={unidade.mtlQuantitativaReal}
                                            p_pesIdioma={user[0].pesIdioma}
                                        />
                                    </div>
                                ))}
                        </div>
                        {/* FINAL - QUADROS DE META E ACUMULADO */}

                        {/* GRÁFICO E LISTAGEM DE ATIVADORES */}
                        <div className="relPizza2">
                            <div className="divisor1">
                                <AtivadoresChart data={ativador} />
                            </div>
                            <div className="divisor2">
                                <TabelaAtivadores
                                    p_Inicio={sessionStorage.DataFiltroInicio}
                                    p_Fim={sessionStorage.DataFiltroFim}
                                />
                            </div>
                        </div>
                        {/* FINAL - GRÁFICO E LISTAGEM DE ATIVADORES */}

                    </div>
                    <div id="containerHeadSub">
                        <TitleOfPage>
                            <TitlePage21>
                                <Translate>
                                    Resultado do período selecionado por Sub Unidade(s) :
                                </Translate>
                            </TitlePage21>
                        </TitleOfPage>
                    </div>
                    {/* DASH COM ÍCONES DOS RESULTADOS POR MÊS */}
                    <div className="dashBoadPadrao">
                        {
                            dashUnidades
                                .filter(val => {
                                    if (
                                        searchTerm === '' &&
                                        val.uniAgrupamento === sessionStorage.uniAgrupamento
                                    ) {
                                        //console.log(val)
                                        return val
                                    }
                                    else
                                        if (
                                            parseInt(val.IdObservacaoTipo) ===
                                            parseInt(sessionStorage.IdObservacaoTipo) &&
                                            val.uniAgrupamento === sessionStorage.uniAgrupamento
                                        ) {
                                            return val
                                        }
                                })
                                .map(unidade => (
                                    <Link
                                        className={
                                            parseFloat(unidade.rtqPontuacao) === 0
                                                ? 'UniClienteMes ics-cor-cinza'
                                                : parseFloat(unidade.rtqPontuacao) < 70
                                                    ? 'UniClienteMes ics-cor-vermelho'
                                                    : parseFloat(unidade.rtqPontuacao) < 90
                                                        ? 'UniClienteMes ics-cor-amarelo'
                                                        : 'UniClienteMes ics-cor-verde'
                                        }
                                        to={(`/UniGerencia/${unidade.IdUnidade}/${id['ano']}/${id['mes']}`)}
                                    >
                                        <Indicador
                                            p_resultado={parseFloat(unidade.rtqPontuacao).toFixed(2)}
                                            p_titulo={unidade.uniNome}
                                            p_meta={unidade.mtlQuantitativa}
                                            p_real={unidade.mtlQuantitativaReal}
                                            p_pesIdioma={user[0].pesIdioma}
                                        />
                                    </Link>
                                )
                                )}
                    </div>
                    {/* FINAL DASH COM ÍCONES DOS RESULTADOS POR MÊS */}
                    <div className="cxLegenda">
                        <label htmlFor="" className="lgHLegenda">
                            <Translate>Legenda:</Translate>
                        </label>
                        <button className="lgHAcima">
                            <Translate>Esperado</Translate>
                        </button>
                        <button className="lgHMedia">
                            <Translate>Atenção</Translate>
                        </button>
                        <button className="lgHAbaixo">
                            <Translate>Risco</Translate>
                        </button>
                    </div>
                </ContainerPage>
                <button
                    className={sidebar ? 'abrirMenu' : 'fecharMenu'}
                    onClick={showSidebar}
                >
                    <img src={imgOpen} alt="" />
                </button>

                {/* ************ MENU LATERAL ************* */}

                <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
                    <div className="cxProfile">
                        <h6 className="titleProfile1">
                            {' '}
                            <Translate>Seja Muito Bem-vindo,</Translate>
                        </h6>
                        <h6 className="titleProfile2">{user[0].pesNome}</h6>
                        <div className="alignFotoProfile">
                            {image ? (
                                <img className="logo1" src={image} alt="perfil" />
                            ) : (
                                <img src={padrao} alt="Profile" className="logo1" />
                            )}
                            {/* <Link to="/ViewProfile">
                                <button
                                    className="profile-profileEditor"
                                // onClick={() => handleToggleComplete(user[0])}
                                >
                                    <Translate>Ver perfil</Translate>
                                </button>
                            </Link> */}
                        </div>
                    </div>
                    <div className="cxLogo">
                        {user[0].gruDescricao === 'Humanit' ? (
                            <SidebarMobile />
                        ) : user[0].gruDescricao === 'Administrativo' ? (
                            <SidebarMobile />
                        ) : user[0].gruDescricao === 'Administrador' ? (
                            <SidebarMobile />
                        ) : (
                            <div></div>
                        )}
                        <img className="logologo" src={logologo} alt="logo" />
                    </div>
                </nav>

            </Translator>
        </div>
    )
}

export default UniUnidades