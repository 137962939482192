import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  ContainerPageProfile,
  TitleOfPage,
  TitlePage1,
  TitlePage2,
  TitlePage8
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import '../../../styles/pages/observCpto.css'
import InfoUnidade from './InfoUnidade'
var user = authService.getCurrentUser()

function ObservCpto() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }

  let id = useParams()
  let history = useHistory()
  const [sidebar, setSidebar] = useState(false)
  const [modalInfo, setModalInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermFiltro, setSearchTermFiltro] = useState(0)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoquestao/list/' +
        id['IdRetornoObservacao']
      )
      .then(response => {
        setModalInfo(response.data)
      })
  }, [])
  const [auditorias, setAuditorias] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashAuditorias/' +
        id['IdUnidade']
      )
      .then(response => {
        setAuditorias(response.data)
      })
  }, [])

  const [ativadores, setAtivadores] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoativador/list/' +
        id['IdRetornoObservacao']
      )
      .then(response => {
        setAtivadores(response.data)
      })
  }, [])
  const showSidebar = () => setSidebar(!sidebar)
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [toogle, setToogle] = React.useState(false)

  return (
    <div>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div className="logo" />
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <i className="icon-search"></i>
          <input
            className="fnamePadraoCpto"
            placeholder="Pesquisar"
            type="text"
            id="fname1"
            name="fname"
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
          />
          <TitleOfPage>
            <TitlePage1>
              {' '}
              {sessionStorage.uniRazaoSocial} /{' '}
              <Translate>OBSERVAÇÃO</Translate>
            </TitlePage1>
            <TitlePage2>{sessionStorage.ObservacaoDesc}</TitlePage2>
            <TitlePage8>
              <Translate>Detalhes da Observação</Translate>
            </TitlePage8>
          </TitleOfPage>
          <div>
            <div className="observComp">
              <label htmlFor="" className="observComp1">
                <Translate> ICS</Translate>:
              </label>

              {auditorias
                .filter(val => {
                  if (
                    parseFloat(val.IdRetornoObservacao) ===
                    parseFloat(id['IdRetornoObservacao'])
                  ) {
                    return val
                  }
                })
                .map(auditoria => (
                  <label htmlFor="" className="observComp2">
                    {parseFloat(auditoria.rtqPontuacao).toFixed(2)}%
                  </label>
                ))}
            </div>

            <h3 className="subtObservCPTO3" />
            <div className="FilterObservCPTO">
              <label htmlFor="" className="filterList">
                {' '}
                <Translate>filtro</Translate>:
              </label>
              <button
                className="filterList1"
                onClick={event => {
                  setSearchTermFiltro(1)
                }}
              >
                <i className="far fa-laugh-beam"></i>
              </button>
              <button
                className="filterList2"
                onClick={event => {
                  setSearchTermFiltro(2)
                }}
              >
                <i className="far fa-meh"></i>
              </button>
              <button
                className="filterList3"
                onClick={event => {
                  setSearchTermFiltro(3)
                }}
              >
                <i className="far fa-frown"></i>
              </button>
              <button
                className="filterList4"
                onClick={event => {
                  setSearchTermFiltro(0)
                }}
              >
                <i className="far fa-meh"></i>
              </button>
            </div>
          </div>
          <div className="tabelaPadraoObservPTO">
            <table>
              <tr>
                <th>
                  <Translate>Grupo</Translate>
                </th>
                <th>
                  <Translate>Questão</Translate>
                </th>
                <th>
                  <Translate>Observação</Translate>
                </th>
                <th>
                  <Translate>Ativadores</Translate>
                </th>
                <th></th>
              </tr>
              {modalInfo
                .filter(val => {
                  if (searchTerm === '' && searchTermFiltro === 0) {
                    return val
                  } else if (
                    searchTerm === '' &&
                    parseInt(val.rtqPontuacao) === searchTermFiltro
                  ) {
                    return val
                  } else if (
                    val.oqsDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) &&
                    parseInt(val.rtqPontuacao) === searchTermFiltro
                  ) {
                    return val
                  } else if (
                    val.oqsDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) &&
                    searchTermFiltro === 0
                  ) {
                    return val
                  }
                })
                .map(observacaoquestao => (
                  <tr>
                    <td>
                      <Translate>{observacaoquestao.ogrDescricao}</Translate>
                    </td>
                    <td>
                      <Translate>{observacaoquestao.oqsDescricao}</Translate>
                    </td>

                    <td className="alignContent">
                      {/* {'teste de espaço - favor, deletar essa linha no codigo '} */}
                      {ativadores
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .filter(val => {
                          if (
                            parseInt(val.IdRetornoQuestao) ===
                            parseInt(observacaoquestao.IdRetornoQuestao)
                          ) {
                            return val
                          }
                        })
                        .map(ativador => (
                          <tr>{ativador.rtaObservacoes}</tr>
                        ))}
                    </td>

                    <td className="alignContent">
                      {ativadores
                        .filter(val => {
                          if (
                            parseInt(val.IdRetornoQuestao) ===
                            parseInt(observacaoquestao.IdRetornoQuestao)
                          ) {
                            return val
                          }
                        })
                        .map(ativador => (
                          //<tr>{ativador.attDescricao}</tr>
                          <tre>{observacaoquestao.rtqObservacoes}</tre>
                        ))}
                    </td>
                    <td>
                      <button
                        className={
                          parseInt(observacaoquestao.rtqPontuacao) === 0
                            ? 'tblFilter ics-cor-cinza1'
                            : parseInt(observacaoquestao.rtqPontuacao) === 3
                              ? 'tblFilter ics-cor-vermelho1'
                              : parseInt(observacaoquestao.rtqPontuacao) === 2
                                ? 'tblFilter ics-cor-amarelo1'
                                : 'tblFilter ics-cor-verde1'
                        }
                      >
                        {parseInt(observacaoquestao.rtqPontuacao) === 3 ? (
                          <i className="far fa-frown"></i>
                        ) : parseInt(observacaoquestao.rtqPontuacao) === 2 ? (
                          <i className="far fa-meh"></i>
                        ) : parseInt(observacaoquestao.rtqPontuacao) === 1 ? (
                          <i className="far fa-laugh-beam"></i>
                        ) : (
                          ' '
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <ul className="nav-menu1-items">
            <li className="navbar-toggle">
              <div className="Profile-direita">
                <div className="cxProfile">
                  <h6 className="titleProfile1">
                    {' '}
                    <Translate>Seja Muito Bem-vindo,</Translate>
                  </h6>
                  <h6 className="titleProfile2">{user[0].pesNome}</h6>
                  <div className="alignFotoProfile">
                    {image ? (
                      <img className="logo1" src={image} alt="perfil" />
                    ) : (
                      <img src={padrao} alt="Profile" className="logo1" />
                    )}
                    {/* <Link to="/ViewProfile">
                      <button
                        className="profile-profileEditor"
                      // onClick={() => handleToggleComplete(user[0])}
                      >
                        <Translate>Ver perfil</Translate>
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
              {auditorias
                .filter(val => {
                  if (
                    parseFloat(val.IdRetornoObservacao) ===
                    parseFloat(id['IdRetornoObservacao'])
                  ) {
                    return val
                  }
                })
                .map(auditoria => (
                  <InfoUnidade
                    Observador={auditoria.Observador}
                    atvDescricao={auditoria.atvDescricao}
                    uniNome={auditoria.uniNome}
                    equipe_abordada={auditoria.equipe_abordada}
                    local={auditoria.local}
                    rtoIniciadoEm={auditoria.rtoIniciadoEm}
                    rtoFinalizadoEm={auditoria.rtoFinalizadoEm}
                    rtoObservados={auditoria.rtoObservados}
                    rtoRotina={auditoria.rtoRotina}
                    rtoObservadores={auditoria.rtoObservadores}
                    rtoObservacoes={auditoria.rtoObservacoes}
                  />
                ))}

              {user[0].gruDescricao === 'Humanit' ? (
                <SidebarMobile />
              ) : user[0].gruDescricao === 'Administrativo' ? (
                <SidebarMobile />
              ) : (
                <div></div>
              )}
              <img className="logologo" src={logologo} alt="logo" />
            </li>
          </ul>
        </nav>
      </Translator>
    </div>
  )
}

export default ObservCpto
