import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { HashScroll } from 'react-hash-scroll'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage2,
  TitlePage3
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'
import '../../../styles/pages/ModalObservaçoes/UniClienteExe.css'
import InfoUnidade from '../../Relatorio/DetalhesDaObservação/InfoUnidade'
import Modal from './ModalConcluiObs'

const uuidv4 = require('uuid/v4')

function RetornoQuestao() {
  /// DESATIVA O VOLTAR DO NAVEGADOR
  window.history.pushState(
    { name: 'browserBack' },
    'on browser back click',
    window.location.href
  )
  window.addEventListener(
    'popstate',
    event => {
      if (event.state) {
        //alert("saindo");
      }
    },
    false
  )

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  sessionStorage.removeItem('IdEquipePree')
  sessionStorage.removeItem('IdObservacaoTipoPree')
  sessionStorage.removeItem('rtoRotinaPree')
  sessionStorage.removeItem('IdAtividadePree')
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  let history = useHistory()
  var checked = true
  const [sidebar, setSidebar] = useState(false)
  // const showIcon = () => setIcons(!icons)
  // const [icons, setIcons] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [searchTerm, setSearchTerm] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  let newDate = new Date()
  let id = useParams()

  sessionStorage.IdUnidadeNovaObs = id['idObs']
  //obtem dados da obsevação da View
  const [auditorias, setAuditorias] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/dashAuditoriasId/' +
        id['idObs']
      )
      .then(response => {
        //console.log(response.data)
        setAuditorias(response.data)
      })
  }, [])
  //obtem dados da observação da tabela retorno
  const [modalInfo, setModalInfo] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoobservacao/' +
        id['idObs']
      )
      .then(response => {
        sessionStorage.IdEquipe = response.data[0].IdEquipe
        //console.log(response.data)
        setModalInfo(response.data)
      })
  }, [])
  //obtem a lista de questões
  const [observacaoquestoes, setObservacaoquestoes] = useState([])
  const grupos = observacaoquestoes.map(q => q.ogrDescricao)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoquestao/list/' +
        id['idObs']
      )
      .then(response => {
        if (response.status === 200) {
          setObservacaoquestoes(response.data)
        }
      })
  }, [])

  function handleAnswerCam(
    IdRetornoQuestao = 0,
    rtqPontuacao = 0,
    rtqStatus = 1,
    rtqPontuacaoAnterior = 0,
    rtqPontuacaoAtivador = 0,
    event
  ) {
    //console.log(event.target.name)
    var list = document.getElementsByName(event.target.name)
    //console.log(list[0])
    if (rtqPontuacao !== 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          '/api/v1/retornoquestao/' +
          IdRetornoQuestao,
          {
            IdRetornoQuestao: IdRetornoQuestao,
            rtqPontuacao: rtqPontuacao,
            rtqStatus: rtqStatus,
            rtqAlteradoPor: user[0].pesEmail,
            rtqAlteradoEm: newDate
          }
        )
        .then(response => {
          //console.log(event)

          if (response.status === 200) {
            history.push(
              '/RetornoQuestaoAtivador/' +
              id['idObs'] +
              '/' +
              IdRetornoQuestao +
              '/' +
              rtqPontuacao
            )
          }
        })
    }
  }

  function handleAnswer(
    IdRetornoQuestao = 0,
    rtqPontuacao = 0,
    rtqStatus = 1,
    rtqPontuacaoAnterior = 0,
    rtqPontuacaoAtivador = 0,
    event
  ) {
    //console.log(rtqPontuacaoAnterior)
    //console.log(rtqPontuacao)
    //console.log(event)

    if (
      rtqPontuacaoAnterior !== 0 &&
      rtqPontuacao !== rtqPontuacaoAnterior &&
      rtqPontuacaoAtivador > 0
    ) {
      if (window.confirm('Modificar a nota?')) {
        if ((rtqPontuacaoAnterior === 3 || rtqPontuacaoAnterior === 2) && rtqPontuacao === 1) {
          axios
            .post(
              process.env.REACT_APP_API_URL +
              '/api/v1/retornoquestao/' +
              IdRetornoQuestao,
              {
                IdRetornoQuestao: IdRetornoQuestao,
                rtqPontuacao: rtqPontuacao,
                rtqStatus: rtqStatus,
                rtqObservacoes: "",
                rtqAlteradoPor: user[0].pesEmail,
                rtqAlteradoEm: newDate
              }
            )
            .then(response => {
              if (response.status === 200) {
                if (rtqPontuacao > 1) {
                  history.push(
                    '/RetornoQuestaoAtivador/' +
                    id['idObs'] +
                    '/' +
                    IdRetornoQuestao +
                    '/' +
                    rtqPontuacao
                  )
                } else {
                  alert('Nota Atualizada!')
                  window.location.reload()
                }
              }
            })
        } else {
          axios
            .post(
              process.env.REACT_APP_API_URL +
              '/api/v1/retornoquestao/' +
              IdRetornoQuestao,
              {
                IdRetornoQuestao: IdRetornoQuestao,
                rtqPontuacao: rtqPontuacao,
                rtqStatus: rtqStatus,
                rtqAlteradoPor: user[0].pesEmail,
                rtqAlteradoEm: newDate
              }
            )
            .then(response => {
              if (response.status === 200) {
                if (rtqPontuacao > 1) {
                  history.push(
                    '/RetornoQuestaoAtivador/' +
                    id['idObs'] +
                    '/' +
                    IdRetornoQuestao +
                    '/' +
                    rtqPontuacao
                  )
                } else {
                  alert('Nota Atualizada!')
                  window.location.reload()
                }
              }
            })
        }

      } else {
        window.location.reload()
      }
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          '/api/v1/retornoquestao/' +
          IdRetornoQuestao,
          {
            IdRetornoQuestao: IdRetornoQuestao,
            rtqPontuacao: rtqPontuacao,
            rtqStatus: rtqStatus,
            rtqAlteradoPor: user[0].pesEmail,
            rtqAlteradoEm: newDate
          }
        )
        .then(response => {
          if (response.status === 200) {
            if (rtqPontuacao > 1) {
              history.push(
                '/RetornoQuestaoAtivador/' +
                id['idObs'] +
                '/' +
                IdRetornoQuestao +
                '/' +
                rtqPontuacao
              )
            } else {
              document.getElementById(event.target.name).hidden = false
            }
            // window.location.reload()
          } else {
            alert('Houve um erro ao atualizar a questão, tente novamente!')
            window.location.reload()
          }
        })
    }
  }

  function handleToggleComplete(objetos) {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/retornoquestao/list/' +
        id['idObs']
      )
      .then(response => {
        if (response.status === 200) {
          setObservacaoquestoes(response.data)
          //console.log(observacaoquestoes)
          var prossegue = true
          response.data.forEach(element => {
            //console.log(element.rtqPontuacao)
            if (parseInt(element.rtqPontuacao) === 0) {
              prossegue = false
              return prossegue
            }
          })
          //console.log(prossegue)
          if (prossegue) {
            objetos = [
              {
                idUni: id['idUni'],
                idAr: id['idAr'],
                idObs: id['idObs']
              }
            ]
            // setModalInfo(objetos)
            setModalOpen(true)
          } else {
            alert('Você possui questões pendentes de resposta!')
          }
        }
      })
  }

  return (
    <div className="displayInteract">
      {modalOpen && <Modal setOpenModal={setModalOpen} modalInfo={modalInfo} />}
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <input
            className="fnamePadrao3"
            placeholder="Pesquisar"
            type="text"
            id="fname1"
            name="fname"
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
          />

          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage2>{sessionStorage.uniRazaoSocial}</TitlePage2>
              <TitlePage3>{sessionStorage.ObservacaoDesc}</TitlePage3>
            </TitleOfPage>{' '}
            <button
              className="btnSalvarObs"
              onClick={() => handleToggleComplete([])}
            >
              <i className="far fa-clipboard"></i>
            </button>
          </div>

          <div className="selectTextAtiv" id="questoes">
            {grupos
              .filter((q, idx) => grupos.indexOf(q) === idx)
              .map(grupo => (
                <>
                  <header>
                    <Translate>{grupo}</Translate>
                  </header>
                  {observacaoquestoes
                    .filter(val => {
                      if (val.ogrDescricao === grupo && searchTerm === '') {
                        return val
                      } else if (
                        val.ogrDescricao === grupo &&
                        val.oqsDescricao
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val
                      }
                    })
                    .map(observacaoquestao => (
                      <line
                        style={{ border: '1px solid #d8d8d8' }}
                        key={observacaoquestao.IdRetornoQuestao}
                      >
                        <div
                          style={{
                            position: 'relative',
                            width: '90%'
                          }}
                        >
                          <Translate>
                            {observacaoquestao.oqsDescricao}
                          </Translate>
                        </div>
                        <HashScroll
                          hash={'#' + observacaoquestao.IdRetornoQuestao}
                        >
                          <div className="cxNota">
                            <input
                              required
                              onClick={event =>
                                handleAnswerCam(
                                  observacaoquestao.IdRetornoQuestao,
                                  1,
                                  1,
                                  parseInt(observacaoquestao.rtqPontuacao),
                                  parseInt(
                                    observacaoquestao.rtqPontuacaoAtivador
                                  ),
                                  event
                                )
                              }
                              id={'camera' + observacaoquestao.IdRetornoQuestao}
                              name={
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              type="radio"
                              {...(parseInt(observacaoquestao.rtqPontuacao) ===
                                1
                                ? { checked }
                                : '')}
                            ></input>
                            <label
                              // className={icons ? 'nav-icon1' : 'nav-iconActive '}
                              className="letter0"
                              htmlFor={
                                'camera' + observacaoquestao.IdRetornoQuestao
                              }
                              id={'radio' + observacaoquestao.IdRetornoQuestao}
                              hidden={
                                parseInt(observacaoquestao.rtqPontuacao) !== 1
                              }
                            >
                              <i className="fas fa-camera"></i>
                            </label>

                            <input
                              onFocus={
                                sessionStorage.x ===
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              autoFocus={
                                sessionStorage.x ===
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              required
                              onClick={event =>
                                handleAnswer(
                                  observacaoquestao.IdRetornoQuestao,
                                  1,
                                  1,
                                  parseInt(observacaoquestao.rtqPontuacao),
                                  parseInt(
                                    observacaoquestao.rtqPontuacaoAtivador
                                  ),
                                  event
                                )
                              }
                              id={'smile' + observacaoquestao.IdRetornoQuestao}
                              name={
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              type="radio"
                              {...(parseInt(observacaoquestao.rtqPontuacao) ===
                                1
                                ? { checked }
                                : '')}
                            ></input>
                            <label
                              className="letter1"
                              htmlFor={
                                'smile' + observacaoquestao.IdRetornoQuestao
                              }
                            >
                              <i className="far fa-smile"></i>
                            </label>

                            <input
                              onClick={event =>
                                handleAnswer(
                                  observacaoquestao.IdRetornoQuestao,
                                  2,
                                  1,
                                  parseInt(observacaoquestao.rtqPontuacao),
                                  parseInt(
                                    observacaoquestao.rtqPontuacaoAtivador
                                  ),
                                  event
                                )
                              }
                              id={'meh' + observacaoquestao.IdRetornoQuestao}
                              name={
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              type="radio"
                              {...(parseInt(observacaoquestao.rtqPontuacao) ===
                                2 &&
                                parseInt(observacaoquestao.rtqPontuacaoAtivador) >
                                0
                                ? { checked }
                                : '')}
                            ></input>
                            <label
                              className="letter2"
                              htmlFor={
                                'meh' + observacaoquestao.IdRetornoQuestao
                              }
                            >
                              <i className="far fa-meh"></i>
                            </label>

                            <input
                              onClick={event =>
                                handleAnswer(
                                  observacaoquestao.IdRetornoQuestao,
                                  3,
                                  1,
                                  parseInt(observacaoquestao.rtqPontuacao),
                                  parseInt(
                                    observacaoquestao.rtqPontuacaoAtivador
                                  ),
                                  event
                                )
                              }
                              id={'frown' + observacaoquestao.IdRetornoQuestao}
                              name={
                                'radio' + observacaoquestao.IdRetornoQuestao
                              }
                              type="radio"
                              {...(parseInt(observacaoquestao.rtqPontuacao) ===
                                3 &&
                                parseInt(observacaoquestao.rtqPontuacaoAtivador) >
                                0
                                ? { checked }
                                : '')}
                            ></input>
                            <label
                              className="letter3"
                              htmlFor={
                                'frown' + observacaoquestao.IdRetornoQuestao
                              }
                            >
                              <i className="far fa-frown"></i>
                            </label>
                          </div>
                        </HashScroll>
                      </line>
                    ))}
                </>
              ))}
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        {/* ------------------------------------------------------------------------------------------------------ */}
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>

          <div className="calendarRelatorio2">
            {auditorias
              .filter(val => {
                if (
                  parseFloat(val.IdRetornoObservacao) ===
                  parseFloat(id['idObs'])
                ) {
                  return val
                }
              })
              .map(auditoria => (
                <InfoUnidade
                  Observador={auditoria.Observador}
                  atvDescricao={auditoria.atvDescricao}
                  uniNome={auditoria.uniNome}
                  equipe_abordada={auditoria.equipe_abordada}
                  local={auditoria.local}
                  rtoIniciadoEm={auditoria.rtoIniciadoEm}
                  rtoFinalizadoEm={auditoria.rtoFinalizadoEm}
                  rtoObservados={auditoria.rtoObservados}
                  rtoRotina={auditoria.rtoRotina}
                  rtoObservadores={auditoria.rtoObservadores}
                  rtoObservacoes={auditoria.rtoObservacoes}
                />
              ))}
          </div>

          <div className="cxLogo">
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default RetornoQuestao
