// import padrao from '../profile.png'

import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from './auth.service'
import '../../styles/pages/MenuDireito/modal/modalPessoa.css'
import padrao from '../../Components/profile.png'
import { useHistory } from 'react-router'

const uuidv4 = require('uuid/v4')
//const crypto = require('crypto')

export default function VerPerfil({ setOpenModal, modalInfo }) {
  let newDate = new Date()
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  //let history = useHistory()
  var user = authService.getCurrentUser()
  //console.log(user[0])
  const [funcoes, setFuncao] = useState([])
  const [grupos, setGrupo] = useState([])
  const [gerencias, setGerencia] = useState([])
  const [modal, setModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  const [image, setImage] = useState('')
  const history = useHistory()

  const [campos, setCampos] = useState({
    IdPessoa: modalInfo.IdPessoa,
    IdEmpresa: modalInfo.IdEmpresa,
    IdFuncao: modalInfo.IdFuncao,
    IdGrupoUsuario: modalInfo.IdGrupoUsuario,
    pesNome: modalInfo.pesNome,
    pesFoto: modalInfo.pesFoto,
    pesEmail: modalInfo.pesEmail,
    pesSenha: '',
    confPesSenha: '',
    pesTelefone: modalInfo.pesTelefone,
    pesCelular: modalInfo.pesCelular,
    pesEndereco: modalInfo.pesEndereco,
    pesAcessoWeb: modalInfo.pesAcessoWeb,
    pesAcessoApp: modalInfo.pesAcessoApp,
    pesIdioma: modalInfo.pesIdioma,
    pesStatus: modalInfo.pesStatus,
    pesObservacoes: modalInfo.pesObservacoes,
    pesAlteradoPor: modalInfo.pesEmail,
    pesAlteradoEm: newDate
  })

  const [pessoa, setPessoa] = useState([])
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/' +
        user[0].IdPessoa
      )
      .then(response => {
        setPessoa(response.data)
        campos.IdPessoa = response.data[0].IdPessoa
        campos.IdEmpresa = response.data[0].IdEmpresa
        campos.IdFuncao = response.data[0].IdFuncao ? response.data[0].IdFuncao : 0
        campos.IdGrupoUsuario = response.data[0].IdGrupoUsuario ? response.data[0].IdGrupoUsuario : 0
        campos.IdGerencia = response.data[0].IdGerencia ? response.data[0].IdGerencia : 0
        campos.pesNome = response.data[0].pesNome
        campos.pesFoto = response.data[0].pesFoto
        campos.pesEmail = response.data[0].pesEmail
        //campos.pesSenha= ''
        //campos.confPesSenha= ''
        campos.pesTelefone = response.data[0].pesTelefone
        campos.pesCelular = response.data[0].pesCelular
        campos.pesEndereco = response.data[0].pesEndereco
        campos.pesAcessoWeb = response.data[0].pesAcessoWeb
        campos.pesAcessoApp = response.data[0].pesAcessoApp
        campos.pesIdioma = response.data[0].pesIdioma
        campos.pesStatus = response.data[0].pesStatus ? response.data[0].pesStatus : 1
        campos.pesObservacoes = response.data[0].pesObservacoes
        campos.pesAlteradoPor = user[0].pesEmail
        campos.pesAlteradoEm = newDate
      })
  }, [])

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/funcao/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setFuncao(response.data)
      })
  }, [])

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/grupoUsuario/list/' +
        user[0].IdEmpresa
      )
      .then(response => {
        setGrupo(response.data)
      })
  }, [])

  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeEdit) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/gerencia/listapp/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          setGerencia(response.data)
        })
    }
    else if (parseInt(sessionStorage.IdUnidadeForm) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/gerencia/listapp/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeForm
        )
        .then(response => {
          setGerencia(response.data)
        })
    }
  }, [])

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  const [imagens, setImagens] = useState({
    NomeImagem: '',
    Imagem: ''
  })

  function handleChangeNome(event) {
    campos.pesNome = event.target.value
    modalInfo.pesNome = event.target.value
  }

  function handleChangeIdioma(event) {
    campos.pesIdioma = event.target.value
    modalInfo.pesIdioma = event.target.value
  }

  function handleChangeEmail(event) {
    campos.pesEmail = event.target.value
    modalInfo.pesEmail = event.target.value
  }

  function handleChangeSenha(event) {
    campos.pesSenha = event.target.value
    modalInfo.pesSenha = event.target.value
  }

  function handleChangeConfSenha(event) {
    campos.confPesSenha = event.target.value
    modalInfo.confPesSenha = event.target.value
  }

  function handleChangeObservacoes(event) {
    campos.pesObservacoes = event.target.value
    modalInfo.pesObservacoes = event.target.value
  }

  function handleInputChange(event) {
    if (event.target.name === 'confPesSenha') {
      if (event.target.value !== campos.pesSenha) {
        setErrorPass(true)
        setErrorMessage('A confirmação da senha está incorreta')
      } else {
        setErrorMessage('')
        setErrorPass(false)
      }
    }
    else {
      campos[event.target.name] = event.target.value
      setCampos(campos)
    }
    //console.log(campos)
    //if (event.target.name === 'pesSenha') {
    // if (
    //   validator.isStrongPassword(event.target.value, {
    //     minLength: 8,
    //     minLowercase: 1,
    //     minUppercase: 1,
    //     minNumbers: 1,
    //     minSymbols: 1
    //   })
    // ) {
    //   setErrorMessage('')
    //   setErrorPass(false)
    //   if (event.target.value !== campos.confPesSenha) {
    //     setErrorPass(true)
    //     setErrorMessage('A confirmação da senha está incorreta')
    //   } else {
    //     setErrorMessage('')
    //     setErrorPass(false)
    //   }
    // } else {
    //   setErrorPass(true)
    //   setErrorMessage(
    //     'A senha não é segura o bastante, a senha deve conter 1 caracter especial, uma letra maiúscula e um número!'
    //   )
    // }
    //}
    // if (event.target.name === 'confPesSenha') {
    //   if (event.target.value !== campos.pesSenha) {
    //     setErrorPass(true)
    //     setErrorMessage('A confirmação da senha está incorreta')
    //   } else {
    //     setErrorMessage('')
    //     setErrorPass(false)
    //   }
    // }
    // else {
    //   campos[event.target.name] = event.target.value
    //   setCampos(campos)
    // }
  }
  // console.log(campos)
  // console.log(pessoa[0])
  var bloqueio = 0
  function handleFormSubmit(event) {
    event.preventDefault()
    //console.log(modalInfo)
    if (bloqueio === 0) {
      bloqueio = 1
      if (campos.pesSenha !== campos.confPesSenha && (campos.pesSenha !== undefined && campos.confPesSenha !== undefined)) {
        //console.log(campos.pesSenha)
        //console.log(campos.confPesSenha)
        setErrorMessage('A confirmação de senha está incorreta')
        bloqueio = 0
        return
      }
      else {
        setErrorMessage('')

        const formData = new FormData()
        formData.append('file', image)
        var reader = new FileReader()
        if (image) {
          reader.readAsDataURL(image)
          reader.onload = function (fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result
            imagens['Imagem'] = srcData.split(',')[1]
            imagens['NomeImagem'] = uuidv4()
            setImagens(imagens)
          }
          axios
            .post(process.env.REACT_APP_API_URL + '/upload', imagens)
            .then(response => {
              campos.pesFoto = response.data['url']
              modalInfo.pesFoto = response.data['url']
            })
        }

        modalInfo.pesEmail = String(modalInfo.pesEmail).replace(/[áàãâä]/g, 'a')
          .replace(/[éèêë]/g, 'e')
          .replace(/[íìîï]/g, 'i')
          .replace(/[óòõôö]/g, 'o')
          .replace(/[úùûü]/g, 'u')
          .replace(/[ç]/g, 'c')

        campos.pesEmail = modalInfo.pesEmail
        campos.confPesSenha = modalInfo.confPesSenha

        //setCampos(campos)

        //console.log(campos)

        axios
          .post(
            process.env.REACT_APP_API_URL +
            '/api/v1/updatepessoaviewprofile/' + modalInfo.IdPessoa,
            campos
          )
          .then(response => {
            if (response.status === 200) {
              alert("Perfil alterado com sucesso!")
              authService.logout()
              history.push("/")
            } else {
              alert(response.data.message)
            }
          })

        bloqueio = 0
      }
    }
    //console.log(campos)
    // event.preventDefault()
    // const formData = new FormData()
    // formData.append('file', image)

    // if (image) {
    //   axios
    //     .post(process.env.REACT_APP_API_URL + '/upload', formData)
    //     .then(response => {
    //       campos['pesFoto'] = response.data.url
    //       setCampos(campos)
    //     })
    // }
    // axios
    //   .post(
    //     process.env.REACT_APP_API_URL + '/api/v1/pessoa/' + campos.IdPessoa,
    //     campos
    //   )
    //   .then(response => {
    //     if (response.status === 200) {
    //       authService.getCurrentUser()
    //       alert('Alterações de idioma só terão efeito ao logar novamente!')
    //       window.location.reload()
    //     } else {
    //       alert(response.data.message)
    //     }
    //   })
  }

  //console.log(modalInfo)

  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className='modalPadrao-content'>
            <div className='cxTituloPadrao'>
              <h1>
                <Translate>Editar Perfil</Translate>
                <hr className="hrTitleModal" />
              </h1>

              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>

            </div>

            <form onSubmit={handleFormSubmit}>
              <table className="linhaUmCadPessoa">
                <tr>
                  <th>
                    <Translate>Nome</Translate>
                  </th>
                </tr>
              </table>
              <input
                className="inputNome"
                placeholder="Nome"
                type="text"
                required
                defaultValue={campos.pesNome}
                name="pesNome"
                onChange={handleChangeNome}
              />

              <table className="linhaDoisCadPessoa">
                <tr>
                  <th>
                    <Translate>Função</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Idioma</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <select
                      disabled
                      name="IdFuncao"
                      className="fcadastroPessoaFuncion"
                      typeof="text"
                      required
                      defaultValue={campos.IdFuncao.toString()}
                      onChange={handleInputChange}
                    >
                      <option value="0"> Selecione uma Função</option>
                      {funcoes
                        .sort((a, b) => (a.fncDescricao > b.fncDescricao ? 1 : -1))
                        .map(funcao => (
                          <option
                            value={funcao.IdFuncao}
                            selected={funcao.IdFuncao === campos.IdFuncao}
                          >
                            {funcao.fncDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <select
                      name="pesIdioma"
                      className="fcadastroPessoaIdiom"
                      typeof="text"
                      required
                      defaultValue={campos.pesIdioma}
                      onChange={handleChangeIdioma}
                    >
                      <option value="">Selecione o Idioma</option>
                      <option value="pt">Português</option>
                      <option value="en">Inglês</option>
                      <option value="es">Espanhol</option>
                    </select>
                  </td>
                </tr>
              </table>

              <table className="linhaTresCadPessoa">
                <tr>
                  <th>
                    <Translate>Digite um E-mail</Translate>
                  </th>
                </tr>
              </table>
              <table className="linhaDoisCadPessoa">
                <input
                  className="inputCadMail"
                  placeholder="E-Mail"
                  type="text"
                  required
                  style={{ textTransform: 'lowercase' }}
                  defaultValue={campos.pesEmail}
                  id="fmail"
                  name="pesEmail"
                  onChange={handleChangeEmail}
                />
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  {' '}
                  <th>
                    <Translate>Telefone</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Celular</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      disabled
                      placeholder="(__)____-____"
                      type="text"
                      defaultValue={campos.pesTelefone}
                      id="ftelefone"
                      name="pesTelefone"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <input
                      disabled
                      placeholder="(__)____-____"
                      type="text"
                      defaultValue={campos.pesCelular}
                      id="ftelefone2"
                      name="pesCelular"
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  {' '}
                  <th>
                    <Translate>Senha</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Confirmar Senha</Translate>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="password"
                      id="fsenha"
                      name="pesSenha"
                      onChange={handleChangeSenha}
                    />
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    <input
                      type="password"
                      id="fconfsenha"
                      name="confPesSenha"
                      onChange={handleChangeConfSenha}
                    />
                  </td>
                </tr>
              </table>

              <table className="linhaDoisCadPessoa">
                <tr>
                  <th>
                    <Translate>Grupo de Usuários</Translate>
                  </th>
                  <th style={{ paddingLeft: '5px' }}>
                    <Translate>Gerência Responsável</Translate>:
                  </th>
                </tr>
                <tr>
                  <td>
                    <select
                      disabled
                      name="IdGrupoUsuario"
                      className="fcadastroPessoaGroup"
                      type="text"
                      required
                      defaultValue={campos.IdGrupoUsuario}
                      id="fgrupo"
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione um grupo</option>
                      {grupos
                        .sort((a, b) =>
                          a.gruDescricao > b.gruDescricao ? 1 : -1
                        )
                        .map(grupo => (
                          <option
                            value={grupo.IdGrupoUsuario}
                            selected={
                              grupo.IdGrupoUsuario === campos.IdGrupoUsuario
                            }
                          >
                            {grupo.gruDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    {' '}
                    <select
                      disabled
                      name="IdGerencia"
                      className="fcadastroPessoaGroup"
                      type="text"
                      required
                      defaultValue={campos.IdGerencia}
                      id="fgerencia"
                      onChange={handleInputChange}
                    >
                      <option value="0">Selecione uma gerência</option>
                      {gerencias
                        .sort((a, b) =>
                          a.gerDescricao > b.gerDescricao ? 1 : -1
                        )
                        .map(ger => (
                          <option
                            value={ger.IdGerencia}
                            selected={ger.IdGerencia === campos.IdGerencia}
                          >
                            {ger.gerDescricao}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
              </table>

              <table className="linhaQuatroCadPessoa">
                <tr>
                  <th>
                    <Translate>Observações</Translate>
                  </th>
                </tr>
                <textarea
                  className="fcadastroPessoaObs"
                  placeholder="Observações"
                  type="text"
                  defaultValue={campos.pesObservacoes}
                  id="fobs"
                  name="pesObservacoes"
                  row="none"
                  maxLength="500"
                  style={{ textTransform: 'uppercase' }}
                  onChange={handleChangeObservacoes}
                />
              </table>



              <div className="barraSalvar">
                {errorMessage !== '' ? (
                  <span className="AlertPassword">
                    <Translate>{errorMessage}</Translate>
                  </span>
                ) : (
                  <div></div>
                )}
                <button
                  type="submit"
                  className="gravarPessoa"
                  disabled={errorPass}
                >
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>

            <div className="cxPhotoImport">
              {image ? (
                <img
                  //src={padrao}  
                  src={URL.createObjectURL(image)}
                  alt="Logo2"
                  className="imgProfile"
                  width="150"
                  height="150"
                />
              ) : (
                <img
                  src={padrao} alt="Foto" className="imgProfile" />
              )}
              <input
                type="file"
                name="empLogo"
                className="form-control-file-1"
                id="empLogo"
                accept=".gif,.jpg,.jpeg,.png"
                onChange={function (event) {
                  setImage(event.target.files[0])
                }}
              />
            </div>
            <table className="criadoAlteradoPor">
              <tr>
                <td>
                  <Translate>Criado por</Translate>:
                </td>
                <td>{modalInfo.pesCriadoPor}</td>
                <td>em {modalInfo.pesCriadoEm}</td>
              </tr>
              <tr>
                <td>
                  <Translate>Editado por</Translate>:
                </td>
                <td>{modalInfo.pesAlteradoPor}</td>
                <td>em {modalInfo.pesAlteradoEm}</td>
              </tr>
            </table>

          </div>

        </Translator>
      </div>
    </>
  )
}
