import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuTextosAtivadores/modal-textosAtivadores/ModalNovoGrupo.css'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdAtivadorGrupo: modalInfo.IdAtivadorGrupo,
    IdEmpresa: modalInfo.IdEmpresa,
    gpaDescricao: modalInfo.gpaDescricao,
    gpaStatus: modalInfo.gpaStatus,
    gpaObservacoes: modalInfo.gpaObservacoes,
    gpaAlteradoPor: user[0].pesEmail,
    gpaAlteradoEm: newDate
  })
  function handleInputChange(event) {
    if (event.target.name === 'gpaStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/ativadorgrupo/' +
          modalInfo.IdAtivadorGrupo,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <Translator
      cacheProvider={cacheProvider}
      from="pt"
      to={user[0].pesIdioma}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <>
        <div className="overlay">
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Grupo de Ativadores</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>

            <form onSubmit={handleFormSubmit}>
              <label>
                <Translate>Descrição</Translate>:
              </label>
              <input
                placeholder="Descrição"
                type="text"
                id="fname"
                defaultValue={campos.gpaDescricao}
                name="gpaDescricao"
                onChange={handleInputChange}
              ></input>

              <label>
                <Translate>Observação</Translate>:
              </label>
              <textarea
                placeholder="Observações"
                type="text"
                id="fname"
                name="gpaObservacoes"
                defaultValue={campos.gpaObservacoes}
                onChange={handleInputChange}
                rows={5}
                maxLength="500"
              ></textarea>

              <div className="caixaSalvarPadrao">
                <div className="InputAuthorization4">
                  <label>
                    <input
                      name="gpaStatus"
                      type="checkbox"
                      onChange={handleInputChange}
                      defaultChecked={!!campos.gpaStatus}
                    />
                    <span>
                      <Translate>Status</Translate>
                    </span>
                  </label>
                </div>
                <button type="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Translator>
  )
}
