import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import authService from './auth.service'

function Login() {
  sessionStorage.clear()
  
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show] = useState(false)

  const loggedIn = localStorage.getItem('auth')
  if (loggedIn) {
    const logado = JSON.parse(loggedIn)
    if (logado['loggedIn']) {
      history.push('/Home')
      window.location.reload()
    }
  }
  function handleFormSubmit(event: any) {
    event.preventDefault()
    authService.login(email, password).then(
      response => {
        if (response.status === 200) {
          const userStr = localStorage.getItem("user");
          if(userStr)
          {
            if(parseInt(JSON.parse(userStr)[0]["IdGrupoUsuario"]) === 0)
            {
              //console.log(userStr)
              alert("Usuário sem permissão de acesso!")
              return
            }
            else
            {
              sessionStorage.TelaMenuPrincipal = 'Home'
              history.push('/Home')
              window.location.reload()
            }
          }
        } else {
            alert(response.data.message)
          }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        alert(resMessage)
      }
    )
  }
  sessionStorage.searchTerm = ""
  return (
    <div className="login">
      <div className="bg1" />
      <div className="bg2" />

      <div className="login-right">
        <div id="tittleLogin">
          <h3 id="titleLogin1">Valorização da vida em tempo real</h3>

          <hr className="hrLogin" />
        </div>

        <form onSubmit={handleFormSubmit}>
          <input
            required
            id="usuario"
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <input
            required
            id="senha"
            placeholder="Senha"
            type={show ? 'text' : 'password'}
            value={password}
            autoComplete={'current-password'}
            onChange={e => setPassword(e.target.value)}
          />
          <button className="enter-app">Entrar</button>

          <div className="alignPassword">
            <div className="recordPassword">
              <input
                type="checkbox"
                placeholder="Lembrar senha!"
                defaultChecked={false}
              />
              <label>Lembra senha</label>
            </div>
            <Link to="/forgot">
              <label className="btnrecovery">Esqueci a senha</label>
            </Link>
          </div>
        </form>
      </div>

      <div className="bg3" />
    </div>
  )
}
export default Login
