import { Redirect, Route, Switch } from 'react-router-dom'
import forgot from './Components/Login/forgotPass'
import index from './Components/Login/index'
import reset from './Components/Login/reset'
import ViewProfile from './Components/Login/ViewProfile'
import FormularioCadastro from './pages/FormularioCadastro'
import Home from './pages/Home'
import ObservacoesApontar from './pages/MenuDireito/ApontarObservacao/ObservacoesApontar'
import ObservacoesListar from './pages/MenuDireito/ApontarObservacao/ObservacoesListar'
import ObservacoesPessoa from './pages/MenuDireito/ApontarObservacao/ObservacoesPessoa'
import PreenchimentoObservacao from './pages/MenuDireito/ApontarObservacao/PreenchimentoObservacao'
import RetornoQuestao from './pages/MenuDireito/ApontarObservacao/RetornoQuestao'
import RetornoQuestaoAtivador from './pages/MenuDireito/ApontarObservacao/RetornoQuestaoAtivador'
import SelecionarArea from './pages/MenuDireito/ApontarObservacao/SelecionarArea'
import SelecionarUnidade from './pages/MenuDireito/ApontarObservacao/SelecionarUnidade'
//import CadAdmin from './pages/MenuDireito/MenuDireito-Modal/ModalCadAdmin'
import Area from './pages/MenuDireito/Area'
import Atividades from './pages/MenuDireito/Atividades'
import Equipes from './pages/MenuDireito/Equipes'
import Gerencias from './pages/MenuDireito/Gerencias'
import MetaLocal from './pages/MenuDireito/LocalMeta'
import Observacoes from './pages/MenuDireito/Observacoes'
import MetaPessoa from './pages/MenuDireito/PessoaMeta'
import RelatorioICS from './pages/MenuDireito/RelatorioICS'
import Unidades from './pages/MenuDireito/Unidades'
import Pessoas from './pages/Pessoas'
import Funcoes from './pages/Funcoes'
import ObservCpto from './pages/Relatorio/DetalhesDaObservação/ObservCpto'
import Relatorio from './pages/Relatorio/Relatorio'
import MesSetor from './pages/Relatorio/UnidadesCadastradas/MesSetor'
import UniClienteExe from './pages/Relatorio/UnidadesCadastradas/UniClienteExe'
import UniClieSetor from './pages/Relatorio/UnidadesCadastradas/UniClieSetor'
import UniGerencia from './pages/Relatorio/UnidadesCadastradas/UniGerencia'
import UniUnidades from './pages/Relatorio/UnidadesCadastradas/UniUnidades'
import UniContribSetor from './pages/Relatorio/UnidadesCadastradas/UniContribSetor'
import SubMenuEmpresas from './pages/SubMenuPages/SubMenuEmpresas/SubMenuEmpresas'
import SubMenuFuncaoEPermissao from './pages/SubMenuPages/SubMenuFunçõesEPermissoes/SubMenuFuncoesEPermissoes'
import PermissoesDeacessoAoSistema from './pages/SubMenuPages/SubMenuGruposDeUsuario/pages-GruposDeUsuario/PermissoesDeAcessoAoSistema'
import SubMenuGruposUsuarios from './pages/SubMenuPages/SubMenuGruposDeUsuario/SubMenuGruposUsuarios'
import SubMenuPessoas from './pages/SubMenuPages/SubMenuPessoas/SubMenuPessoas'
import VinculoUnidade from './pages/SubMenuPages/SubMenuPessoas/VinculoUnidade'
import SubMenuTextActive from './pages/SubMenuPages/SubMenuTextosAtivadores/SubMenuTextosAtivadores'
import Ativadores from './pages/SubMenuPages/SubMenuTextosAtivadores/TextosAtivadores/Ativadores'
import TQOQuestoesReg from './pages/SubMenuPages/SubMenuTiposDeobservaçoes/Questões-tiposDeQuestões/TQOQuestoesReg'
import SubMenuTipoDeObservacao from './pages/SubMenuPages/SubMenuTiposDeobservaçoes/SubMenuTipoDeObservacao'
import tipoQualidadeDeObservacao from './pages/SubMenuPages/SubMenuTiposDeobservaçoes/tipoQualidadeDeObservacao'

function Routes() {
  const permissoes = localStorage.getItem('permissoes')
  let permissao = []
  if (permissoes) {
    permissao = JSON.parse(permissoes)
  } else {
    permissao = [
      {
        perModulo: 'ACECAD',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEQP',
        perAcesso: 1
      },
      {
        perModulo: 'ACEPES',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGER',
        perAcesso: 1
      },
      {
        perModulo: 'ACEATV',
        perAcesso: 1
      },
      {
        perModulo: 'ACEARE',
        perAcesso: 1
      },
      {
        perModulo: 'ACEOBS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEDAS',
        perAcesso: 1
      },
      {
        perModulo: 'ACEREL',
        perAcesso: 1
      },
      {
        perModulo: 'ACEEMP',
        perAcesso: 1
      },
      {
        perModulo: 'ACETPO',
        perAcesso: 1
      },
      {
        perModulo: 'ACETXA',
        perAcesso: 1
      },
      {
        perModulo: 'ACEGPU',
        perAcesso: 1
      },
      {
        perModulo: 'ACEFUN',
        perAcesso: 1
      }
    ]
  }
  let ACECAD = permissao.find((element: any) => {
    return element.perModulo === 'ACECAD'
  })
  let ACEEQP = permissao.find((element: any) => {
    return element.perModulo === 'ACEEQP'
  })
  let ACEPES = permissao.find((element: any) => {
    return element.perModulo === 'ACEPES'
  })
  let ACEGER = permissao.find((element: any) => {
    return element.perModulo === 'ACEGER'
  })
  let ACEATV = permissao.find((element: any) => {
    return element.perModulo === 'ACEATV'
  })
  let ACEARE = permissao.find((element: any) => {
    return element.perModulo === 'ACEARE'
  })
  let ACEOBS = permissao.find((element: any) => {
    return element.perModulo === 'ACEOBS'
  })
  let ACEDAS = permissao.find((element: any) => {
    return element.perModulo === 'ACEDAS'
  })
  let ACEREL = permissao.find((element: any) => {
    return element.perModulo === 'ACEREL'
  })
  let ACEEMP = permissao.find((element: any) => {
    return element.perModulo === 'ACEEMP'
  })
  let ACETPO = permissao.find((element: any) => {
    return element.perModulo === 'ACETPO'
  })
  let ACETXA = permissao.find((element: any) => {
    return element.perModulo === 'ACETXA'
  })
  let ACEGPU = permissao.find((element: any) => {
    return element.perModulo === 'ACEGPU'
  })
  let ACEFUN = permissao.find((element: any) => {
    return element.perModulo === 'ACEFUN'
  })
  
  return (
    <Switch>
      {/* <Route path="/api/v1/login" render={() => {
        return <Redirect  to="https://apimeucpto.hitperformance.com.br/api/v1/login" />
      }} /> */}

      <Route path="/" exact component={index} />
      <Route path="/forgot" exact component={forgot} />
      <Route path="/reset/:codigo" exact component={reset} />
      <Route path="/ViewProfile" component={ViewProfile}></Route>
      {/* ------------ menu princial ----------------------------*/}

      {ACEREL.perAcesso && <Route path="/Home" component={Home}></Route>}

      {ACECAD.perAcesso && (
        <Route path="/FormularioCadastro" component={FormularioCadastro} />
      )}

      {ACEPES.perAcesso && <Route path="/Pessoas" component={Pessoas} />}

      {/* {ACEPES.perAcesso && <Route path="/CadAdmin/:IdEmpresa" component={CadAdmin} ></Route>} */}

      {/* {ACEPES.perAcesso && (
        <Route path="/CadAdmin/:IdEmpresa" component={CadAdmin} />
        )} */}

      {ACEPES.perAcesso && (
        <Route path="/MetaPessoa/:IdPessoa" component={MetaPessoa} />
      )}

      {ACEREL.perAcesso && <Route path="/Relatorio" component={Relatorio} />}

      {/* ------------ menu sideBar direita---------------------- */}

      {ACEOBS.perAcesso && (
        <Route path="/Observacoes" component={Observacoes} />
      )}

      {ACEEQP.perAcesso && <Route path="/Equipes" component={Equipes} />}

      {ACEGER.perAcesso && (
        <Route path="/Gerencias" component={Gerencias}></Route>
      )}

      {ACEEMP.perAcesso && (
        <Route path="/Unidades/:IdEmpresa" component={Unidades}></Route>
      )}

      {ACEARE.perAcesso && <Route path="/Area" component={Area}></Route>}

      {ACEARE.perAcesso && (
        <Route path="/MetaArea/:IdLocal" component={MetaLocal}></Route>
      )}

      {ACEATV.perAcesso && (
        <Route path="/Atividades" component={Atividades}></Route>
      )}

      {/* -------- relaçao de paginas da tela relatório ------------*/}

      {ACEREL.perAcesso && (
        <Route
          path="/UniClienteExe/:IdUnidade"
          component={UniClienteExe}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/UniClieSetor/:IdUnidade/:ano/:mes/:id_gerencia_local"
          component={UniClieSetor}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/UniGerencia/:IdUnidade/:ano/:mes"
          component={UniGerencia}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/UniUnidades/:ano/:mes"
          component={UniUnidades}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/MesSetor/:IdUnidade/:IdLocal"
          component={MesSetor}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/UniContribSetor/:IdLocal"
          component={UniContribSetor}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/ObservCpto/:IdUnidade/:IdRetornoObservacao"
          component={ObservCpto}
        ></Route>
      )}

      <Route
        path="/RelatorioICS/:IdUnidade/:IdRetornoObservacao"
        component={RelatorioICS}
      ></Route>

      {/* -------- relaçao de paginas do apontamento de observação ------------*/}

      {ACEOBS.perAcesso && (
        <Route
          path="/ApontarObservacao/SelecionarUnidade"
          component={SelecionarUnidade}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/ApontarObservacao/SelecionarArea/:id"
          component={SelecionarArea}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/ApontarObservacao/ObservacoesApontar/:idUni/:idAr"
          component={ObservacoesApontar}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/ApontarObservacao/ObservacoesListar"
          component={ObservacoesListar}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/ObservacoesPessoa/:DataInicio/:DataFim/:IdPessoa/:IdObservacaoTipo/:IdUnidade"
          component={ObservacoesPessoa}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/ApontarObservacao/PreenchimentoObservacao/:idUni/:idAr"
          component={PreenchimentoObservacao}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/RetornoQuestao/:idUni/:idAr/:idObs"
          component={RetornoQuestao}
        ></Route>
      )}

      {ACEOBS.perAcesso && (
        <Route
          path="/RetornoQuestaoAtivador/:idObs/:idQue/:nota"
          component={RetornoQuestaoAtivador}
        ></Route>
      )}

      {/* ---------------- SUB MENU Gerencia----------------- */}

      {ACETXA.perAcesso && (
        <Route path="/SubMenuTextActive" component={SubMenuTextActive}></Route>
      )}

      {ACEPES.perAcesso && (
        <Route path="/SubMenuPessoas/:IdEmpresa" component={SubMenuPessoas}></Route>
      )}

      {ACEPES.perAcesso && (
        <Route
          path="/VinculoUnidade/:IdPessoa"
          component={VinculoUnidade}
        ></Route>
      )}

      {ACEEMP.perAcesso && (
        <Route path="/SubMenuEmpresas" component={SubMenuEmpresas}></Route>
      )}

      {ACETPO.perAcesso && (
        <Route
          path="/SubMenuTipoDeObservacao"
          component={SubMenuTipoDeObservacao}
        ></Route>
      )}

      {ACEGPU.perAcesso && (
        <Route
          path="/SubMenuGruposUsuarios"
          component={SubMenuGruposUsuarios}
        ></Route>
      )}

      {ACEFUN.perAcesso && (
        <Route
          path="/SubMenuFuncaoEPermissao"
          component={SubMenuFuncaoEPermissao}
        ></Route>
      )}

      {ACEFUN.perAcesso && (
        <Route
          path="/Funcoes"
          component={Funcoes}
        ></Route>
      )}

      {/*--------------acesso do sub Menu (tipo de observaçao)-------------*/}

      {ACEREL.perAcesso && (
        <Route
          path="/tipoQualidadeDeObservacao/:IdObservacaoGrupo"
          component={tipoQualidadeDeObservacao}
        ></Route>
      )}

      {ACEREL.perAcesso && (
        <Route
          path="/TQOQuestoesReg/:IdObservacaoQuestao"
          component={TQOQuestoesReg}
        ></Route>
      )}

      {ACEGPU.perAcesso && (
        <Route
          path="/PermissoesDeacessoAoSistema/:IdGrupoUsuario"
          component={PermissoesDeacessoAoSistema}
        ></Route>
      )}

      {ACEATV.perAcesso && (
        <Route
          path="/Ativadores/:IdAtivadorGrupo"
          component={Ativadores}
        ></Route>
      )}
    </Switch>
  )
}

export default Routes
