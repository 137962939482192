import axios from 'axios'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import authService from '../../Components/Login/auth.service'
import logologo from '../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../Components/Main'
import padrao from '../../Components/profile.png'
import imgOpen from '../../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../Components/SideBar-right/SidebarMobile'
import ModalCriar from './MenuDireito-Modal/ModalCadMetaLocal'
import Modal from './MenuDireito-Modal/ModalEdiMetaLocal'

function MetaLocal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  let id = useParams()
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  sessionStorage.MetaLocal = id['IdLocal']
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  let history = useHistory()
  const [Metas, setMetas] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  var user = authService.getCurrentUser()
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/metalocal/list/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeEdit +
        '/' +
        id['IdLocal']
      )
      .then(response => {
        setMetas(response.data)
      })
  }, [])
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const [toogle, setToogle] = React.useState(false)

  return (
    <div className="displayInteract">
      {modalOpen && <Modal setOpenModal={setModalOpen} modalInfo={modalInfo} />}
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />

            <div></div>
          </div>{' '}
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {sessionStorage.uniRazaoSocial} /
                <Translate> DADOS CADASTRAIS / META LOCAL-ÁREA</Translate>:
              </TitlePage1>
              <TitlePage2>
                <Translate>Metas</Translate>
              </TitlePage2>
            </TitleOfPage>

            <div id="containerPesquisaModal">
              {' '}
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <ModalCriar />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="tabelaPadrao">
            <table>
              <tr>
                {/* <th>CÓD</th> */}
                <th>
                  <Translate>Tipo observação</Translate>
                </th>
                <th>
                  <Translate>Quantitativa</Translate>
                </th>
                <th>
                  <Translate>Qualitativa</Translate>
                </th>
                <th>
                  <Translate>Ativo</Translate>
                </th>
                <th>
                  <Translate>Registro</Translate> /
                  <br /> <Translate>Atualização</Translate>
                </th>
              </tr>
              {Metas.filter(val => {
                if (searchTerm === '') {
                  return val
                } else if (
                  val.otpDescricao
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return val
                }
              }).map(meta => (
                <tr key={meta.IdMetaLocal}>
                  {/* <td className="tblPASCod">{meta.IdMetaLocal}</td> */}
                  <td onClick={() => handleToggleComplete(meta)}>
                    <Translate> {meta.otpDescricao}</Translate>
                  </td>
                  <td onClick={() => handleToggleComplete(meta)}>
                    {meta.mtlQuantitativa}
                  </td>
                  <td onClick={() => handleToggleComplete(meta)}>
                    {meta.mtlQualitativa}
                  </td>
                  <td onClick={() => handleToggleComplete(meta)}>
                    <label
                      className="container"
                      onClick={() => handleToggleComplete(meta)}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={!!meta.mtlStatus}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </label>
                  </td>
                  <td onClick={() => handleToggleComplete(meta)}>
                    {Moment(meta.mtlAlteradoEm).isValid()
                      ? Moment(meta.mtlAlteradoEm).format('DD/MM/YYYY')
                      : Moment(
                        meta.mtlAlteradoEm,
                        'DD/MM/YYYY HH:mm:ss'
                      ).format('DD/MM/YYYY')}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <SideBarDireita />
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default MetaLocal
