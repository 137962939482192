import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import * as TypIcons from 'react-icons/ti'
import authService from '../../Components/Login/auth.service'
import logologo from '../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../Components/Main'
import padrao from '../../Components/profile.png'
import imgOpen from '../../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../Components/SideBar-right/SidebarMobile'

function Unidades() {
  let id = useParams()
  const history = useHistory()
  var user = authService.getCurrentUser()
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }

  const [image, setImage] = useState('')
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }

  const [searchTerm, setSearchTerm] = useState('')
  const [unidades, setUnidades] = useState([])
  const [empresa, setEmpresa] = useState([])
  //obtem unidades
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/unidade/listv2/' +
        id['IdEmpresa']
      )
      .then(response => {
        setUnidades(response.data)
      })
  }, [])
  //obtem empresa
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/empresa/' +
        id['IdEmpresa']
      )
      .then(response => {
        setEmpresa(response.data)
      })
  })

  function handleUnidadeChange(event, unidade) {

  }

  return (
    <div className='displayInteract'>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          {' '}
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                <Translate>CADASTRO DE UNIDADES</Translate>:
              </TitlePage1>
              <TitlePage2>{empresa.map(emp => (emp.empRazaoSocial))}</TitlePage2>
            </TitleOfPage>

            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fnameUnidade"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <button className="buttonModalPadrao" type="button">
                <Translate>Nova Unidade</Translate>
              </button>
            </div>
          </div>

          <div className="tabelaPadrao">
            <table>
              <tr>
                <th><Translate>Código</Translate></th>
                <th>
                  <Translate>Licenças</Translate>
                </th>
                <th>
                  <Translate>Unidade</Translate>
                </th>
                <th>
                  <Translate>Status</Translate>
                </th>
              </tr>
              {unidades
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.uniNome
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(unidade => (
                  <tbody key={unidade.IdUnidade}>
                    <td>{unidade.IdUnidade}</td>
                    <td>{unidade.uniLicencas}</td>
                    <td>{unidade.uniNome}</td>
                    <td>
                      {/* {unidade.uniStatus} */}
                      <input
                        type="checkbox"
                        checked={!!unidade.uniStatus}
                        onChange={event => {
                          handleUnidadeChange(event, unidade)
                        }}
                      />
                      {/* <i className="far fa-check-circle"></i> */}
                    </td>
                  </tbody>
                ))}
            </table>
          </div>

          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          {/* <SideBarDireita /> */}
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Unidades
