import axios from 'axios'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import { Link } from 'react-router-dom'
import authService from '../Components/Login/auth.service'
import logologo from '../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  //TitlePage24,
  TitlePage8
} from '../Components/Main'
import padrao from '../Components/profile.png'

import imgOpen from '../Images/imgButtonEsquerda/imgOpen.svg'
import SideBarDireita from '../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../Components/SideBar-right/SidebarMobile'
import Modal from './MenuDireito/MenuDireito-Modal/ModalCadPessoa'
import ModalEditar from './MenuDireito/MenuDireito-Modal/ModalEdiPessoa'

function Pessoas() {
  sessionStorage.TelaMenuLateral = 'Pessoas'
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  const [modalOpen, setModalOpen] = useState(false)
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  //var user = authService.getCurrentUser()
  const [pessoas, setPessoas] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  // console.log("pessoa form "+sessionStorage.IdUnidadeForm)
  // console.log("pessoa edit "+sessionStorage.IdUnidadeEdit)
  // console.log("idempresa "+user[0].IdEmpresa)
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/pessoaV2/list/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeForm
        )
        .then(response => {
          //console.log("IdUnidadeForm "+sessionStorage.IdUnidadeForm)
          setPessoas(response.data)
        })
    }
    else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          '/api/v1/pessoaV2/list/' +
          user[0].IdEmpresa +
          '/' +
          sessionStorage.IdUnidadeEdit
        )
        .then(response => {
          //console.log("IdUnidadeEdit "+sessionStorage.IdUnidadeEdit)
          //console.log(response.data)
          setPessoas(response.data)
        })
    }

  }, [])
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  return (
    <div className="displayInteract">
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>

          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {parseInt(sessionStorage.IdUnidadeForm) === 0 ? (sessionStorage.uniRazaoSocial) : (sessionStorage.uniRazaoSocialForm)} /{' '}
                <Translate> DADOS CADASTRAIS / PESSOAS</Translate>:
              </TitlePage1>

              {/* <TitlePage24>{sessionStorage.uniRazaoSocial}</TitlePage24> */}
              <TitlePage8>
                <Translate>CADASTRO DE PESSOAS: </Translate>
              </TitlePage8>
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname1"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <Modal />
            </div>
          </div>
          {modalOpen && (
            <ModalEditar setOpenModal={setModalOpen} modalInfo={modalInfo} />
          )}

          <div className="tabelaPadrao">
            <table>
              <tr>
                <th>
                  CÓD
                </th>
                <th>
                  <Translate>Metas</Translate>
                </th>
                <th>
                  <Translate>Observador</Translate>
                </th>
                <th>
                  <Translate>E-Mail/login</Translate>
                </th>
                <th>
                  <Translate>Função</Translate>
                </th>
                <th>
                  <Translate>Gerência</Translate>
                </th>
                <th>
                  <Translate>Registro</Translate> /
                  <br /> <Translate>Atualização</Translate>
                </th>
                <th>
                  <Translate>Acesso App</Translate>
                </th>
                <th>
                  <Translate>Acesso Web</Translate>
                </th>
                <th>
                  <Translate>Ativo</Translate>
                </th>

              </tr>
              {pessoas
                // eslint-disable-next-line array-callback-return
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.pesNome
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.pesEmail
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(pessoa => (
                  <tr key={pessoa.IdPessoa}>
                    <td>
                      {pessoa.IdPessoa}
                    </td>

                    <td>
                      {' '}
                      <Link to={`/MetaPessoa/${pessoa.IdPessoa}`}>
                        <button className="metaAcess">
                          <i className="fas fa-bars"></i>
                        </button>
                      </Link>
                    </td>

                    <td style={{
                      textTransform: 'uppercase'
                    }} onClick={() => handleToggleComplete(pessoa)}>

                      {pessoa.pesNome}
                    </td>

                    <td style={{
                      textTransform: 'lowercase'
                    }} onClick={() => handleToggleComplete(pessoa)}>
                      {pessoa.pesEmail}
                    </td>

                    <td style={{
                      textTransform: 'uppercase'
                    }} onClick={() => handleToggleComplete(pessoa)}>
                      {pessoa.fncDescricao}
                    </td>

                    <td style={{
                      textTransform: 'uppercase'
                    }} onClick={() => handleToggleComplete(pessoa)}>
                      {pessoa.gerDescricao}
                    </td>

                    <td onClick={() => handleToggleComplete(pessoa)}>
                      {' '}
                      {Moment(pessoa.pesAlteradoEm).isValid()
                        ? Moment(pessoa.pesAlteradoEm).format('DD/MM/YYYY')
                        : Moment(
                          pessoa.pesAlteradoEm,
                          'DD/MM/YYYY HH:mm:ss'
                        ).format('DD/MM/YYYY')}
                    </td>

                    <td width={50}>
                      <label className="btnMGUPermissoes" >
                        <input
                          type="checkbox"
                          defaultChecked={!!pessoa.pesAcessoApp}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>

                    <td width={50}>
                      <label className="btnMGUPermissoes" >
                        <input
                          type="checkbox"
                          defaultChecked={!!pessoa.pesAcessoWeb}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>

                    <td>
                      <label className="btnMGUPermissoes">
                        <input
                          type="checkbox"
                          defaultChecked={!!pessoa.pesStatus}
                          disabled
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>

                  </tr>
                ))}
            </table>
            {pessoas.length === 0 ? (
              <p className="noObs">Carregando informações...</p>
            ) : (
              ''
              // <p className="noObs">
              //   <Translate>
              //     Não há observações para o período selecionado.
              //   </Translate>
              // </p>
            )}
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>

        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          <SideBarDireita />
          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default Pessoas
