import PropTypes from 'prop-types'
import React from 'react'
import ApexCharts from 'apexcharts'

import {
  Cell,
  //Legend,
  Pie,
  PieChart,
  //RadialBar,
  //RadialBarChart,
  Tooltip
} from 'recharts'
//import { render } from '@testing-library/react'
// const data =[{"IdAtivadorGrupo":1,"gpaDescricao":"Sociais","quantidade":6},{"IdAtivadorGrupo":4,"gpaDescricao":"Cognitivos","quantidade":8},{"IdAtivadorGrupo":22,"gpaDescricao":"Pessoal","quantidade":2}];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  startAngle,
  endAngle,
  innerRadius,
  outerRadius,
  percent,
  name
}) => {
  const radius = 10 + innerRadius + (outerRadius - innerRadius)
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <>
      <text
        className="piecha rtStyle"
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name}`} - {`${(percent * 100).toFixed(2)}%`}
      </text>
    </>
  )
}
// =============================================================== Grafico Oficial=================================================================================

export default function App({ data }) {
  return (
    <>
      {/* <h2 style={{ position: 'relative' }}>Ativadores</h2> */}
      <PieChart
        // style={{
        //   position: 'relative',
        //   // background: '#ff0000',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   textAlign: 'center',
        //   cursor: 'default',
        //   width: '100%',
        //   height: '120px'
        // }}
        width={300}
        height={100}
      >
        <Pie
          className="piechartStyle"
          data={data}
          labelLine={true}
          label={renderCustomizedLabel}
          // fill="#8884d8"
          outerRadius={'65%'}
          dataKey="quantidade"
          nameKey="gpaDescricao" 
          isAnimationActive={false} 
          animationDuration={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* {data.map((entry, index) => (
        <Tooltip
          content={function (value) {
            if (value.payload[0]) {
              // console.log(value)
              return ` ${(
                (180 / 100) *
                (value.payload[0].payload.quantidade /
                  value.payload[0].payload.total) *
                100
              ).toFixed(2)}%`
            } else {
              return ''
            }
          }}
        />
      ))} */}
        <Tooltip />
      </PieChart>
    </>
  )
}
App.propTypes = {
  data: PropTypes.array
}

//==============================================================================================================================================================

// ===================================================================================================================================
// const circle = document.querySelector('.progress')
// const radius = circle.r.baseVal.value
// const circumference = radius * 2 * Math.PI

// circle.style.strokeDasharray = `${circumference} ${circumference}`
// circle.style.strokeDashoffset = `${circumference}`

// function setProgress(percent) {
//   const offset = circumference - (percent / 100) * circumference
//   circle.style.strokeDashoffset = offset
// }

// const input = document.querySelector('input')
// setProgress(input.value)

// input.addEventListener('change', function (e) {
//   if (input.value < 101 && input.value > -1) {
//     setProgress(input.value)
//   }
// })

// <>
//   <svg class="progress-ring" width="120" height="120">
//     <circle
//       class="progress"
//       stroke="white"
//       stroke-width="4"
//       fill="transparent"
//       r="52"
//       cx="60"
//       cy="60"
//     />
//   </svg>

//   <input
//     value="35"
//     type="number"
//     step="5"
//     min="0"
//     max="100"
//     placeholder="progress"
//   />
// </>
// ====================================================================================================================================
// import React, { Component } from 'react'
// import Chart from 'react-apexcharts'

// class App extends Component {
//   constructor({ data }) {
//     super(data)

//     this.state = {
//       options: {
//         chart: {
//           id: 'basic-bar'
//         },
//         xaxis: {
//           categories: []
//         }
//       },
//       series: [
//         {
//           name: 'series-1',
//           data: []
//         }
//       ]
//     }
//   }

//   render() {
//     return (
//       <div className="app">
//         <div className="row">
//           <div className="mixed-chart">
//             <Chart
//               options={this.state.options}
//               series={this.state.series}
//               type="bar"
//               width="500"
//             />
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// export default App
//=====================================================================================================================================

// export default function App({ data }) {
//   return (
//     <>
//       <h4
//         style={{
//           position: 'absolute',
//           top: '0',
//           display: 'flex',
//           alignItems: 'center',
//           widht: '100%',
//           height: '20%',
//           fontFamily: 'BalooBhaina2-Medium',
//           fontSize: '12px',
//           color: '#808080'
//           // backgroundColor: '#0000ff'
//         }}
//       >
//         ATIVADORES
//       </h4>
//       <RadialBarChart
//         style={{
//           position: 'relative',
//           top: '20%'
//           // background: '#ff0000'
//         }}
//         data={data}
//         width={220}
//         height={250}
//         innerRadius="10%"
//         outerRadius="80%"
//         startAngle={180}
//         endAngle={0}
//         fontSize={'clamp(0.1em, 0.1em + 1vw, 1em)'}
//       >
//         <RadialBar
//           labelLine={false}
//           background
//           label={{ fill: '#2e2e2e', position: 'insideStart' }}
//           // fill="#8884d8"
//           dataKey="quantidade"
//           nameKey="gpaDescricao"

//           // endAngle={COLORS.length}
//           // endAngle={0}
//         >
//           {data.map((entry, index) => (
//             <Cell
//               // content={console.log(COLORS)}
//               // endAngle={0}
//               // endAngle={index % COLORS.length}
//               fill={COLORS[index % COLORS.length]}
//             />
//           ))}
//         </RadialBar>
//         {/* <Legend /> */}
//         {/* <Legend
//           iconSize={15}
//           width={'100%'}
//           height={70}
//           layout="horizontal"
//           align="left"
//           style={{ position: 'absolute', alignItems: 'Bottom' }}
//         /> */}
//         {/* {data.map(
//           (renderLegend = props => {
//             const { payload } = props

//             return (
//               <ul>
//                 {payload.map((entry, index) => (
//                   <li key={`item-${index}`}>{entry.value}</li>
//                 ))}
//               </ul>
//             )
//           })
//         )} */}
//         {/* <Legend content={renderLegend} /> */}
//         {/* <Tooltip /> */}
//         {data.map((entry, index) => (
//           <Tooltip
//             content={function (value) {
//               if (value.payload[0]) {
//                 // console.log(value)
//                 return ` ${(
//                   (180 / 100) *
//                   (value.payload[0].payload.quantidade /
//                     value.payload[0].payload.total) *
//                   100
//                 ).toFixed(2)}%`
//               } else {
//                 return ''
//               }
//             }}
//           />
//         ))}
//       </RadialBarChart>
//       {/* <ul
//         style={{
//           position: 'absolute',
//           display: 'flex',
//           flexDirection: 'column',
//           width: '100%',
//           height: '40%',
//           left: '5px',
//           bottom: '10px',
//           gap: '2px',
//           textAlign: 'left',
//           listStyle: 'none',
//           zIndex: -1
//         }}
//       >
//         <li
//           style={{
//             position: 'relative',
//             display: 'flex',
//             alignItems: 'center',
//             widht: '100%',
//             height: '20%',
//             fontFamily: 'BalooBhaina2-Medium',
//             fontSize: '12px',
//             color: '#808080'
//             // backgroundColor: '#0000ff'
//           }}
//         >
//           ATIVADORES
//         </li>
//         <li
//           style={{
//             position: 'relative',
//             display: 'flex',
//             alignItems: 'center',
//             widht: '100%',
//             height: '20%',
//             fontFamily: 'BalooBhaina2-Medium',
//             fontSize: '12px',
//             color: '#808080'
//             // backgroundColor: '#0000ff'
//           }}
//         >
//           <div
//             style={{
//               width: '15px',
//               height: '15px',
//               backgroundColor: 'rgb(255, 128, 66)',
//               marginRight: '5px'
//             }}
//           ></div>{' '}
//           <span
//             style={{
//               position: 'relative',
//               display: 'flex',
//               height: '100%',
//               alignItems: 'center',
//               color: '#808080'
//               // backgroundColor: 'rgb(255, 128, 66)'
//             }}
//           >
//             Fisiológicos
//           </span>
//         </li>
//         <li
//           style={{
//             position: 'relative',
//             display: 'flex',
//             alignItems: 'center',
//             widht: '100%',
//             height: '20%',
//             fontFamily: 'BalooBhaina2-Medium',
//             fontSize: '12px',
//             color: '#808080'

//             // backgroundColor: '#0000ff'
//           }}
//         >
//           <div
//             style={{
//               width: '15px',
//               height: '15px',
//               backgroundColor: 'rgb(255, 187, 40)',
//               marginRight: '5px'
//             }}
//           ></div>
//           <span
//             style={{
//               position: 'relative',
//               display: 'flex',
//               height: '100%',
//               alignItems: 'center',
//               color: '#808080'
//               // backgroundColor: 'rgb(255, 187, 40)'
//             }}
//           >
//             Psicológicos
//           </span>
//         </li>
//         <li
//           style={{
//             position: 'relative',
//             display: 'flex',
//             alignItems: 'center',
//             widht: '100%',
//             height: '20%',
//             fontFamily: 'BalooBhaina2-Medium',
//             fontSize: '12px',
//             color: '#808080'

//             // backgroundColor: '#0000ff'
//           }}
//         >
//           <div
//             style={{
//               width: '15px',
//               height: '15px',
//               backgroundColor: 'rgb(0, 196, 159)',
//               marginRight: '5px'
//             }}
//           ></div>
//           <span
//             style={{
//               position: 'relative',
//               display: 'flex',
//               height: '100%',
//               alignItems: 'center',
//               color: '#808080'
//               // backgroundColor: 'rgb(0, 196, 159)'
//             }}
//           >
//             Cognitivos
//           </span>
//         </li>
//         <li
//           style={{
//             position: 'relative',
//             display: 'flex',
//             alignItems: 'center',
//             widht: '100%',
//             height: '20%',
//             fontFamily: 'BalooBhaina2-Medium',
//             fontSize: '12px',
//             color: '#808080'

//             // backgroundColor: '#0000ff'
//           }}
//         >
//           <div
//             style={{
//               width: '15px',
//               height: '15px',
//               backgroundColor: 'rgb(0, 136, 254)',
//               marginRight: '5px'
//             }}
//           ></div>
//           <span
//             style={{
//               position: 'relative',
//               display: 'flex',
//               height: '100%',
//               alignItems: 'center',
//               color: '#808080'
//               // backgroundColor: 'rgb(0, 136, 254)'
//             }}
//           >
//             Sociais
//           </span>
//         </li>
//       </ul> */}
//     </>
//   )
// }

// export default function App({ data }) {
//   return (
//     <>
//       <div id="grafico"></div>
//     </>
//   )
// }
// var options = {
//   labels: ['Sociais', 'Cognitivos', 'Fisiologicos', 'Psicologicos'],
//   series: [75, 50, 90, 85],
//   stroke: {
//     show: true,
//     curve: 'smooth',
//     width: 2,
//     dashArray: 0,
//     lineCap: 'round'
//   },
//   chart: {
//     type: 'radialBar',
//     height: '260px',
//     width: '260px'
//   },
//   plotOptions: {
//     radialBar: {
//       startAngle: -90,
//       endAngle: 90
//     }
//   }
// }
// var chart = new ApexCharts(document.querySelector('#grafico'), options)

// chart.render()

// App.propTypes = {
//   data: PropTypes.array
// }
