import axios from 'axios'
import { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../Components/Login/auth.service'
const uuidv4 = require('uuid/v4')

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  const [pessoas, setPessoas] = useState([])
  useEffect(() => {
    if (parseInt(sessionStorage.IdUnidadeForm) === 0)
    {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/list/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeEdit
      )
      .then(response => {
        setPessoas(response.data)
      })
    }
    else {
      axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/pessoa/list/' +
        user[0].IdEmpresa +
        '/' +
        sessionStorage.IdUnidadeForm
      )
      .then(response => {
        setPessoas(response.data)
      })
    }

  }, [])

  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdGerencia: 0,
    IdEmpresa: user[0].IdEmpresa,
    IdUnidade: parseInt(sessionStorage.IdUnidadeForm) === 0 ? (sessionStorage.IdUnidadeEdit) : (sessionStorage.IdUnidadeForm),
    IdResponsavel: 0,
    gerDescricao: '',
    gerStatus: 1,
    gerObservacoes: '',
    gerCriadoEm: newDate,
    gerCriadoPor: user[0].pesEmail,
    gerAlteradoPor: user[0].pesEmail,
    gerAlteradoEm: newDate,
    gerCodigoGUID: uuidv4()
  })

  function handleInputChange(event) {
    if (event.target.name === 'gerStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/gerencia', campos)
      .then(response => {
        if (response.status === 200) {
          if (response.statusText === 'OK') {
            //console.log(response)
            if (response.data.message === 'Gerência cadastrada com sucesso!') {
              window.location.reload()
            } else {
              alert(response.data.message)
            }
          } else {
            //console.log(response.statusText)
          }
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Nova Gerência</Translate>
        </button>

        {modal && (
          <div className="modalGerencia">
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Nova Gerência</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    <Translate>Nova Gerência</Translate>:
                  </label>
                  <input
                    type="text"
                    id="fname"
                    style={{textTransform:'uppercase'}}
                    defaultValue={campos.gerDescricao}
                    name="gerDescricao"
                    onChange={handleInputChange}
                  ></input>
                  <label>
                    <Translate>Selecione um responsável</Translate>:
                  </label>
                  <select
                    name="IdResponsavel"
                    type="text"
                    defaultValue={campos.IdResponsavel}
                    id="fSexo1"
                    onChange={handleInputChange}
                  >
                    <option value="0">Selecione um responsável</option>
                    {pessoas
                      .sort((a, b) => (a.pesNome > b.pesNome ? 1 : -1))
                      .map(pessoa => (
                        <option value={pessoa.IdPessoa}>
                          {pessoa.pesNome}
                        </option>
                      ))}
                  </select>
                  <label>
                    <Translate>Observações</Translate>:
                  </label>
                  <textarea
                    type="text"
                    id="fname"
                    name="gerObservacoes"
                    rows={5}
                    style={{textTransform:'uppercase'}}
                    defaultValue={campos.gerObservacoes}
                    maxLength="500"
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          name="gerStatus"
                          type="checkbox"
                          defaultChecked={!!campos.gerStatus}
                          onChange={handleInputChange}
                        />
                        <span>
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button type="submit">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Translator>
    </>
  )
}
