import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import * as TypIcons from 'react-icons/ti'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import authService from '../../../Components/Login/auth.service'
import logologo from '../../../Components/logoIniciativaAzul@2x.png'
import {
  ContainerPage,
  TitleOfPage,
  TitlePage1,
  TitlePage2
} from '../../../Components/Main'
import padrao from '../../../Components/profile.png'
import imgOpen from '../../../Images/imgButtonEsquerda/imgOpen.svg'

import SideBarDireita from '../../../Components/SideBar-right/SideBarDireita'
import SidebarMobile from '../../../Components/SideBar-right/SidebarMobile'
import ModalEditar from './modal-tiposDeObservações/modalEditarGrupoDeQuestoes'
import Modal from './modal-tiposDeObservações/modalNovoGrupoDeQuestoes'

function TipoQualidadeDeObservacao() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  const [image, setImage] = useState('')
  var user = authService.getCurrentUser()
  var img = new Image()
  img.src = user[0].pesFoto
  // Se foi possível carregar a imagem
  img.onload = () => {
    setImage(user[0].pesFoto)
  }
  // Caso não seja possível
  img.onerror = () => {
    setImage(padrao)
  }
  const [modal, setModal] = useState(false)
  const toggleModal = () => {
    setModal(!modal)
  }
  const [modalOpen, setModalOpen] = useState(false)
  let id = useParams()
  sessionStorage.IdObservacaoTipo = id['IdObservacaoGrupo']
  const [questoes, setQuestoes] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  var user = authService.getCurrentUser()
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
        '/api/v1/observacaogrupo/listgrupos/' +
        id['IdObservacaoGrupo']
      )
      .then(response => {
        setQuestoes(response.data)
      })
  }, [])
  function handleToggleComplete(objetos = []) {
    setModalInfo(objetos)
    setModalOpen(true)
  }
  let history = useHistory()
  const [toogle, setToogle] = React.useState(false)

  return (
    <div className="displayInteract">
      {modalOpen && (
        <ModalEditar setOpenModal={setModalOpen} modalInfo={modalInfo} />
      )}
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ContainerPage>
          <div id="containerHead1">
            <div className="logo" />
          </div>
          <div id="containerHead2">
            <TitleOfPage>
              <TitlePage1>
                {' '}
                <Translate>DADOS CADASTRAIS / NOVO GRUPO</Translate>:
              </TitlePage1>
              <TitlePage2>
                <Translate>Grupo de Questões</Translate>{' '}
              </TitlePage2>
              {/* QUALIDADE DA OBSERVAÇÃO */}
            </TitleOfPage>
            <div id="containerPesquisaModal">
              <input
                className="fnamePadrao"
                placeholder="Pesquisar"
                type="text"
                id="fname7"
                name="fname"
                onChange={event => {
                  setSearchTerm(event.target.value)
                }}
              />
              <Modal />
            </div>
          </div>
          <button
            className="btnReturn"
            type="button"
            onClick={() => history.goBack()}
          >
            <TypIcons.TiArrowBackOutline />
          </button>
          <div className="tabelaPadrao">
            <table>
              <tr>
                <th>
                  <Translate>Grupo</Translate>
                </th>
                <th>
                  <Translate>Questões</Translate>
                </th>
                <th>
                  <Translate>Status</Translate>
                </th>
              </tr>
              {questoes
                .filter(val => {
                  if (searchTerm === '') {
                    return val
                  } else if (
                    val.ogrDescricao
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val
                  }
                })
                .map(questao => (
                  <tr>
                    {/* <td className="TQOCod">{questao.ogrNumero}</td> */}
                    <td style={{
                      textTransform: 'uppercase'
                    }} onClick={() => handleToggleComplete(questao)}>
                      <Translate>{questao.ogrDescricao}</Translate>
                    </td>
                    <td>
                      <Link to={'/TQOQuestoesReg/' + questao.IdObservacaoGrupo}>
                        <button className="metaAcess">
                          <i className="fas fa-bars"></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      {' '}
                      <input
                        type="checkbox"
                        defaultChecked={!!questao.ogrStatus}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </ContainerPage>
        <button
          className={sidebar ? 'abrirMenu' : 'fecharMenu'}
          onClick={showSidebar}
        >
          <img src={imgOpen} alt="" />
        </button>
        <nav className={sidebar ? 'mostrarFiltro' : 'esconderFiltro'}>
          <div className="cxProfile">
            <h6 className="titleProfile1">
              {' '}
              <Translate>Seja Muito Bem-vindo,</Translate>
            </h6>
            <h6 className="titleProfile2">{user[0].pesNome}</h6>
            <div className="alignFotoProfile">
              {image ? (
                <img className="logo1" src={image} alt="perfil" />
              ) : (
                <img src={padrao} alt="Profile" className="logo1" />
              )}
              {/* <Link to="/ViewProfile">
                <button
                  className="profile-profileEditor"
                  // onClick={() => handleToggleComplete(user[0])}
                >
                  <Translate>Ver perfil</Translate>
                </button>
              </Link> */}
            </div>
          </div>
          {/* <SideBarDireita /> */}

          <div className="cxLogo">
            {user[0].gruDescricao === 'Humanit' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrativo' ? (
              <SidebarMobile />
            ) : user[0].gruDescricao === 'Administrador' ? (
              <SidebarMobile />
            ) : (
              <div></div>
            )}
            <img className="logologo" src={logologo} alt="logo" />
          </div>
        </nav>
      </Translator>
    </div>
  )
}

export default TipoQualidadeDeObservacao
