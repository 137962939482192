import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuEmpresas/Modal-SubMenuEmpresas/AlterarRegistroEmpresas.css'
const uuidv4 = require('uuid/v4')

export const mask = (v = '') => {
  v = v.replace(/\D/g, '')

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return v
}

export default function Modal() {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  const [valor, setValor] = useState('')

  function handleChangeMask(event) {
    const { value } = event.target

    setValor(mask(value))
  }
  const [image, setImage] = useState('')
  const [endImg] = useState('./perfil.png')

  let newDate = new Date()

  const [campos, setCampos] = useState({
    IdEmpresa: '',
    empRazaoSocial: '',
    empLogo: '',
    empCNPJ: '',
    empEndereco: '',
    empStatus: 0,
    empObservacoes: '',
    empCriadoPor: user[0].pesEmail,
    empCriadoEm: newDate,
    empAlteradoPor: user[0].pesEmail,
    empAlteradoEm: newDate,
    empCodigoGUID: uuidv4(),
    empQuantidadeLicencas: 0
  })
  function handleInputChange(event) {
    if (event.target.name === 'empStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }
  const [imagens, setImagens] = useState({
    NomeImagem: '',
    Imagem: ''
  })

  function handleFormSubmit(event) {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', image)
    var reader = new FileReader()
    if (image) {
      reader.readAsDataURL(image)
      reader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result
        imagens['Imagem'] = srcData.split(',')[1]
        imagens['NomeImagem'] = uuidv4()
        setImagens(imagens)
      }
      axios
        .post(process.env.REACT_APP_API_URL + '/upload', imagens)
        .then(response => {
          campos['empLogo'] = response.data['url']
          //console.log(imagens)
          //console.log(response.data)
          setCampos(campos)
        })
    }
    axios
      .post(process.env.REACT_APP_API_URL + '/api/v1/empresa', campos)
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="pt"
        to={user[0].pesIdioma}
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <button onClick={toggleModal} className="buttonModalPadrao">
          <Translate>Novo Registro</Translate>
        </button>

        {modal && (
          <>
            <div className="overlay">
              <div className="modalPadrao-content">
                <div className="cxTituloPadrao">
                  <h1>
                    <Translate>Novo Registro</Translate>
                    <hr className="hrTitleModal" />
                  </h1>
                  <button onClick={toggleModal}>X</button>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="div-group2">
                    {image ? (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Logo2"
                        className="imgProfile"
                        width="150"
                        height="150"
                      />
                    ) : (
                      <img src={endImg} alt="Logo" className="imgProfile" />
                    )}
                    <input
                      type="file"
                      name="empLogo"
                      className="form-control-file-1"
                      id="empLogo"
                      accept=".gif,.jpg,.jpeg,.png"
                      onChange={function (event) {
                        setImage(event.target.files[0])
                      }}
                    />
                  </div>
                  <label>
                    <Translate>Razão Social</Translate>:
                  </label>
                  <input
                    placeholder="Razão Social"
                    type="text"
                    id="fname"
                    name="empRazaoSocial"
                    required
                    onChange={handleInputChange}
                  ></input>

                  {/* <label className="ttlCadARE2">
              <Translate>Selecione o status da empresa</Translate>:
              </label>
              <select
                className="fSelectTipEmpresa"
                type="text"
                required
                onChange={handleInputChange}
                name="empStatus"
              >
                <option value="">Selecione o Status da Empresa:</option>
                <option value="0">Inativo</option>
                <option value="1">Ativo</option>
              </select> */}
                  <div className="linhaDoisCadPessoa">
                    <label>
                      <Translate>Digite o CNPJ</Translate>:
                    </label>
                  </div>
                  <div className="linhaDoisCadPessoa">
                    <input
                      type="text"
                      id="fname"
                      value={valor}
                      maxLength="18"
                      name="empCNPJ"
                      required
                      onChange={function (event) {
                        handleChangeMask(event)
                        handleInputChange(event)
                      }}
                    ></input>
                  </div>

                  <div className="linhaDoisCadPessoa">
                    <label>
                      <Translate>Digite o Endereço</Translate>:
                    </label>
                  </div>

                  <div className="linhaDoisCadPessoa">
                    <input
                      placeholder="Endereço"
                      type="text"
                      id="fname"
                      name="empEndereco"
                      onChange={handleInputChange}
                    ></input>
                  </div>

                  <div className="linhaDoisCadPessoa">
                    <label>
                      <Translate>Quantidade de licenças</Translate>:
                    </label>
                  </div>

                  <div className="linhaDoisCadPessoa">
                    <input
                      type="number"
                      id="fname"
                      name="empQuantidadeLicencas"
                      min={1}
                      onChange={handleInputChange}
                    ></input>
                  </div>

                  <label>
                    <Translate>Observação</Translate>:
                  </label>
                  <textarea
                    placeholder="Observações"
                    type="text"
                    id="fname"
                    name="empObservacoes"
                    row="none"
                    maxLength="500"
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="caixaSalvarPadrao">
                    <div className="InputAuthorization4">
                      <label>
                        <input
                          name="empStatus"
                          type="checkbox"
                          defaultChecked={!!campos.empStatus}
                          onChange={handleInputChange}
                        />
                        <span>
                          <Translate>Status</Translate>
                        </span>
                      </label>
                    </div>
                    <button type="submit">
                      <Translate>Salvar Registro</Translate>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </Translator>
    </>
  )
}
