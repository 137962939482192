import axios from 'axios'
import { useState } from 'react'
import { Translate, Translator } from 'react-auto-translate'
import authService from '../../../../Components/Login/auth.service'
import '../../../../styles/pages/SubMenuPages/SubMenuTipoDeObservação/modal-tiposDeobservaçoes/modalNovoGrupoDeQuestoes.css'

export default function Modal({ setOpenModal, modalInfo }) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem('translations')) || {
        [key]: {}
      }
      existing[key] = { ...existing[key], [language]: value }
      localStorage.setItem('translations', JSON.stringify(existing))
    }
  }
  var user = authService.getCurrentUser()
  let newDate = new Date()
  const [campos, setCampos] = useState({
    IdObservacaoGrupo: modalInfo.IdObservacaoGrupo,
    IdObservacaoTipo: modalInfo.IdObservacaoTipo,
    IdEmpresa: modalInfo.IdEmpresa,
    ogrNumero: modalInfo.ogrNumero,
    ogrDescricao: modalInfo.ogrDescricao,
    ogrStatus: modalInfo.ogrStatus,
    ogrObservacoes: modalInfo.ogrObservacoes,
    ogrAlteradoPor: user[0].pesEmail,
    ogrAlteradoEm: newDate
  })
  function handleInputChange(event) {
    if (event.target.name === 'ogrStatus') {
      if (event.target.checked) {
        campos[event.target.name] = 1
      } else {
        campos[event.target.name] = 0
      }
    } else {
      campos[event.target.name] = event.target.value
    }
    setCampos(campos)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/api/v1/observacaogrupo/' +
          modalInfo.IdObservacaoGrupo,
        campos
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          alert(response.data.message)
        }
      })
  }

  return (
    <>
      <div className="overlay">
        <Translator
          cacheProvider={cacheProvider}
          from="pt"
          to={user[0].pesIdioma}
          googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
          <div className="modalPadrao-content">
            <div className="cxTituloPadrao">
              <h1>
                <Translate>Editar Grupo De Questões</Translate>
                <hr className="hrTitleModal" />
              </h1>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                X
              </button>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="linhaTipObs" style={{ width: '100%' }}>
                <tr>
                  <th>Cód:</th>
                  <th style={{ width: '70%' }}>
                    <Translate>Descrição</Translate>:
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      placeholder="Cód"
                      type="number"
                      id="fname"
                      name="ogrNumero"
                      min={1}
                      onChange={handleInputChange}
                      defaultValue={campos.ogrNumero}
                    ></input>
                  </td>
                  <td>
                    <input
                      placeholder="Descrição"
                      type="text"
                      id="fname"
                      name="ogrDescricao"
                      onChange={handleInputChange}
                      defaultValue={campos.ogrDescricao}
                    ></input>
                  </td>
                </tr>
              </div>

              <label>
                <Translate>Observações</Translate>:
              </label>
              <textarea
                type="text"
                id="fname"
                name="ogrObservacoes"
                row="none"
                maxLength="500"
                onChange={handleInputChange}
                defaultValue={campos.ogrObservacoes}
              ></textarea>
              <div className="caixaSalvarPadrao">
                <button typeof="submit">
                  <Translate>Salvar Registro</Translate>
                </button>
              </div>
            </form>
          </div>
        </Translator>
      </div>
    </>
  )
}
